import React from 'react';
import PropTypes from 'prop-types';


import { Label } from '@domain/components';

import classnames from 'classnames';
import styled from 'styled-components';
import { Colors, } from '@domain/theming';
import { isMobileOnly } from 'react-device-detect';
import { useScaling } from '@domain/hooks';

const StyledInputFile = styled.div`
  width: 100%;
  padding-top: 44.44%;
  margin-top: 15px;
  margin-bottom: 12px;
  position: relative;
  .input--file {
    display: none;
    z-index: 1;
  }
  .input--file__label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('/img/svg/uploader-border.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-clip: content-box;
    color: ${Colors.BLACK};
    text-align: center;
    width: 100%;
    height: 100%;
    font-weight: normal;
    cursor: pointer;

    .label {
      font-weight: normal;
      font-size: ${props => !isMobileOnly ? props.$scaling.textSize(14) : props.$scaling.textSizeMobile(14, props.vwWdith)}px;
      line-height: ${props => !isMobileOnly ? props.$scaling.textSize(18) : props.$scaling.textSizeMobile(18, props.vwWdith)}px;
      b {
        font-size: ${props => !isMobileOnly ? props.$scaling.textSize(16) : props.$scaling.textSizeMobile(16, props.vwWdith)}px;
        line-height: ${props => !isMobileOnly ? props.$scaling.textSize(20) : props.$scaling.textSizeMobile(20, props.vwWdith)}px;
        display: block;
        margin-bottom: 6px;
      }
    }
    .text {
      max-width: 83%;
    }
    svg.svg-border {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      rect {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        fill: none;
        stroke: ${Colors.BLACK};
        stroke-width: 1;
        stroke-dasharray: 8, 8;
      }
    }
  }
  .input-file--icon {
    color: ${Colors.WHITE};
    width: 48px;
    height: 48px;
    margin-bottom: 12px;
  }
  .screen-desktop & {
    .input-file--icon {
      width: ${props => props.$scaling.scaleUpTo4K(48)}px;
      height: ${props => props.$scaling.scaleUpTo4K(48)}px;
    }
  }

  .main__is-dragging & {
    .input--file__label {
      opacity: 0.8;
      z-index: 100;
    }
  }

  .theme-custom & {
    .input--file__label {
      .text {
        b {
          color: ${props => props.theme.colors.primary};
        }
      }
    }
    .input-file--icon {
      title + path:first-of-type {
        fill: ${props => props.theme.colors.primary};
      }
    }
    .subtitle-text {
      color: ${props => props.theme.colors.primary};
    }
  }


  .screen-ie &, .screen-edge & {
    .input--file__label {
      background-size: cover
    }
  }


  @media screen and (max-width: 360px) {
    padding-top: 66.66%;
    .input--file__label {
      background-image: url('/img/svg/uploader-border-sm.svg');
    }
  }
`;
function InputFile({ onChange, autoFocus, ref, inputRef, children, isDragging, multiple = true, accepts }) {
  const scaling = useScaling();
  const labelClassName = classnames('input--file__label', {
    'input--file__label--dragging': isDragging
  });

  return (
    <StyledInputFile className="input--file__container" $scaling={scaling}>
      <Label className={labelClassName} htmlFor="input-file">
        {React.Children.toArray(children)}
      </Label>
      <input
        className="input--file"
        type="file"
        name="photos"
        onChange={onChange}
        id="input-file"
        {...(accepts ? { accept: accepts } : {})}
        {...(multiple ? { multiple: true } : {})}
        autoFocus
        ref={inputRef}
      />
    </StyledInputFile>
  );
}

InputFile.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default InputFile;
