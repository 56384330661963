import { useState, useEffect } from 'react';

function useWindowSize() {
  const isClient = typeof window === 'object';

  // if (!isClient) {
  //   return undefined;
  // }

  const [windowSize, setWindowSize] = useState({
    vWidth: isClient ? window.innerWidth : undefined,
    vHeight: isClient ? window.innerHeight : undefined,
  });

  useEffect(() => {
    if (!isClient) {
      return false;
    }
    const getSize = () => {
      return {
        vWidth: window.innerWidth,
        vHeight: window.innerHeight
      };
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isClient]); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

export default useWindowSize;
