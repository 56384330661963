import React from 'react';

import styled from 'styled-components';
import { motion } from 'framer-motion'

import { useScaling, } from '@domain/hooks';
import { Colors, } from '@domain/theming';

import { AnchorButton, ContainerSP, HeadingSP, ParagraphSP, TickSVG, } from '..';
import UseIcon from '../UseIcon';
import classNames from 'classnames';


const CardContainer = styled(motion(ContainerSP)).attrs((props) => ({
}))`
  position:relative;
  max-width: ${props => props.$scaling.scaleUpTo4K(580)}px;
  margin: 0 auto;
  padding-left: ${props => props.$scaling.scaleUpTo4K(30)}px;
  padding-right: ${props => props.$scaling.scaleUpTo4K(30)}px;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;
  opacity: .85;
  color: ${Colors.WHITE};
  height: 0px;
  will-change: transform, opacity;
  > p,
  > ul,
  > h3 {
    max-width: 100%;
  }
  ul {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
    padding-left: 18px;
    font-size: 13px;
  }
  h3, h4, h5 {
    color: ${Colors.WHITE}
  }

  @media screen and (max-width: 900px) {
    + .card {
      margin-top: 40px;
      margin-bottom:30px;
    }
  }
  @media screen and (max-width: 420px) {
    &.card.card-form {
      padding: 0;
    }
  }
`;



const StyledUseIcon = styled(UseIcon)`
  transition: .25s ease-out;
  transform: rotate(0deg);
  .open & {
    transform: rotate(-180deg) translateY(3px);
  }
  .screen-ie & {
    position: absolute;
    top: 0px;
    right: 24px;
    height: 100%;
  }
`;

const StyledList = styled.ul`
  list-style: none;
  li {
    display:flex;
    padding: ${props => props.$scaling.scaleUpTo4K(5)}px;
    justify-content: flex-start;
    svg {
      flex: 0 0 auto
      margin-right: ${props => props.$scaling.scaleUpTo4K(10)}px;
    }
    p {
      margin: 0;
    }
  }
`;


const ToogleCard = ({ card, isOpen, toggleIsOpen }) => {

  const classNameList = classNames(
    'main__button--redirect',
    'black-and-white',
    card.className || 'toggle-card__button',
    { 'open': isOpen },
  );
  const scaling = useScaling();
  const { paragraphs, title, list, 'closing-paragraphs': closingParagraphs, button } = card
  const generateList = list ? list.map((item, i) => {
    return (
      <li key={i}>
        {item.icon ? <TickSVG width={`${scaling.scaleUpTo4K(16)}px`} height={`${scaling.scaleUpTo4K(16)}px`} color={Colors.WHITE} /> : <UseIcon name={'minus'} height={20} width={20} className={'list-icon'} />}
        <ParagraphSP> {item.text}</ParagraphSP>
      </li>
    )
  }) : null

  const generateParagraphs = paragraphs ? paragraphs.map((item, i) => {
    return (
      <ParagraphSP key={i}>
        {item.text}
      </ParagraphSP>
    )
  }) : null

  const generateFinalParagraphs = closingParagraphs ? closingParagraphs.map((item, i) => {
    return (
      <ParagraphSP key={i}>
        {item.text}
      </ParagraphSP>
    )
  }) : null

  const variants = {
    open: { height: 'auto', },
    closed: { height: 0, overflow: 'hidden' },
  }

  return (
    <>
      <AnchorButton
        className={classNameList}
        dynamicFontSize={true}
        onClick={toggleIsOpen}
        isThemed={true} >{button}
        <StyledUseIcon
          name="arrow-down"
          className="button__icon"
          width={26}
          height={26}
        />
      </AnchorButton>
      <CardContainer
        $scaling={scaling}
        animate={isOpen ? "open" : "closed"}
        variants={variants}
        initial={false}
        transition={{ duration: 0.5 }}
        paddingBottom={isOpen ? scaling.scaleUpTo4K(60) + 'px' : '0'}
      >
        {title && <HeadingSP
          level={3}
          textAlign="center"
          marginBottom="30px"
        >
          {title}
        </HeadingSP>}
        {generateParagraphs && <>{generateParagraphs}</>}
        {generateList && <StyledList $scaling={scaling}>
          {generateList}
        </StyledList>}
        {generateFinalParagraphs && <>{generateFinalParagraphs}</>}
      </CardContainer>
    </>
  )
}

export default ToogleCard
