import { FAQ_VIEWED, FAQ_SEEN_VALUE_FOUND } from '@domain/action-types';

const initialFaqSeen = false;

const faqSeen = (state = initialFaqSeen, { type, faqSeen }) => {
  switch (type) {
    case FAQ_VIEWED:
      return true;
    case FAQ_SEEN_VALUE_FOUND:
      return faqSeen;
    default:
      return state;
  }
};

export default faqSeen;
