const causeDamageCategories = {
  Aanrijding: [
    'Aanrijding met voertuig',
    'Aanrijding met voetganger /fiets',
    'Aanrijding met dier',
    'Aanrijding met (vast) object',
  ],
  Anders: [
    'Inbraak',
    'Vandalisme',
    'Hagel/storm',
    'Brand'
  ],
};

const causeDamageCategoriesDropdown = {
  Aanrijding: [
    'Aanrijding met dieren', 'Aanrijding met vast object', 'Aanrijding met voertuig', 'Aanrijding met voetganger/fietser', 'Aanrijding met wegmeubilair'
  ],
  Anders: [
    'Brand', 'Hagel', 'Inbraak', 'Molest/vandalisme', 'Omslaan', 'Slippen', 'Storm', 'Verkeer'
  ],
};

const causeDamageCategoriesCombinedDropdown = [
  'Aanrijding met dieren', 'Aanrijding met vast object', 'Aanrijding met voertuig', 'Aanrijding met voetganger/fietser', 'Aanrijding met wegmeubilair', 'Brand', 'Hagel', 'Inbraak', 'Molest/vandalisme', 'Omslaan', 'Slippen', 'Storm'
]

const causeDamageCategoriesDropdownBovemij = {
  Aanrijding: [
    'Aanrijding met voertuig', 'Aanrijding met voetganger/fiets', 'Aanrijding met dier', 'Aanrijding met (vast) object', 'Aanrijding met wegmeubilair'
  ],
  Anders: [
    'Inbraak', 'Hagel/storm', 'Brand', 'Molest/vandalisme', 'Diefstal'
  ],
};

const causeDamageCategoriesDropdownIntakePlus = {
  Aanrijding: [
    'Aanrijding met dieren', 'Aanrijding met vast object', 'Aanrijding met voertuig', 'Aanrijding met voetganger/fietser', 'Aanrijding met wegmeubilair'
  ],
  Anders: [
    'Brand', 'Hagel', 'Inbraak', 'Molest/vandalisme', 'Omslaan', 'Slippen', 'Storm', 'Verkeer', 'Steenslag', 'Diefstal'
  ],
};

const causeDamageCategoriesGlass = {
  Steenslag: [
    'Steenslag'
  ],
  Overig: ['Aanrijding', 'Doorscheuren eerdere reparatie', 'Hagel', 'Inbraak', 'Molest en/of vandalisme', 'Storm', 'Overig'],
};


export { causeDamageCategories, causeDamageCategoriesDropdown, causeDamageCategoriesGlass, causeDamageCategoriesCombinedDropdown, causeDamageCategoriesDropdownBovemij, causeDamageCategoriesDropdownIntakePlus }
