import React, { useState, useMemo, memo, useCallback } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import 'moment/locale/nl';
import nlLocale from 'date-fns/locale/nl';
import { isMobileOnly } from 'react-device-detect';
import { Colors } from '@domain/theming';
import { useScaling, useInputBlur } from '@domain/hooks';


const useStyles = makeStyles(() => ({
  textField: {
    //main container
    width: '100%',
    margin: props => props.dynamicFontSize ? props.$scaling.scaleUpTo4K(20) + 'px 0' : '20px 0',
    paddingTop: props => props.dynamicFontSize ? props.$scaling.scaleUpTo4K(22) + 'px' : '22px',
    height: 'auto',
  },
  inputRoot: {
    //input container
    fontFamily: 'Gabriel Sans',
    borderColor: Colors.BLACK,
    color: Colors.BLACK,
  },
  inputInput: {
    textAlign: 'center',
    padding: isMobileOnly
      ? '0 0 0 0' : '0 0 0 48px',
    fontSize: props =>
      props.dynamicFontSize ?
        (isMobileOnly
          ? props.$scaling.textSizeMobile(32)
          : props.$scaling.textSize(32)) + 'px' :
        '32px',
    fontWeight: 'bold',
    lineHeight: props =>
      props.dynamicFontSize ?
        (isMobileOnly
          ? props.$scaling.textSizeMobile(36)
          : props.$scaling.textSize(36)) + 'px' :
        '36px',
    height: props =>
      props.dynamicFontSize ?
        (isMobileOnly
          ? props.$scaling.textSizeMobile(48)
          : props.$scaling.textSize(48)) + 'px' :
        '48px',
    '&::placeholder': {
      fontSize: props =>
        props.dynamicFontSize ?
          (isMobileOnly
            ? props.$scaling.textSizeMobile(16)
            : props.$scaling.textSize(16)) + 'px' :
          '16px',
    }
  },
  inputError: {
    color: Colors.RED,
  },
  inputLabelRoot: {
    height: props =>
      props.dynamicFontSize ?
        (isMobileOnly
          ? props.$scaling.textSizeMobile(48)
          : props.$scaling.textSize(48)) + 'px' :
        '48px',
    maxWidth: '100%',
    top: 'calc(50% + 20px)',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // padding: '6px 0 7px',
    color: Colors.BLACK,
    fontFamily: 'inherit',
    fontSize: props =>
      props.dynamicFontSize ?
        (isMobileOnly
          ? props.$scaling.textSizeMobile(32)
          : props.$scaling.textSize(32)) + 'px' :
        '32px',
    fontWeight: 'bold',
    lineHeight: props =>
      props.dynamicFontSize ?
        (isMobileOnly
          ? props.$scaling.textSizeMobile(36)
          : props.$scaling.textSize(36)) + 'px' :
        '36px',
    letterSpacing: '0.03125em',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    transition: '200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
    '&$focused': {
      color: Colors.BLACK,
      fontWeight: 'bold',
    },
    '&$inputLabelError': {
      color: Colors.BLACK,
    },
    '&+$inputRoot': {
      marginTop: 0,
    },
  },
  inputLabelShrink: {
    top: 0,
    left: 0,
    transform: 'translate(0, 0) scale(1)',
    color: Colors.BLACK,
    lineHeight: '14px',
    letterSpacing: '0.0625em',
    fontSize: props =>
      props.dynamicFontSize ?
        (isMobileOnly
          ? props.$scaling.textSizeMobile(16)
          : props.$scaling.textSize(16)) + 'px' :
        '16px',
    // transition: '200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  },
  inputUnderline: {
    '&$inputError': {
      '&:before': {
        borderColor: Colors.RED,
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: `1px solid ${Colors.RED}`,
      },
      '&:after': {
        borderBottom: `2px solid ${Colors.RED}`,
      },
    },
    '&:before': {
      borderColor: Colors.BLACK,
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: `1px solid ${Colors.BLACK}`,
    },
    '&:after': {
      borderBottom: `2px solid ${Colors.BLACK}`,
    },
  },
  inputLabelClasses: {},
  inputDisabled: {},
  inputLabelDisabled: {},
  focused: {},
  inputLabelError: {},
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
}));


DateFnsUtils.prototype.getDatePickerHeaderText = function (date) {
  const headerDateFormat = 'dd. D MMM'; // wo. 6 nov
  return moment(date)
    .locale('nl')
    .format(headerDateFormat);
};

const DatePickerSP = ({ dynamicFontSize, label, onBlur, onChange, value, error, disabled = false }) => {
  const [inputEl, setInputEl] = useState(null);

  useInputBlur(inputEl, onBlur);

  const setInputRef = useCallback((el) => setInputEl(el), []);

  const scaling = useScaling();
  const classes = useStyles({ dynamicFontSize, value, scaling });

  const inputProps = useMemo(
    () => ({
      classes: {
        root: classes.inputRoot,
        underline: classes.inputUnderline,
        input: classes.inputInput,
        disabled: classes.inputDisabled,
        error: classes.inputError,
      },
      placeholder: 'dd-mm-jjjj',
    }),
    [classes],
  );

  const inputLabelProps = useMemo(
    () => ({
      htmlFor: 'date-picker-inline',
      classes: {
        root: classes.inputLabelRoot,
        shrink: classes.inputLabelShrink,
        formControl: classes.inputLabelFormControl,
        focused: classes.focused,
        disabled: classes.inputLabelDisabled,
        error: classes.inputLabelError,
      },
      shrink: true,
    }),
    [classes],
  );

  const keyboardButtonProps = useMemo(
    () => ({
      'aria-label': 'change date',
    }),
    [],
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nlLocale}>
      <KeyboardDatePicker
        disabled={disabled}
        cancelLabel="Annuleren"
        error={error}
        inputRef={setInputRef}
        className={classes.textField}
        disableFuture
        variant="dialog"
        format="dd-MM-yyyy"
        invalidDateMessage=""
        margin="normal"
        maxDateMessage="De datum mag niet in de toekomst liggen."
        id="date-picker-inline"
        label={label}
        value={value || null}
        onChange={onChange}
        KeyboardButtonProps={keyboardButtonProps}
        required
        InputProps={inputProps}
        InputLabelProps={inputLabelProps}
      />
    </MuiPickersUtilsProvider>
  );
};

export default memo(DatePickerSP);
