// NPM imports.
import React, { useEffect, } from 'react';
import { useSelector } from 'react-redux';

// Domain imports.
import {
  BackgroundVideo,
  Main,
  GridContainer,
  GridColumn,
  Legend,
  Heading,
  Loader, NavBar, CompanyLogo
} from '@domain/components';
import { useSaveIncident } from '@domain/hooks';

import styled from 'styled-components';
import { chooseLogoToBeDisplayed, browserStorage } from '@domain/helpers';
import { companyNames, proccessType } from '@domain/constants';


const StyledMain = styled(Main)`
  .grid-container {
    .checkinout__question--title {
      flex: 1 1 0px;
      max-width: 480px;
      .legend {
        font-size: 16px;
        line-height: 20px;
      }
      .h1 {
        font-size: 48px;
        line-height: 56px;
      }
      p {
        font-size: 14px;
        line-height: 18px;
      }
    }
    .screen-tablet & {
      .checkinout__question--title {
        justify-content: center;
      }
    }
    .checkinout__question--question {
      flex: 1 1 0px;
      .screen-desktop:not(.screen-tablet) & {
        max-width: 540px;
        margin-left: auto;
        padding-left: 60px;
      }
      .checkbox {
        margin-bottom: 16px;
      }
    }
    .checkinout__question--footer {
      .screen-desktop:not(.screen-tablet) & {
        position:absolute;
        padding: 40px;
        bottom: 0;
        right: 0;
        flex: 1 1 0px;
      }
    }
  }
  @media (max-width: 1028px) {
    .screen-desktop:not(.screen-tablet) & {
      .grid-container {
        .checkinout__question--title {
          text-align: center;
          margin: auto;
        }
        .checkinout__question--question {
          margin: auto;
          padding: 60px 0 0 0;
        }
        .checkinout__question--footer {
          position: relative;
          padding: 0;
          width: 100;
        }
      }
    }
  }
  @media (max-width: 580px) {
    .screen-desktop:not(.screen-tablet) & {
      .grid-container {
        .checkinout__question--footer {
          button {
            width: 100%;
          }
        }
      }
    }
  }
`;

const StyledNavBar = styled(NavBar)`
  .main__checkinout--start & {
    border: none;
  }
  img {
    flex: 1 0 auto;
  }
`;


function Success({ history, match }) {

  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);

  const saveIncident = useSaveIncident();
  const portalStatus = 'portalStatus'
  // To tell the core platform the check-out was completed
  useEffect(() => {
    if (incident) {
      saveIncident({
        ...incident,
        [portalStatus]: 'Done',
      });
      const storageList = Object.keys(localStorage);
      browserStorage.clearLocalStorage([...storageList], ['consentCode', 'bannerOpen', 'uuid']);
    }

  }, [incident]);


  if (!incident || !insurer) {
    return <Loader />;
  }
  const isVIP = insurer.objectType === proccessType.Casco && (insurer.brand === companyNames.VIP || !insurer.brand)
  const isThemed = !!insurer && insurer.brand !== companyNames.VIP && insurer.objectType !== proccessType.DI;
  const isNotObjectTypeDefined = !insurer.objectType

  const insurerName = insurer.brandName || 'uw verzekeraar';
  const insurerLogo = insurer.logoURL || '';

  const customCompanyLogo = {
    align: 'center',
    width: '120',
    height: '64',
    src: insurerLogo,
    alt: insurerName,
  };

  const companyLogo = {
    ...chooseLogoToBeDisplayed(insurer),
    className: isVIP ? 'vip-logo' : '',
    height: '60',
    // width: 'auto',
  };

  const chooseMiddleLogo = isNotObjectTypeDefined
    ? <CompanyLogo {...customCompanyLogo} />
    : <CompanyLogo {...companyLogo} />

  return (
    <React.Fragment>
      <StyledMain>
        <StyledNavBar>
          {chooseMiddleLogo}
        </StyledNavBar>
        <GridContainer main={true}>
          <GridColumn className="checkinout__question--title" justify="flex-start">
            <Heading level={'1'} className="checkout--title">Check-out voltooid.</Heading>
            <Legend>U kunt deze pagina sluiten.</Legend>
          </GridColumn>
          <GridColumn className="grid__column--footer checkinout__question--footer">
          </GridColumn>
        </GridContainer>
      </StyledMain>
      <BackgroundVideo
        autoPlay={true}
        img={!isVIP || isThemed}
        imgFileName="BSSP03.jpg"
        loop={true}
        type="video/mp4"
        videoFileName="DEF2.mp4"
      />
    </React.Fragment>
  )
}

export default Success;
