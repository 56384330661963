import React, { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';

import {
  BackgroundVideo,
  CompanyLogo,
  Container,
  HeaderGroup,
  Loader,
  Main,
  NavBar,
  Text,
  TimeLineBlock,
  Section,
  Heading,
  UseIcon,
  Button,
} from '@domain/components';
import { companyNames, proccessType } from '@domain/constants';
import { useSaveIncidentFactory, useScaling } from '@domain/hooks';
import { isMobileOnly } from 'react-device-detect';
import { browserStorage, checkIsFeedback, chooseLogoSize, chooseLogoToBeDisplayed, generatePageContent } from '@domain/helpers';
import { Colors } from '@domain/theming';
import { detectContentType } from '@domain/content';
import { useQuestionnaire } from '@domain/providers';
const StyledMain = styled(Main)`
  // content that came from both ./end.scss
  // and ../../../shared/pages/TimelinePage/timeline-page.scss
  // (and one the .navbar stuff from navbar.scss)
  .navbar {
    .company-logo--left {
      max-width: 120px;
    }
    .left-side-logo {
      flex: 1 0 0;
    }
  }
  .page-wizard__content {
    display: flex;
    flex-direction: column;
    padding: 32px 32px 0;
  }
  .page-wizard__heading {
    flex: 1 1 auto;
    .h1 {
      margin: 30px 0 12px;
      word-break: break-word;
    }
  }
  .page-wizard__info {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    .h4 {
    }
    .button {
      margin-top: 15px;
      width: 100%;
      &__contact {
        height: auto;
        text-align: left;
        padding: 24px;
        .header-group {
          margin-right: auto;
        }
        span {
          text-transform: initial;
          font-weight: normal;
        }
        .h5 {
          margin-bottom: 15px;
        }
      }
    }
  }
  .page-wizard__timeline {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 50px 0;
  }
  .page-wizard__button {
    padding: 32px;
    justify-content: stretch;
    button {
      width: 100%;
    }
  }
  
  .recovery__heading {
    .screen-mobile & {
      padding-bottom: 42px;
    }
    .h4 {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .recovery__info--button {
    display:flex;
    flex-direction: column;
    margin-top: 30px;
    .h4 {
      margin-top: 15px;
    }
    .button {
      margin-top: 15px;
      width: 100%;
      &__contact {
        height: auto;
        text-align: left;
        padding: 24px;
        .button-wrapper {
          justify-content: space-between;
        }
        .header-group {
          margin-right: auto;
        }
        span {
          text-transform: initial;
          font-weight: normal;
        }
        .h5 {
          margin-bottom: 15px;
        }
      }
    }
  }
  .recovery__bodyshop-name {
    .screen-mobile & {
      margin-top: 12px;
    }
  }
  .recovery__bodyshop-description {
    margin-bottom: 60px;
  }
  .recovery__timeline {
    .timeline__item {
      // font-weight: normal;
      // padding-bottom: 5.6vh;
      &:before {
        background-color: ${Colors.WHITE};
        opacity: 1;
      }
      &:last-child {
        padding-bottom: 20px;
      }
    }
    .timeline__item--muted {
      color: ${Colors.WHITE};
      opacity: .6;
      &:before {
        background-color: ${Colors.WHITE};
      }
      .timeline__icon {
        background: ${Colors.WHITE};
        color: ${Colors.GREY_DARKER};
      }
    }
    .contact-person-picture {
      width: ${props => props.$scaling.scaleUpTo4K(32)}px;
      height: ${props => props.$scaling.scaleUpTo4K(32)}px;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 8px;
      object-fit: cover;
      object-position: 0 10%;
    }
  }


  .screen-desktop & {
    .page-wizard__content {
      flex-direction: row;
      padding: ${props => props.$scaling.scaleUpTo4K(22)}px ${props => props.$scaling.scaleUpTo4K(40)}px 0;
      align-items: center;
    }
    .page-wizard__heading {
      padding-left: ${props => props.$scaling.scaleUpTo4K(80)}px;
      &.no-coverage {
        padding-left: 0;
      }
      .h1 {
        margin-bottom: 0;
      }
    }
    .page-wizard__text {
      display: block;
      margin-top: ${props => props.$scaling.scaleUpTo4K(20)}px;
    }
    .page-wizard__info {
      flex: 1 1 auto;
      width: 50%;
      height: 50%;
      justify-content: flex-start;
    }
    .page-wizard__timeline {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      padding: 0;
      padding-right: ${props => props.$scaling.scaleUpTo4K(120)}px;
      padding-left: ${props => props.$scaling.scaleUpTo4K(40)}px;
      .timeline {
        // margin-top: auto;
        // padding-top: ${props => props.$scaling.scaleUpTo4K(50)}px;
      }
      .timeline--heading {
        width: 100%;
        max-width: ${props => props.$scaling.scaleUpTo4K(350)}px;
        display: block;
      }
    }
    .page-wizard__button {
      padding: ${props => props.$scaling.scaleUpTo4K(40)}px;
      padding-top: 0;
      button {
        margin-left: auto;
      }
    }
    
    .recovery__content {
      padding-bottom: ${props => props.$scaling.scaleUpTo4K(82)}px;
    }
    .recovery__bodyshop-name {
      margin: ${props => props.$scaling.scaleUpTo4K(15)}px 0 ${props => props.$scaling.scaleUpTo4K(20)}px 0;
    }
    .recovery__bodyshop-description {
      margin-top: ${props => props.$scaling.scaleUpTo4K(20)}px;
      margin-bottom: ${props => props.$scaling.scaleUpTo4K(40)}px;
      .h4 {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(2)}px;
      }
    }
    .recovery__info-heading {
      margin-top: ${props => props.$scaling.scaleUpTo4K(20)}px;
        .no-coverage {
          text-align:center;
        }
    }
    .recovery__info--button {
      flex: 1 1 auto;
      width: 50%;
      height: 50%;
      justify-content: flex-start;
      > div {
        max-width: ${props => props.$scaling.scaleUpTo4K(350)}px;
      }
      .button {
        &__contact {
          padding: ${props => props.$scaling.scaleUpTo4K(24)}px;
          .header-group {
          }
          .h5 {
            margin-bottom: ${props => props.$scaling.scaleUpTo4K(15)}px;
          }
        }
      }
    }
    .page-wizard__heading {
      flex: 2 0 0;
      display: flex;
      flex-direction:column;
      justify-content: center;
    }
    .recovery__timeline {
      flex: 1 0 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .timeline {
      }
      .h4 {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(10)}px;
        width: 100%;
        max-width: ${props => props.$scaling.scaleUpTo4K(350)}px;
      }
    }
  }
  
  .theme-custom & {
    .recovery__heading {
      color: ${Colors.WHITE}
      .h4 {
        color: ${props => props.theme.colors.primary};
      }
    }
    .recovery__timeline {
      .timeline__item {
        color: ${Colors.WHITE};
        &:before {
          background: ${Colors.WHITE};
        }
        .timeline__icon {
          color: ${Colors.WHITE};
          background: ${props => props.theme.colors.primary};
        }
      }
      .h4 {
        color: ${props => props.theme.colors.primary};
      }
    }
  }

  .screen-ie & {
    .button__contact {
      max-height: none;
    }
  }

  @media screen and (max-width: 1263px) {
    .screen-desktop & {
      .page-wizard__heading {
        padding-left: 20px;
      }
      .page-wizard__timeline {
        padding-right: 40px;
      }
    }
  }

  @media screen and (max-width: 1028px) {
    .rating {
      margin-top: 30px;
    }
    .screen-desktop & {
      .page-wizard__content {
        flex-direction: column;
        justify-content: flex-start;
      }
      .page-wizard__heading {
        text-align: center;
        padding: 0;
      }
      .page-wizard__info {
        width: 100%;
        .button {
          max-width: 580px;
        }
      }
      .page-wizard__button {
        padding-top: 50px;
      }
      .page-wizard__text {
        width: auto;
        margin: auto;
      }
      .page-wizard__timeline {
        padding: 50px 0;
        .timeline {
        }
      }
      
      .recovery__bodyshop-name {
        margin: 15px auto 20px;
      }
      .recovery__info--button {
        width: 100%;
        text-align: center;
        >div {
          max-width: none;
        }
        .button {
          max-width: 580px;
          margin: 15px auto 0;
          &__contact {
            .header-group {
            }
          }
        }
      }
      .recovery__bodyshop-description {
        margin: 20px auto 0;
      }
    }
  }

  @media screen and (max-width: 580px) {
    .screen-desktop & {
      .page-wizard__button {
        button {
          width: 100%;
        }
      }
    }
  }
    
  .screen-ie & {
    .page-wizard__content {
      flex: 1 0 auto;
      height: auto;
      min-height: 454px;
    }
    .page-wizard__timeline {
    }
    .page-wizard__button {
      flex: 0 0 auto;
      button {
        // margin-top: 40%;
      }
    }
    
    .page-wizard__info {
      .button__contact {
        max-height: none;
      }
    }

    .recovery__timeline-and-button {
      display: block;
    }
  
    .recovery__timeline-and-button {
      .timeline {
        margin-top: 33%;
      }
    }
  }
`;


function ThankYou({ match }) {

  const scaling = useScaling();
  const hasUpdated = useRef(false);
  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer)
  const bodyshop = useSelector(state => state.bodyshop);

  const { questionnaire } = useQuestionnaire();

  const themeContext = useContext(ThemeContext);

  const isFeedback = checkIsFeedback(incident)
  const isVIP = insurer.objectType === proccessType.Casco && (insurer.brand === companyNames.VIP || !insurer.brand)
  const isThemed = !!insurer && insurer.brand !== companyNames.VIP && insurer.objectType !== proccessType.DI;

  const bodyshopName = bodyshop ? bodyshop.name || bodyshop.companyName : '';
  const bodyshopFound = !!bodyshopName;
  const bodyshopId = incident.carRefinisherUUID || incident.outletUUID || incident.bodyshopID

  const currentPath = match.path;
  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined

  const saveIncident = useSaveIncidentFactory(incident, saveBehavior, 'thank-you');

  const checkForOutlet = () => {
    const bs = incident && incident.outletUUID && bodyshop && bodyshop.outlets ? bodyshop.outlets.find(outlet => outlet.uuid === incident.outletUUID) : {}
    if (!!bs) {
      return { ...bodyshop, ...bs }
    }
    return bodyshop
  }


  const finalBodyshop = checkForOutlet()

  useEffect(() => {

    const storageList = Object.keys(localStorage);
    browserStorage.clearLocalStorage([...storageList], ['consentCode', 'bannerOpen', 'uuid']);

    if (isFeedback) {
      if (hasUpdated.current) {
        return;
      }
      hasUpdated.current = true;
    } else if ((incident.status === 'process_aborted' && incident.cancelReason === 'VIP_not_chosen') ||
      (incident.status === 'damage_cancelled' && incident.cancelReason === 'inactivity_from_the_customer')) {
      hasUpdated.current = true;
      const progressStr = 'thank_you'
      saveIncident({ ...incident, portalStatus: 'Done', progressStr });
    } else {
      if (incident.portalStatus !== 'Done') {
        if (hasUpdated.current || incident.status !== '_new') {
          return;
        }
        hasUpdated.current = true;
        const progressStr = 'thank_you'
        saveIncident({ ...incident, portalStatus: 'Done', progressStr });
      }
    }
  }, [incident, isFeedback, questionnaire, saveIncident]);

  if (!questionnaire || !questionnaire.length || !insurer || !incident || (!!incident && bodyshopId && !bodyshopFound)) {
    return <Loader />;
  }

  const contentType = detectContentType(insurer)
  const pageName = questionnaire.find(page => page.path === currentPath).name
  const content = generatePageContent(contentType, insurer, incident, false, isMobileOnly, pageName, bodyshop)

  const title = content["title"]
  const text = content["text"]
  const feedbackText = content["feedback-text"]
  const endText = content["end-text"]
  const showLeftLogo = content["show-left-logo"]
  const companyMiddleLogo = content["company-middle-logo"]
  const showTimeline = content["show-timeline"]
  const timelineTitle = content["timeline-title"]
  const timeline = content["timeline"]
  const glassTimeline = content["timeline-glass"]
  const showBodyshopBlock = content["show-bodyshop-block"]
  const bodyshopBlockTitle = content["bodyshop-block-title"]
  const glassTitle = content["glass-title"]
  const glassText = content["glass-text"]
  const glassSecondText = content["glass-second-text"]

  const activeItem = timeline ? timeline.findIndex(item => item.active) : null

  const timelineIcon = (item, i) => ({
    name: item.icon,
    className: 'timeline__item__icon',
    timeline: true,
    muted: i > activeItem
  });

  const companyLogo = {
    align: companyMiddleLogo ? 'center' : 'left',
    width: '120',
    height: '60',
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
  };

  const middleLogo = {
    ...chooseLogoToBeDisplayed(insurer),
    className: isVIP ? 'vip-logo' : '',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  };

  const main = {
    className: 'main__end',
    $scaling: scaling,
  };

  const handleOnButtonClick = () => {
    const protocol = finalBodyshop.website ? finalBodyshop.website.startsWith('http') : null
    if (protocol) {
      window.open(finalBodyshop.website, '_blank');
    }
    window.open(`http://${finalBodyshop.website}`, '_blank');
  };

  const button = {
    onClick: handleOnButtonClick,
    className: 'button__contact',
    theme: 'secondary',
    justify: 'space-between',
    disabled: !finalBodyshop || (finalBodyshop && !finalBodyshop.website)

  };

  if (incident.coverage === 'declined') {
    return (
      <React.Fragment>
        <StyledMain {...main}>
          <NavBar className="navbar-end" isVIP={isVIP}>
            {showLeftLogo ? <Container className="left-side-logo"><CompanyLogo {...companyLogo} /></Container> : <Container className="empty-div" />}
            {companyMiddleLogo ? <CompanyLogo {...companyLogo} /> : <CompanyLogo {...middleLogo} />}
            <Container className="empty-div" />
          </NavBar>
          <Section className="page-wizard__content recovery__content">
            <Container className="page-wizard__heading recovery__heading no-coverage">
              <HeaderGroup>
                <Container className="recovery__info-heading no-coverage">
                  <HeaderGroup className="no-coverage">
                    <Heading level="3" >We hebben uw dekking niet kunnen bepalen.</Heading>
                    <Heading level="3" >Uw verzekeraar neemt contact met u op.</Heading>
                    <Heading level="3" >
                      U kunt ook zelf contact opnemen: 088 185 5555.</Heading>
                  </HeaderGroup>
                </Container>
              </HeaderGroup>
            </Container>
          </Section>
        </StyledMain>
        <BackgroundVideo
          type="video/mp4"
          loop={true}
          autoPlay={true}
          videoFileName="DEF2.mp4"
          img={!isVIP || isThemed}
          imgFileName="BSSP03.jpg"
        />
      </React.Fragment>
    );

  }

  if (incident.damageType === 'Glas') {
    return (
      <React.Fragment>
        <StyledMain {...main}>
          <NavBar className="navbar-end" isVIP={isVIP}>
            {showLeftLogo ? <Container className="left-side-logo"><CompanyLogo {...companyLogo} /></Container> : <Container className="empty-div" />}
            {companyMiddleLogo ? <CompanyLogo {...companyLogo} /> : <CompanyLogo {...middleLogo} />}
            <Container className="empty-div" />
          </NavBar>
          <Section className="page-wizard__content recovery__content">
            <Container className="page-wizard__heading recovery__heading">
              <HeaderGroup>
                {glassTitle && <Heading level="1" >{glassTitle}</Heading>}
                <Container className="recovery__info-heading">
                  <HeaderGroup>
                    {glassText && <Text className="recovery__bodyshop-text">{glassText}</Text>}
                    {glassSecondText && <Text className="recovery__bodyshop-text">{glassSecondText}</Text>}
                    {endText && <Text className="recovery__bodyshop-text"><br />{endText}</Text>}
                  </HeaderGroup>
                </Container>
              </HeaderGroup>
            </Container>
            <Container className="page-wizard__timeline recovery__timeline">
              {(showTimeline && glassTimeline) &&
                <>
                  {timelineTitle && <Heading level={'4'} className={"timeline--heading"}>{timelineTitle}</Heading>}
                  <TimeLineBlock timeline={glassTimeline} activeItem={activeItem} timelineIcon={timelineIcon} bodyshop={finalBodyshop} />
                </>}
            </Container>
          </Section>
        </StyledMain>
        <BackgroundVideo
          type="video/mp4"
          loop={true}
          autoPlay={true}
          videoFileName="DEF2.mp4"
          img={!isVIP || isThemed}
          imgFileName="BSSP03.jpg"
        />
      </React.Fragment>
    );

  }
  return (
    <React.Fragment>
      <StyledMain {...main}>
        <NavBar className="navbar-end" isVIP={isVIP}>
          {showLeftLogo ? <Container className="left-side-logo"><CompanyLogo {...companyLogo} /></Container> : <Container className="empty-div" />}
          {companyMiddleLogo ? <CompanyLogo {...companyLogo} /> : <CompanyLogo {...middleLogo} />}
          <Container className="empty-div" />
        </NavBar>
        <Section className="page-wizard__content recovery__content">
          <Container className="page-wizard__heading recovery__heading">
            <HeaderGroup>
              <Container className="recovery__info-heading">
                <HeaderGroup>
                  {title && <Heading level="1" >{title}</Heading>}
                  {isFeedback && feedbackText && <Text className="recovery__bodyshop-text">{feedbackText}</Text>}
                  {text && <Text className="recovery__bodyshop-text">{text}</Text>}
                  {endText && <Text className="recovery__bodyshop-text"><br />{endText}</Text>}
                </HeaderGroup>
                {(showBodyshopBlock) &&
                  <Container className="recovery__info--button">
                    {bodyshopBlockTitle && <Heading level="4">{bodyshopBlockTitle}</Heading>}
                    <Button {...button}>
                      <HeaderGroup>
                        <Heading level="5">{bodyshopName}</Heading>
                        <Text>{
                          finalBodyshop.street + ' ' +
                          finalBodyshop.houseNumber + ','
                        }<br />{
                            finalBodyshop.zipcode + ' ' +
                            finalBodyshop.city
                          }</Text>
                      </HeaderGroup>
                      {!button.disabled && <UseIcon name="arrow-right" className="button__icon" />}
                    </Button>
                  </Container>}
              </Container>
            </HeaderGroup>
          </Container>
          <Container className="page-wizard__timeline recovery__timeline">
            {(showTimeline && timeline) &&
              <>
                {timelineTitle && <Heading level={'4'} className={"timeline--heading"}>{timelineTitle}</Heading>}
                <TimeLineBlock timeline={incident.damageType === 'Glas' ? glassTimeline : timeline} activeItem={activeItem} timelineIcon={timelineIcon} bodyshop={finalBodyshop} />
              </>}
          </Container>
        </Section>
      </StyledMain>
      <BackgroundVideo
        type="video/mp4"
        loop={true}
        autoPlay={true}
        videoFileName="DEF2.mp4"
        img={!isVIP || isThemed}
        imgFileName="BSSP03.jpg"
      />
    </React.Fragment>
  );
}

export default ThankYou;
