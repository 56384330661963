const object = 'object';

const pascalCaseStringToCamelCaseString = str => {
  if (str === 'CP_ID') {
    return 'cpID';
  }
  if (str === 'UUID') {
    return 'uuid';
  }
  if (str === 'URL') {
    return 'url';
  }
  if (str === 'FID') {
    return 'fid';
  }
  if (str === 'SAFDocuments') {
    return 'safDocuments';
  }
  const firstChar = str.charAt(0);
  return firstChar.toLowerCase() + str.slice(1);
};

const pascalCaseToCamelCase = input => {
  if (typeof input === 'string') {
    return pascalCaseStringToCamelCaseString(input)
  }
  if (Array.isArray(input)) {
    return input.map(pascalCaseToCamelCase);
  }

  const result = {};
  for (let key in input) {
    let newKey = pascalCaseStringToCamelCaseString(key);
    const value = input[key];
    if (typeof value !== object) {
      result[newKey] = value;
    } else {
      result[newKey] = pascalCaseToCamelCase(value);
    }
  }
  return result;
};

export default pascalCaseToCamelCase;
