import React, { useCallback, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';
import { isMobileOnly } from 'react-device-detect';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  Button,
  CompanyLogo,
  Container,
  DatePicker,
  FAQ,
  Form,
  GridColumn,
  GridContainer,
  Heading,
  IconButton,
  IconLink,
  Input,
  Legend,
  Main,
  NavBar,
  ProgressionIndicator,
  Text,
  UseIcon,
  ValidationFeedback,
} from '@domain/components';
import { useFaq, useEnterKey, useFormHandle, useScaling } from '@domain/hooks';
import { goToNextPage, pick, browserStorage } from '@domain/helpers';


const StyledMain = styled(Main)`
  .button--icon {
    margin-bottom: 16px;
  }

  .screen-desktop & {
    .button--icon {
      margin-bottom: 0;
    }
  }
`;

const StyledValidationFeedback = styled(ValidationFeedback)`
  .main__personal--date & {
    max-width: ${props => props.$scaling.scaleUpTo4K(338)}px;
    margin: 0;
  }
`;

function TextInputsPage({
  buttonText,
  controlValue,
  convertPortalValuesToServerValues = x => x,
  convertServerValuesToPortalValues = x => x,
  currentPath,
  customButtonEnabled,
  customCheck,
  customOnValidated,
  faqs,
  fields,
  fieldsPick,
  files,
  formID,
  heading,
  history,
  isSaving,
  legend,
  nextPage,
  pageClassNames,
  prevPage,
  progressIndicatorCurrent,
  progressIndicatorSteps,
  questionnaire,
  saveIncident,
  validationMessage = '',
  validationMessageCheck = () => false,
  validationSchema,
  customHelpLink,
  helpLinkText,
  customMiddleLogo = false,
  middleLogo,
}) {

  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer) || { brandName: 'emptyInsurer', HelpcenterLink: 'emptyHelpcenterLink' };
  const [
    faqVisible,
    handleOnHelpClick,
    clickOutSideFaq,
    faqListProps,
    faqSeen,
  ] = useFaq();
  const [incidentPosted, setIncidentPosted] = useState(false);
  const [backButton, setBackButton] = useState(false)
  const scaling = useScaling();

  useEffect(() => {
    if (!!questionnaire && questionnaire.length) {
      const progress = questionnaire.find(page => page.path === currentPath).name
      browserStorage.setProgress(progress);
    }
  }, [currentPath, questionnaire])

  const isPageBeforeSuccess = questionnaire[questionnaire.length - 2].path === currentPath

  const onValidated = useCallback(
    async function onValidated(values) {
      if (customOnValidated) {
        const validated = await customOnValidated(values);
        if (!validated) {
          setBackButton(false)
        }
        setIncidentPosted(validated);
      } else {
        await saveIncident({
          ...values,
        }, isPageBeforeSuccess);
        setIncidentPosted(true);
      }
    },
    [customOnValidated, saveIncident, isPageBeforeSuccess],
  );
  const fieldNames = useMemo(() => {
    if (fieldsPick) {
      return fieldsPick.map(({ name }) => name);
    }

    return fields.map(({ name }) => name);
  }, [fields, fieldsPick]);

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    buttonClicked,
    isLoading,
  } = useFormHandle(
    validationSchema,
    pick(incident, fieldNames),
    onValidated,
    convertServerValuesToPortalValues,
    convertPortalValuesToServerValues,
    controlValue,
  );

  useEffect(() => {
    if (!buttonClicked || !incidentPosted) {
      return;
    }
    if (fieldsPick) {
      const postedValues = convertPortalValuesToServerValues(values);
      const incidentUpdated = fieldsPick.every(({ name, mandatory }) => {
        if (!mandatory) {
          return true;
        }
        if (customCheck && customCheck[name]) {
          return customCheck[name](incident, postedValues);
        }
        return (
          ('' + incident[name]).toUpperCase() ===
          ('' + postedValues[name]).toUpperCase()
        );
      });

      if (incidentUpdated && !backButton) {
        goToNextPage(history, questionnaire, incident, currentPath, files ? files : {});
      }
      if (incidentUpdated && backButton) {
        history.push(prevPage);
      }
      return;
    }

    const incidentUpdated = fields.every(({ name, mandatory }) => {
      if (!mandatory) {
        return true;
      }

      if (customCheck && customCheck[name]) {
        return customCheck[name](incident, values);
      }

      return `${incident[name]}` === `${values[name]}`;
    });

    if (incidentUpdated && !backButton) {
      goToNextPage(history, questionnaire, incident, currentPath, files ? files : {});
    }
    if (incidentUpdated && backButton) {
      history.push(prevPage);
    }
  }, [
    buttonClicked,
    convertPortalValuesToServerValues,
    currentPath,
    customCheck,
    fields,
    fieldsPick,
    history,
    incident,
    incidentPosted,
    questionnaire,
    values,
    backButton,
    prevPage,
    files
  ]);

  useEnterKey(handleSubmit, [errors]);

  const classes = classNames('main__personal', pageClassNames);

  const main = {
    faqVisible: faqVisible,
    dimmed: faqVisible,
    className: classes,
    callBack: clickOutSideFaq,
    $scaling: scaling,
  };
  const iconLink = (triggerKey) => ({
    type: 'arrow-left',
    theme: 'secondary',
    to: prevPage,
    onClick: async (event) => {
      if (Object.values(values).every(value => value === '')) {
        history.push(prevPage)
        return
      }
      setBackButton(true)
      await handleSubmit(event)
    },
    replace: true,
    triggerKey
  });

  const iconLinkForward = (triggerKey) => ({
    type: 'arrow-right',
    theme: 'secondary',
    to: nextPage,
    onClick: handleSubmit,
    replace: true,
    triggerKey
  });

  const iconButton = {
    type: faqVisible ? 'arrow-right' : 'questionmark',
    className: faqSeen ? '' : 'unclicked-faq-button',
    theme: 'secondary',
    onClick: handleOnHelpClick,
    faq: true,
  };

  const chooseMiddleComponent = customMiddleLogo
    ? <CompanyLogo {...middleLogo} />
    : <ProgressionIndicator steps={progressIndicatorSteps} current={progressIndicatorCurrent} />

  const form = {
    id: formID,
    className: 'form--text-inputs-page',
  };

  const formInput = ({ disabled, name, label, type, displayLabel = true, displayEndAdornment = true }) => ({
    type: type || 'text',
    name,
    id: name,
    valid: !errors[name] || !buttonClicked,
    onChange: handleChange,
    label,
    regexPass: !errors[name],
    disabled,
    value: values[name],
    displayLabel,
    displayEndAdornment,
  });

  const buttonEnabled = customButtonEnabled
    ? customButtonEnabled(errors, values)
    : !errors || fields.every(field => !errors[field.name]);
  const button = {
    theme: buttonEnabled ? 'primary' : 'disabled',
    className: 'button--icon',
    onClick: handleSubmit,
    form: 'contact',
    justify: 'space-between',
    shadow: true,
    loading: isSaving || isLoading,
  };

  const autoFocusedIndex = useMemo(
    () => fields.findIndex(({ disabled }) => !disabled),
    [fields],
  );

  function renderInputAndError(field, i) {
    const { name, type, disabled } = field;

    if (type === 'date') {
      return (
        <Container key={name} className="form-input">
          <DatePicker
            value={values[name]}
            onChange={handleChange}
            name={name}
            valid={!errors[name] || !buttonClicked}
            disabled={disabled}
          />
          <Container className="validation__container">
            {buttonClicked && errors[name] && (
              <StyledValidationFeedback type="error" $scaling={scaling}>
                {errors[name]}
              </StyledValidationFeedback>
            )}
          </Container>
        </Container>
      );
    }

    return (
      <Container key={name} className="form-input">
        <Input
          {...formInput(field)}
          autoFocus={!isMobileOnly && i === autoFocusedIndex}
        />
        <Container className="validation__container">
          {buttonClicked && errors[name] && (
            <StyledValidationFeedback type="error" $scaling={scaling}>{errors[name]}</StyledValidationFeedback>
          )}
        </Container>
      </Container>
    );
  }
  return (
    <React.Fragment>
      {faqs && <FAQ
        faqListProps={faqListProps}
        faqs={faqs}
        faqLink={insurer.helpcenterLink}
        customHelpLink={customHelpLink}
        helpLinkText={helpLinkText}
        faqVisible={faqVisible}
      />}
      <StyledMain {...main}>
        <NavBar>
          <Container className="icon-link-container">
            <IconLink {...iconLink([37])} />
            {nextPage && <IconLink {...iconLinkForward([39])} />}
          </Container>
          {chooseMiddleComponent}
          {faqs && <Container className="faq-button-container">
            <IconButton {...iconButton} />
          </Container>}
        </NavBar>
        <GridContainer main={true} className="two-third">
          <GridColumn className="grid__column--main">
            <Legend>{legend || 'Persoonlijke gegevens'}</Legend>
            <Heading level="1">{heading || 'Controleer of wijzig uw gegevens'}</Heading>
            <Form {...form}>{fields.map(renderInputAndError)}</Form>
          </GridColumn>
          <GridColumn span={6} className="grid__column--footer">
            <Container className="validation__container">
              {validationMessageCheck(values) && (
                <StyledValidationFeedback type="error" $scaling={scaling}>
                  {validationMessage}
                </StyledValidationFeedback>
              )}
            </Container>
            <Button {...button}>
              <span>{buttonText || ' Correct'}</span>
              <UseIcon name="arrow-right" className="button__icon" />
            </Button>
            {!isMobileOnly && (
              <Text className="of--enter">
                of <b>ENTER</b>
              </Text>
            )}
          </GridColumn>
        </GridContainer>
      </StyledMain>
    </React.Fragment>
  );
}

export default TextInputsPage;
