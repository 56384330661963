import React, { useState, useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'


import {
  Button,
  Container,
  Dropdown,
  FAQ,
  GridContainer,
  GridColumn,
  Heading,
  IconButton,
  IconLink,
  Legend,
  Main,
  NavBar,
  ProgressionIndicator,
  Text,
  UseIcon,
  Loader, CompanyLogo
} from '@domain/components';
import {
  getPrevNextPagePath,
  goToNextPage,
  browserStorage,
  generatePageContent,
  chooseLogoSize,
} from '@domain/helpers';
import { useFaq, useScaling, useEnterKey, useSaveIncidentFactory } from '@domain/hooks';
import styled, { ThemeContext } from 'styled-components';
import { detectContentType } from '@domain/content';
import { isMobileOnly } from 'react-device-detect';
import { useQuestionnaire } from '@domain/providers';

const StyledMain = styled(Main)`
  .bodyshop-select {
    &__container {
      justify-content: center;
      .grid__column {
        flex: 0 0 auto;
        &.bodyshop-select__main {
          padding-top: 30px;
        }
      }
    }
    &__title {
      flex: 1;
      h1 {
        margin-bottom: 0;
      }
    }
    &__main {
      padding: 20px 0;
      // flex-direction: row;
      }
    }
    &__footer {
      flex-direction: row;
      justify-content: flex-end;
      &--button-container {
        width: 100%;
        .button {
          width: 100%;
        }
      }
    }
  }

  .screen-desktop & {
    .bodyshop-select {
      &__container {
        //padding: 0 ${props => props.$scaling.scaleUpTo4K(120)}px ${props => props.$scaling.scaleUpTo4K(82)}px;
        flex-direction: column;
      }
      &__title {
        padding: ${props => props.$scaling.scaleUpTo4K(60)}px 0 ${props => props.$scaling.scaleUpTo4K(30)}px;
      }
      &__main {
        padding: 0;
        flex-direction: column;
      }
      &__footer {
        // padding-bottom: ${props => props.$scaling.scaleUpTo4K(60)}px;
        &--button-container {
          width: auto;
          .button {
            width: ${props => props.$scaling.scaleUpTo4K(240)}px;
          }
        }
      }
    }
    .grid__column--main {
      max-width: none;
    }
  }

  @media screen and (max-width: 1028px) {
    .screen-desktop & {
      .bodyshop-select {
        &__container {
          .grid__column {
            flex: 0 0 auto;
            padding-top: 0;
          }
        }
        &__title {
          text-align: center;
          h1 {
            max-width: none;
          }
        }
        &__main {
        }
        &__footer {
          max-width: 500px;
          margin: auto auto 0;
          padding-bottom: 0;
          &--button-container {
            margin: auto;
          }
        }
      }
    }
  }

  @media screen and (max-width: 580px) {
    .bodyshop-select {
      &__container {
        .grid__column {
          &.bodyshop-select__main {
            flex: 1 0 auto;
          }
        }
      }
      &__title {
        h1 {
        }
      }
      &__main {
        .list__container {
        }
      }
      &__footer {
      }
    }

    .screen-desktop & {
      .bodyshop-select {
        &__container {
          .grid__column {
          }
        }
        &__title {
          text-align: left;
          h1 {
            margin-left: 0;
            flex-grow: 0;
          }
        }
        &__main {
          .list__container {
          }
        }
        &__footer {
          &--button-container {
            width: 100%;
            .button {
              width: 100%;
            }
          }
        }
      }
    }
  }
`;

const insuranceType = 'insuranceType';

function InsuranceType({ history, match }) {


  const { questionnaire } = useQuestionnaire();
  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);
  const files = useSelector(state => state.files)

  const scaling = useScaling();

  const themeContext = useContext(ThemeContext);

  const [
    faqVisible,
    handleOnHelpClick,
    clickOutSideFaq,
    faqListProps,
    faqSeen,
  ] = useFaq();

  const currentValue = incident[insuranceType];
  const [selectedValue, setSelectedValue] = useState(currentValue || "");
  const [buttonClicked, setButtonClicked] = useState(false)

  const currentPath = match.path;
  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined

  const saveIncident = useSaveIncidentFactory(incident, saveBehavior);

  const insuranceTypes = ['WA', 'WA + (beperkt casco)', 'Volledig casco (all risk)', 'Onbekend']

  useEffect(() => {
    if (!!questionnaire && questionnaire.length) {
      const progress = questionnaire.find(page => page.path === currentPath).name
      browserStorage.setProgress(progress);
    }
  }, [currentPath, questionnaire])

  const isPageBeforeSuccess = questionnaire[questionnaire.length - 2].path === currentPath

  async function handleClick(event) {
    event.preventDefault();
    const savedIncident = await saveIncident({ ...incident, insuranceType: selectedValue }, isPageBeforeSuccess)
    if (savedIncident) {
      setButtonClicked(true)
    }
  }

  useEffect(() => {
    if (buttonClicked) {
      if (incident.insuranceType === selectedValue) {
        goToNextPage(history, questionnaire, incident, currentPath, files);
      }
    }
  }, [buttonClicked, files, history, selectedValue, incident, questionnaire, currentPath,]);

  const [errors, setErrors] = useState(!currentValue || !selectedValue);

  const nextPageEnabled = !errors

  useEnterKey(handleClick, [errors]);


  if (!insurer || !incident || !questionnaire || (questionnaire && !questionnaire.length)) {
    return <Loader />
  }

  const handleClickItem = (event) => {
    setSelectedValue(event.target.value);
    setErrors(false)
  }

  const pagePath = getPrevNextPagePath(
    history,
    questionnaire,
    incident,
    currentPath,
    files,
  );
  const nextPage = pagePath.nextPage !== currentPath
    ? pagePath.nextPage
    : undefined;

  const main = {
    faqVisible: faqVisible,
    dimmed: faqVisible,
    className: 'main__damage main__damage--bodyshop-select',
    callBack: clickOutSideFaq,
    $scaling: scaling,
  }

  const iconLink = (triggerKey) => ({
    type: 'arrow-left',
    theme: 'secondary',
    to: pagePath.prevPage,
    replace: true,
    triggerKey
  });

  const iconLinkForward = (triggerKey) => ({
    type: 'arrow-right',
    theme: 'secondary',
    to: nextPage,
    replace: true,
    triggerKey
  });

  const iconButton = {
    type: faqVisible ? 'arrow-right' : 'questionmark',
    className: faqSeen ? '' : 'unclicked-faq-button',
    theme: 'secondary',
    onClick: handleOnHelpClick,
    faq: true,
  };

  const button = {
    className: 'button--icon',
    justify: 'space-between',
    onClick: handleClick,
    shadow: true,
    disabled: errors,
    theme: nextPageEnabled ? 'primary' : 'disabled',
  };

  const contentType = detectContentType(insurer)
  const pageName = questionnaire.find(page => page.path === currentPath).name
  const content = generatePageContent(contentType, insurer, incident, false, false, pageName)

  const legend = content["over-title-text"]
  const heading = content["title"]
  const customHelpLink = content["custom-help-link"]
  const helpLinkText = content["help-link-text"]
  const useCustomLogo = content["use-custom-logo"]
  const buttonText = content["button-text"]
  const faqs = content["faqs"]

  const middleLogo = {
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }

  const chooseMiddleComponent = useCustomLogo
    ? <CompanyLogo {...middleLogo} />
    : <ProgressionIndicator steps={3} current={2} />

  return (
    <React.Fragment>
      <FAQ
        faqListProps={faqListProps}
        faqs={faqs}
        faqLink={insurer.helpcenterLink}
        customHelpLink={customHelpLink}
        helpLinkText={helpLinkText}
        faqVisible={faqVisible}
      />
      <StyledMain {...main}>
        <NavBar>
          <Container className="icon-link-container">
            <IconLink {...iconLink([37])} />
            {nextPage && <IconLink {...iconLinkForward([39])} />}
          </Container>
          {chooseMiddleComponent}
          <Container className="faq-button-container">
            <IconButton {...iconButton} />
          </Container>
        </NavBar>
        <GridContainer main={true}>
          <GridColumn className="grid__column--main">
            <Legend>{legend}</Legend>
            <Heading level="1" className={'dropdown'}>{heading}</Heading>
            <Dropdown
              id="dropdownMerk"
              label="Verzekerd"
              onChange={handleClickItem}
              value={selectedValue}
              valueList={insuranceTypes}
            />
          </GridColumn>
          <GridColumn
            justify="flex-end"
            className="grid__column--footer grid__column--radio-group"
          >

            <Button {...button}>
              <span>{buttonText || ' Correct'}</span>
              <UseIcon name="arrow-right" className="button__icon" />
            </Button>
            {!isMobileOnly && (
              <Text className="of--enter">
                of <b>ENTER</b>
              </Text>
            )}
          </GridColumn>
        </GridContainer>
      </StyledMain>
    </React.Fragment>
  )
}

export default InsuranceType;
