import React from 'react';

import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import styled from 'styled-components'
import Button from '../Button';
import Container from '../Container';

const containerStyle = {
  // width: '100%',
  // maxWidth: '600px',
  // height: '400px',
  position: 'relative',
};

const MapContainer = styled.div`
  display: flex;
  >div {
    flex: 1 1 auto;
  }
`;

const StyledContainer = styled(Container)`
  display:flex;
  flex: 1 1 0px;
  padding-top: 10px;
 button {
    height: 42px;
    border-radius:10px;
  }
  .search-button {
    width: 100%;
  }
`;

function MapComponent({
  location,
  zoom,
  ...props
}) {
  return (
    <Map
      google={props.google}
      zoom={zoom}
      // style={mapStyles}
      containerStyle={containerStyle}
      initialCenter={{
        lat: location.latitude,
        lng: location.longitude
      }}
    >
      <Marker position={{
        lat: location.latitude,
        lng: location.longitude
      }} />
    </Map>
  );
};

function GoogleMap({
  location,
  zoom = 10,
  showDirectionsButton = false,
}) {

  async function handleOnClick() {
    const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${location.latitude},${location.longitude}`
    window.open(mapsUrl, '_blank')
  }

  const button = {
    theme: 'secondary',
    onClick: handleOnClick,
    className: "search-button",
    justify: 'center',
  };

  const MapWrapper = GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    location,
    zoom,
  })(MapComponent);
  return (
    <>
      <MapContainer className="google-map">
        <MapWrapper location={location} zoom={zoom} />
        {/* <MapComponent location={location} /> */}
      </MapContainer>
      <StyledContainer>
        {showDirectionsButton && <Button {...button}>Open op google maps</Button>}
      </StyledContainer>
    </>
  )
}
export default GoogleMap;
