import React from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import classNames from 'classnames';
import { Colors } from '@domain/theming';
import { isMobileOnly } from 'react-device-detect';
import { useScaling } from '@domain/hooks';

const GlobalStyle = createGlobalStyle`
  .validation__container {
    min-height: 50px;
  }
`;

const StyledSpan = styled.span`
  display: flex;
  margin-bottom: 20px;
font-size: ${props => !isMobileOnly ? props.$scaling.textSize(14) : props.$scaling.textSizeMobile(14, props.vwWdith)}px;
line-height: ${props => !isMobileOnly ? props.$scaling.textSize(18) : props.$scaling.textSizeMobile(18, props.vwWdith)}px;
  &.validation-feedback--error {
    color: ${Colors.RED};
  }
`;
// to not break the test that is looking for a 'span' element
StyledSpan.displayName = 'span';
function ValidationFeedback({ children, type = 'info', className }) {
  const scaling = useScaling();
  const classes = classNames('validation-feedback', className, {
    'validation-feedback--error': type === 'error',
    'validation-feedback--info': type === 'info',
  });

  return <>
    <StyledSpan className={classes} $scaling={scaling}>{React.Children.toArray(children)}</StyledSpan>
    <GlobalStyle />
  </>;
}

ValidationFeedback.propTypes = {
  children: PropTypes.any.isRequired,
  type: PropTypes.oneOf(['error', 'info'])
};

export default ValidationFeedback;
