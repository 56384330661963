import {
  parseFetchResponseToJson,
  prepareFilesForUploading,
  camelCaseToPascalCase,
} from '@domain/helpers';

import { endpoints } from '@domain/constants';
import retry from 'fetch-retry'

const fetchWithRetry = retry(fetch)

const filesEndpoint = endpoints.files;

async function getFiles(uuid, token) {
  const config = {
    method: 'GET',
    headers: camelCaseToPascalCase({
      token,
      uuid,
    }),
  };
  const response = await fetchWithRetry(filesEndpoint, config).then((response) =>
    parseFetchResponseToJson(response, { endpoint: filesEndpoint }),
  );
  return response;
}

async function postFiles(fileSet, uploads, incident, token, insurer) {
  const encodedFiles = await prepareFilesForUploading(uploads, insurer);
  const postFile = async file => {
    const body = JSON.stringify(
      camelCaseToPascalCase({
        ...incident,
        [fileSet]: [file],
      }),
    )

    const config = {
      method: 'POST',
      body: body,
      headers: camelCaseToPascalCase({
        token,
        'Content-Type': 'application/json',
      }),
    };
    const res = await fetchWithRetry(filesEndpoint, config).then((response) =>
      parseFetchResponseToJson(response, { endpoint: filesEndpoint, body }),
    );
    const success = 'Success';
    const successFeedback = 'FeedbackStored'
    const stored = 'Stored';
    const status = res[fileSet][0].status
    file.status = status

    const succeeded = status === success || status === successFeedback || status === stored;
    return succeeded && file;
  };

  const uploadedFiles = await Promise.all(encodedFiles.map(postFile));
  return uploadedFiles.filter(Boolean);
}

async function postTempStoredFiles(files, incident, token, insurer) {

  const arrayOfSetOfFiles = Object.entries(files).filter(arrayOfFiles => arrayOfFiles[1].length)
  const tempSavedFiles = arrayOfSetOfFiles.map((arrayOfFiles) => {

    const filesToUpload = arrayOfFiles[1].filter(file => !file.status)
    const finalFilesToUpload = filesToUpload.map(file => {
      return { ...file, content: file.content.replace("data:image/png;base64,", "") }
    })
    return { fileSet: arrayOfFiles[0], uploads: finalFilesToUpload }
  })

  const postSetOfFiles = async fileGroup => {
    const postFile = async file => {
      const body = JSON.stringify(
        camelCaseToPascalCase({
          ...incident,
          [fileGroup.fileSet]: [file],
        }),
      )
      const config = {
        method: 'POST',
        body: body,
        headers: camelCaseToPascalCase({
          token,
          'Content-Type': 'application/json',
        }),
      };
      const res = await fetch(filesEndpoint, config).then((response) =>
        parseFetchResponseToJson(response, { endpoint: filesEndpoint, body }),
      );
      const success = 'Success';
      const successFeedback = 'FeedbackStored'
      const stored = 'Stored';
      const status = res[fileGroup.fileSet][0].status
      file.status = status

      const succeeded = status === success || status === successFeedback || status === stored;
      return succeeded && file;
    };
    fileGroup.uploads.map(postFile)
  }
  const uploadedFiles = await Promise.all(tempSavedFiles.map(postSetOfFiles));
  return uploadedFiles.filter(Boolean);
}

async function deleteFile(fileSet: 'damageImage' | 'intakeImages' | 'additionalDamageImages', cpID, incident, token) {
  const body = JSON.stringify(
    camelCaseToPascalCase({
      ...incident,
      [fileSet]: [
        {
          cpID,
          fileAction: 'delete',
        },
      ],
    }),
  )
  const config = {
    method: 'POST',
    body: body,
    headers: camelCaseToPascalCase({
      token,
      'Content-Type': 'application/json',
    }),
  };
  const response = await fetchWithRetry(filesEndpoint, config).then((response) =>
    parseFetchResponseToJson(response, { endpoint: filesEndpoint, body }),
  );
  return response;
}

export default {
  postFiles,
  postTempStoredFiles,
  deleteFile,
  getFiles,
};
