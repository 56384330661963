import React from 'react';
import {
  Text,
  Timeline,
  TimelineItem,
  TimelineIcon,
  UseIcon,
} from '@domain/components';
function TimeLineBlock({ timeline, activeItem, timelineIcon, bodyshop, timelineShowCheck = false }) {


  const generateTimeline = timeline ? timeline.map((item, i, self) => {
    const hasImageIcon = item.imgIcon && bodyshop && bodyshop.contactPersons && bodyshop.contactPersons.length && bodyshop.contactPersons[0].imageURL

    if (activeItem === (-1)) {
      return (
        <TimelineItem key={i}>
          <TimelineIcon name={item.icon} className={'timeline__item__icon'} timeline />
          <Text className="timeline__text">{item.text}</Text>
        </TimelineItem>
      )
    }
    if (i < activeItem) {
      return (
        <TimelineItem key={i} muted={false} active={false}>
          <TimelineIcon {...timelineIcon(item, i)} />
          {timelineShowCheck && <UseIcon
            name="checkmark"
            className="timeline-checkmark"
            width="19"
            height="19"
          />}
          <Text className="timeline__text">{item.text}</Text>
        </TimelineItem>)
    }
    if (i > activeItem) {
      return (
        <TimelineItem key={i} muted={true} active={false}>
          <TimelineIcon {...timelineIcon(item, i)} />
          <Text className="timeline__text">{item.text}</Text>
        </TimelineItem>)
    }
    return (
      <TimelineItem key={i} muted={false} active={true}>
        {hasImageIcon
          ? <img
            src={bodyshop.contactPersons[0].imageURL}
            alt={bodyshop.contactPersons[0].name}
            className="contact-person-picture"
          />
          : <TimelineIcon {...timelineIcon(item, i)} />}
        {!item.imgIcon && <TimelineIcon {...timelineIcon(item, i)} />}
        <Text className="timeline__text">{item.text}</Text>
      </TimelineItem>)
  }
  ) : null
  return generateTimeline ? <Timeline>{generateTimeline}</Timeline> : null
}

export default TimeLineBlock;