import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LicensePlateService } from '@domain/services'
import queryString from 'query-string';
import browserStorage from '@domain/helpers/browserStorage';
import { TEMP_INCIDENT_UPDATED } from '@domain/action-types';
import { useDispatchError } from '..';

const useLicensePlateUUIDLoader = (location) => {

  const dispatch = useDispatch();
  const incident = useSelector(state => state.incident);

  const { dispatchNewError } = useDispatchError();

  const [licensePlateUUID, setLicensePlateUUID] = useState(null)

  useEffect(() => {

    const licensePlateUUIDFromUrl = queryString.parse(location.search).LicensePlateUUID
      ? decodeURIComponent(queryString.parse(location.search).LicensePlateUUID)
      : null

    if (licensePlateUUIDFromUrl) {
      setLicensePlateUUID(licensePlateUUIDFromUrl)
      return
    }

  }, [location.search]);

  useEffect(() => {

    const getlicensePlateWithUUID = async (licensePlateUUIDFromUrl) => {
      try {
        const response = await LicensePlateService.getLicense(licensePlateUUIDFromUrl);

        const newTempIncident = { ...incident, ...response };
        browserStorage.setTempSaved(false)
        browserStorage.saveValuesInLocalStorage(newTempIncident);
        dispatch({
          type: TEMP_INCIDENT_UPDATED,
          incident: newTempIncident,
        })
      } catch ({ error }) {
        console.error(`Error occurred inside useLicensePlateUUIDChecker trying to retrieve license plate for schadegarant (LicensePlateService.getLicense):`, error);
        dispatchNewError(error);
      }
    }

    if (!licensePlateUUID || (incident && incident.licensePlate)) {
      return;
    }

    if (licensePlateUUID) {

      getlicensePlateWithUUID(licensePlateUUID)
      return
    }

  }, [dispatch, licensePlateUUID, incident, dispatchNewError]);
};

export default useLicensePlateUUIDLoader;
