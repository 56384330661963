import './paragraph.scss';
import styled from 'styled-components';

const ParagraphSP = styled.p.attrs(({ level }) => ({
  className:
    level === 1
      ? 'h1'
      : level === 2
        ? 'h2'
        : level === 3
          ? 'h3'
          : level === 4
            ? 'h4'
            : level === 6
              ? 'h6'
              : 'h5',
}))`
  text-align: ${props => props.textAlign};
  margin: ${props => props.removeMargin ? '0' : '0 auto'};
  padding-top: ${props => props.paddingTop};
  padding-bottom: ${props => props.paddingBottom}
`;

export default ParagraphSP;
