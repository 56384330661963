// Only use this component for decorative images
// The large image on image-delete is also decorative, because it simply displays a larger version of the selected image

import React from 'react';
import PropTypes from 'prop-types';

const DecorativeImage = ({ className, imageSrc }) => (
  <div
    style={
      imageSrc
        ? {
            backgroundImage: `url(${imageSrc})`
          }
        : {}
    }
    className={className}
  />
);

DecorativeImage.propTypes = {
  className: PropTypes.string.isRequired,
  imageSrc: PropTypes.string
};

export default DecorativeImage;
