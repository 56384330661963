import { useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { proccessType, companyNames } from '@domain/constants';
import { BodyshopListsService } from '@domain/services';
import { BODYSHOP_LISTS_FETCHED } from '@domain/action-types';
import useDispatchError from '../useDispatchError';

function useGetBodyshopLists() {
  const incident = useSelector(state => state.incident);
  const token = useSelector(state => state.token);
  const uuid = useSelector(state => state.uuid);
  const bodyshopListsLoading = useRef(false);
  const dispatch = useDispatch();
  const insurer = useSelector(state => state.insurer)

  const { dispatchNewError } = useDispatchError();

  const getBodyshopLists = useCallback(
    async (carMake, radius = '10', postcode, city) => {
      if (bodyshopListsLoading.current) {
        return;
      }

      if (!!insurer && insurer.objectType === proccessType.Schadegarant) {
        if (!uuid || !token || !carMake) {
          return;
        }
        bodyshopListsLoading.current = true;
        try {
          const newBodyshopLists = await BodyshopListsService.getSG(
            uuid,
            token,
            incident,
            carMake,
            radius,
            postcode,
            city
          );
          const mergedList = () => {
            const hagelHerstellerList = newBodyshopLists.bedrijvenHagelHerstellers || []
            const herstellerList = newBodyshopLists.bedrijvenHerstellers || []
            const finalHerstelerList = hagelHerstellerList.concat(herstellerList)
            const { bedrijvenHagelHerstellers, ...listMinusHagel } = newBodyshopLists
            return { ...listMinusHagel, bedrijvenHerstellers: finalHerstelerList }
          }
          const bsList = mergedList()
          dispatch({
            type: BODYSHOP_LISTS_FETCHED,
            bodyshopLists: bsList,
          });
          bodyshopListsLoading.current = false;
          return true

        } catch ({ error }) {
          if (error && error.code === 401) {
            dispatchNewError(error);
          }
          bodyshopListsLoading.current = false;
          console.error(`Error occurred inside useGetBodyshopLists trying to retrieve bodyshop list for schadegarant (BodyshopListsService.getSG):`, error);
        }
        return;
      }

      if ((!!insurer && insurer.objectType === proccessType.IntakePlus)) {
        if (!uuid || !token) {
          return;
        }
        bodyshopListsLoading.current = true;
        try {
          const newBodyshopLists = await BodyshopListsService.getIntake(
            uuid,
            token,
            incident,
            radius,
            postcode,
          );
          const bodyshopList = newBodyshopLists.carRefinishers
          if (!bodyshopList) {
            dispatch({
              type: BODYSHOP_LISTS_FETCHED,
              bodyshopLists: {},
            });
            bodyshopListsLoading.current = false;
            return true
          }
          const bodyshopsWithDistance = bodyshopList.filter(
            (bodyshop) => bodyshop.distance)
          const bodyshopswithoutDistance = bodyshopList.filter(
            (bodyshop) => !bodyshop.distance
          )
          const orderedBodyshops = bodyshopsWithDistance.sort(
            (bodyshopA, bodyshopB) => bodyshopA.distance - bodyshopB.distance
          )
          const fullList = [...orderedBodyshops, ...bodyshopswithoutDistance]
          if (bodyshopList.length > 0) {
            dispatch({
              type: BODYSHOP_LISTS_FETCHED,
              bodyshopLists: { carRefinishers: fullList },
            });
          }
          bodyshopListsLoading.current = false;
          return true
        } catch ({ error }) {
          if (error && error.code === 401) {
            dispatchNewError(error);
          }
          bodyshopListsLoading.current = false;
          console.error(`Error occurred inside useGetBodyshopLists trying to retrieve bodyshop list for intake plus (BodyshopListsService.getIntake):`, error);
        }
        return;
      }

      if ((!!insurer && insurer.brand === companyNames.Rhion)) {
        if (!uuid || !token) {
          return;
        }
        bodyshopListsLoading.current = true;
        try {
          const newBodyshopLists = await BodyshopListsService.getEM(
            uuid,
            token,
            incident,
          );
          dispatch({
            type: BODYSHOP_LISTS_FETCHED,
            bodyshopLists: newBodyshopLists,
          });
        } catch ({ error }) {
          if (error && error.code === 401) {
            dispatchNewError(error);
          }
          console.error(`Error occurred inside useGetBodyshopLists trying to retrieve bodyshop list for expertise manager (BodyshopListsService.getEM):`, error);
        }
        bodyshopListsLoading.current = false;
        return;
      }

    }, [dispatch, dispatchNewError, incident, insurer, token, uuid]);

  return getBodyshopLists;
}

export default useGetBodyshopLists;