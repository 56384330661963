// NPM imports.
import React, { useMemo, useContext, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import objectFitImages from 'object-fit-images';
import { ThemeContext } from 'styled-components';

import { useScaling, useThemes } from '@domain/hooks';
import { isEdge } from 'react-device-detect';
import { Colors } from '@domain/theming';

const GlobalStyle = createGlobalStyle`
  .video--edge {
    background-color: ${Colors.GREY_DARKER};
  }
`;

const StyledContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height:100%;
  width: 100%;
  z-index: 2;
  overflow: hidden;
  transition: border-radius 0.3s ease-in-out, transform 0.3s ease-in-out;
  .background-video {
  }
  .background-img {
  }
  .main.faq-open + & {
    transform: translateX(calc(-100% + 92px));
    border-radius: 12px;
  }
  .screen-desktop .main.faq-open + & {
    transform: translateX(-${props => props.$scaling.scaleUpTo4K(480)}px);
    border-radius: ${props => props.$scaling.scaleUpTo4K(12)}px;
  }
  .screen-ie & {
    img {
      height: 120%;
      min-height: 600px;
      width: 100vw;
      object-fit: cover;
      font-family: 'object-fit: cover';
    }
  }
`;

const StyledImg = styled.img`
  filter: blur(1px) brightness(1.18);
  transform: scale(1.1);
  width: 100%;
  height: 100%;
  object-fit: cover;

`;

const StyledVideo = styled.video`
  min-height: 100%;
  height: 0;
  object-fit: cover;
  object-position: center;
  width: 100vw;
  background: ${Colors.GREY_DARKER};
`;

const StyledOverlay = styled.div`
  content: '';
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  z-index: 2;
`;

function BackgroundVideo({
  autoPlay,
  className,
  faqVisible,
  img,
  imgFileName,
  loop,
  muted,
  type,
  videoFileName,
}) {
  const scaling = useScaling();
  const classes = classNames({ 'background-video': !img, 'faq-open': faqVisible, 'background-img': img }, className);
  const themes = useMemo(
    () => (img ? isEdge ? ['video', 'video-img', 'video--edge'] : ['video', 'video-img'] : isEdge ? ['video', 'video--edge'] : ['video']),
    [img],
  );
  useThemes(themes);

  useEffect(() => {
    if (img) {
      objectFitImages('#background-image-custom');
    }
  }, [img])

  const themeContext = useContext(ThemeContext);

  if (img) {
    return (
      <>
        <GlobalStyle />
        <StyledContainer className="video-container" $scaling={scaling}>
          <StyledImg
            className={classes}
            src={themeContext.backgroundImage || `/img/${imgFileName}`}
            alt="background"
            id={'background-image-custom'}
          />
          <StyledOverlay className="video-overlay"></StyledOverlay>
        </StyledContainer>
      </>
    );
  } else {
    return (
      <>
        <GlobalStyle />
        <StyledContainer className="video-container" $scaling={scaling}>
          <StyledVideo
            className={classes}
            autoPlay={autoPlay}
            muted={true}
            loop={loop}
            playsInline={true}
          >
            <source src={`/video/${videoFileName}`} type={type} />
          </StyledVideo>
          <StyledOverlay className="video-overlay"></StyledOverlay>
        </StyledContainer>
      </>
    );
  }
}

BackgroundVideo.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  autoPlay: PropTypes.bool.isRequired,
  loop: PropTypes.bool.isRequired,
  muted: PropTypes.bool.isRequired,
  img: PropTypes.bool,
  imgFileName: PropTypes.string,
  videoFileName: PropTypes.string.isRequired,
};

BackgroundVideo.defaultProps = {
  autoPlay: false,
  loop: true,
  muted: false,
  videoFileName: 'DEF1.mp4',
};

export default BackgroundVideo;
