import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import { companyNames, proccessType } from '@domain/constants'

function HtmlHead({
  faviconCustom,
}) {
  const insurer = useSelector(state => state.insurer)

  const chooseTitle = () => {
    if (insurer.objectType === companyNames.Schadegarant) {
      return { title: 'eXperience' }
    }
    if (insurer.objectType === proccessType.DI) {
      return { title: 'Digitale intake' }
    } if (insurer.objectType === proccessType.EM || (insurer.objectType === proccessType.Casco && insurer.brand === companyNames.Rhion)) {
      return { title: 'Uw schadebeschrijving' }
    } if (insurer.objectType === proccessType.Casco && (insurer.brand === companyNames.VIP || !insurer.brand)) {
      return { title: 'VIP Schadeservice' }
    } else {
      return { title: insurer.brandName || '' }
    }
  }

  const chooseFavicon = () => {
    if (insurer.objectType === companyNames.Schadegarant) {
      return { favicon: '/favicon_sg.ico' }
    }
    if (insurer.objectType === proccessType.DI) {
      return { favicon: '/favicon_akzo.ico' }
    }
    if (insurer.objectType === proccessType.EM || (insurer.objectType === proccessType.Casco && insurer.brand === companyNames.Rhion)) {
      return { favicon: '/favicon_rhion.ico' }
    }
    if (insurer.objectType === proccessType.Casco && (insurer.brand === companyNames.VIP || !insurer.brand)) {
      return { favicon: '/favicon_vip.ico' }
    }
    else {
      return { favicon: '/favicon.ico' }
    }
  }

  if (!insurer) {
    return (
      <Helmet />
    )
  }
  return (
    <Helmet>
      <title>{chooseTitle().title}</title>
      {/* <link rel="shortcut icon" href={chooseTitle().favicon} /> */}
      <link rel="shortcut icon" href={!!faviconCustom ? 'data:image/x-icon;base64,' + faviconCustom : chooseFavicon().favicon} />
    </Helmet>
  );
};


export default HtmlHead;
