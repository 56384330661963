import classNames from 'classnames';
import {
  isChrome,
  isSafari,
  isFirefox,
  isEdge,
  isIE,
  isYandex,
  isChromium,
  isOpera
} from 'react-device-detect';

function detectBrowsers() {
  const screenClassName = classNames('screen-desktop', {
    'screen-chrome': isChrome,
    'screen-safari': isSafari,
    'screen-firefox': isFirefox,
    'screen-edge': isEdge,
    'screen-ie': isIE,
    'screen-chromium': isChromium,
    'screen-opera': isOpera,
    'screen-yandex': isYandex
  });

  return screenClassName;
}

export default detectBrowsers;
