import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { carBrandsSchadegarant, proccessType } from '@domain/constants';
import { BodyshopListsService } from '@domain/services';
import { BODYSHOP_LISTS_FETCHED } from '@domain/action-types';

// This hook will try to load in the bodyshopLists as soon as possible to be available when needed
//
// It will try to match the vehicleManufacturerInformation (car brand) with carBrandsSchadegarant
// if so will use a default radius of 10km to make the call to the bedrijven service (bodyshopListsService)
function useBodyshopListsLoader() {
  const bodyshopLists = useSelector(state => state.bodyshopLists);
  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);
  const pid = useSelector(state => state.pid);
  const token = useSelector(state => state.token);
  const uuid = useSelector(state => state.uuid);
  const bodyshopListsLoading = useRef(false);
  const dispatch = useDispatch();
  const defaultRadius = '10';

  const retrivingCallDone = useRef(false)
  useEffect(() => {
    if (!!bodyshopLists) {
      return;
    }
    if (retrivingCallDone.current) {
      return;
    }
    if (bodyshopListsLoading.current) {
      return;
    }

    if (insurer && insurer.objectType === proccessType.Schadegarant) {
      const getBodyshopListsSG = async (carBrand, radius) => {
        bodyshopListsLoading.current = true;
        retrivingCallDone.current = true
        try {
          const newBodyshopLists = await BodyshopListsService.getSG(
            uuid,
            token,
            incident,
            carBrand,
            radius && radius,
          );
          const mergedList = () => {
            const hagelHerstellerList = newBodyshopLists.bedrijvenHagelHerstellers || []
            const herstellerList = newBodyshopLists.bedrijvenHerstellers || []
            const finalHerstelerList = hagelHerstellerList.concat(herstellerList)
            const { bedrijvenHagelHerstellers, ...listMinusHagel } = newBodyshopLists
            return { ...listMinusHagel, bedrijvenHerstellers: finalHerstelerList }
          }
          const bsList = mergedList()
          dispatch({
            type: BODYSHOP_LISTS_FETCHED,
            bodyshopLists: bsList,
          });
        } catch ({ error }) {
          console.error(`Error occurred inside useBodyshopListsLoader trying to retrieve bodyshop list for schadegarant (BodyshopListsService.getSG):`, error);
        }
        bodyshopListsLoading.current = false;
      };

      if (incident && incident.carMake && incident.radius && incident.debiteurNummer && incident.customerZipCode && uuid && token && incident.causeDamage) {
        getBodyshopListsSG(incident.carMake, incident.radius);
        return;
      }

      const vehicleManufacturerInformation = (!!incident && incident.vehicleMake) || undefined;
      const postcode = (!!incident && incident.customerZipCode) || undefined
      if (!uuid || !token || !vehicleManufacturerInformation || !postcode || !incident.causeDamage) {
        return;
      }
      if (!carBrandsSchadegarant.includes(incident.vehicleMake)) {
        return;
      }
      getBodyshopListsSG(incident.vehicleMake, defaultRadius);
    }
    if (!!insurer && insurer.objectType === proccessType.IntakePlus) {
      const getBodyshopListsIntake = async (radius, postcode) => {
        bodyshopListsLoading.current = true;
        try {
          const newBodyshopListsResponse = await BodyshopListsService.getIntake(
            uuid,
            token,
            incident,
            radius && radius,
            postcode

          );
          const newBodyshopList = newBodyshopListsResponse.carRefinishers;

          if (newBodyshopList && newBodyshopList.length > 0) {

            const bodyshopsWithDistance = newBodyshopList.filter(
              (bodyshop) => bodyshop.distance)
            const bodyshopswithoutDistance = newBodyshopList.filter(
              (bodyshop) => !bodyshop.distance
            )
            const orderedBodyshops = bodyshopsWithDistance.sort(
              (bodyshopA, bodyshopB) => bodyshopA.distance - bodyshopB.distance
            )
            const fullList = [...orderedBodyshops, ...bodyshopswithoutDistance]
            dispatch({
              type: BODYSHOP_LISTS_FETCHED,
              bodyshopLists: { carRefinishers: fullList },
            });
          }
        } catch ({ error }) {
          console.error(`Error occurred inside useBodyshopListsLoader trying to retrieve bodyshop list for intake plus (BodyshopListsService.getIntake):`, error);
        }
        bodyshopListsLoading.current = false;
      };

      if (incident && incident.radius && incident.customerZipCode && uuid && token) {
        getBodyshopListsIntake(incident.radius || 50, incident.customerZipCode)
        return
      }
      const postcode = (!!incident && incident.customerZipCode) || undefined
      if (!uuid || !token || !postcode) {
        return;
      }
      getBodyshopListsIntake(50, postcode);
    }
    if (!!insurer && insurer.objectType === proccessType.EM) {
      const getBodyshopListsEM = async () => {
        bodyshopListsLoading.current = true;
        try {
          const newBodyshopListsResponse = await BodyshopListsService.getEM(
            uuid,
            token,
            incident,
          );
          const newBodyshopList = newBodyshopListsResponse.carRefinishers;
          if (newBodyshopList.length > 0) {
            dispatch({
              type: BODYSHOP_LISTS_FETCHED,
              bodyshopLists: { carRefinishers: newBodyshopList },
            });
          }
        } catch ({ error }) {
          console.error(`Error occurred inside useBodyshopListsLoader trying to retrieve bodyshop list for expertise manager (BodyshopListsService.getEM):`, error);
        }
        bodyshopListsLoading.current = false;
      };

      if (uuid && token) {
        getBodyshopListsEM()
      }
    }

  }, [bodyshopLists, dispatch, incident, insurer, pid, token, uuid])
}

export default useBodyshopListsLoader;
