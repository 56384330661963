// Will only use this on two objects that are known to have the same keys and boolean values
const deepEqualityLVLOne = (obj1, obj2) => {
  if (!obj1 || !obj2) {
    return false;
  }
  const len1 = Object.keys(obj1).length;
  const len2 = Object.keys(obj2).length;
  if (len1 !== len2) {
    return false;
  }
  for (let key in obj1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
};

export default deepEqualityLVLOne;
