const routes = {
  checkin: '/inchecken',
  checkout: '/uitchecken',
  faq: '/veelgestelde-vragen',
  incidentNew: '/nieuw',
  incidentNewCorePlatform: '/New',
  onboarding: '/onboarding',
  preview: '/preview',
  signIn: '/inloggen',
  trackTrace: '/track-en-trace',
}

export default routes;
