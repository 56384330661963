import moment from 'moment';
import { companyNames, proccessType } from '@domain/constants';

export const timelineSteps = (incident, bodyshop, bodyshopName, objectType, isVIP, status, chauffeur) => {

  const {
    carIntakeMethod,
    carOuttakeMethod,
    checkInAddress,
    checkOutAddress,
    intakeDate,
    failedCallDate,
    failedCallTime,
    intakeDate_EndWindow,
    outtakeDate,
    outtakeDate_EndWindow,
  } = incident;

  const timeFormat = 'HH:mm';
  const dateFormat = 'dddd D MMM';
  const checkinDate = moment.utc(intakeDate).locale('nl').format(dateFormat);
  const checkinTime = moment.utc(intakeDate).locale('nl').format(timeFormat);
  const checkinTimeEndWindow = moment.utc(intakeDate_EndWindow).locale('nl').format(timeFormat);
  const checkoutDate = moment.utc(outtakeDate).locale('nl').format(dateFormat);
  const checkoutTime = moment.utc(outtakeDate).locale('nl').format(timeFormat);
  const checkoutTimeEndWindow = moment.utc(outtakeDate_EndWindow).locale('nl').format(timeFormat);

  const firstStep = {
    name: 'Check in gepland',
    icon: 'message',
    text: incident.createdBy === 'exchange'
      ? `U heeft net bij ${bodyshopName} uw schade gemeld. Zij zullen contact met u opnemen voor een afspraak.`
      : status === 'carrefinisher_no_contact'
        ? `${bodyshopName} heeft u ${failedCallDate && failedCallTime && 'op ' + failedCallDate + ' ' + failedCallTime} gebeld voor een herstelafspraak, maar kon u helaas niet bereiken.`
        : objectType === companyNames.Schadegarant
          ? incident.coverage === 'unconfirmed' ? "Let op, uw schade wordt nog beoordeeld door uw verzekeraar. Het kan iets langer duren voordat er contact met u wordt opgenomen." : "U wordt zo spoedig mogelijk gebeld voor het maken van een herstelafspraak."
          : objectType === proccessType.DealerSass
            ? `Dealer ${bodyshopName} neemt contact met u op voor het maken van een afspraak`
            : (status === '_new' ||
              status === 'carrefinisher_chosen' ||
              status === 'feedback' ||
              status === 'feedback_operations' ||
              status === 'carrefinisher_no_contact' ||
              status === 'carrefinisher_choosen')
              ? `${bodyshopName} neemt contact met u op voor het maken van een ${isVIP ? 'check-in' : ""} afspraak.`
              : `${bodyshopName} neemt binnen één werkdag contact met u op voor het maken van een ${isVIP ? 'check-in' : ""} afspraak.`,
    subText:
      (status === 'carrefinisher_no_contact') ?
        `${bodyshopName} zal morgen nogmaals contact met u proberen op te nemen. U kunt ook zelf bellen om een afspraak te maken. \n Telefoonnummer ${bodyshopName}: ${bodyshop.phoneNumber}` :
        ``,
    status: [
      '_new',
      'carrefinisher_chosen',
      "carrefinisher_choosen",
      'feedback',
      'feedback_operations',
      'carrefinisher_no_contact',
    ],
  }

  const secondStep = {
    name: isVIP ? 'check-in' : 'afspraak',
    icon: 'towtruck',
    text: carIntakeMethod
      ? objectType === companyNames.Schadegarant
        ? carIntakeMethod === 'Bodyshop_pickup'
          ? `Er komt een chaffeur op ${checkinDate} tussen ${checkinTime + ' - ' + checkinTimeEndWindow} uw auto op halen.`
          : `U heeft een afspraak bij ${bodyshopName} op ${checkinDate} tussen ${checkinTime + ' - ' + checkinTimeEndWindow} om uw auto te brengen.`
        : objectType === proccessType.DealerSass
          ? "Uw auto gaat naar de hersteller"
          : (status === '_new' ||
            status === 'carrefinisher_chosen' ||
            status === 'feedback' ||
            status === 'feedback_operations' ||
            status === 'carrefinisher_no_contact' ||
            status === 'carrefinisher_choosen' ||
            status === 'damage_published_to_openclaims')
            ? `Uw auto wordt gratis opgehaald (als u daar voor kiest).`
            : carIntakeMethod === 'Bodyshop_pickup'
              ? !!chauffeur
                ? `${chauffeur.name} komt op ${checkinDate} tussen ${checkinTime + ' - ' + checkinTimeEndWindow} uw auto op halen op onderstaande locatie.`
                : `Er komt een medewerker van ${bodyshopName} op ${checkinDate} tussen ${checkinTime + ' - ' + checkinTimeEndWindow} uw auto op halen op onderstaande locatie.`
              : `U heeft een afspraak bij ${bodyshopName} op ${checkinDate} tussen ${checkinTime + ' - ' + checkinTimeEndWindow} om uw auto te brengen.`
      : `Uw auto wordt gratis opgehaald (als u daar voor kiest).`,
    subText: carIntakeMethod === 'Bodyshop_pickup' && checkInAddress &&
      `${checkInAddress.street} ${checkInAddress.houseNumber},
        ${checkInAddress.zipcode} ${checkInAddress.city}`,
    status: [
      'date_planned',
    ],
  }

  const thirdStep = {
    name: 'Auto wordt hersteld',
    icon: 'wrench',
    text: objectType === proccessType.DealerSass
      ? `Uw auto wordt hersteld door dealer ${bodyshopName}`
      : objectType === proccessType.Schadegarant
        ? `Uw schadedossier is momenteel in behandeling bij ${bodyshopName}`
        : (status === '_new' ||
          status === 'carrefinisher_chosen' ||
          status === 'feedback' ||
          status === 'feedback_operations' ||
          status === 'carrefinisher_no_contact' ||
          status === 'carrefinisher_choosen'
        ) ? `Uw auto wordt hersteld door ${bodyshopName}.`
          : `Uw auto wordt momenteel hersteld door ${bodyshopName}`,
    subText: ``,
    status: [
      'repair_in_progress',
      'expert_on_location',
      'Expert_planned',
      'Expert_replanned',
    ],
  }

  const fourthStep = {
    name: 'Check-out gepland',
    icon: 'phone',
    text: objectType === companyNames.Schadegarant
      ? carOuttakeMethod && outtakeDate ? 'Goed nieuws: uw auto is hersteld.'
        : `Goed nieuws: uw auto is hersteld. ${bodyshopName} neemt zo spoedig mogelijk contact met u op om een afspraak in te plannen voor de check-out.`
      : objectType === proccessType.DealerSass
        ? `Goed nieuws: uw auto is hersteld. Dealer ${bodyshopName} neemt zo spoedig mogelijk contact met u op om een afspraak in te plannen voor de check-out`
        : (status === '_new' ||
          status === 'carrefinisher_chosen' ||
          status === 'feedback' ||
          status === 'feedback_operations' ||
          status === 'carrefinisher_no_contact' ||
          status === 'carrefinisher_choosen')
          ? `Goed nieuws: uw auto is hersteld. ${bodyshopName} neemt zo spoedig mogelijk contact met u op om een afspraak in te plannen voor de check-out.`
          : status === 'carrefinisher_no_contact_outtake' ?
            `${bodyshopName} heeft u ${failedCallDate && failedCallTime && 'op ' + failedCallDate + ' ' + failedCallTime} gebeld om een afspraak voor de check-out in te plannen, maar kon u helaas niet bereiken.`
            : `Goed nieuws: uw auto is hersteld. ${bodyshopName} neemt zo spoedig mogelijk contact met u op om een afspraak in te plannen voor de check-out.`,
    subText: status === 'carrefinisher_no_contact_outtake'
      ? `${bodyshopName} zal morgen nogmaals contact met u proberen op te nemen. U kunt ook zelf bellen om een afspraak te maken.`
      : ``,
    status: [
      'damage_repaired',
      'carrefinisher_no_contact_outtake',
    ],
  }

  const fifthStep = {
    name: 'Check-out',
    icon: 'car-out',
    text: objectType === proccessType.DealerSass
      ? `U krijgt uw auto terug`
      : carOuttakeMethod && outtakeDate
        ? objectType === companyNames.Schadegarant
          ? carOuttakeMethod === 'bodyshop_dropoff'
            ? `Er komt een chaffeur op ${checkoutDate} tussen ${checkoutTime + ' - ' + checkoutTimeEndWindow} om uw auto terug te brengen.`
            : `U heeft een afspraak bij ${bodyshopName} op ${checkoutDate} tussen ${checkoutTime + ' - ' + checkoutTimeEndWindow} om uw auto op te halen.`
          : (status === '_new' ||
            status === 'carrefinisher_chosen' ||
            status === 'feedback' ||
            status === 'feedback_operations' ||
            status === 'carrefinisher_no_contact' ||
            status === 'date_planned' ||
            status === 'damage_repaired' ||
            status === 'carrefinisher_choosen')
            ? `${bodyshopName} brengt uw auto gratis terug (als u daar voor kiest).`
            : carOuttakeMethod === 'bodyshop_dropoff'
              ? !!chauffeur
                ? `${chauffeur.name} komt op ${checkoutDate} tussen ${checkoutTime + ' - ' + checkoutTimeEndWindow} uw auto terug brengen op onderstaande locatie.`
                : `Er komt een medewerker van ${bodyshopName} op ${checkoutDate} tussen ${checkoutTime + ' - ' + checkoutTimeEndWindow} om uw auto terug te brengen op onderstaande locatie.`
              : `U heeft een afspraak bij ${bodyshopName} op ${checkoutDate} tussen ${checkoutTime + ' - ' + checkoutTimeEndWindow} om uw auto op te halen.`
        : `${bodyshopName} brengt uw auto gratis terug (als u daar voor kiest).`,
    subText: carOuttakeMethod === 'bodyshop_dropoff' && checkOutAddress &&
      `${checkOutAddress.street} ${checkOutAddress.houseNumber},
        ${checkOutAddress.zipcode} ${checkOutAddress.city}`,
    status: [
      'outtake_scheduled',
      'future_outtake_scheduled',
    ],
  }

  const sixthStep = {
    name: 'Review',
    icon: 'star',
    text:
      (status === '_new' ||
        status === 'carrefinisher_chosen' ||
        status === 'feedback' ||
        status === 'feedback_operations' ||
        status === 'carrefinisher_no_contact' ||
        status === 'carrefinisher_choosen') ?
        `U heeft uw herstelde auto weer terug en rijdt hopelijk weer tevreden rond.` :
        `U heeft uw auto weer terug, we hopen dat u tevreden bent over onze dienst.`,
    subText:
      ``,
    onClick: {},
    status: [
      'Car_Collected',
      'damage_closed',
      'payment',
      'Review_Recieved',
    ],
  }

  return { firstStep, secondStep, thirdStep, fourthStep, fifthStep, sixthStep }
}

