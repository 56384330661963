import React, { useMemo } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import Welcome from './Welcome';
import Timeline from './Timeline';
import Question from './Question';

import routes from './routes';
import baseRoutes from '../routes';
import { createCustomTheme } from '@domain/helpers';
import { useScaling } from '@domain/hooks';
import { Colors } from '@domain/theming';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core';
import queryString from 'query-string';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { companyNames } from '@domain/constants';
import { Loader } from '@domain/components';


const GlobalStyle = createGlobalStyle`
  *,
  :after,
  :before {
    box-sizing: border-box;
  }

  :focus {
    outline: none;
  }

  html {
    -webkit-text-size-adjust: 100%;
  }
  html,
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
  body,
  p,
  span,
  button {
    font-family: 'Gabriel Sans';
  }
  #root,
  html,
  body,
  .screen-mobile,
  .screen-desktop,
  .screen-tablet {
    min-width: 100%;
    min-height: 100%;
  }
  
  .screen-ie {
    height: 0;
  }

  .screen-safari {
    position: absolute;
  }

  .screen-mobile,
  .screen-desktop,
  .screen-tablet {
    display: flex;
    flex-direction: column;
  }

  
  body {
    display: flex;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: ${Colors.BLACK};
    background-color: ${Colors.GREY_LIGHTER};
    &.theme-dark {
      color: ${Colors.WHITE};
      background-color: ${Colors.BLACK};
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  h1 {
    line-height: 1;
  }
  section {
    flex: 1 0 auto;
  }

  ul {
    padding-left: 0;
    margin: 0;
  }

  li {
    list-style-type: none;
  }

  .video {
    color: ${Colors.WHITE};

    .screen-ie,
    .screen-edge {
      padding: 0 80px;
      @media screen and (max-width: 1028px) {
        padding: 0;
      }
    }
    &.video-img {
      .screen-ie,
      .screen-edge {
        padding: 0;
      }
    }
  }

  dl {
    margin: 0;
  }
  dt,
  dd {
    display: inline;
  }

  dd {
    margin-left: 0;
  }

  dt {
    font-style: italic;
  }
  //overlay for background video
  :root > div:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0);
    z-index: -1;
  }

  .word-break {
    word-break: break-all;
  }
`;



function Preview({ location, match, history }) {
  const { welcome, timeline, question } = routes;
  const scaling = useScaling();

  const search = location.search;
  const queries = queryString.parse(search);

  const brand = !!queries && !!queries.Brand && decodeURI(queries.Brand);
  const displayName = !!queries && !!queries.DisplayName && decodeURI(queries.DisplayName);
  const logoURL = !!queries && !!queries.LogoURL && decodeURI(queries.LogoURL);
  const logoSize = !!queries && !!queries.LogoSize && decodeURI(queries.LogoSize);
  const backgroundURL = !!queries && !!queries.BackgroundURL && decodeURI(queries.BackgroundURL);
  const primaryColor = !!queries && !!queries.ColorPrimaryHEX && decodeURI(queries.ColorPrimaryHEX);
  const diBranding = !!queries && !!queries.bs;

  const validateHex = new RegExp("^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$")

  const branding = useMemo(() => (
    {
      brand: !!brand ? brand : companyNames.VIP,
      brandName: !!displayName && displayName,
      di: diBranding,
      fontFamily: 'Gabriel Sans',
      logoURL: !!logoURL ? logoURL : '',
      logoSize: !!logoSize ? logoSize : 'S',
      backgroundURL: !!backgroundURL ? backgroundURL : null,
      primaryColor: !!primaryColor && validateHex.test(primaryColor) ? primaryColor : Colors.GREY_DARKER,
    }), [brand, displayName, diBranding, logoURL, logoSize, backgroundURL, primaryColor, validateHex]);

  const theme = useMemo(() => createCustomTheme(branding), [branding]);

  const muiTheme = useMemo(() => (
    {
      ...createTheme({
        overrides: {
          MuiTouchRipple: {
            child: {
              backgroundColor: branding.primaryColor
            }
          }
        },
        palette: {
          primary: {
            main: branding.primaryColor
          }
        }
      }), ...theme
    }
  ), [branding, theme]);


  if (!queries) return <Loader />;

  return (
    <MuiThemeProvider theme={muiTheme}>
      <ThemeProvider theme={theme}>
        <GlobalStyle $scaling={scaling} />
        <Switch>
          <Route path={baseRoutes.preview + welcome} render={(props) => (<Welcome {...props} branding={branding} search={search} />)} />
          <Route path={baseRoutes.preview + timeline} render={(props) => (<Timeline {...props} branding={branding} search={search} />)} />
          <Route path={baseRoutes.preview + question} render={(props) => (<Question {...props} branding={branding} search={search} />)} />
          <Redirect to={baseRoutes.preview + welcome + search} />
        </Switch>
      </ThemeProvider>
    </MuiThemeProvider>
  );
}

export default withRouter(Preview);
