import browserStorage from "../browserStorage"
import checkIsFeedback from "../checkIsFeedback";
import checkIsNewStatusCase from "../checkIsNewStatusCase";

function checkInitialProgress(incident, questionnaire) {

  const isFeedback = checkIsFeedback(incident)
  const isNewStatusCase = checkIsNewStatusCase(incident)

  const progresFromLS = browserStorage.getProgress() || null
  const incidentProgress = !!incident && incident.progressStr ? incident.progressStr : null
  //Old progress. Keep it for cases midway
  const oldprogress = !!incident && incident.progress ? incident.progress : null

  const pageProgress = progresFromLS || incidentProgress
  const progress = pageProgress ? questionnaire.findIndex((page) => page.name === pageProgress) : oldprogress
  const currentProgress = () => {
    if (isFeedback) {
      return 0
    }
    if ((!!progress && parseInt(progress) > (questionnaire.length - 1)) || !isNewStatusCase) {
      return questionnaire.length - 1
    }
    if (!progress || parseInt(progress) < 0) {
      return 0
    }
    return parseInt(progress)
  }
  return currentProgress()
}

export default checkInitialProgress
