// NPM imports.
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// Domain imports.
import {
  Button,
  Checkbox,
  Container,
  GridColumn,
  GridContainer,
  Heading,
  IconLink,
  Legend,
  Loader,
  Main,
  NavBar,
  ProgressionIndicator,
  Textarea,
  UseIcon,
} from '@domain/components'
import { getPrevNextPagePath, stripLastPathSegment } from '@domain/helpers';
import { useSaveIncident, useCheckoutQuestionnaire } from '@domain/hooks';
import styled from 'styled-components';

// Routes for checkin flow.
import routes from '../routes';
import { companyNames, proccessType } from '@domain/constants';

const StyledMain = styled(Main)`
  .grid-container {
    .checkinout__question--title {
      flex: 1 1 0px;
      max-width: 100%;
      .legend {
        font-size: 16px;
        line-height: 20px;
      }
      .h1 {
        font-size: 48px;
        line-height: 56px;
      }
      p {
        font-size: 14px;
        line-height: 18px;
      }
    }
    .checkinout__question--question {
      flex: 1 1 0px;
      .screen-desktop:not(.screen-tablet) & {
        max-width: 540px;
        margin-left: auto;
        padding-left: 60px;
      }
    }
    .checkinout__question--footer {
      z-index: 3;
      .screen-desktop:not(.screen-tablet) & {
        position:absolute;
        padding: 40px;
        bottom: 0;
        right: 0;
        flex: 1 1 0px;
      }
      .screen-tablet & {
        padding-top: 20px;
      }
    }
  }
  @media (max-width: 1028px) {
    .screen-desktop:not(.screen-tablet) & {
      .grid-container {
        .checkinout__question--title {
          text-align: center;
          margin: auto;
        }
        .checkinout__question--question {
          margin: auto;
          padding: 60px 0 0 0;
        }
        .checkinout__question--footer {
          position: relative;
          padding: 0;
          width: 100;
        }
      }
    }
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 16px;
`;

const StyledTextArea = styled(Textarea)`
  margin-bottom: 16px;
`;

const StyledButton = styled(Button)`
  @media screen and (max-width: 580px) {
    .screen-desktop & {
      width: 100%;
    }
  }
`;

function Questions({ history, match }) {


  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);

  const questionnaire = useCheckoutQuestionnaire()

  const [customerOuttakeComment, setCustomerOuttakeComment] = useState(incident ? incident.customerOuttakeComment : '');
  const [outtakeQuestionBodyshop, setOuttakeQuestionBodyshop] = useState(incident ? incident.checkOutBodyshopQuestion : false);
  const [outtakeQuestionProcess, setOuttakeQuestionProcess] = useState(incident ? incident.checkOutProcessQuestion : false);
  const [outtakeQuestionTechnical, setOuttakeQuestionTechnical] = useState(incident ? incident.checkOutTechnicalQuestion : false);

  const saveIncident = useSaveIncident();

  // const nextButtonEnabled = !errors ? true : false;
  const nextButtonEnabled = true;
  const currentPath = match.path;
  const pagePath = getPrevNextPagePath(history, questionnaire, incident, currentPath, []);
  const { nextPage, prevPage } = pagePath;



  const checkCheckbox = (func) => () => {
    func(prevValue => {
      const newValue = !prevValue;
      return newValue;
    })
  }

  const handleChange = (event) => {
    setCustomerOuttakeComment(event.target.value);
  };

  const checkOutBodyshopQuestion = 'checkOutBodyshopQuestion';
  const checkOutProcessQuestion = 'checkOutProcessQuestion';
  const checkOutTechnicalQuestion = 'checkOutTechnicalQuestion';
  const customerOuttakeCommentName = 'customerOuttakeComment';

  const noOwnRiskRoute = stripLastPathSegment(currentPath) + routes.replacementVehicle;

  async function handleOnClick() {
    const savedIncident = await saveIncident({
      ...incident,
      [checkOutBodyshopQuestion]: outtakeQuestionBodyshop,
      [checkOutProcessQuestion]: outtakeQuestionProcess,
      [checkOutTechnicalQuestion]: outtakeQuestionTechnical,
      [customerOuttakeCommentName]: customerOuttakeComment,
    });
    if (savedIncident) {
      history.push(nextPage);
    }
  }

  if (!incident || !insurer) {
    return <Loader />;
  }


  const main = {
    className: 'main__checkinout main__checkinout--questions',
  };

  const iconLink = {
    type: 'arrow-left',
    theme: 'secondary',
    to: (incident.OwnRiskPaid || incident.deductibles === 0) ? noOwnRiskRoute : prevPage,
    replace: true,
  };

  const iconLinkForward = {
    type: 'arrow-right',
    theme: 'secondary',
    to: nextButtonEnabled && nextPage && nextPage,
    replace: true,
  };


  const button = {
    theme: nextButtonEnabled ? 'primary' : 'disabled',
    onClick: handleOnClick,
    justify: 'space-between',
    shadow: true,
    className: 'image-upload__button button--icon',
  };


  const isVIP = insurer.objectType === proccessType.Casco && (insurer.brand === companyNames.VIP || !insurer.brand)
  const header = isVIP
    ? <Heading level="1">Heeft u nog vragen over de VIP Schadeservice?</Heading>
    : <Heading level="1">Heeft u nog vragen over de schadeservice?</Heading>

  return (
    <React.Fragment>
      <StyledMain {...main}>
        <NavBar>
          <Container className="icon-link-container">
            <IconLink {...iconLink} />
            {!!nextButtonEnabled && <IconLink {...iconLinkForward} />}
          </Container>
          <ProgressionIndicator steps={3} current={2} />
          <Container className="empty-div" />
        </NavBar>
        <GridContainer main={true}>
          <GridColumn className="checkinout__question--title" justify="flex-start">
            <Legend>Vragen</Legend>
            {header}
          </GridColumn>

          <GridColumn
            className="checkinout__question--question"
            justify="flex-start"
          // className="grid__column--main"
          >
            <StyledCheckbox
              name="Vragen van technische aard"
              id="Vragen van technische aard"
              value={outtakeQuestionTechnical}
              onClick={checkCheckbox(setOuttakeQuestionTechnical)}
            >Vragen van technische aard
              <UseIcon name="checkmark" className="checkbox__icon" />
            </StyledCheckbox>
            <StyledCheckbox
              name="Vragen over het proces"
              id="Vragen over het proces"
              value={outtakeQuestionProcess}
              onClick={checkCheckbox(setOuttakeQuestionProcess)}
            >Vragen over het proces
              <UseIcon name="checkmark" className="checkbox__icon" />
            </StyledCheckbox>
            <StyledCheckbox
              name="Vragen over het schadeherstelbedrijf"
              id="Vragen over het schadeherstelbedrijf"
              value={outtakeQuestionBodyshop}
              onClick={checkCheckbox(setOuttakeQuestionBodyshop)}
            >Vragen over het schadeherstelbedrijf
              <UseIcon name="checkmark" className="checkbox__icon" />
            </StyledCheckbox>
            <StyledTextArea
              id="Vragen"
              name="Vragen"
              rows={4}
              onBlur={(e) => handleChange(e)}
              defaultValue={customerOuttakeComment}
            />
          </GridColumn>
          <GridColumn className="grid__column--footer checkinout__question--footer">
            <StyledButton {...button}>
              Volgende
              <UseIcon name="arrow-right" className="button__icon" />
            </StyledButton>
          </GridColumn>
        </GridContainer>
      </StyledMain>
    </React.Fragment>
  )
}

export default Questions;
