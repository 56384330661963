import React, { useState } from 'react';

import ToggleCard from '../ToogleCard';

const ToggleCardGroup = ({ cards, setCurrentLink, initialOpenCard = null }) => {


  const [openedItem, setOpenedItem] = useState(initialOpenCard);

  const generatedCards = cards.map((card, i) => {

    const toggleIsOpen = () => {
      if (card.link && setCurrentLink) {
        setCurrentLink(openedItem === i ? null : card.link)
      }
      setOpenedItem(item => (item === i ? null : i))
    };

    return (
      <ToggleCard
        key={i}
        card={card}
        isOpen={openedItem === i}
        toggleIsOpen={toggleIsOpen}
      />
    );
  })

  return <>{generatedCards}</>
};

export default ToggleCardGroup;
