import { Colors } from '@domain/theming';
import Color from 'color'

const createCustomTheme = (branding, isCustomThemed) => {
  if (!branding && !branding.keyOf('colors')) {
    return {};
  }
  const {
    favicon,
    fontFamily,
    fontFile,
    fontFileBold,
    logoURL,
    logoSize,
    backgroundURL,
    primaryColor,
    secondaryColor
  } = branding;
  const colorLight = `lighten(${primaryColor}, 33)`;
  const colorDark = `darken(${primaryColor}, 10)`;
  const color = Color(branding.primaryColor)

  const gradient = `linear-gradient(90deg,${primaryColor} 10%, ${!!secondaryColor ? secondaryColor : primaryColor} 90%)`;

  const isLight = (val: string) => {
    const baseColor = Color(val);
    const g = baseColor.green();
    const r = baseColor.red();
    const b = baseColor.blue();

    // const totalLuminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    // return totalLuminance > Math.sqrt(1.05 * 0.05) - 0.05;

    return (r * 0.299 + g * 0.587 + b * 0.114) > 186
  }
  return ({
    name: 'branded',
    colors: {
      primary: primaryColor,
      light: colorLight,
      dark: colorDark,
      secondary: secondaryColor,
      gradient: gradient,
      contrastText: branding.primaryColor && isLight(color.hex()) ? Color(Colors.GREY_DARK).hex() : Color(Colors.WHITE).hex(),
    },
    font: {
      family: fontFamily,
      bold: {
        baseSize: '',
        // baseLineHeight: '',
        // baseLetterSpacing: '',
        file: fontFileBold,
      },
      normal: {
        baseSize: '',
        // baseLineHeight: '',
        // baseLetterSpacing: '',
        file: fontFile,
      }
    },
    fontFamily: fontFamily,
    logo: logoURL,
    logoSize: logoSize,
    favicon: favicon,
    backgroundImage: backgroundURL,
    isCustomThemed,
    colorAsBackground: true,
  });
};

export default createCustomTheme;
