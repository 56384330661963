import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { Colors } from '@domain/theming';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';

import UseIcon from '../UseIcon';
import { useScaling } from '@domain/hooks';

const GlobalStyle = createGlobalStyle`

`;

const StyledFileIcon = styled(UseIcon)`
  background: ${Colors.BLACK};
  color: ${Colors.WHITE};
  border: 1px solid ${Colors.WHITE};
  border-radius: 35%;
  .screen-desktop & {
    width: ${props => props.$scaling.scaleUpTo4K(24)}px;
    height: ${props => props.$scaling.scaleUpTo4K(24)}px;
    margin-right: ${props => props.$scaling.scaleUpTo4K(8)}px;
  }
`;

const StyledMinusIcon = styled(UseIcon)`
  color: ${Colors.BLACK};
  .screen-desktop & {
    width: ${props => props.$scaling.scaleUpTo4K(24)}px;
    height: ${props => props.$scaling.scaleUpTo4K(24)}px;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    maxHeight: '100px',
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

function AttachmentViewer({ files, onClickDelete }) {
  const scaling = useScaling();
  const classes = useStyles();

  function generateItems() {
    const filesToDisplay = files ? files : []
    if (filesToDisplay.length > 0) {
      const fileItems = files.map((file) => {
        return (<ListItem key={file.cpID} style={{ backgroundColor: '#ffffff', paddingTop: '5px', paddingBottom: '5px', borderRadius: '8px', marginTop: '5px', marginBottom: '5px' }}>
          <ListItemIcon  >
            <StyledFileIcon
              name="icon-file"
              className="image-uploads"
              $scaling={scaling}
            />
          </ListItemIcon>
          <ListItemText
            primary={file.fileName}
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete" onClick={() => onClickDelete(file)} >
              <StyledMinusIcon
                name="icon-minus-circle"
                className="image-minus"
                $scaling={scaling}
              />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>)
      })
      return fileItems
    } else {
      return null
    }
  }

  return (
    <>
      <GlobalStyle />
      <div className={`${classes.root} scale`}>
        <div >
          <List>
            {generateItems()}
          </List>
        </div>
      </div>
    </>
  )
}

export default AttachmentViewer
