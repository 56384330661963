import {
  parseFetchResponseToJson,
  camelCaseToPascalCase,
} from '@domain/helpers';

import { endpoints } from '@domain/constants';

const { newIncident } = endpoints;

function save(incident, pid, fid, rid) {

  const PID = pid ? { PID: pid } : {}
  const FID = fid ? { FID: fid } : {}
  const RID = rid ? { RID: rid } : {}

  const headers = camelCaseToPascalCase({ 'Content-Type': 'application/json', ...PID, ...FID, ...RID })


  const body = JSON.stringify(camelCaseToPascalCase({ ...incident }))

  const config = {
    method: 'POST',
    headers: headers,
    body: body
  };

  return fetch(newIncident, config).then((response) => parseFetchResponseToJson(response, { body, endpoint: newIncident }));


}

export default { endpoints, save };
