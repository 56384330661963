import React, { useRef, useEffect, useCallback } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import 'moment/locale/nl';
import nlLocale from 'date-fns/locale/nl';
import { isMobileOnly } from 'react-device-detect';
import { useScaling } from '@domain/hooks';

const colorDanger = '#ed1c24';
const black = '#000000';
// const schadegarant_color = Colors.SCHADEGARANT_COLOR;

DateFnsUtils.prototype.getDatePickerHeaderText = function (date) {
  // wo. 6 nov
  return moment(date)
    .locale('nl')
    .format('dd. D MMM');
};

function DatePicker({ name, onChange, valid, value, disabled }) {
  const hasDefaulted = useRef(false);
  const inputRef = useRef(null);
  const defaultValue = value || null;
  const scaling = useScaling();
  const handleChange = useCallback(
    function handleChange(date) {
      const dateFormat = 'YYYY-MM-DD';
      const formattedDate = moment(date).format(dateFormat);

      const event = {
        target: {
          name,
          value: formattedDate,
        },
      };
      onChange(event);
    },
    [name, onChange],
  );

  const color = valid ? black : colorDanger;

  const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(1),
      width: '100%',
      // height: '67px',
      height:
        (isMobileOnly
          ? scaling.textSizeMobile(67)
          : scaling.textSize(67)) + 'px',
      // background: 'rgba(30,0,255,0.2)'
    },
    inputRoot: {
      fontSize:
        (isMobileOnly
          ? scaling.inputSizeMobile(defaultValue || value, 32, inputRef.current)
          : scaling.inputSize(defaultValue || value, 32, inputRef.current)) + 'px',
      fontWeight: 'bold',
      lineHeight:
        (isMobileOnly
          ? scaling.textSizeMobile(36)
          : scaling.textSize(36)) + 'px',
      borderColor: color,
      // alignItems: 'flex-end',
      '&$inputDisabled': {
        color: black,
      },
      // height: '51px',
      marginBottom: '0',
      marginTop: 'auto',
      // background: 'rgba(30,0,255,0.2)'
    },
    inputInput: {
      textAlign: 'left',
      letterSpacing: 'normal',
      marginTop: 'auto',
      paddingTop:
        ((!isMobileOnly ?
          scaling.textSize(6) :
          scaling.textSizeMobile(6)) + 'px'),
      paddingBottom:
        ((!isMobileOnly ?
          scaling.textSize(7) :
          scaling.textSizeMobile(7)) + 'px'),
      paddingLeft: '0',
      height: isMobileOnly
        ? scaling.textSizeMobile(56)
        : scaling.textSize(56) + 'px',
      // background: 'rgba(30,0,255,0.2)'
      // marginBottom: '8px'
    },
    adornmentRoot: {
      height:
        (isMobileOnly
          ? scaling.textSizeMobile(56)
          : scaling.textSize(56)) + 'px',
      '& button': {
        '.theme-custom &': {
          color: valid ? theme.colors.primary : colorDanger,
        }
      },
      '& svg': {
        fontSize: ((!isMobileOnly ?
          scaling.textSize(24) :
          scaling.textSizeMobile(24)) + 'px'),
      }
    },
    inputLabelRoot: {
      display: 'block',
      fontFamily: theme.fontFamily,
      height: scaling.scaleUpTo4K(32),
      borderBottom: '0.5px',
      color: color,
      fontSize:
        (isMobileOnly
          ? scaling.inputSizeMobile(defaultValue || value, 32, inputRef.current)
          : scaling.inputSize(defaultValue || value, 32, inputRef.current)) + 'px',
      fontWeight: 'bold',
      lineHeight: (isMobileOnly
        ? scaling.textSizeMobile(32)
        : scaling.textSize(32)) + 'px',
      transform: `translate(0, ${scaling.scaleUpTo4K(25)}px)`,
      whiteSpace: 'nowrap',
      '&$inputLabelDisabled': {
        color,
      },
      '&+$inputRoot': {
        marginTop: '0',
      },
      '.theme-custom &': {
        color: theme.colors.primary,
        // fontFamily: 'Dag'
      }
    },
    inputLabelShrink: {
      fontSize:
        (isMobileOnly
          ? scaling.inputSizeMobile(defaultValue || value, 16, inputRef.current)
          : scaling.inputSize(defaultValue || value, 16, inputRef.current)) + 'px',
      fontWeight: 'bold',
      color,
      transform: `translate(0, -${((!isMobileOnly ?
        scaling.textSize(20) :
        scaling.textSizeMobile(20)))}px)`,
      lineHeight: '14px',
      letterSpacing: '0.0625em',
      transformOrigin: 'top left',
      '.theme-custom &': {
        color: theme.colors.primary,
      }
    },
    inputError: { color: colorDanger },
    inputLabelAsterisk: {
      visibility: 'hidden',
    },
    inputLabelFormControl: {
      '&$focused': {
        color,
        fontWeight: 'bold',
      },
      '&$inputLabelError': {
        color: colorDanger,
      },
      '.theme-custom &': {
        '&$focused': {
          color: theme.colors.primary,
        }
      }
    },

    inputUnderline: {
      '&$inputError': {
        '&:before': {
          borderColor: colorDanger,
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: `1px solid ${colorDanger}`,
        },
        '&:after': {
          borderBottom: `2px solid ${colorDanger}`,
        },
      },
      '&:before': {
        borderColor: color,
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: `1px solid ${color}`,
      },
      '&:after': {
        borderBottom: `2px solid ${color}`,
      },
      '&$inputDisabled:before': {
        borderBottom: '0px',
      },
      '&$inputDisabled:after': {
        borderBottom: '0px',
      },
      '.theme-custom &': {
        '&:before': {
          borderColor: theme.colors.primary,
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: `1px solid ${theme.colors.primary}`
        },
        '&:after': {
          borderBottom: `2px solid ${theme.colors.primary}`
        },
      },
    },
    inputLabelClasses: {},
    inputDisabled: {},
    inputLabelDisabled: {},
    focused: {},
    inputLabelError: {},
    dense: {
      marginTop: 19,
    },
    menu: {
      width: 200,
    },
  }));

  useEffect(() => {
    if (hasDefaulted.current) {
      return;
    }

    hasDefaulted.current = true;

    if (value) {
      return;
    }

    handleChange(null);
  }, [handleChange, value]);

  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nlLocale}>
      <KeyboardDatePicker
        cancelLabel="Annuleren"
        inputRef={inputRef}
        className={classes.textField}
        disableFuture
        variant="dialog"
        format="dd-MM-yyyy"
        invalidDateMessage=""
        margin="normal"
        maxDateMessage=""
        error={!valid}
        id="date-picker-inline"
        label="Schadedatum"
        value={defaultValue}
        onChange={handleChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        disabled={disabled}
        InputAdornmentProps={{
          classes: {
            root: classes.adornmentRoot,
          },
        }}
        InputProps={{
          classes: {
            root: classes.inputRoot,
            underline: classes.inputUnderline,
            input: classes.inputInput,
            disabled: classes.inputDisabled,
            error: classes.inputError,
          },
          placeholder: 'dd-mm-jjjj',
        }}
        InputLabelProps={{
          htmlFor: 'date-picker-inline',
          classes: {
            root: classes.inputLabelRoot,
            shrink: classes.inputLabelShrink,
            asterisk: classes.inputLabelAsterisk,
            formControl: classes.inputLabelFormControl,
            focused: classes.focused,
            disabled: classes.inputLabelDisabled,
            error: classes.inputLabelError,
          },
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

export default DatePicker;
