import checkOutRoutes from './routes';
import routes from '../routes';

const { checkout } = routes;
const {
  chauffeur,
  //end,
  instructions,
  overview,
  ownRisk,
  questions,
  replacementVehicle,
  //signing,
  start,
  success
} = checkOutRoutes;

const questionnaire = [
  {
    name: 'instructions',
    path: checkout + instructions,
    check: () => true,
  },
  {
    name: 'start',
    path: checkout + start,
    check: () => true,
  },
  {
    name: 'chauffeur',
    path: checkout + chauffeur,
    check: () => true,
  },
  {
    name: 'overview',
    path: checkout + overview,
    check: () => true,
  },
  {
    name: 'replacementVehicle',
    path: checkout + replacementVehicle,
    check: () => true,
  },
  {
    name: 'ownRisk',
    path: checkout + ownRisk,
    check: () => true,
  },
  {
    name: 'questions',
    path: checkout + questions,
    check: () => true,
  },
  // {
  //   name: 'end',
  //   path: checkout + end,
  //   check: () => true,
  // },
  {
    name: 'success',
    path: checkout + success,
    check: () => true,
  },
];

export default questionnaire;
