import {
  INCIDENT_FETCHED,
  INCIDENT_CREATED,
  LOGGED_IN,
  INCIDENT_UPDATED, LOGGED_OUT,
  SP_DETAILS_DETECTED,
  SP_SG_DETAILS_DETECTED,
  TEMP_INCIDENT_CREATED,
  TEMP_INCIDENT_UPDATED,
  TEMP_INCIDENT_FOUND_IN_BROWSER_STORAGE,

} from '@domain/action-types';

// Since the incident is an object I have given it a default value of null.
// Null is often used as a sort of falsy object (not just in javascript).
// I would also do the same if state was an array.
// There's no rule stating that the default value should be falsy; but it gives the app an easy way to detect if the incident has been fetched

// I've used destructuring for the second argument. You'd usually see it written as just action, and then the values action.type and action.incident would be used instead
// The type value should always be a string value. The incident value is optional and might just be undefined
// Also you will sometimes see people use 'payload" instead of "incident", but I don't like this so I didn't use it
const incident = (state = null, { type, incident }) => {


  switch (type) {
    case LOGGED_IN:
      // Note that neither the state nor the action should never be mutated
      return !!incident ? { ...incident } : state;
    case LOGGED_OUT:
      return null;
    case INCIDENT_FETCHED:
      return { ...incident };
    case INCIDENT_CREATED:
      return { ...incident }
    case INCIDENT_UPDATED:
      // Server always responds with the full object
      return { ...incident };
    case SP_DETAILS_DETECTED:
      return state ?
        { ...state, ...incident } :
        { ...incident };
    case SP_SG_DETAILS_DETECTED:
      return state ?
        { ...state, ...incident } :
        { ...incident };
    case TEMP_INCIDENT_CREATED:
      return state ?
        { ...state, ...incident } :
        { ...incident };
    case TEMP_INCIDENT_UPDATED:
      return { ...incident };
    case TEMP_INCIDENT_FOUND_IN_BROWSER_STORAGE:
      return { ...state, ...incident };

    // Every reducer will see every action dispatched, so it is essential to have the reducer return the currentState by default
    default:
      return state;
  }
};

export default incident;
