import React from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

const StyledDiv = styled.div`
  &.flex-container {
    display: flex;
  }
`;

const Container = ({ children, className, flex, }) => {
  
  const classes = classNames({'flex-container' : flex} , className);

  return (
    <StyledDiv className={classes} >{React.Children.toArray(children)}</StyledDiv>
  );
};

export default Container;
