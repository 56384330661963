import { Colors } from '@domain/theming';
import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  width: ${props => props.width};
  height: ${props => !!props.height ? props.height : props.width};
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color};
  border: ${props => !!props.border ? '1px solid ' + props.color : 'none'};
  border-radius: ${props => props.borderRadius};
`;

const TickSVG = ({
  width = '25px',
  height,
  color = Colors.BLACK,
  backgroundColor = 'transparent',
  border = true,
  borderRadius = '50%',
}) => (
  <SVG
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={border ? '0 0 64 64' : '16 16 33 33'}
    //preserveAspectRatio="meet"
    width={width}
    height={height}
    backgroundColor={backgroundColor}
    color={color}
    border={border}
    borderRadius={borderRadius}
  >
    <path
      d="M30.27,40.25a2,2,0,0,1-1.41-.59l-6-6a2,2,0,0,1,2.83-2.83l4.57,4.58,10.1-10.08a2,2,0,0,1,2.83,2.83l-11.5,11.5a2,2,0,0,1-1.42.59Z"
      fill="currentColor"
    />
  </SVG>
);

export default TickSVG;
