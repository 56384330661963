import React, { Fragment, useContext } from 'react';
import { useSelector } from 'react-redux';

import { Loader } from '@domain/components';
import { chooseLogoSize, generatePageContent, stripLastPathSegment } from '@domain/helpers';
import { DeleteImagesPage } from '@domain/pages';
import imageRoutes from '../routes';
import { detectContentType } from '@domain/content';
import { ThemeContext } from 'styled-components';

function ImageDelete({ history, match }) {

  const files = useSelector(state => state.files);
  const images = files.damageImages
  const insurer = useSelector(state => state.insurer);

  const themeContext = useContext(ThemeContext);

  const currentPath = match.path;
  const { upload } = imageRoutes;

  const prevPage = stripLastPathSegment(currentPath) + upload;

  if (!images || !insurer) {
    return <Loader />;
  }

  const contentType = detectContentType(insurer)
  const pageName = "images"
  const content = generatePageContent(contentType, insurer, undefined, false, false, pageName)

  const customHelpLink = content["custom-help-link"]
  const helpLinkText = content["help-link-text"]
  const useCustomLogo = content["use-custom-logo"]
  const instructions = content["instructions"]
  const firstFaqs = content["faqs"]
  const faqs = firstFaqs.concat({
    question: "Hoe maak ik goede foto's?",
    answer: (
      <dl>
        {instructions.map(({ title, explanation }, i) => (
          <Fragment key={title}>
            <dt>
              {i + 1}. {title}
            </dt>
            <br />
            <dd>{explanation}</dd>
            <br />
            <br />
          </Fragment>
        ))}
      </dl>
    ),
  })

  const middleLogo = {
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }

  return (
    <DeleteImagesPage
      history={history}
      prevPage={prevPage}
      faqs={faqs}
      filesSetName="damageImages"
      filesSet={images}
      insurer={insurer}
      currentPath={currentPath}
      customHelpLink={customHelpLink}
      helpLinkText={helpLinkText}
      customMiddleLogo={useCustomLogo}
      middleLogo={middleLogo}
    />
  )
}

export default ImageDelete;
