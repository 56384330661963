import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TEMP_INCIDENT_UPDATED } from '@domain/action-types';
import { useDispatchError } from '..';
import { browserStorage } from '@domain/helpers';

function useSaveTempIncident() {
  // Only happens with Digital Intake cases
  // The incident will only be created in the core platform
  // after all necessary data was collected

  const dispatch = useDispatch();
  const { dispatchNewError } = useDispatchError();

  const currentIncident = useSelector(state => state.incident);

  const savingTempIncident = useRef(false);
  const saveTempIncident = useCallback(
    async function (newDetails) {
      if (savingTempIncident.current) {
        return;
      }
      savingTempIncident.current = true;
      try {
        const newTempIncident = { ...currentIncident, ...newDetails };
        browserStorage.saveValuesInLocalStorage(newTempIncident);
        dispatch({
          type: TEMP_INCIDENT_UPDATED,
          incident: newTempIncident,
        })

        savingTempIncident.current = false;
        return newTempIncident;
      } catch ({ error }) {
        console.error(`Error occurred trying to save temp incident to local storage`, error);
        dispatchNewError(error);
      }
      savingTempIncident.current = false;
    },
    [currentIncident, dispatch, dispatchNewError,]
  )

  return saveTempIncident;
}

export default useSaveTempIncident;
