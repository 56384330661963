import { useEffect, useCallback } from 'react';

const excludedTargets = ['INPUT', 'TEXTAREA'];

function useKeyboardShortcut(triggerKeys, callback) {
  const keyListener = useCallback(
    (keyboardEvent) => {
      const { which, keyCode, repeat, target } = keyboardEvent;

      const currentKey = which || keyCode;
      if (excludedTargets.includes(target.tagName)) {
        return;
      }
      if (triggerKeys.includes(currentKey) && !repeat) {
        callback();
      }
    },
    [triggerKeys, callback],
  );

  useEffect(() => {
    if (triggerKeys) {
      window.addEventListener('keydown', keyListener);
      return () => window.removeEventListener('keydown', keyListener);
    }
  }, [keyListener, triggerKeys]);
}
export default useKeyboardShortcut;
