import { CASE_PID_FOUND, NEW_PID_FOUND } from '@domain/action-types';

const initialCasePID = '';

const pid = (state = initialCasePID, { type, pid }) => {
  switch (type) {
    case CASE_PID_FOUND:
      return pid;
    case NEW_PID_FOUND:
      return pid;
    default:
      return state;
  }
};

export default pid;
