import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import { useScaling } from '@domain/hooks';
import { isIE } from 'react-device-detect';

const StyledLogo = styled.img`
  height: ${props => isIE ? !!props.$logoHeight ? props.$logoHeight + 'px' : 'auto' : props.$logoHeight + 'px'};
  width: ${props => isIE ? !!props.$logoHeight ? 'auto' : '100%' : props.logoWidth + 'px'};
  &.company-logo--left {
    // align-self: flex-start;
    object-position: left;
  }
  &.company-logo--right {
    // align-self: flex-end;
    object-position: right;
  }
  &.company-logo--center {
    align-self: center;
  }
  &.company-logo {
    object-fit: contain;
  }
  &.vip-logo {
    max-width: ${props => props.$scaling.scaleUpTo4K(100)}px;
  }
  @media screen and (max-width: 360px) {
    height: 28px;
  }
`;

const StyledLogoContainer = styled.div`
  height: ${props => !!props.$logoHeight ? props.$logoHeight + 'px' : 'auto'};
  width: ${props => !!props.logoWidth ? props.logoWidth + 'px' : 'auto'};
`;

function CompanyLogo({ width, height, src, align, className, alt = '' }) {
  const scaling = useScaling();
  const classes = classNames(
    'company-logo',
    {
      'company-logo--center': align === 'center',
      'company-logo--right': align === 'right',
      'company-logo--left': align === 'left'
    },
    className
  );

  if (isIE) {
    return (
      <StyledLogoContainer
        $logoHeight={scaling.scaleUpTo4K(height)}
        logoWidth={scaling.scaleUpTo4K(width)}
      >
        <StyledLogo
          alt={alt}
          className={classes}
          $logoHeight={scaling.scaleUpTo4K(height)}
          logoWidth={scaling.scaleUpTo4K(width)}
          $scaling={scaling}
          src={src}
        />
      </StyledLogoContainer>
    )
  }

  return (
    <StyledLogo
      alt={alt}
      className={classes}
      $logoHeight={scaling.scaleUpTo4K(height)}
      logoWidth={scaling.scaleUpTo4K(width)}
      $scaling={scaling}
      src={src}
    />
  );
}

CompanyLogo.propTypes = {
  height: PropTypes.number.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  align: PropTypes.oneOf(['left', 'right', 'center']),
  className: PropTypes.string
};

export default CompanyLogo;
