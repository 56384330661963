import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import ListItem from '@material-ui/core/ListItem';



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: '10px 16px 10px 22px;',
    display: 'flex',
    borderBottom: '1px solid #DDDDDD',
    alignItems: 'center',
  },
  closeButton: {
    padding: 0,
    height: '32px',
    width: '32px',
    marginLeft: '10px',
  },
  options: {
    color: '#000000',
    padding: '0px',
    '& a': {
      textDecoration: 'none',
      fontSize: '16px',
      color: '#000000',
      width: '100%',
      padding: '10px 16px',
    }
  },
  item: {
    fontSize: '18px',
    color: '#000000',
  }

});

function CalendarModal({
  children,
  isOpen,
  onRequestClose,
  title
}) {

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="subtitle1">{children}</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onRequestClose}>
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
    );
  });

  const CalendarOptions = withStyles(styles)(props => {
    const { classes } = props
    const link = React.Children.map(children || null, (child, i) => {
      return <ListItem className={classes.options} button key={i} onClick={onRequestClose}> {child}</ListItem>
    })
    return link
  })

  return (
    <div>
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="customized-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <CalendarOptions />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CalendarModal;
