import { object, string } from 'yup';
import moment from 'moment';

const licenseRegex = /^[0-9a-zA-Z]{6}$/;

const pastDate = date => {
  const inputDate = moment(date);
  const now = moment();
  return !inputDate.isAfter(now);
};

const validDate = date => {
  return date !== 'Invalid date';
};

const validationSchema = (invalidLicenses: string[], usedLicenses: string[]) =>
  object({
    licensePlate: string()
      .matches(licenseRegex, 'Vul alstublieft een geldig kenteken in')
      .test(
        'Case with license must not already exist',
        'Er bestaat al een dossier met dit kenteken. Click op de knop in de email die u heeft ontvangen bij het melden van de schade om de status van dit dossier in te zien.',
        value => !usedLicenses.includes(value),
      )
      .test(
        'This license does not exist',
        'Het ingevulde kenteken bestaat niet',
        value => !invalidLicenses.includes(value),
      )
      .required()
      .label('Kenteken'),
    damageDatePortal: string()
      .required()
      .test(
        'Date must not be in future',
        'De datum mag niet in de toekomst liggen',
        pastDate,
      )
      .test('Date must be valid', 'Voeg een geldige datum toe', validDate)
      .label('Schadedatum'),
  });

export default validationSchema;
