import styled from 'styled-components';
import { Colors, Shadows } from '@domain/theming';
import classNames from 'classnames';


const Card = styled.div.attrs(({ className }) => ({
  className: classNames('card', className),
}))`
  display: flex;
  position: relative;
  flex-direction: column;
  flex: ${props =>
    `${props.flexGrow || 1} ${props.flexShrink || 1} ${props.flexBasis ||
    'auto'}`};
  
  margin: ${props => props.margin};
  margin-top: ${props => props.marginTop};
  margin-right: ${props => props.marginRight};
  margin-bottom: ${props => props.marginBottom};
  margin-left: ${props => props.marginLeft};
  padding: ${props => props.padding || '50px'};
  padding-top: ${props => props.paddingTop};
  padding-right: ${props => props.paddingRight};
  padding-bottom: ${props => props.paddingBottom};
  padding-left: ${props => props.paddingLeft};
  background: ${props =>
    props.background === 'black'
      ? Colors.BLACK
      : props.background === 'white'
        ? Colors.WHITE
        : props.background === 'dark-grey'
          ? Colors.GREY_DARK
          : Colors.GREY_LIGHT};
  border-radius: ${props => props.borderRadius || '16px'};
  min-width: ${props => props.minWidth || 0};
  max-width: ${props => props.maxWidth};
  width: ${props => props.width};
  height: ${props => props.height};
  box-shadow: ${props =>
    props.shadow === 1 ?
      Shadows.SHADOW1 :
      props.shadow === 2 ?
        Shadows.SHADOW2 :
        props.shadow === 3 ?
          Shadows.SHADOW3 :
          props.shadow === 4 ?
            Shadows.SHADOW4 :
            props.shadow === 6 ?
              Shadows.SHADOW6 :
              props.shadow === 12 ?
                Shadows.SHADOW12 :
                ''
  };
`;

export default Card;
