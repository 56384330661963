function filterNonImages(uploads) {
  const result = {};

  let wrongImageType = false;
  for (let i = 0; i < uploads.length; i++) {
    const img = uploads[i];
    if (['image/jpeg', 'image/png'].includes(img.type)) {
      result[i] = img;
    } else {
      wrongImageType = true;
    }
  }

  return [result, wrongImageType];
}

export default filterNonImages;
