import React, { useRef } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';

import { useKeyboardShortcut, useScaling } from "@domain/hooks"

import UseIcon from '../UseIcon';
import { Colors } from '@domain/theming';

const GlobalStyle = createGlobalStyle`
  .icon-link-container {
    display:flex;
    align-items: center;
    justify-content: flex-start;
  }
`;

const StyledLink = styled(Link)`
  width: 33px;
  height: 33px;
  border: 0;
  cursor: pointer;
  background: transparent;
  padding: 0;
  .icon-link-container & {
    + .icon-link {
      margin-left: ${props => props.$scaling.textSize(20)}px;
    }
  }

  .screen-desktop & {
    width: ${props => props.$scaling.textSize(33)}px;
    height: ${props => props.$scaling.textSize(33)}px;
  }
  @media screen and (max-width: 580px) {
    .icon-link-container & {
      + .icon-link {
        margin-left: 10px;
      }
    }
  }

  @media screen and (max-width: 360px) {
    width: 28px;
    height: 28px;
    svg {
      width: 26px;
      height: 26px;
    }
    .icon-link-container & {
      + .icon-link {
      }
    }
  }
`;

const StyledUseIcon = styled(UseIcon)`
  width: 31px;
  height: 31px;
  border-radius: 50%;
  background-color: ${Colors.WHITE};
  color: ${Colors.BLACK};
  border: ${props => props.$scaling.scaleUpTo4K(1).toFixed()}px solid ${Colors.WHITE};
  box-sizing: content-box;

  &.icon-link-icon--secondary {
    background-color: transparent;
    color: ${Colors.BLACK};
    border-color: ${Colors.GREY_LIGHT};
  }
  &.icon-link-icon--tertiary {
    background-color: transparent;
    color: ${Colors.WHITE};
    border-color: ${Colors.WHITE};
  }
  .screen-desktop & {
    width: ${props => props.$scaling.textSize(31)}px;
    height: ${props => props.$scaling.textSize(31)}px;
  }
  .theme-custom & {
    &.icon-link-icon {
      &--primary {
        color: ${props => props.theme.colors.primary};
      }
      &--secondary {
        color: ${Colors.WHITE};
        background: ${props => props.theme.colors.primary};
        border-color: ${props => props.theme.colors.primary};
      }
      &--tertiary {
        
      }
    }
  }
`;

function IconLink({ type, theme, to, replace, triggerKey, onClick }) {
  const scaling = useScaling();
  const iconClasses = classNames('icon-link-icon', {
    'icon-link-icon--primary': theme === 'primary', //white on black
    'icon-link-icon--secondary': theme === 'secondary', //black/grey outline
    'icon-link-icon--tertiary': theme === 'tertiary' //black on white
  });

  const arrowRef = useRef(null);

  useKeyboardShortcut(triggerKey, () => arrowRef.current.click())
  return (
    <>
      <GlobalStyle />
      <StyledLink
        className="icon-link"
        to={to}
        replace={replace}
        ref={arrowRef}
        onClick={onClick}
        $scaling={scaling} // Link only accepted custom prop without capital letters
      >
        <StyledUseIcon name={type} className={iconClasses} $scaling={scaling} />
      </StyledLink>
    </>
  );
}

IconLink.defaultProps = {
  theme: 'primary'
};

IconLink.propTypes = {
  type: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  to: PropTypes.any.isRequired,
  replace: PropTypes.bool
};

export default IconLink;
