import { object, string } from 'yup';

const damageNumberRegEx = /[^A-Za-z0-9]*/

export const validationSchema = object({
  damageNumber: string()
    .matches(damageNumberRegEx, 'Controleer alstublieft uw schadenummer.')
    .required()
    .label('schadenummer'),
});



