import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

function FAQList({
  children,
  faqVisible,
  onTouchStart,
  onTouchMove,
  onTouchEnd
}) {
  const classes = classNames('faq__list', {
    // 'faq__list--invisible': !faqVisible
  });

  const [startX, setStartX] = useState(null);
  const [newX, setNewX] = useState(null);

  useEffect(() => {
    if (newX - startX > 100) {
      setStartX(null);
      setNewX(null);
    }
  }, [newX]);

  return (
    <ul
      className={classes}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      {React.Children.toArray(children)}
    </ul>
  );
}

export default FAQList;
