
function filterLargeFiles(uploads, maxNoOfMbs = 10) {
  //const oneMb = 1024 * 1024;
  const oneMb = 1048576
  // To alter the size of what is considered a big image:
  const bigNoOfMbs = 5;
  // To alter the max size accepted for images

  const sizeBig = bigNoOfMbs * oneMb;
  const sizeLimit = maxNoOfMbs * oneMb;

  let bigFilePresent = false;
  let largeFilePresent = false;


  function checkActualUploads() {
    const actualUploads = {};
    for (let key in uploads) {
      const file = uploads[key];
      if (file.size) {
        if (file.size >= sizeBig && file.size < sizeLimit) {
          bigFilePresent = true;
          actualUploads[key] = file
        } else if (file.size >= sizeLimit) {
          largeFilePresent = true;
        } else {
          actualUploads[key] = file
        }
      }
    }
    return actualUploads
  }

  return ([checkActualUploads(), bigFilePresent, largeFilePresent]);
}

export default filterLargeFiles;
