import {
  parseFetchResponseToJson,
  camelCaseToPascalCase,
} from '@domain/helpers';
import { browserStorage } from '@domain/helpers';

import { endpoints } from '@domain/constants';
const callbackEndpoint = endpoints.callback;

function save(callback) {
  const body = JSON.stringify(camelCaseToPascalCase(callback))
  const config = {
    method: 'POST',
    headers: camelCaseToPascalCase({
      token: browserStorage.getAuthenticationToken(),
      'Content-Type': 'application/json',
    }),
    body: body,
  };

  return fetch(callbackEndpoint, config).then((response) => parseFetchResponseToJson(response, { endpoint: callbackEndpoint, body }));
}

export default { save }
