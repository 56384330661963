import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useScaling } from '@domain/hooks';

const StyledSVG = styled.svg`
`;

const UseIcon = ({ name, width = 32, height = 32, className = '' }) => {
  const scaling = useScaling();
  switch (name) {
    case 'exclamation-mark':
      return (
        <StyledSVG
          className={className}
          id="Asset_46"
          data-name="Asset 46"
          xmlns="http://www.w3.org/2000/svg"
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          viewBox="0 0 64 64"
        >
          <title>{'icon-' + name}</title>
          <g id="Layer_1" data-name="Layer 1">
            <circle
              id="Ellipse_9"
              data-name="Ellipse 9"
              cx="32"
              cy="32"
              r="32"
              fill="currentColor"
            />
            <path
              id="Path_21"
              data-name="Path 21"
              d="M32,39.11a2,2,0,0,1-2-2V23.89a2,2,0,1,1,4,0V37.11A2,2,0,0,1,32,39.11Z"
              fill="currentColor"
            />
            <circle
              id="Ellipse_10"
              data-name="Ellipse 10"
              cx="2"
              cy="2"
              r="2"
              transform="translate(30 42.11)"
              fill="currentColor"
            />
          </g>
        </StyledSVG>
      );
    case 'arrow-down':
      return (
        <StyledSVG
          className={className}
          id="Asset_46"
          data-name="Asset 46"
          xmlns="http://www.w3.org/2000/svg"
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          viewBox="0 0 256 256"
        >
          <title>{'icon-' + name}</title>
          <g><polygon
            fill="currentColor"
            points="225.813,48.907 128,146.72 30.187,48.907 0,79.093 128,207.093 256,79.093" />
          </g>
        </StyledSVG>
      );
    case 'icon-arrow-left':
      return (
        <StyledSVG
          className={className}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          viewBox="0 0 10 9"
        >
          <title>{'icon-' + name}</title>
          <path
            fill="currentColor"
            d="M9,5H2.3l2.5,2.4C4.9,7.6,5,7.7,5,7.8c0,0.1,0,0.3-0.1,0.4C4.7,8.3,4.6,8.3,4.5,8.3c-0.1,0-0.3,0-0.4-0.1L0.6,4.9
	C0.5,4.8,0.5,4.7,0.5,4.5c0-0.1,0.1-0.3,0.2-0.4l3.5-3.3c0.1-0.1,0.2-0.1,0.4-0.1c0.1,0,0.3,0.1,0.4,0.2C4.9,0.9,5,1,5,1.2
	c0,0.1-0.1,0.3-0.2,0.4L2.3,4H9c0.1,0,0.2,0,0.4,0.1c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0,0.3-0.1,0.4C9.3,5,9.1,5,9,5z"
          />
        </StyledSVG>
      );
    case 'icon-arrow-right':
      return (
        <StyledSVG
          className={className}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          viewBox="0 0 10 9"
        >
          <title>{'icon-' + name}</title>
          <path
            fill="curentColor"
            d="M1,4h6.7L5.2,1.5C5.1,1.4,5,1.3,5,1.2s0-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.4-0.2s0.3,0,0.4,0.1l3.4,3.3
	c0.1,0.1,0.2,0.2,0.2,0.4c0,0.1-0.1,0.3-0.2,0.4L5.9,8.2C5.8,8.3,5.7,8.3,5.5,8.3c-0.1,0-0.3-0.1-0.4-0.2C5.1,8.1,5,8,5,7.8
	c0-0.1,0.1-0.3,0.2-0.4L7.7,5H1C0.9,5,0.7,5,0.6,4.9C0.5,4.8,0.5,4.7,0.5,4.5c0-0.1,0-0.3,0.1-0.4C0.7,4,0.9,4,1,4z"
          />
        </StyledSVG>
      );
    case 'icon-check-circle-filled':
      return (
        <StyledSVG
          className={className}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 21 21"
        >
          <title>{'icon-' + name}</title>
          <path d="M10.5,21C4.7,21,0,16.3,0,10.5S4.7,0,10.5,0S21,4.7,21,10.5S16.3,21,10.5,21z" />
          <path
            fill="currentColor"
            d="M13.7,8.1C13.7,8.2,13.7,8.2,13.7,8.1c0,0.1,0,0.1,0,0.2l-3.9,5c-0.1,0.1-0.1,0.1-0.1,0.1
	c-0.1,0-0.1,0-0.2-0.1l-2.2-2.1l0,0v-0.1c0,0,0,0,0-0.1l0,0c0.3-0.3,0.5-0.6,0.7-0.7c0.1-0.1,0.1-0.1,0.1-0.1s0.1,0,0.2,0.1l1.2,1.2
	l3.1-4h0.1h0.1L13.7,8.1z"
          />
        </StyledSVG>
      );
    case 'icon-check-circle':
      return (
        <StyledSVG
          className={className}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          viewBox="0 0 21 21"
        >
          <title>{'icon-' + name}</title>
          <path
            fill="currentColor"
            d="M10.5,21C4.7,21,0,16.3,0,10.5C0,4.7,4.7,0,10.5,0S21,4.7,21,10.5C21,16.3,16.3,21,10.5,21z M10.5,1.2
	c-5.1,0-9.3,4.2-9.3,9.3s4.2,9.3,9.3,9.3c5.1,0,9.3-4.2,9.3-9.3S15.6,1.2,10.5,1.2z"
          />
          <path
            fill="currentColor"
            d="M10,13c-0.2,0-0.3-0.1-0.4-0.2L7.7,11c-0.2-0.2-0.2-0.6,0-0.9c0.2-0.2,0.6-0.2,0.9,0l1.4,1.4l3.1-3.1c0.2-0.2,0.6-0.2,0.9,0
	c0.2,0.2,0.2,0.6,0,0.9l-3.6,3.6C10.3,13,10.1,13,10,13z"
          />
        </StyledSVG>
      );
    case 'icon-check':
      return (
        <StyledSVG
          className={className}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          viewBox="0 0 10 9"
        >
          <title>{'icon-' + name}</title>
          <path
            d="M9.8,1.1c0,0.1,0,0.1,0,0.1c0,0,0,0.1,0,0.1L4,8.9C3.9,9,3.8,9,3.8,9C3.7,9,3.6,9,3.5,8.9L0.2,5.7L0.2,5.6
	c0-0.1,0-0.1,0-0.1c0,0,0-0.1,0-0.1l0,0c0.4-0.5,0.8-0.8,1-1.1c0.1-0.1,0.2-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0.1l1.9,1.8l4.7-6
	c0,0,0.1,0,0.1,0c0,0,0.1,0,0.2,0L9.8,1.1z"
          />
        </StyledSVG>
      );
    case 'icon-file':
      return (
        <StyledSVG
          className={className + ' input-file--icon'}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          viewBox="0 0 96 96"
        >
          <title>{'icon-' + name}</title>
          <path
            d="M36,96h24c19.8,0,36-16.2,36-36V36C96,16.2,79.8,0,60,0H36C16.2,0,0,16.2,0,36v24
    C0,79.8,16.2,96,36,96z"
          />
          <path
            fill="currentColor"
            d="M62.2,50.1c-1,0-1.8,0.8-1.8,1.8v6.2H35.6v-6.2c0-1-0.8-1.8-1.8-1.8S32,50.8,32,51.8v8c0,1,0.8,1.8,1.8,1.8h28.4
    c1,0,1.8-0.8,1.8-1.8v-8C64,50.8,63.2,50.1,62.2,50.1z"
          />
          <path
            fill="currentColor"
            d="M43.1,43.6l3.2-3.2v11.4c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8V40.5l3.2,3.2c0.3,0.3,0.8,0.5,1.3,0.5c0.5,0,0.9-0.2,1.3-0.5
    c0.7-0.7,0.7-1.8,0-2.5l-6.2-6.2c-0.1-0.1-0.2-0.2-0.3-0.2c0,0-0.1,0-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2-0.1
    c-0.1,0-0.1,0-0.2,0c-0.2,0-0.5,0-0.7,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0.1
    c-0.1,0.1-0.2,0.1-0.3,0.2l-6.2,6.2c-0.7,0.7-0.7,1.8,0,2.5C41.2,44.3,42.4,44.3,43.1,43.6z"
          />
        </StyledSVG>
      );
    case 'icon-minus-circle':
      return (
        <StyledSVG
          className={className}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          viewBox="0 0 32 32"
        >
          <title>{'icon-' + name}</title>
          <path
            fill="currentColor"
            d="M16,0C7.2,0,0,7.2,0,16s7.2,16,16,16s16-7.2,16-16S24.8,0,16,0z M20.4,16.8
	c-0.1,0.1-0.2,0.1-0.3,0.1h-8.2c-0.1,0-0.2,0-0.3-0.1s-0.1-0.2-0.1-0.3s0-0.2,0.1-0.3s0.2-0.1,0.3-0.1h8.2c0.1,0,0.2,0,0.3,0.1
	s0.1,0.2,0.1,0.3S20.5,16.7,20.4,16.8z"
          />
        </StyledSVG>
      );
    case 'icon-plus-circle':
      return (
        <StyledSVG
          className={className}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          viewBox="0 0 32 32"
        >
          <title>{'icon-' + name}</title>
          <path
            fill="currentColor"
            d="M20.1,16.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3s0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1h-3.7v3.7
	c0,0.1,0,0.2-0.1,0.3C16.2,21,16.1,21,16,21c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3v-3.7h-3.7c-0.1,0-0.2,0-0.3-0.1
	c-0.1-0.1-0.1-0.2-0.1-0.3s0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h3.7v-3.6c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1
	c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3v3.6H20.1z"
          />
        </StyledSVG>
      );
    case 'icon-questionnaire':
      return (
        <StyledSVG
          className={className}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          viewBox="0 0 6 10"
        >
          <title>{'icon-' + name}</title>
          <path
            d="M2.4,9.7C2.3,9.6,2.3,9.4,2.3,9.2s0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4
      S3.5,9.6,3.4,9.7C3.2,9.8,3.1,9.9,2.9,9.9C2.7,9.9,2.6,9.8,2.4,9.7z M5.2,1.1C5.8,1.7,6,2.4,6,3.1c0,0.4-0.1,0.8-0.3,1.1
      C5.5,4.6,5.1,5.1,4.5,5.7c-0.7,0.7-1,1.2-1.1,1.5c0,0.1-0.1,0.3-0.2,0.3C3.2,7.7,3.1,7.7,2.9,7.7H2.9c-0.1,0-0.3-0.1-0.4-0.2
      C2.4,7.4,2.4,7.3,2.4,7.2c0-0.4,0.2-0.7,0.4-1.1C3,5.8,3.3,5.4,3.8,5c0.4-0.4,0.6-0.7,0.8-0.9c0.2-0.3,0.3-0.4,0.3-0.6
      c0-0.1,0-0.3,0.1-0.4c0-0.5-0.1-0.9-0.5-1.3C4.1,1.4,3.6,1.2,3,1.2h0c-0.5,0-1,0.2-1.4,0.6C1.2,2.1,1.1,2.6,1.1,3.1
      c0,0.1-0.1,0.3-0.2,0.4C0.8,3.6,0.7,3.7,0.5,3.7S0.3,3.6,0.2,3.5C0.1,3.4,0,3.3,0,3.1C0,2.3,0.3,1.6,0.9,1C1.5,0.4,2.2,0.1,3,0.1
      C3.9,0.1,4.6,0.5,5.2,1.1z"
          />
        </StyledSVG>
      );
    case 'icon-star':
      return (
        <StyledSVG
          className={className}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width={scaling.scaleUpTo4K(14.096)}
          height={scaling.scaleUpTo4K(13.41)}
          viewBox="0 0 14.096 13.41"
        >
          <title>{'icon-' + name}</title>
          <path
            d="M14.1,18.174l-4.87-.708L7.048,13.052,4.87,17.466,0,18.174,3.524,21.61l-.832,4.852,4.356-2.291L11.4,26.462l-.832-4.852Z"
            transform="translate(0 -13.052)"
            fill="currentColor"
          />
        </StyledSVG>
      );
    case 'rangeslider__handle':
      return (
        <StyledSVG
          className={className}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          viewBox="0 0 74 48"
        >
          <title>{'icon-' + name}</title>
          <path
            fill="#434244"
            d="M4.5,27.3v-6.5c0-0.2-0.2-0.3-0.4-0.2l-4,3.2c-0.1,0.1-0.1,0.2,0,0.3l4,3.3C4.3,27.5,4.5,27.4,4.5,27.3z"
          />
          <path
            opacity="0.7"
            fill="#434244"
            d="M37,0C23.7,0,13,10.7,13,24s10.7,24,24,24s24-10.7,24-24
      S50.3,0,37,0z M37,47c-12.7,0-23-10.3-23-23C14,11.3,24.3,1,37,1c12.7,0,23,10.3,23,23C60,36.7,49.7,47,37,47z"
          />
          <path
            fill="#434244"
            d="M69.5,27.3v-6.5c0-0.2,0.2-0.3,0.4-0.2l4,3.2c0.1,0.1,0.1,0.2,0,0.3l-4,3.3C69.7,27.5,69.5,27.4,69.5,27.3z"
          />
        </StyledSVG>
      );
    case 'stroke-file-container':
      return (
        <StyledSVG
          className={className}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="100%"
          y="100%"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <title>{'icon-' + name}</title>
          <rect
            stroke="#E2E2E2"
            strokeDasharray="8px"
            strokeWidth="1px"
            fill="none"
            vectorEffect="non-scaling-stroke"
            x="0"
            y="0"
            width="99"
            height="99"
            rx="4"
            ry="4"
          />
        </StyledSVG>
      );
    case 'tick':
      return (
        <StyledSVG
          className={className}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="100%"
          y="100%"
          viewBox="0 0 64 64"
          preserveAspectRatio="none"
        >
          <title>{'icon-' + name}</title>
          <path
            d="M30.27,40.25a2,2,0,0,1-1.41-.59l-6-6a2,2,0,0,1,2.83-2.83l4.57,4.58,10.1-10.08a2,2,0,0,1,2.83,2.83l-11.5,11.5a2,2,0,0,1-1.42.59Z"
            fill="currentColor"
          />
        </StyledSVG>
      );
    case 'user':
      return (
        <StyledSVG
          className={className}
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <title>{'icon-' + name}</title>
          <path
            fill="currentColor"
            d="M19.1,16.8c1.1-0.9,1.9-2.3,1.9-3.9c0-2.8-2.2-5-5-5s-5,2.2-5,5c0,1.6,0.7,3,1.9,3.9C10.6,18,9,20.3,9,23.1
    c0,0.6,0.4,1,1,1h6c0.6,0,1-0.4,1-1s-0.4-1-1-1h-4.9c0.5-2.3,2.5-4,4.9-4c2.8,0,5,2.2,5,5c0,0.6,0.4,1,1,1s1-0.4,1-1
    C23,20.3,21.4,18,19.1,16.8z M16,9.9c1.7,0,3,1.3,3,3s-1.3,3-3,3s-3-1.3-3-3S14.3,9.9,16,9.9z"
          />
        </StyledSVG>
      );
    case 'questionmark':
      return (
        <StyledSVG
          className={className}
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <title>{'icon-' + name}</title>
          <path
            fill="currentColor"
            d="M16,20.3c-0.6,0-1-0.4-1-1v-2.4c0-0.6,0.4-1,1-1c1.5,0,2.7-1.2,2.7-2.7s-1.2-2.7-2.7-2.7c-1.5,0-2.7,1.2-2.7,2.7
	  c0,0.6-0.4,1-1,1s-1-0.4-1-1c0-2.6,2.1-4.7,4.7-4.7s4.7,2.1,4.7,4.7c0,2.2-1.6,4.1-3.7,4.6v1.5C17,19.8,16.6,20.3,16,20.3z"
          />
          <circle fill="currentColor" cx="16" cy="22.6" r="0.9" />
        </StyledSVG>
      );
    case 'star':
      return (
        <StyledSVG
          className={className}
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <title>{'icon-' + name}</title>
          <path
            fill="currentColor"
            d="M20.9,24.6c-0.2,0-0.3,0-0.5-0.1L16,22.1l-4.5,2.4c-0.3,0.2-0.7,0.1-1.1-0.1c-0.3-0.2-0.5-0.6-0.4-1l0.9-5l-3.6-3.5
    c-0.3-0.3-0.4-0.7-0.3-1c0.1-0.4,0.4-0.6,0.8-0.7l5-0.7l2.2-4.5c0.2-0.3,0.5-0.6,0.9-0.6c0.4,0,0.7,0.2,0.9,0.6l2.2,4.5l5,0.7
    c0.4,0.1,0.7,0.3,0.8,0.7c0.1,0.4,0,0.8-0.3,1l-4,3.9c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1,0-1.4l2.5-2.5l-3.5-0.5
    c-0.3,0-0.6-0.3-0.8-0.5L16,10.7l-1.6,3.2c-0.1,0.3-0.4,0.5-0.8,0.5l-3.5,0.5l2.5,2.5c0.2,0.2,0.3,0.6,0.3,0.9l-0.6,3.5l3.2-1.7
    c0.3-0.2,0.6-0.2,0.9,0l4.9,2.6c0.5,0.3,0.7,0.9,0.4,1.4C21.7,24.4,21.3,24.6,20.9,24.6z"
          />
        </StyledSVG>
      );
    case 'file':
      return (<svg
        className={className}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={scaling.scaleUpTo4K(width)}
        height={scaling.scaleUpTo4K(height)}
        viewBox="0 0 96 96"
      >
        <title>{'icon-' + name}</title>
        <path
          fill="currentColor"
          d="M62.2,50.1c-1,0-1.8,0.8-1.8,1.8v6.2H35.6v-6.2c0-1-0.8-1.8-1.8-1.8S32,50.8,32,51.8v8c0,1,0.8,1.8,1.8,1.8h28.4
  c1,0,1.8-0.8,1.8-1.8v-8C64,50.8,63.2,50.1,62.2,50.1z"
        />
        <path
          fill="currentColor"
          d="M43.1,43.6l3.2-3.2v11.4c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8V40.5l3.2,3.2c0.3,0.3,0.8,0.5,1.3,0.5c0.5,0,0.9-0.2,1.3-0.5
  c0.7-0.7,0.7-1.8,0-2.5l-6.2-6.2c-0.1-0.1-0.2-0.2-0.3-0.2c0,0-0.1,0-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2-0.1
  c-0.1,0-0.1,0-0.2,0c-0.2,0-0.5,0-0.7,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0.1
  c-0.1,0.1-0.2,0.1-0.3,0.2l-6.2,6.2c-0.7,0.7-0.7,1.8,0,2.5C41.2,44.3,42.4,44.3,43.1,43.6z"
        />
      </svg>);
    case 'phone':
      return (
        <StyledSVG
          className={className}
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <title>{'icon-' + name}</title>
          <path
            fill="currentColor"
            d="M12.6,24c-1.4,0-2.7-0.5-3.6-1.4C8.3,22,8,21.2,8,20.4c0-0.8,0.3-1.6,0.9-2.2c0.6-0.6,1.4-0.9,2.3-0.9
    c0.8,0,1.6,0.3,2.2,0.9c0.1,0.1,0.1,0,0.1,0.1c0.2,0.2,1.2,0.2,3-1.6c1.8-1.8,1.8-2.9,1.7-3.1c0,0,0,0-0.1-0.1
    c-1.2-1.2-1.2-3.2,0-4.5v0c1.2-1.2,3.2-1.2,4.5,0c1.9,1.9,1.8,5.2-0.1,8.5c-0.6,1-1.4,2-2.2,2.9c-0.7,0.7-1.5,1.4-2.4,1.9
    C16.1,23.4,14.2,24,12.6,24z M11.2,19.2c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8s0.1,0.6,0.3,0.8c1.3,1.3,3.9,1,6.5-0.7
    c0.7-0.5,1.4-1,2-1.7c0.8-0.8,1.4-1.6,1.9-2.5c1.4-2.4,1.6-4.9,0.4-6c-0.5-0.5-1.2-0.5-1.7,0l0,0c-0.5,0.5-0.5,1.2,0,1.6
    c0.2,0.2,0.7,0.7,0.7,1.6c0.1,1.3-0.7,2.8-2.3,4.4c-1.6,1.6-3.1,2.4-4.4,2.3c-0.9-0.1-1.4-0.5-1.6-0.8
    C11.8,19.3,11.5,19.2,11.2,19.2C11.2,19.2,11.2,19.2,11.2,19.2z M13.5,18.3L13.5,18.3L13.5,18.3z M18.2,13.5L18.2,13.5L18.2,13.5z"
          />
        </StyledSVG>
      );
    case 'mail':
      return (
        <StyledSVG
          className={className}
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <title>{'icon-' + name}</title>
          <path
            fill="currentColor"
            d="M23.2,21.5H8.8c-0.6,0-1-0.4-1-1v-9c0-0.4,0.2-0.7,0.5-0.9c0.3-0.2,0.7-0.2,1,0l6.7,4.2l6.7-4.2c0.5-0.3,1.1-0.1,1.4,0.3
    c0.3,0.5,0.2,1.1-0.3,1.4l-7.2,4.5c-0.3,0.2-0.7,0.2-1.1,0l-5.7-3.5v6.2h12.4v-2.9c0-0.6,0.4-1,1-1s1,0.4,1,1v3.9
    C24.2,21.1,23.8,21.5,23.2,21.5z"
          />
          <path
            fill="currentColor"
            d="M23.2,12.5H8.8c-0.6,0-1-0.4-1-1s0.4-1,1-1h14.4c0.6,0,1,0.4,1,1S23.8,12.5,23.2,12.5z"
          />
        </StyledSVG>
      );
    case 'car-out':
      return (
        <StyledSVG
          className={className}
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <title>{'icon-' + name}</title>
          <path
            fill="currentColor"
            d="M24.9,10h-3.4c-0.2,0-0.3,0-0.5,0.1l-4.5,2.4l-1.7,0.1H9.2c-0.6,0-1,0.4-1,1s0.4,1,1,1H11l-1,1.1H6.8c-0.6,0-1,0.4-1,1
    s0.4,1,1,1h2.6v1.5c0,0.6,0.4,1,1,1h3.7c0.4,1.1,1.4,1.8,2.6,1.8c1.2,0,2.2-0.8,2.6-1.8h2.3c0.6,0,1-0.4,1-1s-0.4-1-1-1h-2.3
    c-0.4-1.1-1.4-1.8-2.6-1.8c-1.2,0-2.2,0.8-2.6,1.8h-2.7v-1.1l2.1-2.4l3.4-0.2c0.1,0,0.3,0,0.4-0.1l4.5-2.3h3.1c0.6,0,1-0.4,1-1
    S25.5,10,24.9,10z M16.8,18.4c0.4,0,0.8,0.4,0.8,0.8S17.2,20,16.8,20S16,19.6,16,19.2S16.3,18.4,16.8,18.4z"
          />
        </StyledSVG>
      );
    case 'car-in':
      return (
        <StyledSVG
          className={className}
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <title>{'icon-' + name}</title>
          <path
            fill="currentColor"
            d="M26.5,15.8H23l-1.3-1.5h2.2c0.6,0,1-0.4,1-1s-0.4-1-1-1h-6.2l-1.9-0.1l-5-2.6c-0.1-0.1-0.3-0.1-0.5-0.1H6.5
    c-0.6,0-1,0.4-1,1s0.4,1,1,1H10l4.9,2.6c0.1,0.1,0.3,0.1,0.4,0.1l3.7,0.2l2.4,2.7v1.3h-3.2c-0.4-1.2-1.5-2-2.8-2s-2.4,0.8-2.8,2
    h-2.6c-0.6,0-1,0.4-1,1s0.4,1,1,1h2.6c0.4,1.2,1.5,2,2.8,2s2.4-0.8,2.8-2h4.2c0.6,0,1-0.4,1-1v-1.7h3c0.6,0,1-0.4,1-1
    S27,15.8,26.5,15.8z M15.5,20.5c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S16.1,20.5,15.5,20.5z"
          />
        </StyledSVG>
      );
    case 'wrench':
      return (
        <StyledSVG
          className={className}
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 64 64"
        >
          <title>{'icon-' + name}</title>
          <path
            fill="currentColor"
            d="M49.33,20.89a2,2,0,0,0-3.23-.57L39.32,27.1l-2.39-.34-.33-2.41,6.77-6.77a2,2,0,0,0-.57-3.22,13.5,13.5,0,0,0-19,14.21,1.83,1.83,0,0,0,.07.32l.85,2.64L15.1,41.11a5.73,5.73,0,0,0,8.11,8.1L31,41.36a2,2,0,0,0-2.84-2.82l-7.77,7.84a1.75,1.75,0,0,1-2.44,0,1.71,1.71,0,0,1,0-2.44L28.38,33.49a2,2,0,0,0,.48-2l-1.17-3.63A9.5,9.5,0,0,1,38.13,17.16l-5.06,5.07a2,2,0,0,0-.57,1.69l.67,4.86a2,2,0,0,0,1.68,1.71l2.7.41,2.24.29a2,2,0,0,0,1.67-.57l5.07-5.07a9.57,9.57,0,0,1-2.73,7.74,9.44,9.44,0,0,1-6.71,2.78,2,2,0,0,0,0,4,13.4,13.4,0,0,0,9.54-4A13.54,13.54,0,0,0,49.33,20.89Z"
          />
        </StyledSVG>
      );
    case 'message':
      return (
        <StyledSVG
          className={className}
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <title>{'icon-' + name}</title>
          <path
            fill="currentColor"
            d="M8.8,24.1c-0.1,0-0.3,0-0.4-0.1c-0.4-0.2-0.6-0.5-0.6-0.9V12.9c0-0.6,0.4-1,1-1h7.6l0,2H9.8v7l1.2-1.1
    c0.2-0.2,0.4-0.3,0.7-0.3H20v-1.3c0-0.6,0.4-1,1-1s1,0.4,1,1v2.3c0,0.6-0.4,1-1,1h-9l-2.6,2.3C9.2,24,9,24.1,8.8,24.1z"
          />
          <path
            fill="currentColor"
            d="M24.8,17c-0.2,0-0.5-0.1-0.7-0.3l-1.4-1.3h-5.3c-0.6,0-1-0.4-1-1V9.8c0-0.6,0.4-1,1-1h7.4c0.6,0,1,0.4,1,1V16
    c0,0.4-0.2,0.8-0.6,0.9C25,17,24.9,17,24.8,17z M18.3,13.4H23c0.2,0,0.5,0.1,0.7,0.3l0.1,0.1v-3h-5.4V13.4z"
          />
        </StyledSVG>
      );
    case 'car':
      return (
        <StyledSVG
          className={className}
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <title>{'icon-' + name}</title>
          <path
            fill="currentColor"
            d="M20.9,23.2c-1.2,0-2.2-0.7-2.6-1.8h-4.7c-0.4,1-1.4,1.8-2.6,1.8s-2.2-0.7-2.6-1.8H8c-0.6,0-1-0.4-1-1v-4c0-0.6,0.4-1,1-1h16
    c0.6,0,1,0.4,1,1v4c0,0.6-0.4,1-1,1h-0.5C23.1,22.5,22.1,23.2,20.9,23.2z M12.8,19.4h6.3c0.6,0,1,0.4,1,1c0,0.4,0.3,0.8,0.8,0.8
    s0.8-0.3,0.8-0.8c0-0.6,0.4-1,1-1H23v-2H9v2h0.3c0.6,0,1,0.4,1,1c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8
    C11.8,19.9,12.3,19.4,12.8,19.4z"
          />
          <path
            fill="currentColor"
            d="M23.3,12L22,9.4c-0.2-0.3-0.5-0.6-0.9-0.6H16h-5.1c-0.4,0-0.7,0.2-0.9,0.6L8.7,12c-0.2,0.5,0,1.1,0.4,1.3
    c0.1,0.1,0.3,0.1,0.4,0.1c0.4,0,0.7-0.2,0.9-0.6l1.1-2.1H16h4.5l1.1,2.1c0.2,0.4,0.5,0.6,0.9,0.6c0.2,0,0.3,0,0.4-0.1
    C23.4,13.1,23.6,12.5,23.3,12z"
          />
        </StyledSVG>
      );
    case 'bike':
      return (
        <StyledSVG
          className={className}
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <title>{'icon-' + name}</title>
          <path
            fill="currentColor"
            d="M9.2,22.6c-2,0-3.7-1.7-3.7-3.7s1.7-3.7,3.7-3.7c2,0,3.7,1.7,3.7,3.7S11.3,22.6,9.2,22.6z M9.2,17.1c-0.9,0-1.7,0.8-1.7,1.7
    c0,0.9,0.8,1.7,1.7,1.7s1.7-0.8,1.7-1.7C10.9,17.9,10.2,17.1,9.2,17.1z"
          />
          <path
            fill="currentColor"
            d="M22.8,22.6c-2,0-3.7-1.7-3.7-3.7s1.7-3.7,3.7-3.7s3.7,1.7,3.7,3.7S24.8,22.6,22.8,22.6z M22.8,17.1c-0.9,0-1.7,0.8-1.7,1.7
    c0,0.9,0.8,1.7,1.7,1.7s1.7-0.8,1.7-1.7C24.5,17.9,23.7,17.1,22.8,17.1z"
          />
          <path
            fill="currentColor"
            d="M21.3,17.3c-0.3,0-0.7-0.2-0.9-0.5L17,10.9c-0.2-0.3-0.2-0.7,0-1c0.2-0.3,0.5-0.5,0.9-0.5h2.2c0.6,0,1,0.4,1,1s-0.4,1-1,1
    h-0.4l2.5,4.3c0.3,0.5,0.1,1.1-0.4,1.4C21.6,17.2,21.5,17.3,21.3,17.3z"
          />
          <path
            fill="currentColor"
            d="M15.7,12.7h-1.4c0,0,0,0-0.1,0c-0.4,0-1.4-0.1-1.7-0.5c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.6-0.4-1-1-1s-1,0.4-1,1
    c0,0.7,0.3,1.4,0.8,1.8c0.2,0.2,0.4,0.3,0.6,0.4L10,16c-0.3,0.4-0.3,1.1,0.2,1.4c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0,0.6-0.1,0.8-0.4
    l2.1-2.6h2c0.6,0,1-0.4,1-1S16.2,12.7,15.7,12.7z"
          />
        </StyledSVG>
      );
    case 'cards':
      return (
        <StyledSVG
          className={className}
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <title>{'icon-' + name}</title>
          <path
            fill="currentColor"
            d="M19,25c-0.4,0-0.7-0.1-1.1-0.2c-1-0.4-5.5-2.2-6.6-3.3c-1.1-1.1-5.4-6.6-5.6-6.8c0,0,0,0,0-0.1c-0.6-1-0.4-2.3,0.6-3L9,9.7
    c0.4-0.3,1.1-0.2,1.4,0.2c0.3,0.4,0.2,1.1-0.2,1.4l-2.7,1.9c-0.1,0.1-0.1,0.1-0.1,0.2c1.3,1.6,4.6,5.8,5.4,6.6
    c0.5,0.5,3.5,1.9,6,2.8c0,0,0,0,0.1,0c0.2,0.1,0.4,0.1,0.6,0c0.2-0.1,0.3-0.2,0.4-0.4l2.3-5.2c0.2-0.5,0.8-0.7,1.3-0.5
    c0.5,0.2,0.7,0.8,0.5,1.3l-2.3,5.2c-0.3,0.7-0.8,1.2-1.5,1.4C19.7,24.9,19.3,25,19,25z"
          />
          <path
            fill="currentColor"
            d="M10.3,20.1l-1.7-9.7C8.4,9.2,9.3,8,10.5,7.8L14.6,7c0.6-0.1,1.2,0,1.7,0.4c0.5,0.4,0.9,0.9,1,1.5l-2,0.4
    c0-0.1-0.1-0.2-0.1-0.2C15.1,9,15.1,9,15,9l-4.1,0.7c-0.2,0-0.3,0.2-0.3,0.4l1.7,9.7L10.3,20.1z"
          />
          <path
            fill="currentColor"
            d="M24.6,10l-5.8-2.6c-1.4-0.6-3,0-3.6,1.4l-4.9,10.7c-0.2,0.5,1,2.1,1.2,2.3l1.4-1.4c-0.4-0.4-0.5-0.9-0.2-1.5
    c0,0,0-0.1,0-0.1L17,9.6c0.2-0.4,0.6-0.5,1-0.4l5.8,2.6c0.4,0.2,0.5,0.6,0.4,1c-0.2,0.5,0,1.1,0.5,1.3c0.5,0.2,1.1,0,1.3-0.5
    C26.6,12.2,25.9,10.6,24.6,10z"
          />
        </StyledSVG>
      );
    case 'invoice':
      return (
        <StyledSVG
          className={className}
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <title>{'icon-' + name}</title>
          <path
            fill="currentColor"
            d="M21.3,10H10.7c-0.6,0-1-0.4-1-1s0.4-1,1-1h10.5c0.6,0,1,0.4,1,1S21.8,10,21.3,10z"
          />
          <path
            fill="currentColor"
            d="M21.3,24H10.7c-0.6,0-1-0.4-1-1V9c0-0.6,0.4-1,1-1s1,0.4,1,1v13h8.5v-8.3c0-0.6,0.4-1,1-1s1,0.4,1,1V23
    C22.3,23.6,21.8,24,21.3,24z"
          />
          <path
            fill="currentColor"
            d="M16.3,13.5h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h2c0.6,0,1,0.4,1,1S16.8,13.5,16.3,13.5z"
          />
          <path
            fill="currentColor"
            d="M16.3,16.6h-2c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,1-1h2c0.6,0,1,0.4,1,1C17.3,16.2,16.8,16.6,16.3,16.6z"
          />
        </StyledSVG>
      );
    case 'money':
      return (
        <StyledSVG
          className={className}
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <title>{'icon-' + name}</title>
          <circle fill="currentColor" cx="12.3" cy="17" r="1" />
          <circle fill="currentColor" cx="13.8" cy="17" r="1" />
          <path
            fill="currentColor"
            d="M23.2,15c-0.6,0-1,0.4-1,1v3.5H9.8v-7h13.4c0,0,0,0,0,0c0.3,0,0.5-0.1,0.7-0.3
    c0.2-0.2,0.3-0.4,0.3-0.7c0-0.6-0.4-1-1-1H8.8c-0.6,0-1,0.4-1,1v9c0,0.6,0.4,1,1,1h14.4c0.6,0,1-0.4,1-1V16
    C24.2,15.4,23.8,15,23.2,15z"
          />
        </StyledSVG>
      );
    case 'search':
      return (
        <StyledSVG
          className={className}
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <title>{'icon-' + name}</title>
          <path
            fill="currentColor"
            d="M21.5,20.7l-2.1-2.1c0.6-0.8,0.9-1.8,0.9-2.8c0-1.4-0.5-2.6-1.5-3.5s-2.2-1.5-3.5-1.5s-2.6,0.5-3.5,1.5s-1.5,2.2-1.5,3.5
	s0.5,2.6,1.5,3.5s2.2,1.5,3.5,1.5c1,0,2-0.3,2.8-0.9l2.1,2.1c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.5-0.1,0.7-0.3
	c0.2-0.2,0.2-0.4,0.2-0.6S21.7,20.9,21.5,20.7z M16.5,18.7c-0.8,0.3-1.7,0.3-2.4,0c-0.4-0.2-0.7-0.4-1-0.7c-0.3-0.3-0.5-0.6-0.7-1
	c-0.2-0.4-0.3-0.8-0.3-1.2s0.1-0.8,0.3-1.2c0.2-0.4,0.4-0.7,0.7-1c0.3-0.3,0.6-0.5,1-0.7c0.4-0.2,0.8-0.2,1.2-0.2s0.8,0.1,1.2,0.2
	c0.4,0.2,0.7,0.4,1,0.7s0.5,0.6,0.7,1c0.2,0.4,0.2,0.8,0.2,1.2s-0.1,0.8-0.2,1.2c-0.2,0.4-0.4,0.7-0.7,1S16.8,18.5,16.5,18.7z"
          />
        </StyledSVG>
      );
    case 'arrow-right':
      return (
        <StyledSVG
          className={className}
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <title>{'icon-' + name}</title>
          <path
            fill="currentColor"
            d="M15.3,20.5c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l2.8-2.8l-2.8-2.8c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0
	  l3.5,3.5c0.4,0.4,0.4,1,0,1.4L16,20.2C15.8,20.4,15.5,20.5,15.3,20.5z"
          />
        </StyledSVG>
      );
    case 'arrow-left':
      return (
        <StyledSVG
          className={className}
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <title>{'icon-' + name}</title>
          <path
            fill="currentColor"
            d="M17.2,20.5c-0.3,0-0.5-0.1-0.7-0.3L13,16.7c-0.4-0.4-0.4-1,0-1.4l3.5-3.5c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4L15.2,16
	  l2.8,2.8c0.4,0.4,0.4,1,0,1.4C17.7,20.4,17.5,20.5,17.2,20.5z"
          />
        </StyledSVG>
      );
    case 'plus':
      return (
        <StyledSVG
          className={className}
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <title>{'icon-' + name}</title>
          <path
            fill="currentColor"
            d="M16,20.6c-0.6,0-1-0.4-1-1v-7.1c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1v7.1C17,20.1,16.6,20.6,16,20.6z"
          />
          <path
            fill="currentColor"
            d="M19.6,17h-7.1c-0.6,0-1-0.4-1-1s0.4-1,1-1h7.1c0.6,0,1,0.4,1,1S20.1,17,19.6,17z"
          />
        </StyledSVG>
      );
    case 'minus':
      return (
        <StyledSVG
          className={className}
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <title>{'icon-' + name}</title>
          <path
            fill="currentColor"
            d="M19.6,17h-7.1c-0.6,0-1-0.4-1-1s0.4-1,1-1h7.1c0.6,0,1,0.4,1,1S20.1,17,19.6,17z"
          />
        </StyledSVG>
      );
    case 'vip':
      return (
        <StyledSVG
          className={className}
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 64 64"
        >
          <title>{'icon-' + name}</title>
          <rect
            className="cls-2"
            x="30.85"
            y="21.04"
            width="4"
            height="21.89"
            rx="2"
            id="rect10"
          />
          <rect
            className="cls-2"
            x="37.8"
            y="21.05"
            width="4"
            height="21.89"
            rx="2"
            id="rect12"
          />
          <path
            fill="currentColor"
            d="M20.57,43h-.23a2,2,0,0,1-1.89-1.36l-6.07-17.9a2,2,0,0,1,3.79-1.28l4.3,12.68,4.36-12.66a2,2,0,1,1,3.78,1.3L22.46,41.66A2,2,0,0,1,20.57,43z"
          />
          <path
            fill="currentColor"
            d="M45.83,33.18h0a2,2,0,0,1,0-4h0a2,2,0,0,0,1.41-.58,2,2,0,0,0-1.41-3.42,2,2,0,0,1,0-4h0a6,6,0,0,1,4.22,10.25A5.94,5.94,0,0,1,45.83,33.18z"
          />
        </StyledSVG>
      );
    case 'towtruck':
      return (
        <StyledSVG
          className={className}
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 64 64"
        >
          <title>{'icon-' + name}</title>
          <path
            fill="currentColor"
            d="M47.6,23a6.09,6.09,0,0,0,4.92,2.08,2,2,0,0,0,0-4c-1.31,0-1.72-.48-1.85-.64a4.38,4.38,0,0,1-.54-3,2,2,0,0,0-3.37-1.77l-10.16,10V23a2,2,0,0,0-2-2H27.69c-5,.31-9.32,3.13-12.23,8l-3.72,2.13a4.24,4.24,0,0,0-2.12,3.66v5.63a2,2,0,0,0,2,2h7.45a5.61,5.61,0,0,0,10.47,0h12.7a5.62,5.62,0,1,0,7.16-7.31V29.75a2,2,0,0,0-2-2H40.18l6.46-6.35A6.07,6.07,0,0,0,47.6,23ZM24.31,42a1.63,1.63,0,1,1,1.62-1.62A1.62,1.62,0,0,1,24.31,42Zm0-7.25a5.64,5.64,0,0,0-5.26,3.65H13.62V34.75a.21.21,0,0,1,.11-.19l4.18-2.4a2,2,0,0,0,.75-.75c2.2-3.89,5.48-6.16,9.21-6.41H32.6V38.38h-3A5.62,5.62,0,0,0,24.31,34.73ZM47.48,42a1.63,1.63,0,1,1,1.62-1.62A1.63,1.63,0,0,1,47.48,42ZM45.4,35.14a5.6,5.6,0,0,0-3.18,3.25H36.6V31.75h8.8Z"
          />
        </StyledSVG>
      );
    case 'car-returned':
      return (
        <StyledSVG
          className={className}
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 64 64"
        >
          <title>{'icon-' + name}</title>
          <path
            fill="currentColor"
            d="M12.89,22a2,2,0,0,0,2,2h6.3l8.92,4.7a1.84,1.84,0,0,0,.8.22l6.71.45,4.25,4.84v2.17H36.44a5.61,5.61,0,0,0-10.5,0h-4.5a2,2,0,0,0,0,4h4.5a5.62,5.62,0,0,0,10.5,0h7.43a2,2,0,0,0,2-2V35.46h5.24a2,2,0,0,0,0-4H44.78l-2-2.27h3.53a2,2,0,0,0,0-4H35.06L31.59,25l-9-4.73a2,2,0,0,0-.94-.23H14.89A2,2,0,0,0,12.89,22ZM33.37,38.38a2.18,2.18,0,1,1-2.18-2.18A2.18,2.18,0,0,1,33.37,38.38Z"
          />
        </StyledSVG>
      );
    case 'checkmark':
      return (
        <StyledSVG
          className={className}
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 64 64"
        >
          <title>{'icon-' + name}</title>
          <path
            fill="currentColor"
            d="M31.27,41.25a2,2,0,0,1-1.41-.59l-6-6a2,2,0,1,1,2.83-2.83l4.57,4.58,10.1-10.08a2,2,0,1,1,2.83,2.83l-11.5,11.5a2,2,0,0,1-1.42.59Z"
          />
        </StyledSVG>
      );
    case 'upload-plus':
      return (
        <StyledSVG
          xmlns="http://www.w3.org/2000/svg"
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          viewBox="0 0 24 24"
          className={className}
        >
          <title>{'icon-' + name}</title>
          <path
            fill="currentColor"
            d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 13h-5v5h-2v-5h-5v-2h5v-5h2v5h5v2z"
          />
        </StyledSVG>
      );
    default:
      // question-mark svg
      return (
        <StyledSVG
          className={className}
          width={scaling.scaleUpTo4K(width)}
          height={scaling.scaleUpTo4K(height)}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <title>{'icon-' + name}</title>
          <path
            fill="currentColor"
            d="M16,20.3c-0.6,0-1-0.4-1-1v-2.4c0-0.6,0.4-1,1-1c1.5,0,2.7-1.2,2.7-2.7s-1.2-2.7-2.7-2.7c-1.5,0-2.7,1.2-2.7,2.7
	  c0,0.6-0.4,1-1,1s-1-0.4-1-1c0-2.6,2.1-4.7,4.7-4.7s4.7,2.1,4.7,4.7c0,2.2-1.6,4.1-3.7,4.6v1.5C17,19.8,16.6,20.3,16,20.3z"
          />
          <circle fill="currentColor" cx="16" cy="22.6" r="0.9" />
        </StyledSVG>
      );
  }
};

UseIcon.propTypes = {
  name: PropTypes.oneOf([
    'exclamation-mark',
    'icon-arrow-left',
    'icon-arrow-right',
    'icon-check-circle-filled',
    'icon-check-circle',
    'icon-check',
    'icon-file',
    'icon-minus-circle',
    'icon-plus-circle',
    'icon-questionnaire',
    'icon-star',
    'file',
    'rangeslider__handle',
    'stroke-file-container',
    'tick',
    'user',
    'questionmark',
    'star',
    'phone',
    'mail',
    'car-out',
    'car-out',
    'car-in',
    'wrench',
    'message',
    'car',
    'bike',
    'cards',
    'invoice',
    'money',
    'search',
    'arrow-right',
    'arrow-left',
    'plus',
    'minus',
    'vip',
    'towtruck',
    'car-returned',
    'checkmark',
    'upload-plus'
  ]).isRequired,
  className: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default UseIcon;
