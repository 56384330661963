import { COMPANY_TOKEN_FOUND } from '@domain/action-types';

const initialInsurerUUID = '';

const companyUUID = (state = initialInsurerUUID, { type, companyToken }) => {
  switch (type) {
    case COMPANY_TOKEN_FOUND:
      return companyToken;
    default:
      return state;
  }
};

export default companyUUID;
