import React from 'react';

import { useScaling } from '@domain/hooks';
import { Colors } from '@domain/theming';
import { isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';

const StyledA = styled.a`
  // font-size: ${props => !isMobileOnly ? props.$scaling.textSize(14) : props.$scaling.textSizeMobile(14, props.vwWdith)}px;
  // line-height: ${props => !isMobileOnly ? props.$scaling.textSize(18) : props.$scaling.textSizeMobile(18, props.vwWdith)}px;
  text-decoration: none;
  // display: inline-block;
  .theme-custom & {
    color: ${Colors.BLACK}; 
  }
  .theme-dark & {
    color: ${Colors.WHITE}; 
  }
`;

function StyledLink({ children, ...props }) {
  const scaling = useScaling();
  return <StyledA {...props} $scaling={scaling}><u>{React.Children.toArray(children)}</u></StyledA>;
}

export default StyledLink;