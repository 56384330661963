import { object, string } from 'yup';

const postcodeRegEx = /^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i;

const phoneRegEx = /^((\+|00(\s|\s?-\s?)?)31(\s|\s?-\s?)?(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/;
const houseNumberRegEx = /^\d+$/i;

const validationSchema = object({
  companyName: string()
    .required()
    .label('Naam'),
  zipCode: string()
    .matches(postcodeRegEx, 'Controleer alstublieft uw hersteller postcode in.')
    .required()
    .label('Postcode'),
  houseNumber: string()
    .required()
    .label('Huisnummer')
    .matches(houseNumberRegEx, 'U kunt hier enkel cijfers invullen.'),
  houseNumberAddition: string()
    .label('Toevoeging')
    .max(5),
  phoneNumber: string()
    .matches(phoneRegEx, { message: 'Controleer alstublieft uw hersteller telefoon.', excludeEmptyString: true })
    .label('Telefoon (optional)'),
});

export default validationSchema;