import React from 'react'
import AddToCalendarHOC, { SHARE_SITES } from 'react-add-to-calendar-hoc'
import moment from 'moment'

import { Button, CalendarModal } from '@domain/components'


function AddToCalendar({ startDatetime, endDatetime, address, description, title }) {

  const initialDatetime = moment(startDatetime).locale('nl')
  const finishDatetime = moment(endDatetime).locale('nl');
  const duration = moment.duration(finishDatetime.diff(initialDatetime)).asHours();
  const zipcode = address.zipcode || address.zipCode
  const location = `${address.street} ${address.houseNumber}, ${zipcode} ${address.city}`

  const event = {
    description,
    duration,
    endDatetime: finishDatetime.format('YYYYMMDDTHHmmss'),
    location,
    startDatetime: initialDatetime.format('YYYYMMDDTHHmmss'),
    title,
  }

  const AddToCalendarModal = AddToCalendarHOC(Button, CalendarModal);
  const items = [SHARE_SITES.GOOGLE, SHARE_SITES.ICAL]

  return (
    <AddToCalendarModal event={event} buttonText={'Toevoegen aan agenda'} items={items} dropdownProps={{ title: 'Toevoegen aan agenda' }} />
  )
}

export default AddToCalendar