// NPM imports.
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';

// Bounded context imports.
import Start from './Start';
import Offer from './Offer';
import BodyshopSelectEM from './BodyshopSelectEM';
import BodyshopConfirm from './BodyshopConfirm';
import BodyshopSelect from './BodyshopSelect';
import BodyshopSelectIntakePlus from './BodyshopSelectIntakePlus'

// Routes for damage flow.
import routes from './routes';
import { useScaling } from '@domain/hooks';

import { useQuestionnaire } from '@domain/providers';
import { checkforValidPath } from '@domain/helpers';
import { useSelector } from 'react-redux';

const OwnBodyshopStyle = createGlobalStyle`
  .main__uploads {
    .h1 {
      margin-bottom: 42px;
    }
  }
  .screen-desktop & {
    .main__uploads {
      .grid__column--main {
        padding-right: 0;
      }
      .legend {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(24)}px;
      }
      h1 {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(50)}px;
        // max-width: 500px;
      }
      h1 + .button {
        margin-top: ${props => props.$scaling.scaleUpTo4K(70)}px;
      }
    }
  }
  @media screen and (max-width: 1028px) {
    .screen-desktop & {
      .main__uploads {
        .grid__column--main {
          padding-right: 0;
        }
        .damage-region {
          margin: auto;
        }
        h1 {
          margin: 0 auto 50px;
        }
      }
    }
    .screen-ie & {
      .main__uploads {
        .grid-container__main {
          // display:block;
          legend {
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
  @media screen and (max-width: 580px) {
    .screen-desktop & {
      .main__uploads {
        h1 {
          margin-left: 0;
        }
      }
    }
    .screen-ie & {
      .main__uploads {
        .grid-container__main {
          legend {
            text-align: left;
          }
        }
      }
    }
  }
`;

function OwnBodyshop({ history, match, location }) {
  const scaling = useScaling();
  const currentPath = match.path;

  const { questionnaire } = useQuestionnaire();

  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);
  const files = useSelector(state => state.files)

  const validPath = !!questionnaire && !!questionnaire.find(page => page.path === location.pathname)
  const newValidPath = checkforValidPath(incident, questionnaire, files, insurer).path

  return (
    <>
      <Switch>
        {(!!questionnaire && questionnaire.length && !validPath) && <Redirect to={newValidPath} />}
        <Route exact path={currentPath + routes.bodyshopSelect} component={BodyshopSelect} />
        <Route exact path={currentPath + routes.bodyshopConfirm} component={BodyshopConfirm} />
        <Route exact path={currentPath + routes.start} component={Start} />
        <Route exact path={currentPath + routes.offer} component={Offer} />
        <Route exact path={currentPath + routes.bodyshopSelectEM} component={BodyshopSelectEM} />
        <Route exact path={currentPath + routes.bodyshopSelectIntakePlus} component={BodyshopSelectIntakePlus} />
        <Redirect to={currentPath + routes.start} />
      </Switch>
      <OwnBodyshopStyle $scaling={scaling} />
    </>
  );
}

export default OwnBodyshop;
