import {
  parseFetchResponseToJson,
  camelCaseToPascalCase,
} from '@domain/helpers';

import { endpoints } from '@domain/constants';

const bsIncidentEndpoint = endpoints.bodyshopIncident;

function save(pid, uuid, incident, fid) {
  const headers = !!uuid && !!fid
    ? camelCaseToPascalCase({
      'Content-Type': 'application/json',
      BSID: pid,
      PID: pid,
      FID: fid,
      uuid,
    })
    : !!uuid
      ? camelCaseToPascalCase({
        'Content-Type': 'application/json',
        BSID: pid,
        PID: pid,
        uuid,
      })
      : !!fid
        ? camelCaseToPascalCase({
          'Content-Type': 'application/json',
          BSID: pid,
          PID: pid,
          FID: fid,
        })
        : camelCaseToPascalCase({
          'Content-Type': 'application/json',
          BSID: pid,
          PID: pid,
        })

  const body = JSON.stringify(
    camelCaseToPascalCase({
      ...incident,
    }),
  )
  const config = {
    method: 'POST',
    headers: headers,
    body: body,
  };
  return fetch(bsIncidentEndpoint, config).then((response) => parseFetchResponseToJson(response, { body, endpoint: bsIncidentEndpoint }));
}

export default { endpoints, save };
