import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NEW_RID_FOUND } from '@domain/action-types';
import queryString from 'query-string';
import { browserStorage } from '@domain/helpers';

// This hook should only ever be loaded once in the entire app

const useRIDChecker = (location) => {
  const dispatch = useDispatch();
  const pid = useSelector((state) => state.pid);
  const rid = useSelector((state) => state.rid);

  useEffect(() => {
    if (rid) {
      return;
    }

    if (pid) {
      const RID = queryString.parse(location.search).RID
        ? decodeURIComponent(queryString.parse(location.search).RID)
        : browserStorage.getRID() !== 'undefined'
          ? browserStorage.getRID()
          : null
      if (RID) {
        dispatch({ type: NEW_RID_FOUND, rid: RID });
        browserStorage.setRID(RID)
      }
      return
    }

  }, [dispatch, location.search, pid, rid]);
};

export default useRIDChecker;
