// NPM imports.
import React, { useMemo } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
// Bounded context imports.
import routes from './routes'
import Instructions from './Instructions'
import Start from './Start'
import Chauffeur from './Chauffeur'
import Overview from './Overview'
// import AgreementRecovery from './AgreementRecovery'
import ReplacementVehicle from './ReplacementVehicle'
import Questions from './Questions'
import OwnRisk from './OwnRisk'
// import Signing from './Signing'
//import End from './End'
import Success from './Success';
import { useThemes } from '@domain/hooks'
import { companyNames, proccessType } from '@domain/constants'

function CheckOut({ match }) {
  const insurer = useSelector(state => state.insurer);

  const currentPath = match.path
  const isThemed = !!insurer && insurer.brand !== companyNames.VIP && insurer.objectType !== proccessType.DI;
  const themes = useMemo(() => [isThemed ? 'theme-custom' : 'theme-def'], [isThemed]);
  useThemes(themes);

  return (
    <React.Fragment>
      <Switch>
        <Route exact path={currentPath + routes.instructions} component={Instructions} />
        <Route exact path={currentPath + routes.start} component={Start} />
        <Route exact path={currentPath + routes.chauffeur} component={Chauffeur} />
        <Route exact path={currentPath + routes.overview} component={Overview} />
        {/* <Route exact path={currentPath + routes.agreementRecovery} component={AgreementRecovery} /> */}
        <Route exact path={currentPath + routes.replacementVehicle} component={ReplacementVehicle} />
        <Route exact path={currentPath + routes.ownRisk} component={OwnRisk} />
        <Route exact path={currentPath + routes.questions} component={Questions} />
        {/* <Route exact path={currentPath + routes.signing} component={Signing} /> */}
        {/* <Route exact path={currentPath + routes.end} component={End} /> */}
        <Route exact path={currentPath + routes.success} component={Success} />
        <Redirect to={currentPath + routes.instructions} />
      </Switch>
    </React.Fragment>
  );
}

export default CheckOut
