import { object, string } from 'yup';

export default object({
  customerStreet: string()
    .required()
    .label('Straatnaam'),
  customerCity: string()
    .required()
    .label('Plaats'),
});
