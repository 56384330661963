
import { proccessType } from '@domain/constants';
import { useSelector } from 'react-redux';
import { useSaveCompleteIncident, useSaveIncident, useSaveTempIncident, useTempIncidentLicensePlateCheck } from '..';

const useSaveIncidentFactory = (incident, saveBehavior, pageName) => {

  const insurer = useSelector(state => state.insurer)
  const saveIncident = useSaveIncident()
  const saveTempIncident = useSaveTempIncident();
  const saveTempIncidentLicensePlateCheck = useTempIncidentLicensePlateCheck();
  const saveCompleteIncident = useSaveCompleteIncident()

  if (saveBehavior === 'create') {
    return saveCompleteIncident;
  }
  if (pageName && pageName === 'license') {
    if (incident.tempSaved || incident.status) {
      return saveIncident;
    }
    if (insurer && (insurer.objectType === proccessType.Schadegarant || insurer.objectType === proccessType.DealerSass) && !!incident.redirect && incident.redirect !== 'direct') {
      return saveTempIncident;
    }
    return saveTempIncidentLicensePlateCheck;
  }
  if (incident.tempSaved || incident.status) {
    return saveIncident;
  }
  if (pageName && (pageName === 'success' || pageName === 'thank-you' || pageName === 'end')) {
    if (incident.tempSaved || incident.status) {
      return saveIncident;
    }
    return saveCompleteIncident;
  }
  return saveTempIncident;
}

export default useSaveIncidentFactory