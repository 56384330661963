import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';



function ConfirmationDialog({
  open,
  onYesClick = () => { },
  onNoClick = () => { },
  title,
  content,
  yesLabel = 'Ja',
  noLabel = 'Nee',
  showYesButton = true,
  showNoButton = true,
}) {
  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {showYesButton && (
            <Button onClick={onYesClick} color="primary">
              {yesLabel}
            </Button>
          )}
          {showNoButton && (
            <Button onClick={onNoClick} color="primary" autoFocus>
              {noLabel}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmationDialog;
