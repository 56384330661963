import { default as mainQuestionnaire } from './onboarding';
import { default as checkinQuestionnaire } from './checkin';
import { default as checkoutQuestionnaire } from './checkout';


export default {
  mainQuestionnaire,
  checkinQuestionnaire,
  checkoutQuestionnaire,
}
