// NPM imports.
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// Bounded context imports.
import Start from './Start';
// import Bodyshop from './Bodyshop';
import Success from './Success';
import ThankYou from './ThankYou'
import End from './End'

// Routes for personal flow.
import routes from './routes';
import { useSelector } from 'react-redux';
import { Loader } from '@domain/components';
import { proccessType } from '@domain/constants'
import EndOfFlow from './EndOfFlow';

import { useQuestionnaire } from '@domain/providers';
import { checkforValidPath } from '@domain/helpers';

// import { NoBodyshop } from '../ProcessError';

function Recovery({ match, history, location }) {
  const insurer = useSelector(state => state.insurer)
  const isCasco = insurer.objectType === proccessType.Casco
  const currentPath = match.path;

  const { questionnaire } = useQuestionnaire();

  const incident = useSelector(state => state.incident);
  const files = useSelector(state => state.files)

  const validPath = !!questionnaire && !!questionnaire.find(page => page.path === location.pathname)
  const newValidPath = checkforValidPath(incident, questionnaire, files, insurer).path

  const { start, success, thankYou, end, endOfFlow } = routes;
  if (!insurer) {
    return <Loader />;
  }
  return (
    <Switch>
      {(!!questionnaire && questionnaire.length && !validPath) && <Redirect to={newValidPath} />}
      <Route exact path={currentPath + start} component={Start} />
      <Route exact path={currentPath + success} component={Success} />
      <Route exact path={currentPath + thankYou} component={ThankYou} />
      <Route exact path={currentPath + end} component={End} />
      <Route exact path={currentPath + endOfFlow} component={EndOfFlow} />
      <Redirect to={isCasco ? currentPath + start : currentPath + success} />
    </Switch>
  );
}

export default Recovery;
