import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { FAQ_VIEWED } from '@domain/action-types';
import { browserStorage } from '@domain/helpers';

function useFaq() {
  const [faqVisible, setFaqVisible] = useState(false);
  const [swipeStart, setSwipeStart] = useState(null);
  const [swipeCurrent, setSwipeCurrent] = useState(null);
  const faqSeen = useSelector(state => state.faqSeen);
  const dispatch = useDispatch();

  function onTouchStart(e) {
    setSwipeStart(e.changedTouches[0].clientX);
  }

  function onTouchMove(e) {
    setSwipeCurrent(e.changedTouches[0].clientX);
  }

  function onTouchEnd(e) {
    setSwipeStart(null);
    setSwipeCurrent(null);
  }

  const swipeRight = useCallback(
    function swipeRight() {
      if (swipeCurrent !== null && swipeCurrent - swipeStart > 100) {
        setFaqVisible(false);
        setSwipeStart(null);
        setSwipeCurrent(null);
      }
    },
    [swipeCurrent, swipeStart]
  );

  useEffect(swipeRight, [swipeRight]);

  function handleOnHelpClick(event) {
    event.stopPropagation();
    dispatch({ type: FAQ_VIEWED });
    browserStorage.setFaqSeen(true)
    setFaqVisible(!faqVisible);
  }

  function clickOutSideFaq(event) {
    if (faqVisible) {
      setFaqVisible(false);
    }
  }

  const faqListProps = {
    faqVisible: faqVisible,
    onTouchStart: onTouchStart,
    onTouchMove: onTouchMove,
    onTouchEnd: onTouchEnd
  };

  return [
    faqVisible,
    handleOnHelpClick,
    clickOutSideFaq,
    faqListProps,
    faqSeen
  ];
}

export default useFaq;
