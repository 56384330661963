import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledList = styled.ul`
  display: flex;
  justify-content: space-between;
  padding: 10px 2px;
  .screen-desktop & {
    padding: 20px 2px;
  }
`;

function SingleImageViewerContainer({ children }) {
  return (
    <StyledList className="image__viewer__container">
      {React.Children.toArray(children)}
    </StyledList>
  );
}

SingleImageViewerContainer.propTypes = {
  children: PropTypes.any.isRequired
};

export default SingleImageViewerContainer;
