function filterFileTypes(uploads, types) {
  const result = {};

  let notAcceptedFileType = false;
  for (let i = 0; i < uploads.length; i++) {
    const file = uploads[i];
    if (types.includes(file.type)) {
      result[i] = file;
    } else {
      notAcceptedFileType = true;
    }
  }

  return [result, notAcceptedFileType];
}

export default filterFileTypes;
