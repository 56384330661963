import { parseFetchResponseToJson, camelCaseToPascalCase } from '@domain/helpers';

import { endpoints } from '@domain/constants';

const bodyshopListsEndpointSG = endpoints.bodyshopListsSG;
const bodyshopListsEndpointEM = endpoints.bodyshopListsEM;
const bodyshopListsIntakePlus = endpoints.bodyshopListsIntakePlus

function getSG(uuid, token, incident, carMake, radius = 10, postcode, city) {
  const damageType = incident.damageType
  const config = {
    method: 'GET',
    headers: camelCaseToPascalCase({
      token,
      uuid,
    }),
  };

  const encodedCarMake = carMake.replace(/\s+/g, '_').replace("-", '_').replace(/\u00eb/, "e")
  const encodedPostcode = postcode ? `&Postcode=${encodeURIComponent(postcode)}` : ''
  const encodedCity = city ? `&City=${encodeURIComponent(city)}` : ''

  return fetch(`${bodyshopListsEndpointSG}?Radius=${radius}&DamageType=${damageType}&CarMake=${encodedCarMake}${encodedPostcode}${encodedCity}`, config).then((response) => parseFetchResponseToJson(response, { endpoint: bodyshopListsEndpointSG }));
};

function getIntake(uuid, token, incident, radius = 50, postcode) {
  const config = {
    method: 'GET',
    headers: camelCaseToPascalCase({
      token,
      uuid,
    }),
  };

  const encodedPostcode = postcode ? `&postcode=${encodeURIComponent(postcode)}` : ''

  return fetch(`${bodyshopListsIntakePlus}?radius=${radius}${encodedPostcode}`, config).then((response) => parseFetchResponseToJson(response, { endpoint: bodyshopListsIntakePlus }));
};

function getEM(uuid, token, incident) {
  const config = {
    method: 'GET',
    headers: camelCaseToPascalCase({
      token,
      uuid,
    }),
  };
  return fetch(`${bodyshopListsEndpointEM}`, config).then((response) => parseFetchResponseToJson(response, { endpoint: bodyshopListsEndpointEM }));
};

export default {
  getSG,
  getEM,
  getIntake
};
