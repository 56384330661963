import { object, string } from 'yup';

const validationSchema = object({
  witnessStreet: string()
    .required()
    .label('Straatnaam'),
  witnessCity: string()
    .required()
    .label('Plaats'),
});

const validationSchemaNotRequired = object({
  witnessStreet: string()
    .label('Straatnaam'),
  witnessCity: string()
    .label('Plaats'),
});

export { validationSchema, validationSchemaNotRequired };