import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import DoneIcon from '@material-ui/icons/Done';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useScaling } from '@domain/hooks';

import { Colors } from '@domain/theming';

const colorDanger = Colors.RED;
const black = Colors.BLACK;

const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop);

function Input({
  className,
  id,
  type,
  name,
  placeholder,
  defaultValue,
  minLength,
  maxLength,
  min,
  max,
  multiline,
  rows,
  onChange,
  disabled,
  value,
  valid,
  autoFocus,
  label,
  displayLabel = true,
  textColor = black,
  borderColor = black,
  displayEndAdornment = true,
  textAlign = 'left',
  letterSpacing = 'normal',
  width = '100%',
  labelColor = '#000000',
  transparentAdornment = false,
  regexPass,
  customBaseSize
}) {
  const inputRef = useRef(null);

  const scaling = useScaling();

  const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    textField: {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(1),
      width: width || (isMobileOnly ? '100%' : '360px'),
      // height: '67px',
      height:
        (!isMobileOnly
          ? scaling.textSize(!!customBaseSize ? (customBaseSize * 1.75) + 11 : 67)
          : scaling.textSizeMobile(!!customBaseSize ? customBaseSize * 1.75 + 11 : 67)) + 'px'
      // background: 'rgba(30,0,255,0.2)'
    },
    inputRoot: {
      fontSize:
        (isMobileOnly
          ? scaling.inputSizeMobile(defaultValue || value, customBaseSize || 32, inputRef.current)
          : scaling.inputSize(defaultValue || value, customBaseSize || 32, inputRef.current)) + 'px',
      fontWeight: 'bold',
      lineHeight:
        (!isMobileOnly
          ? scaling.textSize(!!customBaseSize ? customBaseSize * 1.125 : 36)
          : scaling.textSizeMobile(!!customBaseSize ? customBaseSize * 1.125 : 36)) + 'px',
      borderColor,
      color: textColor,
      // alignItems: 'flex-end',
      '&$inputDisabled': {
        color: black
      },
      // height: '51px',
      marginBottom: '0',
      marginTop: 'auto',
      // background: 'rgba(30,0,255,0.2)'
    },
    inputInput: {
      textAlign,
      letterSpacing,
      marginTop: 'auto',
      paddingTop:
        ((!isMobileOnly ?
          scaling.textSize(6) :
          scaling.textSizeMobile(6)) + 'px'),
      paddingBottom:
        ((!isMobileOnly ?
          scaling.textSize(7) :
          scaling.textSizeMobile(7)) + 'px'),
      paddingLeft:
        textAlign === 'center'
          ? !!displayEndAdornment
            ? ((!isMobileOnly ?
              scaling.textSize(customBaseSize || 36) :
              scaling.textSizeMobile(customBaseSize || 26)) + 'px')
            : '0'
          : '0',
      // height: 'auto',
      height:
        (!isMobileOnly
          ? scaling.textSize(!!customBaseSize ? customBaseSize * 1.75 : 56)
          : scaling.textSizeMobile(!!customBaseSize ? customBaseSize * 1.75 : 56)) + 'px'
      // background: 'rgba(30,0,255,0.2)'
      // marginBottom: '8px'
    },
    inputAdornmentRoot: {
      fontSize: ((!isMobileOnly ?
        scaling.textSize(customBaseSize || 16) :
        scaling.textSizeMobile(customBaseSize || 16)) + 'px'),
    },
    inputAdornment: {
      height: '100%',
      maxHeight: 'none',
      justifyContent: 'center',
      width: ((!isMobileOnly ?
        scaling.textSize(customBaseSize || 28) :
        scaling.textSizeMobile(customBaseSize || 18)) + 'px'),
      // paddingLeft: '10px',
      flex: '0 0 auto',
      background: transparentAdornment
        ? 'rgba(0,0,0,0)'
        : 'linear-gradient(90deg, rgba(242,242,242,0) 0%, rgba(242,242,242,1) 36%)',
    },
    svgIcon: {
      fontSize: ((!isMobileOnly ?
        scaling.textSize(customBaseSize || 24) :
        scaling.textSizeMobile(customBaseSize || 24)) + 'px'),
    },
    errorAdornment: {
      color: colorDanger,
    },
    validAdornment: {
      color: labelColor,
      '.theme-custom &': {
        color: theme.colors.primary,
      }
    },
    inputLabelRoot: {
      display: displayLabel ? 'block' : 'none',
      fontFamily: theme.fontFamily,
      height: scaling.scaleUpTo4K(44),
      borderBottom: '0.5px',
      color: labelColor,
      fontSize: ((!isMobileOnly ?
        scaling.textSize(customBaseSize || 32) :
        scaling.textSizeMobile(customBaseSize || 32)) + 'px'),
      fontWeight: 'bold',
      lineHeight: ((!isMobileOnly ?
        scaling.textSize(customBaseSize * 1.125 || 32) :
        scaling.textSizeMobile(customBaseSize * 1.125 || 32)) + 'px'),
      transform: `translate(0, ${scaling.scaleUpTo4K(!!customBaseSize ? customBaseSize / 1.28 : 25)}px)`,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: '100%',
      '&$inputLabelDisabled': {
        color: labelColor
      },
      '&+$inputRoot': {
        marginTop: 'auto'
      },
      '.theme-custom &': {
        color: theme.colors.primary,
        // fontFamily: 'Dag'
      }
    },
    inputLabelShrink: {
      fontSize: ((!isMobileOnly ?
        scaling.textSize(customBaseSize / 2 || 16) :
        scaling.textSizeMobile(customBaseSize / 2 || 16)) + 'px'),
      fontWeight: 'bold',
      color: labelColor,
      transform: `translate(0, -${((!isMobileOnly ?
        scaling.textSize(customBaseSize || 20) :
        scaling.textSizeMobile(customBaseSize || 20)))}px)`,
      lineHeight: ((!isMobileOnly ?
        scaling.textSize(customBaseSize / 2 || 14) :
        scaling.textSizeMobile(customBaseSize / 2 || 14)) + 'px'),
      letterSpacing: '0.0625em',
      transformOrigin: 'top left',
      '.theme-custom &': {
        color: theme.colors.primary,
      }
    },
    inputError: { color: colorDanger },
    inputLabelAsterisk: {
      visibility: 'hidden'
    },
    inputLabelFormControl: {
      '&$focused': {
        color: labelColor,
        fontWeight: 'bold'
      },
      '&$inputLabelError': {
        color: colorDanger
      },
      '.theme-custom &': {
        '&$focused': {
          color: theme.colors.primary,
        }
      }
    },
    inputUnderline: {
      '&$inputError': {
        '&:before': {
          borderColor: colorDanger
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: `1px solid ${colorDanger}`
        },
        '&:after': {
          borderBottom: `2px solid ${colorDanger}`
        }
      },
      '&:before': {
        borderColor
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: `1px solid ${borderColor}`
      },
      '&:after': {
        borderBottom: `2px solid ${borderColor}`
      },
      '.theme-custom &': {
        '&:before': {
          borderColor: theme.colors.primary,
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: `1px solid ${theme.colors.primary}`
        },
        '&:after': {
          borderBottom: `2px solid ${theme.colors.primary}`
        },
      },
    },
    inputLabelClasses: {},
    inputDisabled: {},
    inputLabelDisabled: {},
    focused: {},
    inputLabelError: {},
    dense: {
      marginTop: 19
    },
    menu: {
      width: 200
    }
  }));

  const classes = useStyles();

  const { textField, validAdornment, errorAdornment } = classes;

  useEffect(
    () =>
      inputRef.current.addEventListener('onfocus', () => scrollToRef(inputRef)),
    []
  );

  function inputAdornment() {
    if (regexPass) {
      return (
        <>
          <DoneIcon classes={{ root: classes.svgIcon }} /> <Typography variant="srOnly">Goed ingevuld</Typography>
        </>
      );
    }

    if (!valid) {
      return (
        <>
          <PriorityHighIcon />{' '}
          <Typography variant="srOnly">Niet goed ingevuld</Typography>
        </>
      );
    }

    return <> </>;
  }

  return (
    <TextField
      autoFocus={autoFocus && !isMobile}
      className={textField}
      disabled={disabled}
      error={!valid}
      id={id}
      inputRef={inputRef}
      key={id}
      label={label}
      margin="normal"
      multiline={multiline}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      required
      rows={rows}
      spellCheck={false}
      type={type}
      value={value || ''}
      InputProps={{
        disableUnderline: disabled,
        classes: {
          root: classes.inputRoot,
          underline: classes.inputUnderline,
          input: classes.inputInput,
          disabled: classes.inputDisabled,
          error: classes.inputError
        },
        endAdornment: displayEndAdornment ? (
          <InputAdornment
            className={valid ? validAdornment : errorAdornment}
            classes={{ root: classes.inputAdornment }}
            position="end"
            disableTypography
          >
            {inputAdornment()}
          </InputAdornment>
        ) : (
          false
        )
      }}
      InputLabelProps={{
        htmlFor: id,
        classes: {
          root: classes.inputLabelRoot,
          shrink: classes.inputLabelShrink,
          asterisk: classes.inputLabelAsterisk,
          formControl: classes.inputLabelFormControl,
          focused: classes.focused,
          disabled: classes.inputLabelDisabled,
          error: classes.inputLabelError
        }
      }}
    />
  );
}

Input.propTypes = {
  type: PropTypes.oneOf(['text', 'email', 'number', 'tel', 'password', 'date'])
    .isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.any,
  minLength: PropTypes.string,
  maxLength: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  multiline: PropTypes.bool,
  onChange: PropTypes.func,
  rows: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  regexPass: PropTypes.bool
};

export default Input;
