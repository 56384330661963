import queryString from 'query-string';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SET_EXCLUDED_PAGES,
  EXCLUDED_PAGES_FOUND_IN_INCIDENT,
  EXCLUDED_PAGES_FOUND_IN_BROWSER_STORAGE,
  UPDATE_EXCLUDED_PAGES,
} from '@domain/action-types';
import { companyNames, proccessType } from '@domain/constants'
import { browserStorage } from '@domain/helpers';

function useExcludedPagesChecker(location) {
  const [params, setParams] = useState({});
  const [excludedPagesString, setExcludedPagesString] = useState(null)

  const incident = useSelector((state) => state.incident);
  const insurer = useSelector(state => state.insurer)
  const pagesExcluded = useSelector((state) => state.pagesExcluded);
  const dispatch = useDispatch();
  const search = location.search;
  const inCreation = useSelector((state) => state.inCreation)

  const generateJumpOverString = useCallback(() => {
    const pages = [];
    //Information that if present should exclude a page. 
    //For example, if I have debiteurnummer I don't need to show the select bodyshop page
    if (!!insurer && insurer.objectType === proccessType.Schadegarant) {
      pages.push('speed', 'interior', 'welcome', 'recovery_start');
      if (params.DamageType && params.DamageType === 'Glas') {
        pages.push('exterior')
      }
      if (params.DebiteurNummer) {
        pages.push('bodyshop_select');
      }
    }
    if (!!insurer && (insurer.objectType === proccessType.DealerSass || insurer.objectType === proccessType.Intake || insurer.objectType === proccessType.IntakeSpecial)) {
      pages.push('bodyshop_select', 'bodyshop_confirm')
      // if (params.DamageType && params.DamageType === 'Glas') {
      //   pages.push('exterior')
      // }
    }
    if (!!insurer && insurer.objectType === proccessType.DI) {
      pages.push('bodyshop_select', 'bodyshop_confirm', 'welcome', 'recovery_start')
    }
    if (!!insurer && insurer.objectType === proccessType.Casco && (insurer.brand === companyNames.VIP || !insurer.brand) && !inCreation) {
      pages.push('license', 'name')
    }
    if (!!insurer && insurer.objectType === proccessType.Casco && insurer.brand !== companyNames.Rhion) {
      pages.push('otherfiles')
    }
    if (!!insurer && (insurer.objectType === proccessType.Casco || insurer.objectType === proccessType.EM || !insurer.objectType || insurer.objectType === proccessType.DealerDistribution)) {
      pages.push('bodyshop_select', 'bodyshop_confirm')
    }
    const stringOfPages = pages.join(' ');
    browserStorage.setPagesExcluded(stringOfPages)
    setExcludedPagesString(stringOfPages)
  }, [params.DebiteurNummer, params.DamageType, insurer, inCreation]);


  const updateJumpOverString = useCallback(() => {
    if (!pagesExcluded) {
      return
    }
    let pages = pagesExcluded ? pagesExcluded.split(' ') : []
    if (insurer && incident && incident.cascoAvailable === false && insurer.objectType === proccessType.EM) {
      if (!pages.includes('recovery_start')) {
        pages.push('recovery_start')
        const updatedStringOfPages = pages.join(' ')
        if (updatedStringOfPages !== pagesExcluded) {
          browserStorage.setPagesExcluded(updatedStringOfPages);
          setExcludedPagesString(updatedStringOfPages)
        }
      }
    }
    if (insurer && incident && incident.cascoAvailable === true && insurer.objectType === proccessType.EM) {
      if (pages.includes('recovery_start')) {
        pages = pages.filter(page => page !== 'recovery_start')
        const updatedStringOfPages = pages.join(' ')
        if (updatedStringOfPages !== pagesExcluded) {
          browserStorage.setPagesExcluded(updatedStringOfPages);
          setExcludedPagesString(updatedStringOfPages)
        }
      }
    }
  }, [incident, insurer, pagesExcluded])


  useEffect(() => {
    if (pagesExcluded) {
      return;
    }
    if (search) {
      const searchObject = queryString.parse(search);
      setParams(searchObject);
      return;
    }
    if (!!insurer) {
      generateJumpOverString();
    }
    if (excludedPagesString) {
      dispatch({
        type: SET_EXCLUDED_PAGES,
        pages: excludedPagesString,
      });
      return
    }
    if (incident && incident.pagesExcluded) {
      dispatch({
        type: EXCLUDED_PAGES_FOUND_IN_INCIDENT,
        pages: incident.pagesExcluded,
      });
      return;
    }
    if (browserStorage.getPagesExcluded() &&
      (!incident || !incident.pagesExcluded)) {
      dispatch({
        type: EXCLUDED_PAGES_FOUND_IN_BROWSER_STORAGE,
        pages: browserStorage.getPagesExcluded(),
      });
      return;
    }
  }, [search, generateJumpOverString, incident, insurer, dispatch, pagesExcluded, excludedPagesString]);

  useEffect(() => {
    if (!pagesExcluded) {
      return
    }
    setExcludedPagesString(null)
    updateJumpOverString()
    if (excludedPagesString && excludedPagesString !== pagesExcluded) {
      dispatch({
        type: UPDATE_EXCLUDED_PAGES,
        pages: excludedPagesString,
      });
      return
    }
    return
  }, [pagesExcluded, updateJumpOverString, dispatch, excludedPagesString, incident]);
}

export default useExcludedPagesChecker;
