import { useContext } from 'react';

function useProvider(context) {
  const contextObj = useContext(context);
  if (contextObj === null) {
    const { displayName } = context;
    throw new Error(
      `use${displayName} must have ${displayName}Provider as an ancestor`
    );
  }
  return contextObj;
}

export default useProvider;
