import { NEW_RID_FOUND } from '@domain/action-types';

const initialFID = null;

const rid = (state = initialFID, { type, rid }) => {
  switch (type) {
    case NEW_RID_FOUND:
      return rid;
    default:
      return state;
  }
};

export default rid;
