import { checkQuestionnaire } from '../checkQuestionnaire';
const getPrevNextPagePath = (history, questionnaire, incident, currentPath, files, isFeedback = false) => {
  const progress = questionnaire.findIndex(page => page.path === currentPath);
  const prevPage = progress <= 0 ? undefined : questionnaire[progress - 1].path;
  const nextPage = checkQuestionnaire(
    progress + 1,
    questionnaire,
    incident,
    files,
    isFeedback,
  );
  return { prevPage: !!prevPage ? prevPage : undefined, nextPage: !!nextPage ? nextPage : undefined };
};

export default getPrevNextPagePath;
