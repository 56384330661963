async function encodeFileInBase64(file) {
  try {
    const encodedFile = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });

    return encodedFile;
  } catch (error) {
    console.error(`Error occurred during encode of files (encodeFileInBase64):`, error);
  }
}

export default encodeFileInBase64;
