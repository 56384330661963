import { useDispatch } from 'react-redux';
import { AuthService } from '@domain/services';
import { LOGGED_IN } from '@domain/action-types';
import { browserStorage } from '@domain/helpers';

const useSignIn = () => {
  const dispatch = useDispatch();

  const signIn = async (licensePlate, uuid) => {
    try {
      const incident = await AuthService.signIn(licensePlate, uuid);

      const storageList = Object.keys(localStorage);
      browserStorage.clearLocalStorage([...storageList], ['consentCode', 'bannerOpen', 'spCase', 'uuid', 'progressStr']);
      dispatch({
        type: LOGGED_IN,
        incident,
      })
      browserStorage.setAuthenticationToken(incident.token)
    } catch ({ error }) {
      console.warn(`Error occurred inside useSignIn trying to login (AuthService.signIn):`, error);
      return error;
    }
  };

  return signIn;
};

export default useSignIn;
