// NPM imports.
import React, { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

// Domain imports.
import { useReloadInsurer, useSaveIncidentFactory } from '@domain/hooks';
import { generatePageContent, getPrevNextPagePath, goToNextPage, browserStorage, chooseLogoSize } from '@domain/helpers';
import { TimelinePage } from '@domain/pages';
import { companyNames, proccessType } from '@domain/constants';
import {
  Button,
  CheckList,
  CheckListItem,
  Container,
  Heading, Loader, Paragraph, Text,
} from '@domain/components';
import { detectContentType } from '@domain/content';
import { useQuestionnaire } from '@domain/providers';
import { ThemeContext } from 'styled-components';
function Start({ history, match }) {


  const incident = useSelector(state => state.incident);
  const files = useSelector(state => state.files);
  const insurer = useSelector(state => state.insurer);

  const { questionnaire } = useQuestionnaire();

  const reloadInsurer = useReloadInsurer()

  const themeContext = useContext(ThemeContext);

  const fetching = useRef(false);

  const isVIP = insurer.objectType === proccessType.Casco && (insurer.brand === companyNames.VIP || !insurer.brand)

  const currentPath = match.path;
  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined

  const saveIncident = useSaveIncidentFactory(incident, saveBehavior);

  useEffect(() => {
    if (!!questionnaire && questionnaire.length) {
      const progress = questionnaire.find(page => page.path === currentPath).name
      browserStorage.setProgress(progress);
    }
  }, [currentPath, questionnaire])

  const isPageBeforeSuccess = questionnaire[questionnaire.length - 2].path === currentPath

  async function handleClickSwapVIP(event) {
    event.preventDefault()
    fetching.current = true;
    const incidentSwitched = await saveIncident({ ...incident, switchCasco: true }, isPageBeforeSuccess);
    if (Object.keys(incidentSwitched).length) {
      await reloadInsurer()
      fetching.current = false;
      goToNextPage(history, questionnaire, incidentSwitched, currentPath, files);
    }
  }

  if (!questionnaire || !questionnaire.length || !insurer) {
    return <Loader />;
  }

  const pagePath = getPrevNextPagePath(
    history,
    questionnaire,
    incident,
    currentPath,
    files,
  );
  const nextPage = pagePath.nextPage !== currentPath
    ? pagePath.nextPage
    : undefined;

  const contentType = detectContentType(insurer)
  const pageName = questionnaire.find(page => page.path === currentPath).name
  const content = generatePageContent(contentType, insurer, incident, false, false, pageName)

  const title = content["title"]
  const text = content["text"]
  const showTimeline = content["show-timeline"]
  const timelineTitle = content["timeline-title"]
  const timeline = content["timeline"]
  const showExtraBlock = content["show-extra-block"]
  const extraBlockTitle = content["extra-block-title"]
  const extraBlockAuxiliarText = content["extra-block-auxiliar-text"]
  const extraButtonText = content["extra-button-text"]
  const showChecklist = content["show-checklist"]
  const checklistTitle = content["checklist-title"]
  const checkList = content["checklist"]
  const useCustomLogo = content["use-custom-logo"]

  const middleLogo = {
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }

  const generateCheckList = showChecklist ? checkList.map((item, i) => {
    return (
      <CheckListItem className="recovery" icon={item.icon} key={i}>
        {item.text}
      </CheckListItem>
    )
  }) : null

  const buttonSwapVIP = {
    className: 'button--primary',
    onClick: handleClickSwapVIP,
    shadow: true,
    theme: 'primary',
    justify: 'space-between',
  };

  const extraBlock =
    <Container className="page-wizard__info">
      {extraBlockTitle && <Heading level="4">{extraBlockTitle}</Heading>}

      {extraBlockAuxiliarText && <Paragraph>{extraBlockAuxiliarText}</Paragraph>}
      {checklistTitle && <Paragraph><b>{checklistTitle}</b></Paragraph>}
      {showChecklist && <CheckList>{generateCheckList}</CheckList>}
      {extraButtonText && <Button {...buttonSwapVIP}>
        {extraButtonText}
      </Button>}
    </Container>





  const headerGroupContent = (
    <>
      {title && <Heading level="1" className="page-wizard__h1 h1__mobile">{title}</Heading>}
      {text && <Text>{text}</Text>}
    </>
  )

  return (
    <TimelinePage
      currentPath={currentPath}
      headerGroupContent={headerGroupContent}
      history={history}
      files={files}
      nextPage={nextPage}
      isVIP={isVIP}
      prevPage={pagePath.prevPage}
      questionnaire={questionnaire}
      saveIncident={saveIncident}
      showTimeline={showTimeline}
      timeline={timeline}
      unmuted={3}
      showExtraBlock={showExtraBlock}
      extraBlock={extraBlock}
      timelineHeader={timelineTitle}
      customMiddleLogo={useCustomLogo}
      middleLogo={middleLogo}
    />
  );
}

export default Start;
