// NPM imports.
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

// Domain imports.
import { Text, UseIcon } from '@domain/components';
import { useScaling } from '@domain/hooks';


const StyledListItem = styled.li`
  margin: 0 0 ${props => props.$scaling.scaleUpTo4K(30)}px 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  &:last-child {
    margin: 0;
  }
  .checklist__item__icon {
    margin-right: ${props => props.$scaling.scaleUpTo4K(20)}px;
    flex: 0 0 ${props => props.$scaling.scaleUpTo4K(20)}px;
  }
  .checklist__item__text {
    display: inline;
    flex: 1 1 0px;

  }
`;

function CheckListItem({ children, className, icon = 'icon-check-circle' }) {
  const scaling = useScaling();
  const classes = classNames('checklist__item', className);

  return (
    <StyledListItem className={classes} $scaling={scaling}>
      <UseIcon
        name={icon}
        height="20"
        width="20"
        className="checklist__item__icon"
      />
      <Text className="checklist__item__text">
        {React.Children.toArray(children)}
      </Text>
    </StyledListItem>
  );
}

CheckListItem.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

export default CheckListItem;
