import React, { useState, useCallback, useMemo, useContext } from 'react';
import { useSelector } from 'react-redux'

import { TextInputsPage } from '@domain/pages';
import { validationSchema, validationSchemaNotRequired } from './validation-schema';
import { useSaveIncidentFactory } from '@domain/hooks';
import { chooseLogoSize, generatePageContent, getPrevNextPagePath } from '@domain/helpers';
import { Loader } from '@domain/components';
import { detectContentType } from '@domain/content';
import { companyNames, proccessType } from '@domain/constants';
import { useQuestionnaire } from '@domain/providers';
import { ThemeContext } from 'styled-components';

function WitnessPostal({ history, match }) {
  const [badZipCodes, setBadZipCodes] = useState(() => []);

  const inCreation = useSelector(state => state.inCreation);
  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);
  const files = useSelector(state => state.files)

  const themeContext = useContext(ThemeContext);

  const isVIP = (insurer.objectType === proccessType.Casco && (insurer.brand === companyNames.VIP || !insurer.brand))
  const { questionnaire } = useQuestionnaire();

  const currentPath = match.path;
  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined

  const saveIncident = useSaveIncidentFactory(incident, saveBehavior);

  const faqs = useMemo(
    () => [
      {
        question: 'Waarom moet ik de gegevens van de getuige opgeven?',
        answer: 'Deze informatie helpt ons met het eventuele verhalen van uw schade. Indien nodig nemen wij ook contact op met de tegenpartij voor extra informatie.'
      }
    ], []);


  const isPageBeforeSuccess = questionnaire[questionnaire.length - 2].path === currentPath

  const onValidated = useCallback(
    async function onValidated(values) {
      const witnessZipCode = values.witnessZipCode
        .toUpperCase()
        .replace(/ /g, '');
      const valuesCopy = {
        ...values,
        witnessZipCode,
      };

      const returnedIncident = await saveIncident({
        ...incident,
        ...valuesCopy,
      }, isPageBeforeSuccess);

      if (!returnedIncident) {
        return;
      }

      const invalidPostCode = 3;
      const {
        errorCode,
        witnessZipCode: returnedWitnessZipCode,
      } = returnedIncident;
      if (errorCode === invalidPostCode || !returnedWitnessZipCode) {
        setBadZipCodes(codes => [...codes, witnessZipCode]);
        return
      }
      return true
    },
    [saveIncident, incident, isPageBeforeSuccess],
  );

  const customCheck = useMemo(
    () => ({
      witnessZipCode: (
        { witnessZipCode },
        { witnessZipCode: postedZipCode },
      ) => {
        if (!witnessZipCode || !postedZipCode) {
          return false;
        }
        const sanitiseZipCode = (postcode: string) => {
          return postcode.replace(' ', '').toUpperCase;
        };

        return (
          sanitiseZipCode(witnessZipCode) === sanitiseZipCode(postedZipCode)
        );
      },
    }),
    [],
  );

  if (!insurer || !questionnaire || !incident) {
    return <Loader />;
  }

  const pagePath = getPrevNextPagePath(
    history,
    questionnaire,
    incident,
    currentPath,
    files
  );


  const nextPage = pagePath.nextPage !== currentPath
    ? pagePath.nextPage
    : undefined;


  const contentType = detectContentType(insurer)
  const pageName = page.name
  const content = generatePageContent(contentType, insurer, incident, inCreation, false, pageName)

  const validationSchemaToBeUsed = () => {
    if (page.required === false) {
      return validationSchemaNotRequired(badZipCodes)
    }
    return validationSchema(badZipCodes)
  }

  const fields = [
    {
      name: 'witnessZipCode',
      label: 'Postcode',
      mandatory: true,
      displayEndAdornment: page.required !== false
    },
    {
      name: 'witnessHouseNumber',
      label: 'Huisnummer',
      mandatory: true,
      displayEndAdornment: page.required !== false
    },
    {
      name: 'witnessHouseNumberAddition',
      label: 'Toevoeging',
      mandatory: false,
      displayEndAdornment: page.required !== false
    },
  ];

  const customHelpLink = content["custom-help-link"]
  const helpLinkText = content["help-link-text"]
  const useCustomLogo = content["use-custom-logo"]
  const legend = content["over-title-text"]
  const heading = content["title"]

  const middleLogo = {
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }

  return (
    <TextInputsPage
      buttonText={!!inCreation ? 'Volgende' : 'Correct'}
      currentPath={currentPath}
      customCheck={customCheck}
      customOnValidated={onValidated}
      faqs={faqs}
      fields={fields}
      files={files}
      formID="postal"
      legend={legend}
      heading={heading}
      history={history}
      nextPage={nextPage}
      prevPage={pagePath.prevPage}
      progressIndicatorCurrent={1}
      progressIndicatorSteps={3}
      questionnaire={questionnaire}
      saveIncident={saveIncident}
      validationSchema={validationSchemaToBeUsed()}
      isVIP={isVIP}
      customHelpLink={customHelpLink}
      helpLinkText={helpLinkText}
      customMiddleLogo={useCustomLogo}
      middleLogo={middleLogo}
    />
  );
}

export default WitnessPostal;
