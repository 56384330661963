import { useCallback, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { INCIDENT_UPDATED } from '@domain/action-types'
import { CallbackRequestService } from '@domain/services';

import useDispatchError from '../useDispatchError';

const useSaveCallbackRequest = () => {

  const uuid = useSelector(state => state.uuid);
  const incident = useSelector(state => state.incident)
  const callbackSaving = useRef(false);
  const dispatch = useDispatch();

  const [callbackConfirmationMessage, setCallbackConfirmationMessage] = useState(null);

  const { dispatchNewError } = useDispatchError();

  // example of argument 'callback'({ ...values, uuid: uuid, callbackDate: moment(new Date()).locale('nl').format('YYYY-MM-DDTHH:mm:ss') })
  const saveCallbackRequest = useCallback(
    async (callback) => {
      if (callbackSaving.current) {
        return;
      }
      callbackSaving.current = true;

      try {
        const response = await CallbackRequestService.save({ ...callback, UUID: uuid });

        const updatedIncident = { ...incident, callbackRequestPossible: response.callbackRequestPossible };

        dispatch({
          type: INCIDENT_UPDATED,
          incident: updatedIncident
        })
        if (response.errorCode === 21) {
          setCallbackConfirmationMessage("U heeft al een terugbelverzoek ingediend")
        } else if (response.errorCode === 22) {
          setCallbackConfirmationMessage("Probeer het zo nogmaals")
        } else {
          setCallbackConfirmationMessage("U heeft een terugbelverzoek ingediend. U wordt zo snel mogelijk gebeld.")
        }
      } catch ({ error }) {
        console.error(`Error occurred inside useSaveCallBackRequest trying to post callback request from track and trace (CallbackRequestService.save):`, error);
        dispatchNewError(error);
      }
      callbackSaving.current = false;
    },
    [uuid, dispatchNewError, dispatch, incident],
  );

  return {
    callbackConfirmationMessage,
    setCallbackConfirmationMessage,
    saveCallbackRequest,
  };
};

export default useSaveCallbackRequest;
