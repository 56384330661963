// NPM imports.
import React, { useMemo } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

// Domain wide imports.
import { LOGGED_OUT } from '@domain/action-types';
import { Loader, HtmlHead, ScrollToTop, Cookie, Consent } from '@domain/components';
import { companyNames, flows, proccessType } from '@domain/constants';
import { createCustomTheme } from '@domain/helpers';
import { useCookieConsent, useDispatchError, useGlobalEffects, useScaling } from '@domain/hooks';

// Bounded context imports.
import SignIn from './Authentication';
import Checkin from './Checkin';
import Checkout from './Checkout';
import Error from './Error';
import Onboarding from './Onboarding';
import routes from './routes';
import TrackTrace from './TrackTrace';
import { Colors } from '@domain/theming';
import { QuestionnaireProvider } from '@domain/providers';
import useTracking from '@domain/hooks/useTracking';

const GlobalStyle = createGlobalStyle`
  *,
  :after,
  :before {
    box-sizing: border-box;
  }

  :focus {
    outline: none;
  }

  html {
    -webkit-text-size-adjust: 100%;
  }
  html,
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
  body,
  p,
  span,
  button {
    font-family: ${props => props.theme.fontFamily};
  }
  #root,
  html,
  body,
  .screen-mobile,
  .screen-desktop,
  .screen-tablet {
    min-width: 100%;
    min-height: 100%;
  }
  
  .screen-ie {
    height: 0;
  }

  .screen-safari {
    position: absolute;
  }

  .screen-mobile,
  .screen-desktop,
  .screen-tablet {
    display: flex;
    flex-direction: column;
  }

  
  body {
    display: flex;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: ${Colors.BLACK};
    background-color: ${Colors.GREY_LIGHTER};
    &.theme-dark {
      color: ${Colors.WHITE};
      background-color: ${Colors.BLACK};
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  h1 {
    line-height: 1;
  }
  section {
    flex: 1 0 auto;
  }

  ul {
    padding-left: 0;
    margin: 0;
  }

  li {
    list-style-type: none;
  }

  .video {
    color: ${Colors.WHITE};

    .screen-ie,
    .screen-edge {
      padding: 0 80px;
      @media screen and (max-width: 1028px) {
        padding: 0;
      }
    }
    &.video-img {
      .screen-ie,
      .screen-edge {
        padding: 0;
      }
    }
  }

  dl {
    margin: 0;
  }
  dt,
  dd {
    display: inline;
  }

  dd {
    margin-left: 0;
  }

  dt {
    font-style: italic;
  }
  //overlay for background video
  :root > div:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0);
    z-index: -1;
  }

  .word-break {
    word-break: break-all;
  }

  ${props => !!props.theme.font.normal.file && `
  @font-face {
    font-family: ${props.theme.fontFamily};
    src: url(data:application/font-woff;charset=utf-8;base64,${props.theme.font.normal.file}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
  `}
  
  ${props => !!props.theme.font.bold.file && `
  @font-face {
    font-family: ${props.theme.fontFamily};
    src: url(data:application/font-woff;charset=utf-8;base64,${props.theme.font.bold.file}) format('woff');
    font-weight: 700;
    font-style: bold;
  }
  `}
`;


function AppContstructor({ location, history }) {
  // We should upgrade our React and React-Router versions so that we can use
  // the useLocation and useHistory hooks instead of passing location and history as props

  const authenticated = useSelector(state => state.authenticated);
  const flow = useSelector(state => state.flow);
  const incident = useSelector(state => state.incident);
  const pid = useSelector(state => state.pid);
  const uuid = useSelector(state => state.uuid);
  const insurer = useSelector(state => state.insurer);
  const token = useSelector(state => state.token);
  const error = useSelector(state => state.httpError);
  const { dispatchErrorCleared } = useDispatchError();
  const dispatch = useDispatch();

  // This hook is essentially in charge of the background logic that always needs to be running
  // This includes things like retrieving details from the URL, setting information in local storage, setting the UUID in Redux, deciding when to download bodyshop details, and so forth
  useGlobalEffects(location, history);



  if (!flow) {
    return <Loader />;
  }
  if (error) {
    return (
      <Error
        logOut={() => dispatch({ type: LOGGED_OUT })}
        error={error}
        history={history}
        clearError={dispatchErrorCleared}
      />
    );
  }

  const { checkin, checkout, trackTrace, onboarding, signIn } = routes;

  const gotToken = (!!token && token !== '');
  const willGetInsurer = !!pid || !!uuid

  if (flow === flows.NEW) {
    return (
      <Switch>
        <Route path={onboarding} component={Onboarding} />
        <Redirect to={{ pathname: onboarding, search: location.search }} />
      </Switch>
    );
  }

  else if (flow === flows.CHECKIN && gotToken) {
    return (
      <Switch>
        <Route path={checkin} component={Checkin} />
        <Redirect to={checkin} />
      </Switch>
    );
  } else if (flow === flows.CHECKOUT && gotToken) {
    return (
      <Switch>
        <Route path={checkout} component={Checkout} />
        <Redirect to={checkout} />
      </Switch>
    );
  } else if (flow === flows.ONBOARDING && gotToken) {
    return (
      <Switch>
        <Route path={onboarding} component={Onboarding} />
        <Route exact path={trackTrace} component={TrackTrace} />
        <Redirect to={onboarding} />
      </Switch>
    )
  }

  else if ((!authenticated && (!incident || (incident && !incident.status)) && !gotToken && (!!insurer || !willGetInsurer))) {
    return (
      <Switch>
        <Route exact path={signIn} component={SignIn} />
        <Redirect to={signIn} />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route exact path={signIn} component={SignIn} />
      <Route path={onboarding} component={Onboarding} />
      <Route path={checkin} component={Checkin} />
      <Route path={checkout} component={Checkout} />
      <Route exact path={trackTrace} component={TrackTrace} />
      <Redirect to={!authenticated && (!incident || (incident && !incident.status)) && !gotToken ? signIn : onboarding} />
    </Switch>
  );
}

function App({ location, history }) {
  const insurer = useSelector(state => state.insurer);

  const isThemed = !!insurer && insurer.brand !== companyNames.VIP && insurer.objectType !== proccessType.DI;
  const isCustomThemed = !!insurer && insurer.objectType === proccessType.Schadegarant
  const scaling = useScaling();
  const cookieConsentHook = useCookieConsent()
  useTracking()
  const branding = useMemo(() => ({
    primaryColor: isThemed && !!insurer ? insurer.colorPrimaryHEX : Colors.GREY_DARKER,
    fontFamily: (!!insurer && insurer.font) || 'Gabriel Sans',
    // fontFamily: 'Bolleta',
    fontFile: !!insurer && insurer.fontFileRegular,
    // fontFile: fontFile,
    fontFileBold: !!insurer && insurer.fontFileBold,
    // fontFileBold: fontFile,
    // fontFile: {
    //   normal: {
    //     eot: (!!insurer && insurer.fontFile.normal.eot) || '',
    //     ttf: (!!insurer && insurer.fontFile.normal.ttf) || '',
    //     woff: (!!insurer && insurer.fontFile.normal.woff) || '',
    //   },
    //   bold: {
    //     eot: (!!insurer && insurer.fontFile.bold.eot) || '',
    //     ttf: (!!insurer && insurer.fontFile.bold.ttf) || '',
    //     woff: (!!insurer && insurer.fontFile.bold.woff) || '',
    //   },
    // },
    logoURL: !!insurer && insurer.logoURL,
    logoSize: !!insurer && insurer.logoSize,
    backgroundURL: !!insurer && insurer.backgroundURL,
    favicon: !!insurer && insurer.favicon,
  }), [insurer, isThemed]);

  const theme = useMemo(() => createCustomTheme(branding, isCustomThemed), [branding, isCustomThemed]);
  const muiTheme = useMemo(() => (
    isThemed ?
      {
        ...createTheme({
          overrides: {
            MuiTouchRipple: {
              child: {
                backgroundColor: !!branding.primaryColor ? branding.primaryColor : Colors.GREY_DARKER,
              }
            }
          },
          palette: {
            primary: {
              main: !!branding.primaryColor ? branding.primaryColor : Colors.GREY_DARKER,
            }
          }
        }), ...theme
      } : {
        ...createTheme(),
        ...theme
      }
  ), [branding, theme, isThemed]);

  return (
    <>
      <QuestionnaireProvider>
        <MuiThemeProvider theme={muiTheme}>
          <ThemeProvider theme={theme}>
            <GlobalStyle scaling={scaling} />
            <HtmlHead faviconCustom={!!theme.favicon && theme.favicon} />
            <ScrollToTop>
              <AppContstructor location={location} history={history} />
            </ScrollToTop>
            <Cookie  {...cookieConsentHook} />
            <Consent />
          </ThemeProvider>
        </MuiThemeProvider>
      </QuestionnaireProvider>
    </>
  )
}

export default withRouter(App);
