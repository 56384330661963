const httpErrorMessages = Object.freeze({
  7: 'U heeft te veel actieve sessies. Wacht a.u.b. tot de andere sessie is verlopen.',
  8: 'Te vaak verkeerd ingevuld. Wacht a.u.b. 5 minuten en probeer het nogmaals',
  9: 'Geen case gevonden of verkeerd kenteken ingevuld. Click a.u.b. op de link in de e-mail en probeer het nogmaals.',
  10: 'U bent uitgelogd. Klik op onderstaande knop om naar de inlogpagina te gaan.',
  11: 'Uw heeft te vaak geprobeerd het incident aan te passen. Probeer het later a.u.b. nogmaals.',
  12: 'Uw heeft te vaak geprobeerd het incident aan te passen. Probeer het later a.u.b. nogmaals.',
  404: 'Door een technische storing is deze dienst niet beschikbaar. Probeer het over een paar minuten nog een keer.',
  default: 'Oeps er gaat iets fout, probeer het later nog een keer.'
});

const httpErrorButtonLabel = Object.freeze({
  401: 'Terug naar inlog pagina',
  404: 'Probeer het nogmaals'
});
const errorActionTypes = {
  newHttpError: 'NEW_HTTP_ERROR',
  newProcessError: 'NEW_PROCESS_ERROR',
  errorHandled: 'ERROR_HANDLED'
};

export default {
  httpErrorMessages,
  httpErrorButtonLabel,
  errorActionTypes
};
