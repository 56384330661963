import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useScaling } from '@domain/hooks';

const StyledList = styled.ul`
  display: flex;
  justify-content: space-between;
  padding: ${props => props.$scaling.scaleUpTo4K(10)}px ${props => props.$scaling.scaleUpTo4K(2)}px;
  .screen-desktop & {
  padding: ${props => props.$scaling.scaleUpTo4K(20)}px ${props => props.$scaling.scaleUpTo4K(2)}px; 
  }
`;

function ImageViewerContainer({ children }) {
  const scaling = useScaling()
  return (
    <StyledList $scaling={scaling} className="image__viewer__container">
      {React.Children.toArray(children)}
    </StyledList>
  );
}

ImageViewerContainer.propTypes = {
  children: PropTypes.any.isRequired
};

export default ImageViewerContainer;
