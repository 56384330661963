import {
  LOGGED_OUT,
  LOGGED_IN,
  // NEW_UUID_FOUND,
  // NEW_FLOW_DETECTED,
  INCIDENT_FETCHED,
  // TOKEN_FOUND,
} from '@domain/action-types';

const initialAuthenticated = false;

const authenticated = (state = initialAuthenticated, { action, type }) => {
  switch (type) {
    case LOGGED_OUT:
      return false;
    case LOGGED_IN:
      return true;
    // case NEW_UUID_FOUND:
    //   return false;
    // case NEW_FLOW_DETECTED:
    //   return false;
    // case TOKEN_FOUND:
    //   return false;
    case INCIDENT_FETCHED:
      return true;
    default:
      return state;
  }
};

export default authenticated;
