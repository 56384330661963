import routes from '../routes';
import onBoardingRoutes from './routes';
import personalRoutes from './Personal/routes';
import damageRoutes from './Damage/routes';
import uploadsRoutes from './Uploads/routes'
import ownbodyshopRoutes from './OwnBodyshop/routes'
import serviceAdvisorRoutes from './ServiceAdvisor/routes'
import recoveryRoutes from './Recovery/routes';
import damageImagesRoutes from './Uploads/DamageImages/routes';

const { personal, damage, recovery, landing, welcome, uploads, ownbodyshop, serviceAdvisor } = onBoardingRoutes;
const { onboarding, trackTrace } = routes;

const {
  start: personalStart,
  license,
  name,
  postal,
  address,
  contact,
  gender,
  genderCompleteOptions
} = personalRoutes;
const {
  start: damageStart,
  causeDamageCategory,
  causeDamageDropDown,
  causeDamageCombinedDropDown,
  speed,
  interior,
  exterior,
  createdByDealer,
  carInfo,
  //  damageDescription,
  animalOwnerKnown,
  counterPartyKnown,
  damageType,
  witnessKnown,
  witnessName,
  witnessAddress,
  witnessPostal,
  witnessContact,
  glassDamageSort,
  policyNumber,
  driveabledamage,
  damageNumber,
  vehicleMileage,
  carOwner,
  leasingCompany,
  payingDamage,
  recoverableDamage,
  possibleWBF,
  insuranceType,
  extraDamage

} = damageRoutes;

const { start: uploadsStart,
  damageImages,
  otherFiles,
  greenCard,
  licensePlateRegister,
  saf,
  policeReport,
} = uploadsRoutes

const {
  start: ownbodyshopStart,
  offer,
  bodyshopSelectEM,
  bodyshopSelect,
  bodyshopConfirm,
  bodyshopSelectIntakePlus
} = ownbodyshopRoutes

const { comment, serviceAdvisorName } = serviceAdvisorRoutes
const { start: recoveryStart, success, thankYou, end, endOfFlow } = recoveryRoutes;
const { upload } = damageImagesRoutes;

// Each page comes with a method to decide if that page has been completed
// Usually we only verify that the values are there, because the incident object
// is only updated when the server sends a response.
// Each method is of the form
// (incident, images) => check(incident, object)
// For example, if the incident object holds truthy values for customerZipCode
// and customerHouseNumber, then the Postal page must have been completed

const questionnaire = [
  {
    name: 'welcome',
    path: onboarding + welcome,
    check: () => true,
    feedbackCheck: () => true,
  },
  {
    name: 'landing',
    path: onboarding + landing,
    check: () => true,
    feedbackCheck: () => true,
  },
  {
    name: 'personal_start',
    path: onboarding + personal + personalStart,
    check: () => true,
    feedbackCheck: () => true,
  },
  {
    name: 'license',
    path: onboarding + personal + license,
    check: ({ licensePlate, damageDate }) => licensePlate && damageDate,
    feedbackCheck: () => true,
  },
  {
    name: 'name',
    path: onboarding + personal + name,
    check: ({ customerFirstName, customerLastName }) =>
      customerFirstName && customerLastName,
    feedbackCheck: () => true,
  },
  {
    name: 'contact',
    path: onboarding + personal + contact,
    check: ({ customerEmail, customerMobileNumber }) =>
      customerEmail && customerMobileNumber,
    feedbackCheck: () => true,
  },
  {
    name: 'postal',
    path: onboarding + personal + postal,
    check: ({ customerZipCode, customerHouseNumber }) =>
      customerZipCode && customerHouseNumber,
    feedbackCheck: () => true,
  },
  {
    name: 'address',
    path: onboarding + personal + address,
    check: ({ customerCity, customerStreet }) => customerCity && customerStreet,
    feedbackCheck: () => true,
  },
  {
    name: 'gender',
    path: onboarding + personal + gender,
    check: ({ customerGender }) => customerGender,
    feedbackCheck: () => true,
  },
  {
    name: 'gender_2',
    path: onboarding + personal + genderCompleteOptions,
    check: ({ customerGender }) => customerGender,
    feedbackCheck: () => true,
  },
  {
    name: 'damage_start',
    path: onboarding + damage + damageStart,
    check: () => true,
    feedbackCheck: () => true,
  },
  {
    name: 'car_owner',
    path: onboarding + damage + carOwner,
    check: ({ carOwner }) => carOwner,
    feedbackCheck: () => true,
  },
  {
    name: 'leasing_company',
    path: onboarding + damage + leasingCompany,
    check: ({ leasingCompany }) => leasingCompany,
    feedbackCheck: () => true,
  },
  {
    name: 'insurance_type',
    path: onboarding + damage + insuranceType,
    check: ({ insuranceType }) => insuranceType,
    feedbackCheck: () => true,
  },
  {
    name: 'wbf_possible',
    path: onboarding + damage + possibleWBF,
    check: ({ wbfPossible }) => wbfPossible,
    feedbackCheck: () => true,
  },
  {
    name: 'paying_damage',
    path: onboarding + damage + payingDamage,
    check: ({ payingDamage }) => payingDamage,
    feedbackCheck: () => true,
  },
  {
    name: 'recoverable_damage',
    path: onboarding + damage + recoverableDamage,
    check: () => true,
    feedbackCheck: () => true,
  },
  {
    name: 'extra_damage',
    path: onboarding + damage + extraDamage,
    check: ({ extraDamage }) => extraDamage,
    feedbackCheck: () => true,
  },
  {
    name: 'damage_cause_category',
    path: onboarding + damage + causeDamageCategory,
    check: ({ causeDamage }) => causeDamage,
    feedbackCheck: () => true,
  },
  {
    name: 'damage_cause_dropdown',
    path: onboarding + damage + causeDamageDropDown,
    check: ({ causeDamage }) => causeDamage,
    feedbackCheck: () => true,
  },
  {
    name: 'damage_cause_combined_dropdown',
    path: onboarding + damage + causeDamageCombinedDropDown,
    check: ({ causeDamage }) => causeDamage,
    feedbackCheck: () => true,
  },
  {
    name: 'counterparty_known',
    path: onboarding + damage + counterPartyKnown,
    check: ({ counterpartyKnown }) => counterpartyKnown,
    feedbackCheck: () => true,
  },
  {
    name: 'animal_owner_known',
    path: onboarding + damage + animalOwnerKnown,
    check: ({ counterpartyKnown }) => counterpartyKnown,
    feedbackCheck: () => true,
  },
  {
    name: 'witness_known',
    path: onboarding + damage + witnessKnown,
    check: ({ witnessPresent }) => witnessPresent,
    feedbackCheck: () => true,
  },
  {
    name: 'witness_name',
    path: onboarding + damage + witnessName,
    check: ({ witnessFirstName, witnessLastName }) => witnessFirstName && witnessLastName,
    feedbackCheck: () => true,
  },
  {
    name: 'witness_address',
    path: onboarding + damage + witnessAddress,
    check: ({ witnessStreet, witnessCity }) => witnessStreet && witnessCity,
    feedbackCheck: () => true,
  },
  {
    name: 'witness_postal',
    path: onboarding + damage + witnessPostal,
    check: ({ witnessZipCode, witnessHouseNumber }) => witnessZipCode && witnessHouseNumber,
    feedbackCheck: () => true,
  },
  {
    name: 'witness_contact',
    path: onboarding + damage + witnessContact,
    check: ({ witnessEmail, witnessMobileNumber }) => witnessEmail && witnessMobileNumber,
    feedbackCheck: () => true,
  },
  {
    name: 'speed',
    path: onboarding + damage + speed,
    check: ({ vehicleSpeed }) => vehicleSpeed,
    feedbackCheck: () => true,
  },
  {
    name: 'interior',
    path: onboarding + damage + interior,
    check: ({ vehicleDamageInside }) => vehicleDamageInside,
    feedbackCheck: () => true,
  },
  {
    name: 'exterior',
    path: onboarding + damage + exterior,
    check: ({ damageIndication }) =>
      damageIndication && Object.values(damageIndication).some(Boolean),
    feedbackCheck: () => true,
  },
  {
    name: 'driveable_damage',
    path: onboarding + damage + driveabledamage,
    check: ({ driveableDamage }) => driveableDamage,
    feedbackCheck: () => true,
  },
  {
    name: 'glass_damage_sort',
    path: onboarding + damage + glassDamageSort,
    check: ({ damageTypeAddition }) => damageTypeAddition,
    feedbackCheck: () => true,
  },
  {
    name: 'policy_number',
    path: onboarding + damage + policyNumber,
    check: ({ customerPolisNumber }) => customerPolisNumber,
    feedbackCheck: () => true,
  },
  {
    name: 'damage_type',
    path: onboarding + damage + damageType,
    check: ({ damageType, licensePlate, damageDate }) => damageType && licensePlate && damageDate,
    feedbackCheck: () => true,
  },
  {
    name: 'vehicle_mileage',
    path: onboarding + damage + vehicleMileage,
    check: ({ vehicleMileage }) => vehicleMileage,
    feedbackCheck: () => true,
  },
  {
    name: 'car_info',
    path: onboarding + damage + carInfo,
    check: ({ vehicleManufacturerName, vehicleModelName }) => vehicleManufacturerName && vehicleModelName,
    feedbackCheck: () => true,
  },
  {
    name: 'created_by_dealer',
    path: onboarding + damage + createdByDealer,
    check: ({ createdByDealerINP }) => createdByDealerINP,
    feedbackCheck: () => true,
  },
  {
    name: 'damage_number',
    path: onboarding + damage + damageNumber,
    check: ({ damageNumber }) => damageNumber,
    feedbackCheck: () => true,
  },
  {
    name: 'uploads_start',
    path: onboarding + uploads + uploadsStart,
    check: () => true,
    feedbackCheck: () => true,
  },
  {
    name: 'images',
    path: onboarding + uploads + damageImages + upload,
    check: ({ portalStepContinue, status, requiredPhotos }, { damageImages = [] }) =>
      damageImages.length >= 5 ||
      portalStepContinue === 'Onboarding' ||
      status === 'carrefinisher_choosen' ||
      status === 'validate_by_OC' ||
      requiredPhotos === false,
    feedbackCheck: (incident, { damageImages = [] }) => damageImages.find(document => document.status === 'FeedbackStored'),
  },
  ///////////////////////////pages added
  /// attributes of files : { damageImages, otherFiles, offers, safDocuments}
  {
    name: 'green_card',
    path: onboarding + uploads + greenCard,
    check: (incident, { greenCard = [] }) => greenCard.length >= 1,
    feedbackCheck: (incident, { greenCard = [] }) => greenCard.find(document => document.status === 'FeedbackStored'),
  },
  {
    name: 'licenseplate_register',
    path: onboarding + uploads + licensePlateRegister,
    check: (incident, { registrationCertificate = [] }) => registrationCertificate.length >= 1,
    feedbackCheck: (incident, { registrationCertificate = [] }) => registrationCertificate.find(document => document.status === 'FeedbackStored'),
  },
  {
    name: 'police_report',
    path: onboarding + uploads + policeReport,
    check: (incident, { officialReports = [] }) => officialReports.length >= 1,
    feedbackCheck: (incident, { officialReports = [] }) => officialReports.find(document => document.status === 'FeedbackStored'),
  },
  {
    name: 'saf',
    path: onboarding + uploads + saf,
    check: (incident, { safDocuments = [] }) => safDocuments.length >= 1,
    feedbackCheck: (incident, { safDocuments = [] }) => safDocuments.find(document => document.status === 'FeedbackStored'),
  },
  {
    name: 'otherfiles',
    path: onboarding + uploads + otherFiles,
    check: (incident, { otherFiles = [] }) => otherFiles.length >= 1,
    feedbackCheck: (incident, { otherFiles = [] }) => otherFiles.find(document => document.status === 'FeedbackStored'),
  },
  /////////////////////////////////////////////////////////////
  {
    name: 'ownbodyshop_start',
    path: onboarding + ownbodyshop + ownbodyshopStart,
    check: () => true,
    feedbackCheck: () => true,
  },
  {
    name: 'bodyshop_select',
    path: onboarding + ownbodyshop + bodyshopSelect,
    check: ({ debiteurNummer, carRefinisherUUID }) => debiteurNummer || carRefinisherUUID,
    feedbackCheck: () => true,
  },
  {
    name: 'bodyshop_select_intakeplus',
    path: onboarding + ownbodyshop + bodyshopSelectIntakePlus,
    check: ({ carRefinisherUUID }) => carRefinisherUUID,
    feedbackCheck: () => true,
  },
  {
    name: 'bodyshop_confirm',
    path: onboarding + ownbodyshop + bodyshopConfirm,
    check: () => true,
    feedbackCheck: () => true,
  },
  {
    name: 'bodyshop_selectem',
    path: onboarding + ownbodyshop + bodyshopSelectEM,
    check: () => true,
    feedbackCheck: () => true,
  },
  {
    name: 'offer',
    path: onboarding + ownbodyshop + offer,
    check: ({ calculationTotalPriceInc }, { offers = [] }) => calculationTotalPriceInc && offers.length >= 1,
    feedbackCheck: ({ calculationTotalPriceInc }, { offers = [] }) => offers.find(document => document.status === 'FeedbackStored') && calculationTotalPriceInc,
  },
  {
    name: 'service_advisor_name',
    path: onboarding + serviceAdvisor + serviceAdvisorName,
    check: ({ advisorName }) => advisorName,
    feedbackCheck: () => true,
  },
  {
    name: 'comment',
    path: onboarding + serviceAdvisor + comment,
    check: ({ advisorComment }) => advisorComment,
    feedbackCheck: () => true,
  },
  {
    name: 'recovery_start',
    path: onboarding + recovery + recoveryStart,
    check: () => true,
    feedbackCheck: () => true,
  },
  {
    name: 'thank_you',
    path: onboarding + recovery + thankYou,
    check: () => true,
    feedbackCheck: () => true,
  },
  {
    name: 'success',
    path: onboarding + recovery + success,
    check: () => true,
    feedbackCheck: () => true,
  },
  {
    name: 'end',
    path: onboarding + recovery + end,
    check: () => true,
    feedbackCheck: () => true,
  },
  {
    name: 'end_of_flow',
    path: onboarding + recovery + endOfFlow,
    check: () => true,
    feedbackCheck: () => true,
  },
  {
    name: 'track_trace',
    path: trackTrace,
    check: ({ status }) => status !== '_new',
    feedbackCheck: () => true,
  },
];

export default questionnaire;
