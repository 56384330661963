function generateColumnClasses(span, maxNrOfGridColumns) {
  const classes = {};
  // +1 cause of arrays being zero based.
  for (let i = maxNrOfGridColumns; i >= 0; i--) {
    classes[`grid__column--${i}`] = span === i;
  }

  return classes;
}

export default generateColumnClasses;
