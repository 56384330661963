const routes = {
  start: '/overzicht',
  causeDamageCategory: '/oorzaak',
  causeDamageDropDown: '/schadeoorzaken',
  causeDamageCombinedDropDown: '/schade-oorzaak',
  causeDamageAanrijding: '/aanrijding',
  causeDamageAnders: '/anders',
  causeDamageOverig: '/overig',
  damageType: '/schade-type',
  speed: '/snelheid',
  interior: '/schade-binnenkant',
  exterior: '/schade-buitenkant',
  damageDescription: '/schadebeschrijving',
  animalOwnerKnown: '/dier-eigenaar',
  counterPartyKnown: '/tegenpartij-bekend',
  witnessKnown: '/getuige-bekend',
  witnessName: '/getuige-naam',
  witnessAddress: '/getuige-adres',
  witnessPostal: '/getuige-postcode',
  witnessContact: '/getuige-contact',
  glassDamageSort: '/ster-scheur',
  policyNumber: '/polisnummer',
  driveabledamage: '/berijdbare-schade',
  vehicleMileage: '/kilometerstand',
  damageNumber: '/schadenummer',
  carOwner: '/eigenaar-van-auto',
  leasingCompany: '/leasemaatschappij',
  payingDamage: '/betaalt-de-schade',
  recoverableDamage: '/aansprakelijke-voor-schade',
  possibleWBF: '/schade-aangetroffen',
  insuranceType: '/verzekerd',
  extraDamage: '/meer-schades',
  createdByDealer: '/schade-gemeld-bij-dealer',
  carInfo: '/voertuiggegevens'

};

export default routes;
