import React, { useEffect, Fragment, useContext } from 'react';
import { useSelector } from 'react-redux';

// Domain imports.
import { Loader } from '@domain/components';
import { useSaveIncidentFactory, } from '@domain/hooks';
import { browserStorage, chooseLogoSize, checkIsFeedback, generatePageContent, getPrevNextPagePath } from '@domain/helpers';
import { ImagesUploadPage } from '@domain/pages';
import { companyNames, proccessType } from '@domain/constants'
import imageRoutes from '../routes';
import { validationSchema, validationSchemaNotRequired } from './validation-schema';
import { detectContentType } from '@domain/content';
import { useQuestionnaire } from '@domain/providers';
import { ThemeContext } from 'styled-components';

function ImageUploads({ history, match }) {


  const { questionnaire } = useQuestionnaire();

  const incident = useSelector((state) => state.incident);
  const insurer = useSelector(state => state.insurer);
  const isVIP = insurer.objectType === proccessType.Casco && (insurer.brand === companyNames.VIP || !insurer.brand)
  const files = useSelector(state => state.files)
  const images = files.damageImages

  const themeContext = useContext(ThemeContext);

  const currentPath = match.path;
  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined

  const saveIncident = useSaveIncidentFactory(incident, saveBehavior);

  useEffect(() => {
    if (!!questionnaire && questionnaire.length) {
      const progress = questionnaire.find(page => page.path === currentPath).name
      browserStorage.setProgress(progress);
    }
  }, [currentPath, questionnaire])


  if (!images || !insurer || !incident || !questionnaire.length) {
    return <Loader />;
  }

  const isFeedback = checkIsFeedback(incident)

  const pagePath = getPrevNextPagePath(
    history,
    questionnaire,
    incident,
    currentPath,
    files,
    isFeedback
  );
  const nextPage = pagePath.nextPage !== currentPath
    ? pagePath.nextPage
    : undefined;


  const isPageBeforeSuccess = questionnaire[questionnaire.length - 2].path === currentPath

  function feedbackCause() {
    const page = questionnaire.find(page => page.name === 'images')
    const feedbacktext = page.feedbacktext ? decodeURIComponent(page.feedbacktext) : null
    return feedbacktext
  }

  const imagesDisplayed = [...images, {}, {}, {}, {}, {}].slice(0, 5);

  const contentType = detectContentType(insurer)
  const pageName = questionnaire.find(page => page.path === currentPath).name
  const content = generatePageContent(contentType, insurer, incident, false, false, pageName)

  const legend = content["over-title-text"]
  const title = content["title"]
  const mobileText = content["mobile-text"]
  const text = content["intro-text"]
  const secondText = content["second-text"]
  const customHelpLink = content["custom-help-link"]
  const helpLinkText = content["help-link-text"]
  const useCustomLogo = content["use-custom-logo"]
  const maxImgSize = content["max-img-size"]
  const maxNumberOfImages = content["max-number-images"]
  const instructions = content["instructions"]
  const firstFaqs = content["faqs"]
  const validationText = content["validation-text"]

  const faqs = firstFaqs.concat({
    question: "Hoe maak ik goede foto's?",
    answer: (
      <dl>
        {instructions.map(({ title, explanation }, i) => (
          <Fragment key={title}>
            <dt>
              {i + 1}. {title}
            </dt>
            <br />
            <dd>{explanation}</dd>
            <br />
            <br />
          </Fragment>
        ))}
      </dl>
    ),
  })


  const middleLogo = {
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }

  const validationSchemaToBeUsed = () => {
    if (incident.requiredPhotos === false && page.required === false) {
      return validationSchemaNotRequired(maxNumberOfImages)
    }
    return validationSchema(maxNumberOfImages, validationText)
  }

  return (
    <ImagesUploadPage
      history={history}
      nextPage={nextPage}
      prevPage={pagePath.prevPage}
      questionnaire={questionnaire}
      saveIncident={saveIncident}
      buttonText="Volgende"
      currentPath={currentPath}
      instructions={instructions}
      filesSetName="damageImages"
      filesSet={images}
      feedbackCause={feedbackCause()}
      validationSchema={validationSchemaToBeUsed()}
      faqs={faqs}
      legend={legend}
      heading={title}
      mobileParagraph={mobileText}
      firstParagraph={text}
      secondParagraph={secondText}
      files={files}
      incident={incident}
      insurer={insurer}
      isPageBeforeSuccess={isPageBeforeSuccess}
      isVIP={isVIP}
      isFeedback={isFeedback}
      imagesDisplayed={imagesDisplayed}
      routes={imageRoutes}
      customHelpLink={customHelpLink}
      helpLinkText={helpLinkText}
      customMiddleLogo={useCustomLogo}
      middleLogo={middleLogo}
      maxImgSize={maxImgSize}
      maxNumberOfImages={maxNumberOfImages}
    />
  )
}

export default ImageUploads;
