import { useState, useEffect } from 'react';
import { mapYupValidationSchemaErrors } from '@domain/helpers';

const useValidation = (validationSchema, values) => {
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const validate = async () => {
      try {
        await validationSchema.validate(values, { abortEarly: false });
        setErrors(null);
      } catch (errorResult) {
        setErrors(mapYupValidationSchemaErrors(errorResult.inner));
      }
    };

    const id = setTimeout(validate, 100);

    return () => clearTimeout(id);
  }, [values, validationSchema]);

  return errors;
};

export default useValidation;
