import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import { isIE, isEdge } from 'react-device-detect';
import styled from 'styled-components';
import { Colors } from '@domain/theming';
import { alpha } from '@material-ui/core/styles';
import { useScaling } from '@domain/hooks';

const isIEorEdge = isIE || isEdge;

const carParts = [
  {
    part: 'backLeft',
    d:
      'M172.44,739.46a175.53,175.53,0,0,1-57.21-106.85c-.16-1.17-.3-2.36-.42-3.58-.45-4.35-.51-7.13-.52-7.87-.1-2.42-.16-4.95-.19-7.57v.31l-8.53-55.08a5.69,5.69,0,0,1-1.1-3c-.51-6.15-1-13.5-1.44-21.78H73.53c-2.19,39.47-5,83.68-5,83.68,0,25.24,3.19,45.3,9,61.27a296.9,296.9,0,0,0,5.19,32.12c1.41,6.85,46.27,23,68.05,25.51C157.7,737.9,165,738.82,172.44,739.46Z',
  },
  {
    part: 'backRight',
    d:
      'M376.16,617.94s-3.05-44.39-5.39-83.92h-24.2c-.45,8.28-.93,15.63-1.44,21.78-.24,2.91-2.12,4.56-4.11,4.77h0l-8.26,53.33c0-.75,0-1.54,0-2.32,0,.67,0,1.35,0,2,0,2.63-.09,5.15-.19,7.58,0,.74-.07,3.52-.53,7.87q-.18,1.83-.42,3.57A175.49,175.49,0,0,1,275,739q7.54-.81,14.71-2.05c19.58-.59,70.63-18.51,72.14-25.82,3-14.34,4.62-25.13,5.27-33.07C372.87,662.23,376.06,642.53,376.16,617.94Z',
  },
  {
    part: 'backCentre',
    d:
      'M114.08,611c0,.88,0,1.77,0,2.62,0,2.63.09,5.15.19,7.58,0,.74.07,3.52.52,7.87.12,1.22.26,2.41.42,3.58a175.53,175.53,0,0,0,57.21,106.85c17,1.47,35.13,1.54,53.73,1.54,17.13,0,33.53-.41,48.8-2A175.46,175.46,0,0,0,331.62,632.6q.24-1.74.42-3.57c.46-4.35.52-7.13.53-7.87.1-2.43.16-4.95.19-7.58,0-.83,0-1.69,0-2.55,0-.54,0-1.07,0-1.61,0-1.43,0-2.88,0-4.36,0-51.34-42.41-49.11-42.41-49.11a344.62,344.62,0,0,1-133.92,0s-42.42-2.23-42.42,49.11c0,1.49,0,2.94,0,4.37C114.07,610,114.07,610.45,114.08,611Z',
  },
  {
    part: 'centreLeft',
    d:
      'M114.07,309c0-.16,0-.3,0-.45l-8.34-49.66c-.94-5.61-7.3-5.5-8.69-.91l-1.79-.44L71.28,169a47.75,47.75,0,0,0-2.81-5.09c-1,10.88-1,26.74-.48,44.6q.11,4.19.25,8.51h0c.27,8.61.62,17.56,1,26.54.36,9.18.74,18.39,1.05,27.3-10.93,4.72-22.83,12.81-22.83,28.53,0,4.82,13.11-.56,23.49-5.57.07,3.46.11,6.82.13,10.05,0,.13,0,.26,0,.4,0,1.05,0,2.1,0,3.13,0,4.81,0,10.21.12,16.05q0,2.92.09,6c0,2,.07,4.11.12,6.23.14,7.41.33,15.27.54,23.35q.09,3.46.19,7c.13,4.67.27,9.39.41,14.11q.17,5.33.33,10.61c.15,4.7.31,9.36.46,13.95l.12,3.43q.12,3.4.23,6.75t.24,6.58c.08,2.16.15,4.29.23,6.37.15,4.18.3,8.2.44,12,.08,1.91.15,3.77.22,5.57,0,.9.06,1.79.1,2.66.06,1.75.13,3.44.19,5.06.57,14.59,1,23.95,1,23.95,0,.32,0,.66,0,1v.31c0,.27,0,.55,0,.84,0,.12,0,.25,0,.38,0,.31,0,.63,0,1v.32c0,.43,0,.88,0,1.35,0,.12,0,.24,0,.36,0,.37,0,.74,0,1.13,0,.17,0,.34,0,.51,0,.35,0,.72,0,1.09,0,.19,0,.38,0,.57,0,.49,0,1-.05,1.51v.32l-.06,1.58c0,.21,0,.41,0,.62,0,.41,0,.83,0,1.25l0,.73c0,.41,0,.84-.05,1.26,0,.25,0,.49,0,.74,0,.64,0,1.29-.08,1.95l0,.64c0,.47,0,.93-.06,1.41,0,.31,0,.62,0,.94l-.06,1.31c0,.3,0,.6-.05.9,0,.5,0,1-.07,1.51L75.11,504c0,.49,0,1-.07,1.46L75,506.65l-.06,1.08L74.86,509l-.06,1.25-.06,1.14c0,.73-.07,1.45-.11,2.19,0,.45,0,.91-.07,1.37l-.06,1.16c0,.46,0,.91-.07,1.37l-.06,1.07c0,.52-.05,1-.08,1.55l0,.54c-.06,1.16-.12,2.33-.19,3.51l0,.67c0,.58-.07,1.16-.1,1.74l0,.8c0,.59-.07,1.18-.1,1.78,0,.22,0,.45,0,.68-.07,1.39-.15,2.78-.23,4.18H103c.45,8.28.93,15.63,1.44,21.78.47,5.67,7.2,6.61,8.78,1.22,5.93-20.18,14.2-51.6,14.2-69.8C127.45,459.46,114.64,315.38,114.07,309Z',
  },
  {
    part: 'centreRight',
    d:
      'M375.77,269.53c.09-2.29.19-4.58.29-6.9v-.15q.31-7.19.63-14.47c.09-2.07.19-4.14.28-6.21l0-.58q.3-7,.59-13.84v-.06q.14-3.37.27-6.7v-.31q.12-3.34.24-6.63h0c.68-19.93.91-37.77-.14-49.64-.06-.36-.11-.71-.17-1a1.32,1.32,0,0,0-.33-.78c-.06.39-.12.79-.21,1.18L352.8,268.62c0-2.95.06-5.84.09-8.64.07-6.46-7.91-7.4-9-1.07l-8.33,49.66a3.25,3.25,0,0,0-.06.45c-.57,6.36-13.38,150.44-13.38,178.2,0,18.2,8.27,49.62,14.2,69.8,1.58,5.39,8.32,4.45,8.78-1.22.51-6.15,1-13.5,1.44-21.78h24.2l0-.53c-.07-1.27-.15-2.53-.22-3.79,0-.13,0-.27,0-.4,0-.58-.07-1.17-.11-1.75,0-.33,0-.66-.05-1l-.09-1.51c0-.38,0-.76-.07-1.14l-.12-2.25-.09-1.54c0-.4-.05-.81-.07-1.21s0-.86-.07-1.29-.05-.91-.08-1.36l-.06-1.13c0-.46-.05-.93-.07-1.39,0-.3,0-.6-.05-.91-.05-.79-.09-1.57-.13-2.34,0-.48-.05-.95-.07-1.41s0-.63-.05-.94c0-.5-.05-1-.08-1.48s0-.67-.05-1-.05-.88-.07-1.31-.05-1-.07-1.46-.06-1.07-.08-1.6c0-.33,0-.64-.05-1l-.06-1.3c0-.28,0-.57,0-.85,0-.47-.05-.94-.07-1.4l0-.74c0-.66-.06-1.32-.09-2,0-.17,0-.34,0-.51l-.06-1.45,0-.69q0-.65-.06-1.26c0-.21,0-.42,0-.63q0-.78-.06-1.53c0-.08,0-.17,0-.25,0-.56,0-1.11-.06-1.64,0-.18,0-.35,0-.53l0-1.11c0-.17,0-.34,0-.51,0-.39,0-.76,0-1.12,0-.12,0-.25,0-.37,0-.47,0-.92,0-1.36a2.81,2.81,0,0,0,0-.29c0-.34,0-.66,0-1,0-.14,0-.27,0-.4s0-.55,0-.81v-1.34s6.43-118.31,6.62-169.64c0-1.09,0-2.21,0-3.33,0-.26,0-.52,0-.79q0-3.42.11-7c0-.23,0-.45,0-.68q0-1.85.09-3.72c10.64,5.34,27,12.68,27,7.3C402,282.06,387.47,274,375.77,269.53Z',
  },
  {
    part: 'centre',
    d:
      'M341,560.57c-1.9.19-3.9-.92-4.67-3.55-5.93-20.18-14.2-51.6-14.2-69.8,0-27.76,12.81-171.84,13.38-178.2a3.25,3.25,0,0,1,.06-.45l8.33-49.66a4.75,4.75,0,0,1,3.4-4.16l-8.17-16.07c3-26.55-55-48.5-72.61-50.5a349.79,349.79,0,0,0-91.42,1.69c-17.3,2.61-71.27,24.67-68.33,50.61l-7.42,14.6c2.4-1.19,5.66-.06,6.31,3.83L114,308.57c0,.15,0,.29,0,.45.57,6.36,13.38,150.44,13.38,178.2,0,18.2-8.27,49.62-14.2,69.8-1.23,4.21-5.6,4.54-7.68,1.8l8.53,55.08c0-2.83,0-5.77,0-8.84,0-51.34,42.42-49.11,42.42-49.11a344.62,344.62,0,0,0,133.92,0s42.41-2.23,42.41,49.11c0,3.07,0,6,0,8.84Z',
  },
  {
    part: 'frontLeft',
    d:
      'M115.79,183.28c0-.45.08-.91.16-1.37l11.47-71.6c0-.13,0-.25.07-.38.57-2.51,6.94-29.16,22.73-45.6C158.67,53.94,166.39,37.67,173,12.8l-16.48,9.76c-13.58,3-29.8,6.94-49,11.88-9,2.29-15,14.08-19,28.39l-2.2,1.31L71.07,151.19c-1.23,2.51-2.06,6.91-2.6,12.75A47.75,47.75,0,0,1,71.28,169l19.94,73.83C102.26,227.73,111.67,209.9,115.79,183.28Z',
  },
  {
    part: 'frontRight',
    d:
      'M375.43,151.42l-15.2-87.1-2.32-1.38c-4-14.36-10-26.2-19-28.5-19-4.87-35.05-8.75-48.53-11.76l-16.66-9.94c5.11,33.26,12.8,45.8,18.8,53.77,13.14,17.47,20.18,41,20.72,43.42,0,.13.06.25.08.38l11.47,71.6c8.22,29.85,20.28,47.9,34,60.79l18.43-79.3c.09-.39.15-.79.21-1.18a1.32,1.32,0,0,1,.33.78c.06.32.11.67.17,1C377.45,158.27,376.64,153.91,375.43,151.42Z',
  },
  {
    part: 'frontCentre',
    d:
      'M324.81,181.91l-11.47-71.6c0-.13,0-.25-.08-.38-.54-2.4-7.58-25.95-20.72-43.42-6-8-13.69-20.51-18.8-53.77,0,0-18.48-3.79-39.51-5.74l-21.66.17C191.53,9.06,173,12.8,173,12.8c-6.65,24.87-14.37,41.14-22.82,51.53-15.79,16.44-22.16,43.09-22.73,45.6,0,.13,0,.25-.07.38L116,181.91c-.08.46-.12.92-.16,1.37-4.12,26.62-13.53,44.45-24.57,59.58l4,14.7L97,258a4.65,4.65,0,0,1,2.38-2.92l7.42-14.6c-2.94-25.94,51-48,68.33-50.61a349.79,349.79,0,0,1,91.42-1.69c17.56,2,75.6,23.95,72.61,50.5l8.17,16.07c2.58-.64,5.61,1.24,5.57,5.23,0,2.8-.06,5.69-.09,8.64l6-25.92C345.09,229.81,333,211.76,324.81,181.91Z',
  },
];

const StyledDamageRegion = styled.div`
  max-width: 450px;
  width: 100%;
  margin-bottom: auto;
  position: relative;
  padding: 20px;
  margin: 0 auto auto;
  transition: margin .4s ease-in-out;
  .damage-region_container {
    position: relative;
    width: 100%;
    padding-top: 166.6666666666667%;
    //  background: white;
    .damage-region_block {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      img.car-icon {
        display: block;
        position: relative;
        margin: auto;
        width: 100%;
        max-width: 100%;
        height: auto;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }
  }
  .screen-desktop & {
    max-width: ${props => props.$scaling.scaleUpTo4K(450)}px;
    width: 100%;
    padding: ${props => props.$scaling.scaleUpTo4K(20)}px;
    padding-bottom: ${props => props.$scaling.scaleUpTo4K(60)}px;
  }

  @media screen and (min-width: 1921px) {
    .screen-desktop & {
      margin: 0 auto;
    }
  }
`;

const CarClicker = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  svg {
    opacity: 0.6;
    overflow: visible;
    path {
      transition: 0.3s ease-in-out;
      fill: transparent;
      stroke-width: 0;
      stroke: transparent;
      position: relative;
      z-index: 1;
      &.selected {
        fill: ${Colors.RED};
        z-index: 2;
        .theme-custom & {
          fill: ${props => props.theme.colors.primary};
        }
      }
      &.filtered {
        filter: url(#shadow-diffuse);
      }
    }
  }
  
  .screen-desktop & {
    svg {
      path {
        &:hover {
          z-index: 3;
        }
        &.filtered {
          filter: url(#shadow-diffuse);
        }
        &.selected  {
          &:hover {
            fill: ${Colors.RED};
            .theme-custom & {
              fill: ${props => props.theme.colors.primary};
            }
          }
        }
        &:hover {
          fill: ${alpha(Colors.RED, 0.6)};
          .theme-custom & {
            fill: ${props => alpha(props.theme.colors.primary, 0.6)};
          }
        }
      }
    }
  }
`;

function DamageRegion({ value, setValue }) {
  const scaling = useScaling();
  const toggleDamageRegion = part => {
    setValue(prevValue => ({
      ...prevValue,
      [part]: !prevValue[part],
    }));
  };

  const carPartClassName = part =>
    classnames(
      {
        selected: value[part],
      },
      {
        filtered: value[part] && !isIEorEdge,
      },
    );

  const renderCarPart = ({ part, d }) => (
    <path
      className={carPartClassName(part)}
      key={part}
      onClick={() => toggleDamageRegion(part)}
      d={d}
      id={part
        .replace('R', '-r')
        .replace('C', '-c')
        .replace('L', '-l')}
      role="button"
    />
  );

  return (
    <StyledDamageRegion className="damage-region" $scaling={scaling}>
      <div className="damage-region_container">
        <div className="damage-region_block">
          <img
            className="car-icon"
            src="/img/carIcon.png"
            id="car-icon"
            alt="car"
          />
          <CarClicker className="car-clicker">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 450 750">
              <defs>
                <filter id="shadow-diffuse">
                  <feDropShadow dx="0" dy="0" stdDeviation="8" />
                </filter>
              </defs>
              {carParts.map(renderCarPart)}
            </svg>
          </CarClicker>
        </div>
      </div>
    </StyledDamageRegion>
  );
}

DamageRegion.propTypes = {
  className: PropTypes.string,
};

export default DamageRegion;
