import { IN_CREATION, IN_CREATION_STOP } from '@domain/action-types';

const initialState = false;

const inCreation = (state = initialState, { type }) => {
  switch (type) {
    case IN_CREATION:
      return true;
    case IN_CREATION_STOP:
      return false;
    default:
      return state;
  }
};

export default inCreation;
