import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment'
import FormSG from './FormSG'

import { validationSchema } from './validationSchema';
import { ContainerSP, ErrorMessage } from '@domain/components';
import { useScaling, useValidation } from '@domain/hooks';
import { goToNextPage } from '@domain/helpers';

const FormWrapperSG = ({ content, saveIncident, isPageBeforeSuccess, history, currentPath, files, questionnaire }) => {

  const scaling = useScaling()
  const incident = useSelector((state) => state.incident)

  const initialFormData = { licensePlate: incident.licensePlate || "", damageDate: incident.damageDate || "", damageType: incident.damageType || "" }
  const [formDataCreation, setFormDataCreation] = useState(initialFormData);
  const [licenseCreationBlurred, setLicenseCreationBlurred] = useState(false);
  const [dateCreationBlurred, setDateCreationBlurred] = useState(false);
  const [damageType, setDamageType] = useState('Carrosserie');

  useEffect(() => {
    if (incident.damageType) {
      setDamageType(incident.damageType)
    }
  }, [incident.damageType])

  const updateFormDataCreation = useCallback(
    (name, value) =>
      setFormDataCreation((d) => ({ ...d, [name]: value })),
    [],
  );

  const errors = useValidation(validationSchema, formDataCreation)

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitDamageType = (event) => {
    event && event.preventDefault();
    updateFormDataCreation('damageType', damageType)
  };

  const handleDamageType = (event, selectedDamageType) => {
    if (selectedDamageType !== null) {
      setDamageType(selectedDamageType);
    }
  };

  const onChangeCreation = useCallback(
    (event) => {
      if (event == null) {
        return
      }
      if (event instanceof Date) {
        const dateFormat = 'YYYY-MM-DD';
        const formattedDate = moment(event).format(dateFormat);

        updateFormDataCreation('damageDate', formattedDate)
        return
      }
      const { name, value } = event.target;

      if (name === 'licensePlate') {
        const nonAlphaNumRegExp = /[^0-9A-Z]/gi;
        const newValue = value
          .toUpperCase()
          .replace(nonAlphaNumRegExp, '')
          .slice(0, 6);
        updateFormDataCreation(name, newValue);
        return;
      }
      updateFormDataCreation(name, value);
    },
    [updateFormDataCreation],
  );

  const onBlurLicensePlate = () => setLicenseCreationBlurred(true)
  const onBlurDamageDate = () => setDateCreationBlurred(true)

  const handleResetCreation = () => {
    setFormDataCreation({ ...initialFormData, 'damageType': "" });
    setLicenseCreationBlurred(false)
    setDateCreationBlurred(false)
  };

  const shouldShowError = (name, blurred) => {
    if (!blurred) {
      return false;
    }
    if (errors === null) {
      return false
    }
    return !!errors[name]
  };

  const showError = (name, blurred) => {
    if (!shouldShowError(name, blurred)) {
      return <></>;
    }
    if (errors === null) {
      return <></>;
    }
    return (
      <ContainerSP marginTop={'-' + scaling.scaleUpTo4K(14) + 'px'} maxHeight={'-' + scaling.scaleUpTo4K(14) + 'px'}>
        <ErrorMessage dynamicFontSize={true}>{errors[name]}</ErrorMessage>
      </ContainerSP>
    );
  };

  const verifyAndSubmit = async (event) => {
    event && event.preventDefault();
    if (errors === null) {
      setIsLoading(true)
      const savedIncident = await saveIncident({
        ...incident,
        ...formDataCreation,
      }, isPageBeforeSuccess);
      setIsLoading(false)
      if (savedIncident) {
        goToNextPage(history, questionnaire, savedIncident, currentPath, files)
      }
    }
  }

  return (
    <>
      <FormSG
        onChangeCreation={onChangeCreation}
        handleSubmitDamageType={handleSubmitDamageType}
        handleDamageType={handleDamageType}
        damageType={damageType}
        shouldShowError={shouldShowError}
        showError={showError}
        formDataCreation={formDataCreation}
        onBlurLicensePlate={onBlurLicensePlate}
        onBlurDamageDate={onBlurDamageDate}
        incident={incident}
        verifyAndSubmit={verifyAndSubmit}
        handleResetCreation={handleResetCreation}
        licenseCreationBlurred={licenseCreationBlurred}
        dateCreationBlurred={dateCreationBlurred}
        errors={errors}
        content={content}
        isLoading={isLoading}
      />
    </>
  )

}

export default FormWrapperSG