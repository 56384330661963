const NEW_UUID_FOUND = 'NEW_UUID_FOUND';
const CASE_UUID_FOUND = 'CASE_UUID_FOUND';
const UUID_CHECKED = 'UUID_CHECKED';

const NEW_PID_FOUND = 'NEW_PID_FOUND';
const CASE_PID_FOUND = 'CASE_PID_FOUND';

const NEW_RID_FOUND = 'NEW_RID_FOUND'
const NEW_FID_FOUND = 'NEW_FID_FOUND'
const FAQ_SEEN_VALUE_FOUND = 'FAQ_SEEN_VALUE_FOUND';
const FAQ_VIEWED = 'FAQ_VIEWED';

const HTTP_ERROR_CLEARED = 'HTTP_ERROR_CLEARED';
const HTTP_ERROR_ENCOUNTERED = 'HTTP_ERROR_ENCOUNTERED';

const IN_CREATION = 'IN_CREATION';
const IN_CREATION_STOP = 'IN_CREATION_STOP';

const LOGGED_IN = 'LOGGED_IN';
const LOGGED_OUT = 'LOGGED_OUT';

const NEW_PROGRESS = 'NEW_PROGRESS';

const TOKEN_FOUND = 'TOKEN_FOUND';
const UPDATE_CONSENTCODE = 'UPDATE_CONSENTCODE'
// Pages to Jump Over
const SET_EXCLUDED_PAGES = 'SET_EXCLUDED_PAGES';
const EXCLUDED_PAGES_FOUND_IN_INCIDENT = 'EXCLUDED_PAGES_FOUND_IN_INCIDENT';
const EXCLUDED_PAGES_FOUND_IN_BROWSER_STORAGE = 'EXCLUDED_PAGES_FOUND_IN_BROWSER_STORAGE';
const UPDATE_EXCLUDED_PAGES = 'UPDATE_EXCLUDED_PAGES'

// Flows
const CHECKIN_FLOW_DETECTED = 'CHECKIN_FLOW_DETECTED';
const CHECKOUT_FLOW_DETECTED = 'CHECKOUT_FLOW_DETECTED';
const FLOW_DETECTED = 'FLOW_DETECTED';
const NEW_FLOW_DETECTED = 'NEW_FLOW_DETECTED';
const ONBOARDING_FLOW_DETECTED = 'ONBOARDING_FLOW_DETECTED';

// Images
const FILES_FETCHED = 'FILES_FETCHED';

const GREENCARD_UPLOADED = 'GREENCARD_UPLOADED';
const GREENCARD_DELETED = 'GREENCARD_DELETED';

const IMAGE_DELETED = 'IMAGE_DELETED';
const IMAGE_SELECTED = 'IMAGE_SELECTED';
const IMAGES_FETCHED = 'IMAGES_FETCHED';
const IMAGES_UPLOADED = 'IMAGES_UPLOADED';
const INTAKE_IMAGE_DELETED = 'INTAKE_IMAGE_DELETED';
// const INTAKE_IMAGE_SELECTED = 'INTAKE_IMAGE_SELECTED';
const INTAKE_IMAGES_FETCHED = 'INTAKE_IMAGES_FETCHED';
const INTAKE_IMAGES_UPLOADED = 'INTAKE_IMAGES_UPLOADED';

const OFFERS_UPLOADED = 'OFFERS_UPLOADED';
const OFFER_DELETED = 'OFFER_DELETED';

const OTHER_FILES_FETCHED = 'OTHER_FILES_FETCHED';
const OTHER_FILES_UPLOADED = 'OTHER_FILES_UPLOADED';
const OTHER_FILE_DELETED = 'OTHER_FILE_DELETED';

const REGISTRATION_CERTIFICATE_UPLOADED = 'REGISTRATION_CERTIFICATE_UPLOADED';
const REGISTRATION_CERTIFICATE_DELETED = 'REGISTRATION_CERTIFICATE_DELETED';

const SAF_DOCUMENTS_FETCHED = 'SAF_DOCUMENTS_FETCHED';
const SAF_DOCUMENTS_UPLOADED = 'SAF_DOCUMENTS_UPLOADED';
const SAF_DOCUMENT_DELETED = 'SAF_DOCUMENT_DELETED';

const OFFICIAL_REPORT_FETCHED = 'OFFICIAL_REPORT_FETCHED';
const OFFICIAL_REPORT_UPLOADED = 'OFFICIAL_REPORT_UPLOADED';
const OFFICIAL_REPORT_DELETED = 'OFFICIAL_REPORT_DELETED';
// Incident
const INCIDENT_FETCHED = 'INCIDENT_FETCHED';
const INCIDENT_UPDATED = 'INCIDENT_UPDATED';
const INCIDENT_CREATED = 'INCIDENT_CREATED';
const SP_DETAILS_DETECTED = 'SP_DETAILS_DETECTED';
const SP_DI_DETAILS_DETECTED = 'SP_DI_DETAILS_DETECTED'; // 'DI' as in digital intake
const SP_FOUND_IN_BROWSER_STORAGE = 'SP_FOUND_IN_BROWSER_STORAGE'
const SP_SG_DETAILS_DETECTED = 'SP_SG_DETAILS_DETECTED'

// Temp Incident
const TEMP_INCIDENT_CREATED = 'TEMP_INCIDENT_CREATED';
const TEMP_INCIDENT_UPDATED = 'TEMP_INCIDENT_UPDATED';
const TEMP_INCIDENT_FOUND_IN_BROWSER_STORAGE = 'TEMP_INCIDENT_FOUND_IN_BROWSER_STORAGE';
const TEMP_INCIDENT_CLEANED = 'TEMP_INCIDENT_CLEANED'
//Insurer
const COMPANY_TOKEN_FOUND = 'COMPANY_TOKEN_FOUND';
const INSURER_FETCHED = 'INSURER_FETCHED';
const INSURER_UUID_FOUND = 'INSURER_UUID_FOUND';

//Bodyshop
const BODYSHOP_FETCHED = 'BODYSHOP_FETCHED';
const BODYSHOP_LISTS_FETCHED = 'BODYSHOP_LISTS_FETCHED';

export {
  BODYSHOP_FETCHED,
  BODYSHOP_LISTS_FETCHED,
  CASE_PID_FOUND,
  CASE_UUID_FOUND,
  CHECKIN_FLOW_DETECTED,
  CHECKOUT_FLOW_DETECTED,
  COMPANY_TOKEN_FOUND,
  FAQ_SEEN_VALUE_FOUND,
  FAQ_VIEWED,
  FLOW_DETECTED,
  IN_CREATION,
  IN_CREATION_STOP,
  HTTP_ERROR_CLEARED,
  HTTP_ERROR_ENCOUNTERED,

  FILES_FETCHED,

  GREENCARD_UPLOADED,
  GREENCARD_DELETED,

  IMAGE_DELETED,
  IMAGE_SELECTED,
  IMAGES_FETCHED,
  IMAGES_UPLOADED,
  INTAKE_IMAGE_DELETED,
  // INTAKE_IMAGE_SELECTED,
  INTAKE_IMAGES_FETCHED,
  INTAKE_IMAGES_UPLOADED,

  OFFERS_UPLOADED,
  OFFER_DELETED,

  OTHER_FILES_FETCHED,
  OTHER_FILES_UPLOADED,
  OTHER_FILE_DELETED,

  REGISTRATION_CERTIFICATE_UPLOADED,
  REGISTRATION_CERTIFICATE_DELETED,

  SAF_DOCUMENTS_FETCHED,
  SAF_DOCUMENTS_UPLOADED,
  SAF_DOCUMENT_DELETED,

  OFFICIAL_REPORT_FETCHED,
  OFFICIAL_REPORT_UPLOADED,
  OFFICIAL_REPORT_DELETED,

  INCIDENT_FETCHED,
  INCIDENT_CREATED,
  INCIDENT_UPDATED,
  TEMP_INCIDENT_CLEANED,
  TEMP_INCIDENT_CREATED,
  TEMP_INCIDENT_UPDATED,
  TEMP_INCIDENT_FOUND_IN_BROWSER_STORAGE,

  EXCLUDED_PAGES_FOUND_IN_INCIDENT,
  EXCLUDED_PAGES_FOUND_IN_BROWSER_STORAGE,
  SET_EXCLUDED_PAGES,
  UPDATE_EXCLUDED_PAGES,

  INSURER_FETCHED,
  INSURER_UUID_FOUND,
  LOGGED_IN,
  LOGGED_OUT,
  NEW_RID_FOUND,
  NEW_FID_FOUND,
  NEW_PID_FOUND,
  NEW_FLOW_DETECTED,
  NEW_PROGRESS,
  NEW_UUID_FOUND,
  ONBOARDING_FLOW_DETECTED,
  SP_DETAILS_DETECTED,
  SP_DI_DETAILS_DETECTED,
  SP_FOUND_IN_BROWSER_STORAGE,
  SP_SG_DETAILS_DETECTED,
  TOKEN_FOUND,
  UUID_CHECKED,
  UPDATE_CONSENTCODE
};
