
const checkIsNewStatusCase = (incident) => {
  const isNewStatusCase = (!incident || (!!incident && (
    (incident.status === '_new' || incident.status === undefined) ||
    (incident.status === 'damage_cancelled' && !!incident.cancelReason && incident.cancelReason === 'inactivity_from_the_customer') ||
    (incident.status === 'process_aborted' && !!incident.cancelReason && incident.cancelReason === 'VIP_not_chosen'))));

  return isNewStatusCase
}

export default checkIsNewStatusCase