import {
  parseFetchResponseToJson,
  camelCaseToPascalCase
} from '@domain/helpers';

import { browserStorage } from '@domain/helpers';

import { endpoints } from '@domain/constants';

const insurerEndpoint = !!process.env.REACT_APP_ENDPOINTS_URL_V2 ? endpoints.insurerV2 : endpoints.insurer;

function get(caseUUID) {
  const uuid = browserStorage.getUUID() || caseUUID;
  const config = {
    method: 'GET',
    headers: camelCaseToPascalCase({
      uuid,
      'Pragma': 'no-cache',
    })
  };
  return fetch(`${insurerEndpoint}`, config).then((response) =>
    parseFetchResponseToJson(response, { endpoint: insurerEndpoint })
  );
}

function getBranding(pID, fID, rID) {
  const PID = pID ? { PID: pID } : browserStorage.getPID() ? { PID: browserStorage.getPID() } : {}
  const FID = fID ? { FID: fID } : browserStorage.getFID() ? { FID: browserStorage.getFID() } : {}
  const RID = rID ? { RID: rID } : browserStorage.getRID() ? { RID: browserStorage.getRID() } : {}

  const headers = {
    'Content-Type': 'application/json',
    'Pragma': 'no-cache',
    ...camelCaseToPascalCase({ ...PID, ...FID, ...RID }),
  }

  const config = {
    method: 'GET',
    headers: headers
  };
  return fetch(`${insurerEndpoint}`, config).then(
    (response) => parseFetchResponseToJson(response, { endpoint: insurerEndpoint })
  );
}

export default {
  get,
  getBranding,
};
