import { object, string } from 'yup';

const postcodeRegEx = /^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i;
const houseNumberRegEx = /^\d+$/i;

const alreadyRejected = (badZipCodes: string[]) => (zipCode: string) =>
  !badZipCodes.includes(zipCode.toUpperCase().replace(/ /g, ''));

const validationSchema = badZipCodes =>
  object({
    customerZipCode: string()
      .matches(postcodeRegEx, 'Controleer alstublieft uw postcode.')
      .required()
      .test(
        'This postcode has already been rejected',
        'Uw postcode bestaat niet',
        alreadyRejected(badZipCodes),
      )
      .label('Postcode'),
    customerHouseNumber: string()
      .matches(houseNumberRegEx, 'Controleer alstublieft uw huisnummer. U kunt hier enkel cijfers invullen.')
      .required()
      .label('Huisnummer'),
    customerHouseNumberAddition: string()
      .label('Toevoeging')
      .max(5),
  });

export default validationSchema;
