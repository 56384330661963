// NPM imports.
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled, { ThemeContext } from 'styled-components';
import { Colors } from '@domain/theming';
import { useScaling } from '@domain/hooks';
import { useContext } from 'react';
import { chooseLogoSize } from '@domain/helpers';


const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: ${props => props.$scaling.scaleUpTo4K(props.height)}px;
  z-index: 3;
  padding: 18px 32px;
  border-bottom: 1px solid ${Colors.GREY_LIGHT};
  flex: 0 0 auto;
  transition: border-radius 0.3s ease-in-out;
  .icon-link-container,
  .progression-indicator,
  .faq-button-container,
  .empty-div {
    flex: 1 0 0px;
  }
  .company-logo {
    flex: 1 0 0;
    .screen-ie & {
      flex: 0 0 auto;
    }
  }
  .icon-button {
    text-align: right;
  }
  &.navbar-end {
    padding: 12px 32px;
  }
  &.navbar--black {
    border-color: ${Colors.GREY_DARK};
    padding: 5px 32px;
  }
  &.navbar--novip {
    padding: 5px 32px;
  }
  &.navbar__tablet {
    padding: 32px;
  }
  &.navbar--overlap {
    position: absolute;
    z-index: 1;
    width: 100%;
    color: ${Colors.WHITE};
  }

  .screen-desktop & {
    min-height: ${props => props.$scaling.scaleUpTo4K(props.height)}px;
    padding: ${props => props.$scaling.scaleUpTo4K(10)}px ${props => props.$scaling.scaleUpTo4K(120)}px;
    &.navbar-end {
      padding-top: ${props => props.$scaling.scaleUpTo4K(12)}px;
      padding-bottom: ${props => props.$scaling.scaleUpTo4K(12)}px;
    }
    &.navbar--novip {
      // padding: ${props => props.$scaling.scaleUpTo4K(10)}px ${props => props.$scaling.scaleUpTo4K(56)}px;
    }
    &.navbar--black {
      border-color: ${Colors.GREY_DARK};
      // padding: ${props => props.$scaling.scaleUpTo4K(10)}px ${props => props.$scaling.scaleUpTo4K(56)}px;
    }
  }
  
  .theme-custom & {
    // here would come custom navbar styling for theming
    // (not for any children of the navbar!)
  }

  .video & {
    .text--faq-veelgestelde-vragen {
      color: ${Colors.WHITE};
    }
    border-color: ${Colors.GREY_LIGHT};
  }

  .theme-custom.video & {
    border-color: ${Colors.GREY_PRIMARY};
  }

  @media screen and (max-width: 1263px) {
    .screen-desktop & {
      padding: 10px 56px;
    }
  }

  @media screen and (max-width: 580px) {
    .screen-desktop & {
      padding: 24px 40px;
      &.navbar-end {
        padding: 12px 40px;
      }
      &.navbar--nologo {
        padding: 10px 40px;
      }
    }
  }
`;

function NavBar({ children, className, blackPage, isVIP = true }) {
  const scaling = useScaling();
  const classes = classNames(
    'navbar',
    className,
    { 'navbar--black': blackPage },
    { 'navbar--novip': !isVIP }
  );

  const themeContext = useContext(ThemeContext)
  const height = chooseLogoSize(themeContext.logoSize) + 21
  return (
    <StyledNav className={classes} role="navigation" $scaling={scaling} height={height}>
      {React.Children.toArray(children)}
    </StyledNav>
  );
}

NavBar.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  blackPage: PropTypes.bool,
  isVIP: PropTypes.bool,
};

export default NavBar;
