import React from 'react';
import styled, { keyframes } from 'styled-components';
import classNames from 'classnames';
import { Colors } from '@domain/theming';
import { useScaling } from '@domain/hooks';

const keyframesLoader = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  .loader__spinner {
    display: inline-block;
    position: relative;
    width: ${props => props.size === 'small' ? props.$scaling.scaleUpTo4K(48) : props.$scaling.scaleUpTo4K(64)}px;
    height: ${props => props.size === 'small' ? props.$scaling.scaleUpTo4K(48) : props.$scaling.scaleUpTo4K(64)}px;
    .loader__sphere {
      width: ${props => props.size === 'small' ? '84%' : '90%'};
      height: ${props => props.size === 'small' ? '84%' : '90%'};
      top: ${props => props.size === 'small' ? '10%' : '5%'};
      left: ${props => props.size === 'small' ? '8%' : '5%'};
      position: absolute;
      // transform-origin: ${props => props.size === 'small' ? `${props.$scaling.scaleUpTo4K(24)}px ${props.$scaling.scaleUpTo4K(24)}px` : `${props.$scaling.scaleUpTo4K(32)}px ${props.$scaling.scaleUpTo4K(32)}px`};
      animation: ${keyframesLoader} 1.2s cubic-bezier(0.5,0.3,0.4,0.8) infinite;
      &:after {
        content: '';
        display: block;
        width: ${props => props.size === 'small' ? props.$scaling.scaleUpTo4K(5) : props.$scaling.scaleUpTo4K(6)}px;
        height: ${props => props.size === 'small' ? props.$scaling.scaleUpTo4K(5) : props.$scaling.scaleUpTo4K(6)}px;
        border-radius: 50%;
        background: ${props => props.color === 'white' ? Colors.WHITE : Colors.GREY_DARK};
        // margin: -3px 0 0 -3px;
        margin: 0 auto;
      }
      &:nth-child(1) {
        // animation-delay: -0.036s;
        animation-delay: -0.5s;
        transform: rotate(45deg);
        &:after {
          // top: 50px;
          // left: 50px;
        }
      }
      &:nth-child(2) {
        // animation-delay: -0.072s;
        animation-delay: -0.425s;
        transform: rotate(90deg);
        &:after {
          // top: 54px;
          // left: 45px;
        }
      }
      &:nth-child(3) {
        // animation-delay: -0.108s;
        animation-delay: -0.375s;
        transform: rotate(135deg);
        &:after {
          // top: 57px;
          // left: 39px;
        }
      }
      &:nth-child(4) {
        // animation-delay: -0.144s;
        animation-delay: -0.3s;
        transform: rotate(180deg);
        &:after {
          // top: 58px;
          // left: 32px;
        }
      }
      &:nth-child(5) {
        // animation-delay: -0.18s;
        animation-delay: -0.225s;
        transform: rotate(225deg);
        &:after {
          // top: 57px;
          // left: 25px;
        }
      }
      &:nth-child(6) {
        // animation-delay: -0.216s;
        animation-delay: -0.15s;
        transform: rotate(270deg);
        &:after {
          // top: 54px;
          // left: 19px;
        }
      }
      &:nth-child(7) {
        // animation-delay: -0.252s;
        animation-delay: -0.075s;
        transform: rotate(315deg);
        &:after {
          // top: 50px;
          // left: 14px;
        }
      }
      &:nth-child(8) {
        // animation-delay: -0.288s;
        &:after {
          // top: 45px;
          // left: 10px;
        }
      }
    }
  }
`;


function Loader({ size, color, className }) {
  const scaling = useScaling();
  const classes = classNames('loader', className);

  return (
    <StyledLoader className={classes} size={size} color={color} $scaling={scaling}>
      <div className="loader__spinner">
        <div className="loader__sphere" />
        <div className="loader__sphere" />
        <div className="loader__sphere" />
        <div className="loader__sphere" />
        <div className="loader__sphere" />
        <div className="loader__sphere" />
        <div className="loader__sphere" />
        <div className="loader__sphere" />
      </div>
    </StyledLoader>
  );
}

export default Loader;
