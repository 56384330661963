import React from 'react';
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import { Collapsible, Container, FAQItem, FAQList, Heading } from '../../';
import { Colors } from '@domain/theming';
import { useScaling } from '@domain/hooks';
import onboardingRoutes from '../../../../Onboarding/routes'
import routes from '../../../../routes'
const StyledFAQ = styled.div`
  display: flex;
  flex-direction:column;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 30px 30px 30px 122px;
  background: ${Colors.BLACK};
  color: ${Colors.WHITE};
  box-sizing: border-box;
  transition: visibility 0s 0.3s;
  visibility: hidden;
  overflow-y: auto;
  z-index: 1;
  &.faq__container--visible {
    transition: visibility 0s;
    visibility: visible;
  }
  .theme-dark & {
    background: ${Colors.GREY_DARKER};
  }
  .faq__list {
    display: block;
    flex: 1 1 auto;
  }
  .faq__item {
    margin-bottom: 20px;
  }
  .faq__link--container {
    display:flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    flex: 1 1 auto;
    a {
      margin-top: auto;
      text-decoration: none;
      color: inherit;
      &:active,
      &:focus,
      &:hover {
        color: inherit;
        text-decoration:underline;
      }
      &:visited {
        color: inherit;
      }
    }
    .h4 {
      a {
        
      }
    }
  }
  .small__image {
    cursor: pointer;
    max-width: ${props => props.$scaling.scaleUpTo4K(250)}px;
    margin-top: ${props => props.$scaling.scaleUpTo4K(10)}px;
    height: auto;
    max-height: 180px; // IE bug -- big images brake from flex flow
    img {
      max-width: 100%;
      height: auto;
    }
  }
  
  .screen-desktop & {
    width: ${props => props.$scaling.scaleUpTo4K(500)}px;
    padding: ${props => props.$scaling.scaleUpTo4K(80)}px ${props => props.$scaling.scaleUpTo4K(56)}px 0 ${props => props.$scaling.scaleUpTo4K(76)}px;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar { 
      display: none;  /* Safari and Chrome */
    }
    .faq__link--container {
      padding: ${props => props.$scaling.scaleUpTo4K(60)}px 0 ${props => props.$scaling.scaleUpTo4K(80)}px;
    }
  }

  @media screen and (max-width: 1028px) {
    .screen-desktop & {
      .small__image {
        margin: 0 auto;
      }
    }
  }

  @media screen and (max-width: 580px)  {
    .screen-desktop & {
      width: 78%;
      .small__image {
        margin-left: 0;
      }
    }
  }
`;


function FAQ({ faqListProps, faqs, faqLink, faqVisible, customHelpLink, helpLinkText }) {
  const scaling = useScaling();
  const classes = classnames(
    'faq__container',
    { 'faq__container--visible': faqVisible },
  );
  const renderFaq = faq => (
    <FAQItem key={faq.question}>
      <Collapsible title={faq.question}>{faq.answer}</Collapsible>
    </FAQItem>
  );
  return (
    <StyledFAQ className={classes} $scaling={scaling}>
      <FAQList {...faqListProps}>
        {faqs.map(renderFaq)}
      </FAQList>
      <Container className="faq__link--container">
        {customHelpLink ? <Heading level="4">
          <Link to={routes.onboarding + onboardingRoutes.help}>
            {helpLinkText || "Staat uw vraag hier niet tussen? Klik hier"}
          </Link>
        </Heading> : <Heading level="4">
          <a
            className="h4"
            target="blank"
            href={faqLink ? faqLink : "https://vipschadeservice.zendesk.com"}>
            Staat uw vraag hier niet tussen? Klik hier
          </a>
        </Heading>}
      </Container>
    </StyledFAQ>
  );
}

FAQ.propTypes = {
  faqListProps: PropTypes.shape({
    faqVisible: PropTypes.bool.isRequired,
    onTouchStart: PropTypes.func,
    onTouchMove: PropTypes.func,
    onTouchEnd: PropTypes.func
  }),
  faqs: PropTypes.arrayOf(
    PropTypes.shape({
      answer: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
        .isRequired,
      question: PropTypes.string.isRequired
    })
  ),
  faqLink: PropTypes.string,
  faqVisible: PropTypes.bool,
};

export default FAQ;
