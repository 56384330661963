import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'

const Consent: FC = () => {

  const consent = useSelector((state) => state.consentCode)

  const writeKeyHotjar = process.env.REACT_APP_HOTJAR_API_KEY
  const writeKeyGA = process.env.REACT_APP_GA_API_KEY

  if (writeKeyHotjar && writeKeyGA) {
    return (
      <Helmet>
        {(consent && consent.ga) && <script src={`https://www.googletagmanager.com/gtag/js?id=${writeKeyGA}`} async />}
        {(consent && consent.ga) &&
          (<script>
            {`window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                window.gtag = gtag;
                gtag('js', new Date());

                gtag('config', '${writeKeyGA}');`}
          </script>)}
        {(consent && consent.hotjar) && (<script>
          {`
              (function (h, o, t, j, a, r) {
                h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
                h._hjSettings = { hjid: ${writeKeyHotjar}, hjsv: 6 };
                a = o.getElementsByTagName('head')[0];
                r = o.createElement('script'); r.async = 1;
                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                a.appendChild(r);
              })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');`
          }
        </script>)}
      </Helmet>
    )
  }
  if (writeKeyGA) {
    return (
      <Helmet>
        {(consent && consent.ga) && <script src={`https://www.googletagmanager.com/gtag/js?id=${writeKeyGA}`} async />}
        {(consent && consent.ga) &&
          (<script>
            {`window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                window.gtag = gtag;
                gtag('js', new Date());

                gtag('config', '${writeKeyGA}');`}
          </script>)}
      </Helmet>
    )
  }
  if (writeKeyHotjar) {
    return (
      <Helmet>
        {(consent && consent.hotjar) && (<script>
          {`
              (function (h, o, t, j, a, r) {
                h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
                h._hjSettings = { hjid: ${writeKeyHotjar}, hjsv: 6 };
                a = o.getElementsByTagName('head')[0];
                r = o.createElement('script'); r.async = 1;
                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                a.appendChild(r);
              })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');`
          }
        </script>)}
      </Helmet>
    )
  }
  return null
}
export default Consent