import { BODYSHOP_FETCHED } from '@domain/action-types';

const bodyshop = (state = null, { type, bodyshop }) => {
  switch (type) {
    case BODYSHOP_FETCHED:
      return { ...bodyshop };
    default:
      return state;
  }
};

export default bodyshop;
