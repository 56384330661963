import {
  parseFetchResponseToJson,
  camelCaseToPascalCase
} from '@domain/helpers';

import { endpoints } from '@domain/constants';

const loginEndpoint = endpoints.login;

async function signIn(licensePlate, uuid, bodyshop = false) {
  const body = !bodyshop ?
    JSON.stringify(camelCaseToPascalCase({ licensePlate, uuid })) :
    JSON.stringify(camelCaseToPascalCase({ licensePlate }));
  const config = {
    method: 'POST',
    headers: camelCaseToPascalCase({
      'Content-Type': 'application/json'
    }),
    body: body,
  };

  const response = await fetch(loginEndpoint, config).then((response) =>
    parseFetchResponseToJson(response, { body, endpoint: loginEndpoint })
  );
  return response;
}

export default { signIn };
