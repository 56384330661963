import { pascalCaseToCamelCase } from '..';
import { datadogLogs } from '@datadog/browser-logs';

async function parseFetchResponseToJson(response, reqDetails) {
  if (response.ok && response.status === 200) {
    return response.json().then(pascalCaseToCamelCase);
  } else {
    const stringifiedErrors = await response.text();
    datadogLogs.logger.error(`error on Parse`, reqDetails)
    const obj = pascalCaseToCamelCase(JSON.parse(stringifiedErrors));
    throw obj;
  }
}

export default parseFetchResponseToJson;
