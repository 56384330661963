import React from 'react';
import { useSelector } from 'react-redux';

import { motion } from 'framer-motion';
import styled from 'styled-components';

import { ContainerSP, ExpandableTextBox, HeadingSP, Loader, ParagraphSP } from '@domain/components';
import { useWindowSize } from '@domain/hooks';


const AnimatedContainer = styled(
  motion(ContainerSP))`
  `;

const introTextVariants = {
  hidden: {
    // y: 200,
    opacity: 0,
  },
  visible: {
    // y: 0,
    opacity: 1,
    transition: {
      ease: 'easeInOut',
      opacity: {
        // delay: 0.54,
        duration: 0.66,
      },
    },
  },
}


const InfoBlock = ({ content }) => {

  const { vWidth } = useWindowSize();
  const insurer = useSelector((state) => state.insurer)

  if (!insurer) {
    return <Loader />;
  }


  const showIntroTextList = content['show-intro-text-list']
  const title = content["title"]
  const introTextParagraphs = content['intro-text-paragraphs']
  const introTextList = content['intro-text-list']

  const generateParagraphs = introTextParagraphs.map((item) => {
    return (
      <ParagraphSP paddingTop="15px" textAlign="left" level={4} key={Math.random()}>
        {item.text}
      </ParagraphSP>
    )
  })

  const generateIntroTextList = introTextList ? introTextList.map((item, i) => {
    return (
      <li key={i}><ParagraphSP marginTop="20px" textAlign="left" level={4}> {item.text}</ParagraphSP></li>
    )
  }) : null;

  return (
    <AnimatedContainer
      className="landing__intro-text--content"
      flexDirection="column"
      flexGrow="0"
      justifyContent="flex-start"
      variants={introTextVariants}
      initial="hidden"
      animate="visible"
      margin="auto"
    >
      {title && <HeadingSP className="h1" level={1} marginBottom="15px" textAlign="left"> {title} </HeadingSP>}
      <ExpandableTextBox
        className="landing__intro-text--content"
        minLine={vWidth > 900 ? 0 : 6}
        startOpen={vWidth > 900}
      >
        {<>{generateParagraphs}</>}
        {showIntroTextList && <ul>{generateIntroTextList}</ul>}
      </ExpandableTextBox>
    </AnimatedContainer>
  )
}

export default InfoBlock
