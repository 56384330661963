/* 
All the Redux state is stored in the store (hence the name) which is technically agnostic from React.
To add a new state to Redux you must do the following:

Create a reducer. This must be a pure function of the form (currentState = initialState, action) => newState. 
Create any needed action types. These should be strings in all capitals, and should be declared like
const SOMETHING_HAPPENED = "SOMETHING_HAPPENED"
Not because this is somehow more correct than other patterns, but simply for consistency.
Include your new reducer in the object given to combineReducers in src/reducers/index.js
Export your new action type.
N.B. before creating a new redux state, consider if it can be inferred from the state already existing

I have added comments to the incident reducer to see how a reducer should be written.

For example, if I have a state called 'count', which is simply an integer, and a state called isEven, which is true whenever count is even
Then this is redundent because whenever I use the isEven state, I could simply write const isEven = (count % 2 === 0)

However, having the incident uuid be separate from the incident is not redundant, because the incident uuid tells the app which incident to fetch.

(Recently read a convincing blog post which suggests that having the incident uuid be a state is an anti-pattern, and that instead the function which detects the incident uuid fetch the incident directly would be better. 
  I believe they are correct, but that's a lesson for the future.)
*/

import { applyMiddleware, createStore } from 'redux';
import reducer from '@domain/reducers';
import { composeWithDevTools as remoteDevtools } from 'remote-redux-devtools';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxThunk from 'redux-thunk';

const useRemoteDevtools =
  String(process.env.REACT_APP_REMOTE_REDUX_DEVTOOLS).trim() === 'true';

const reduxDevTools = !useRemoteDevtools
  ? composeWithDevTools
  : remoteDevtools({
    name: 'CP',
    hostname: 'localhost',
    port: 8000,
  });

const composeEnhancers = reduxDevTools(applyMiddleware(ReduxThunk));

const store = createStore(reducer, composeEnhancers);

export default store;
