const Colors = {
  BLACK: '#000000',
  GREY_DARKER: '#212121',
  GREY_DARK: '#434244', // _TEXT
  GREY_PRIMARY: '#A1A0A1',
  GREY_LIGHT: '#E2E2E2',
  GREY_LIGHTER: '#F2F2F2', // _BACKGROUND
  WHITE: '#FFFFFF',
  BLUE: '#48b0f7',
  BLUE_DARK: '#393EB9',
  RED: '#ed1c24',
  YELLOW: '#f99b1d',
  YELLOW_LIGHT: '#FFDD00',
  GREEN: '#52ce59',

  //company specific colors
  SCHADEGARANT_COLOR: '#ea650d',
  SCHADEGARANT_DARK_COLOR: '#e64415',
  SCHADEGARANT_LIGHT_COLOR: '#fff7ee',
  SCHADEGARANT_GRADIENT: 'linear-gradient(90deg, rgba(234,101,13,1) 10%, rgba(230,68,21,1) 90%)',
}

export default Colors;
