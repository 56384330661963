import {
  parseFetchResponseToJson,
  camelCaseToPascalCase,
} from '@domain/helpers';

import { endpoints } from '@domain/constants';
const pdfDossierEndpoint = endpoints.pdfDossier;

function get(token, uuid) {

  const config = {
    method: 'get',
    headers: camelCaseToPascalCase({
      token,
      uuid,
    }),
  };

  const res = fetch(pdfDossierEndpoint, config).then((response) => parseFetchResponseToJson(response, { endpoint: pdfDossierEndpoint }));

  return res;
}

export default { get }
