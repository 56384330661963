import React, { useMemo, useContext } from 'react';
import { useSelector } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';

import { useThemes, useScaling } from '@domain/hooks';
import { Main, GridContainer, Heading, Loader, Container, GridColumn, Legend, Paragraph, GoogleMap, NavBar, IconLink, CompanyLogo } from '@domain/components';
import { companyNames, proccessType } from '@domain/constants';
import { chooseLogoSize, generatePageContent } from '@domain/helpers';
import { Colors } from '@domain/theming';
import { detectContentType } from '@domain/content'

const StyledMain = styled(Main)`
  .support {
    &__container {
      .grid__column {
      }
    }
    &__main {
      h1 {
        padding-bottom: ${props => props.$scaling.scaleUpTo4K(30)}px;
        padding-top: ${props => props.$scaling.scaleUpTo4K(20)}px;
      }
      .support__main--card {
        flex-direction: column;
        p {
          a {
            color: ${Colors.GREY_DARK};
            word-break: break-word;
          }
        }
        .bodyshop-info {
          flex-direction: column;
          .map-info {
            h4 {
              padding-top:10px;
            }
          }
          .map-container {
            width: 100%;
            height:  ${props => props.$scaling.scaleUpTo4K(250)}px;
            .google-map {
              width: 100%;
              height:  ${props => props.$scaling.scaleUpTo4K(200)}px;
            }
          }
        }
        .map-info {
          h3 {
          padding-top:${props => props.$scaling.scaleUpTo4K(30)}px;
          padding-bottom:${props => props.$scaling.scaleUpTo4K(20)}px;
          }
        }
      }
    }
  }

  .screen-desktop & {
    .support {
      &__container {
      }
      &__main {
        padding-top: ${props => props.$scaling.scaleUpTo4K(20)}px;
        .support-text {
          width: 60%;
          a{
            color: ${Colors.BLACK}
          }
        }
        .support__main--card {
          flex-direction: row;
          .support-info {
            padding-right: ${props => props.$scaling.scaleUpTo4K(100)}px;
            width: 100%;
          }
          .contact-info {
            align-self: flex-start;
          }
          .map-info {
            width: 100%;
            height: auto;
          .bodyshop-info {
            flex-direction: row;
            flex-grow: 1;
            .map-container {
              width: 100%;
              height:  ${props => props.$scaling.scaleUpTo4K(250)}px;
            }
          }
          h1 {
            margin-bottom: 0;
          }
          p {
            margin: ${props => props.$scaling.scaleUpTo4K(5)}px 0px 0px;
            a {
            }
          }
        }
      }
    }
    .grid__column--main {
      max-width: none;
    }
  }

  @media screen and (max-width: 1028px) {
    .screen-desktop & {
      .support {
        &__container {
          .grid__column {
            flex: 0 0 auto;
            padding-top: 0;
          }
        }
        &__main {
          max-width: 500px;
          text-align: center;
          margin: 0 auto;
          flex-direction: column;
          .support-text {
            width: 100%;
          }
          .support__main--card {
            flex-direction: column;
            margin-top: 10px;
            .support-info {
              width: 100%;
              padding: 0 0 20px;
            }
            .map-info {
              width: 100%;
              height: auto;
              flex-direction: column;
              flex-grow: 1;
            }
            .bodyshop-info {
              flex-direction: column;
              .map-container {
                height: 250px;
                width: 100%;
              }
            }
          }
        }
      }
    }
    .support {
      &__container {
        .grid__column {
          flex: 0 0 auto;
          padding-top: 0;
        }
      }
      &__main {
        max-width: 500px;
        text-align: center;
        margin: 0 auto;
        flex-direction: column;
        .support-text {
          width: 100%;
        }
        .support__main--card {
          flex-direction: column;
          margin-top: 10px;
          .support-info {
            width: 100%;
            padding: 0 0 20px;
          }
          .map-info {
            width: 100%;
            height: auto;
            flex-direction: column;
            flex-grow: 1;
          }
          .bodyshop-info {
            flex-direction: column;
            .map-container {
              height: 250px;
              width: 100%;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 580px) {
    
    .screen-desktop & {
      .support {
        &__container {
          .grid__column {
          }
        }
        &__main {
          text-align: left;
          h1 {
            margin-left: 0;
          }
          .support-text {
            width: 100%;
          }
          .support__main--card {
          }
        }
      }
    }
    .support {
      &__container {
        .grid__column {
        }
      }
      &__main {
        text-align: left;
        h1 {
          margin-left: 0;
        }
        .support-text {
          width: 100%;
        }
        .support__main--card {
        }
      }
    }
  }
  }
`;
const InfoDiv = styled.div`
padding-top:20px;
`

function Support({ history, match }) {

  const insurer = useSelector(state => state.insurer);
  const incident = useSelector(state => state.incident);
  const inCreation = useSelector(state => state.inCreation);
  const bodyshop = useSelector(state => state.bodyshop)

  const scaling = useScaling();

  const themeContext = useContext(ThemeContext);

  const isThemed = !!insurer && insurer.brand !== companyNames.VIP && insurer.objectType !== proccessType.DI;
  const themes = useMemo(() => isThemed ? [] : ['home-layout'], [isThemed]);

  useThemes(themes);

  if (!insurer || (incident && incident.debiteurNummer && !bodyshop)) return <Loader />;

  const iconLink = (triggerKey) => ({
    type: 'arrow-left',
    theme: 'secondary',
    to: "",
    replace: true,
    triggerKey
  });

  const main = {
    className: 'main__damage main__damage--support',
    $scaling: scaling,
  }

  const contentType = detectContentType(insurer)
  const content = generatePageContent(contentType, insurer, incident, inCreation, false, 'support')

  const legend = content["over-title-text"]
  const title = content["title"]
  const subtitle = content["subtitle"]
  const useCustomLogo = content["use-custom-logo"]

  const middleLogo = {
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }

  if (bodyshop) {

    const { street, companyName, houseNumber, zipcode, city, phoneNumber, emailAddress, website, longitude, latitude } = bodyshop
    const location = { latitude, longitude }
    const showContactInformation = phoneNumber || emailAddress || website

    const hrefLink = () => {

      const protocol = website ? website.startsWith('http') : null
      if (protocol) {
        return website
      }
      return `http://${website}`
    };

    return (
      <React.Fragment>
        <StyledMain {...main}>
          <NavBar>
            <Container className="icon-link-container">
              <div ><IconLink {...iconLink([37])} /></div>
              {useCustomLogo && <CompanyLogo {...middleLogo} />}
            </Container>
          </NavBar>
          <GridContainer main={true} className="support__container">
            <GridColumn className="support__main">
              {legend && <Legend>{legend}</Legend>}
              {title && <Heading level="1">{title}</Heading>}
              {subtitle && <Heading level="4">{subtitle}</Heading>}
              <Paragraph className="support-text">U kunt naar het door u gekozen herstelbedrijf rijden om uw schade te laten herstellen. Wilt u toch liever gebruik maken van een ander herstelbedrijf, klik dan <a href='https://www.schadegarant.nl' target="blank">hier</a>.</Paragraph>
              <Container flex={true} className="support__main--card">
                <Container className="support-info">
                  <Container className="bodyshop-info">
                    <Container className="map-info">
                      <Heading level="3">{companyName}</Heading>
                    </Container>
                    <Container className="map-container">
                      <GoogleMap location={location} zoom={12} showDirectionsButton={true} />
                    </Container>
                  </Container>
                </Container>
                <Container className="map-info contact-info">
                  {showContactInformation && <Heading level="3">Contact Information</Heading>}
                  <><Heading level="4">Adres</Heading>
                    <Paragraph>
                      {
                        street + ' ' +
                        houseNumber + '\n' +
                        zipcode + ' ' +
                        city}
                    </Paragraph></>
                  {phoneNumber && <InfoDiv><Heading level="4">Telefoon</Heading>
                    <Paragraph><a href={'callto:' + phoneNumber}>{phoneNumber}</a></Paragraph></InfoDiv>}
                  {emailAddress && <InfoDiv><Heading level="4">Email</Heading>
                    <Paragraph><a href={'mailto:' + emailAddress}>{emailAddress}</a></Paragraph></InfoDiv>}
                  {website && <InfoDiv><Heading level="4">Website</Heading>
                    <Paragraph><a href={hrefLink()} target="blank">{website}</a></Paragraph></InfoDiv>}
                </Container>
              </Container>
            </GridColumn>
          </GridContainer>
        </StyledMain>
      </React.Fragment>

    );
  }

  return (
    <React.Fragment>
      <StyledMain $scaling={scaling}>
        <NavBar>
          <Container className="icon-link-container">
            <div><IconLink {...iconLink([37])} /></div>
            {useCustomLogo && <CompanyLogo {...middleLogo} />}
          </Container>
        </NavBar>
        <GridContainer main={true} className="support__container">
          <GridColumn className="support__main">
            {legend && <Legend>{legend}</Legend>}
            {title && <Heading level="1">{title}</Heading>}
            <Container flex={true} className="support__main--card">
              <Container className="support-info">
                {subtitle && <Heading level="4">{subtitle}</Heading>}
                <Paragraph>Via <a href='https://www.schadegarant.nl' target="blank">www.schadegarant.nl</a> kunt u een herstelbedrijf kiezen en vervolgens naar het herstelbedrijf rijden om uw schade te laten repareren.</Paragraph>
              </Container>
              <Container className="bodyshop-info">
              </Container>
            </Container>
          </GridColumn>
        </GridContainer>
      </StyledMain>
    </React.Fragment>

  );
}

export default Support;
