import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { alpha } from '@material-ui/core/styles';
// import ValidationFeedback from '../ValidationFeedback';

function Dropdown({
  label,
  onChange,
  value,
  valueList = {},
}) {
  const useStyles = makeStyles((theme) => ({
    formControl: {
      '& .MuiInputUnderline': {
        '&:after': {
          borderColor: theme.colors.primary + ' !important',
        },
        '&:before': {
          borderColor: theme.colors.primary + ' !important',
        }
      },
      '& .MuiInputLabel-formControl': {
        top: '10px'
      },
    },
    dropdown: {
      lineHeight: '24px',
      fontSize: '24px',
      textAlign: 'left',
    },
    inputUnderline: {
      borderColor: theme.colors.primary,
    },
    dropdownIcon: {
      color: theme.colors.primary,
    },
    dropdownUnlabelled: {
      // a dropdown without label will have a reduced height
      // marginTop: theme.spacing(2),
    },
    dropdownLabel: {
      transform: 'translate(0, 12px) scale(1)',
      fontSize: '24px',
      fontWeight: 'bold'
    },
    dropdownLabelShrink: {
      transform: 'translate(0, -20.00px)',
      fontSize: '14px',
      fontWeight: 'bold',
      height: '44px',
      transformOrigin: 'top left',
      color: theme.colors.primary,
    },
    dropdownLabelFocused: {
      color: theme.colors.primary,
    },
    menuItem: {
      '&:hover': {
        backgroundColor: alpha(theme.colors.primary, 0.18)
      },
    },
    menuItemSelected: {
      '&&': {
        backgroundColor: alpha(theme.colors.primary, 0.28),
        '&:hover': {
          backgroundColor: alpha(theme.colors.primary, 0.28)
        }
      }
    }
  }));

  const classes = useStyles();

  if (Array.isArray(valueList)) {

    return (
      <FormControl classes={{ root: classes.formControl }}>
        {!!label && <InputLabel classes={{ root: classes.dropdownLabel, shrink: classes.dropdownLabelShrink, focused: classes.dropdownLabelFocused }}>{label}</InputLabel>}
        <Select
          classes={{ root: classes.dropdown }}
          onChange={onChange}
          value={value}
        >
          {valueList.map((item, i) => {

            return (
              <MenuItem classes={{ root: classes.menuItem, selected: classes.menuItemSelected }} key={label + item + i} value={item}>{item}</MenuItem>
            );
          })}
        </Select>
      </FormControl>
    )

  } else {
    // the list is an object
    // where the key is the value
    // and the value attached to the key is the display name
    const valueListKeys = Object.keys(valueList);

    return (
      <FormControl classes={{ root: classes.formControl }}>
        {!!label && <InputLabel classes={{ root: classes.dropdownLabel, shrink: classes.dropdownLabelShrink, focused: classes.dropdownLabelFocused }}>{label}</InputLabel>}
        <Select
          classes={{ root: classes.dropdown }}
          onChange={onChange}
          value={value}
        >
          {valueListKeys.map((item, i) => {

            return (
              <MenuItem classes={{ root: classes.menuItem, selected: classes.menuItemSelected }} key={label + item + i} value={item}>{valueList[item]}</MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }
}

export default Dropdown;
