import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';
import { UseIcon, Tooltip } from '../..';
import { Colors } from '@domain/theming';
import { isMobileOnly } from 'react-device-detect';
import { useScaling } from '@domain/hooks';
import Loader from '../../Loader';

const squareSize = 48;
const squareSizeSmall = 40;

const StyledLi = styled.li`
  position: relative;
  z-index: 2;
  .image-container {
    position: relative;
    height: ${props => props.$scaling.scaleUpTo4K(squareSize)}px;
    width: ${props => props.$scaling.scaleUpTo4K(squareSize)}px;
    background: transparent;
    border-radius: 8px;
    display: block;
    cursor: pointer;
  }
  .image-container--loading {
    cursor: wait;
  }
  .image__viewer__img {
    height: ${props => props.$scaling.scaleUpTo4K(squareSize)}px;
    width: ${props => props.$scaling.scaleUpTo4K(squareSize)}px;
    border-radius: 5px;
    object-fit: cover;
    border: 1px solid ${Colors.BLACK};
  }
  .image__viewer__img--extra {
    filter: brightness(0);
  }
  .image__viewer__img--highlighted:not(.extra__images__number) {
    z-index: 2;
  }
  .extra__images__number {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${Colors.WHITE};
    font-size: ${props => !isMobileOnly ? props.$scaling.textSize(12) : props.$scaling.textSizeMobile(16, props.vwWdith)}px;
    line-height: ${props => !isMobileOnly ? props.$scaling.textSize(20) : props.$scaling.textSizeMobile(20, props.vwWdith)}px;
    width: 100%;
    height: 100%;
  }
  .image-file-name {
    display: flex;
  }
  .filename-title {
    padding-left: 20px;
    cursor: pointer;
  }
  .image-checkmark {
    background: ${Colors.BLACK};
    color: ${Colors.WHITE};
    border: 1px solid ${Colors.WHITE};
    border-radius: 50%;
    position: absolute;
    top: -10px;
    right: -10px;
    .screen-ie & {
      border: none;
    }
  }
  .imageviewer__loader {
    transform: scale(0.75) translate(-7px, -10px);
  }
  .image-loading-circle__outer--black {
    background-color: ${Colors.BLACK};
  
    .image-loading-circle__inner--white:before {
      background-color: ${Colors.WHITE};
    }
  }
  &.image__viewer--dulled {
    filter: brightness(0.3);
  }
  &.image__viewer--is-dragging {
    z-index: 1;
  }
  &.image__viewer--noimage {
    .image-container {
      background-image: url('/img/svg/thumbnail-border.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-clip: content-box;
    }
  }
  &.image__viewer--noimage-fileName {
    .image-container {
      background-image: url('/img/svg/thumbnail-checked.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-clip: content-box;
    }
  }

  .theme-custom & {
    .image-checkmark {
      background: ${props => props.theme.colors.primary};
    }
  }

  @media screen and (max-width: 360px) {
    .image-container {
      height: ${squareSizeSmall}px;
      width: ${squareSizeSmall}px;
    }
    .image__viewer__img {
      height: ${squareSizeSmall}px;
      width: ${squareSizeSmall}px;
    }
  }
`;

// TODO: Improve alt text
function SingleImageViewer({
  src,
  onClick,
  selected,
  clickDelete,
  clickView,
  dulled,
  loading,
  htmlFor,
  isDragging,
  fileName
}) {
  const scaling = useScaling();
  const imageViewerClassNames = classNames(
    'image__viewer',
    {
      'image__viewer--image': src,
      'image__viewer--noimage-fileName': src && src.includes('undefined'),
      'image__viewer--noimage': !src || loading,
    },
    { 'image__viewer--selected': selected },
    { 'image__viewer--dulled': dulled && !selected },
    {
      'image__viewer--is-dragging': isDragging
    }
  );

  const imageClassNames = classNames(
    'image__viewer__img',
    {
      'image__viewer__img--highlighted': selected
    }
  );

  const imageContainerClassNames = classNames('image-container', {
    'image-container--loading': loading
  });

  const labelContents = () => {
    if (loading) {
      return <Loader size="small" />
    }

    if (src) {
      return (
        <React.Fragment>
          <div className="">
            <img
              onClick={event => {
                event.stopPropagation();
                onClick(event);
              }}
              className={imageClassNames}
              src={src}
              alt="Gebruikers foto"
            />
            {selected && (
              <Tooltip clickDelete={clickDelete} clickView={clickView} />
            )}
          </div>

          {
            src && (
              <UseIcon
                name="checkmark"
                className="image-checkmark"
                width="19"
                height="19"
              />
            )
          }
        </React.Fragment>
      );
    }
  };

  if (src && src.includes('undefined')) {
    return (
      <StyledLi className={imageViewerClassNames} $scaling={scaling}>
        <div className="image-file-name">
          <div>
            <label className={imageContainerClassNames} htmlFor={htmlFor}
              onClick={event => {
                event.stopPropagation();
                onClick(event);
              }}>
            </label>
          </div>
          <div className="filename-title">
            <p onClick={event => {
              event.stopPropagation();
              onClick(event);
            }}> {fileName} </p>
            {selected && (
              <Tooltip clickDelete={clickDelete} />
            )}
          </div>
        </div>
      </StyledLi>
    );
  }

  return (
    <StyledLi className={imageViewerClassNames} $scaling={scaling}>
      <label className={imageContainerClassNames} htmlFor={htmlFor}>
        {labelContents()}
      </label>
    </StyledLi>
  );
}

SingleImageViewer.propTypes = {
  src: PropTypes.string,
  extraImages: PropTypes.number
};

export default SingleImageViewer;
