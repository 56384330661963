import {
  INTAKE_IMAGES_FETCHED,
  INTAKE_IMAGES_UPLOADED,
  INTAKE_IMAGE_DELETED
} from '@domain/action-types';
import { addMetaToImage } from '@domain/helpers';

const intakeImages = (state = null, { type, intakeImages, cpID }) => {
  switch (type) {
    case INTAKE_IMAGES_FETCHED:
      return intakeImages ? intakeImages.map(addMetaToImage) : [];
    case INTAKE_IMAGES_UPLOADED:
      return [...state, ...intakeImages.map(addMetaToImage)];
    case INTAKE_IMAGE_DELETED:
      return state.filter(intakeImage => intakeImage.cpID !== cpID);
    default:
      return state;
  }
};

export default intakeImages;
