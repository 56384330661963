import React, { useEffect, useMemo, useContext } from 'react';
import { useSelector } from 'react-redux';

import { validationSchema, validationSchemaNotRequired } from './validation-schema';
import { ThemeContext } from 'styled-components';

// Domain imports.
import { Loader } from '@domain/components';
import { FilesUploadPage } from '@domain/pages'
import { useSaveIncidentFactory, } from '@domain/hooks';
import { getPrevNextPagePath, browserStorage, chooseLogoSize, generatePageContent, checkIsFeedback } from '@domain/helpers';
import { detectContentType } from '@domain/content';
import { useQuestionnaire } from '@domain/providers';
function PoliceReport({ history, match }) {


  const insurer = useSelector(state => state.insurer)
  const incident = useSelector(state => state.incident);
  const files = useSelector(state => state.files)
  const officialReports = files.officialReports

  const { questionnaire } = useQuestionnaire()

  const themeContext = useContext(ThemeContext);
  const currentPath = match.path;
  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined

  const saveIncident = useSaveIncidentFactory(incident, saveBehavior);

  useEffect(() => {
    if (!!questionnaire && questionnaire.length) {
      const progress = questionnaire.find(page => page.path === currentPath).name
      browserStorage.setProgress(progress);
    }
  }, [currentPath, questionnaire])

  const faqs = useMemo(
    () => [
      {
        question: 'Waarom moet ik het proces verbaal delen?',
        answer: 'Deze informatie helpt ons met het eventuele verhalen van uw schade. Indien nodig nemen wij ook contact op met de politie voor extra informatie.'
      }
    ], []);

  if (!officialReports || !insurer || !incident || !questionnaire) {
    return <Loader />;
  }

  const isFeedback = checkIsFeedback(incident)
  const isPageBeforeSuccess = questionnaire[questionnaire.length - 2].path === currentPath

  const pagePath = getPrevNextPagePath(
    history,
    questionnaire,
    incident,
    currentPath,
    files,
    isFeedback
  );
  const nextPage = pagePath.nextPage !== currentPath
    ? pagePath.nextPage
    : undefined;

  function feedbackCause() {
    const page = questionnaire.find(page => page.name === 'police_report')
    const feedbacktext = page.feedbacktext ? decodeURIComponent(page.feedbacktext) : null
    return feedbacktext
  }

  const contentType = detectContentType(insurer)
  const pageName = questionnaire.find(page => page.path === currentPath).name
  const content = generatePageContent(contentType, insurer, incident, false, false, pageName)

  const legend = content["over-title-text"]
  const heading = content["title"]
  const firstParagraph = content["intro-text"]
  const secondParagraph = content["second-text"]
  const customHelpLink = content["custom-help-link"]
  const helpLinkText = content["help-link-text"]
  const useCustomLogo = content["use-custom-logo"]
  const firstParagraphRequired = content["intro-text-required"]
  const secondParagraphRequired = content["second-text-required"]
  const validationText = content["validation-text"]

  const validationSchemaToBeUsed = () => {
    if (page.required === false) {
      return validationSchemaNotRequired
    }
    return validationSchema(validationText)
  }
  const middleLogo = {
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }

  return (
    <FilesUploadPage
      customMiddleLogo={useCustomLogo}
      middleLogo={middleLogo}
      history={history}
      nextPage={nextPage}
      prevPage={pagePath.prevPage}
      questionnaire={questionnaire}
      saveIncident={saveIncident}
      buttonText="Volgende"
      currentPath={currentPath}
      filesSetName="officialReports"
      filesSet={officialReports}
      feedbackCause={feedbackCause()}
      validationSchema={validationSchemaToBeUsed()}
      faqs={faqs}
      legend={legend}
      heading={heading}
      firstParagraph={page && page.required === true ? firstParagraphRequired : firstParagraph}
      secondParagraph={page && page.required === true ? secondParagraphRequired : secondParagraph}
      files={files}
      incident={incident}
      insurer={insurer}
      isFeedback={isFeedback}
      isPageBeforeSuccess={isPageBeforeSuccess}
      showDesktopParagraphOnMobile={true}
      customHelpLink={customHelpLink}
      helpLinkText={helpLinkText}
    />
  );
}

export default PoliceReport
