import { endpoints, proccessType } from '@domain/constants';
import { parseFetchResponseToJson, camelCaseToPascalCase } from '@domain/helpers';

const licensePlateCheckEndpoint = endpoints.licensePlateCheck;

function check(pid, licensePlate, fid, damageDate, rid, insurer, uuid) {
  // Will not return a full incident but the following:
  // pseudoIncident = {
  //   licensePlate,
  //   errorCode,
  //   VehicleIdentificationID,
  // }
  //
  // VehicleIdentificationID might be empty
  const PID = pid ? { PID: pid } : {}
  const FID = fid ? { FID: fid } : {}
  const RID = rid ? { RID: rid } : {}

  const headers = !!uuid ? camelCaseToPascalCase({ 'Content-Type': 'application/json', UUID: uuid }) : camelCaseToPascalCase({ 'Content-Type': 'application/json', ...PID, ...FID, ...RID })

  const body = insurer.objectType === proccessType.DI
    ? JSON.stringify(camelCaseToPascalCase({ licensePlate }))
    : JSON.stringify(camelCaseToPascalCase({ licensePlate, damageDate }))

  const config = {
    method: 'POST',
    headers: headers,
    body: body,
  }
  return fetch(licensePlateCheckEndpoint, config).then((response) =>
    parseFetchResponseToJson(response, { endpoint: licensePlateCheckEndpoint, body }));
}


function getLicense(licensePlateUUID) {

  const licensePlateUUIDEndpoint = `${endpoints.licensePlateCheck}/${licensePlateUUID}`
  const headers = {
    'Content-Type': 'application/json',
    'Pragma': 'no-cache',
  }

  const config = {
    method: 'GET',
    headers: headers
  };
  return fetch(`${licensePlateUUIDEndpoint}`, config).then(
    (response) => parseFetchResponseToJson(response, { endpoint: licensePlateUUIDEndpoint })
  );
}

export default { check, getLicense };
