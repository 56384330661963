import styled from 'styled-components';
import classNames from 'classnames';

const ContainerSP = styled.div.attrs(({ className }) => ({
  className: classNames('container'),
}))`
  background: ${props => props.background};
  box-sizing: ${props => props.boxSizing};
  display: ${props => props.display || 'flex'};
  position: ${props => props.position};
  flex-direction: ${props => props.flexDirection || 'row'};
  flex: ${props =>
    `${props.flexGrow || 1} ${props.flexShrink || 0} ${props.flexBasis ||
    'auto'}`};
  flex-wrap: ${props => props.flexWrap};
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
  min-width: ${props => !!props.minWidth && typeof props.minWidth === 'number' ? props.$scaling.scaleUpTo4K(props.minWidth) : props.minWidth || 0};
  min-height: 0;
  width: ${props => !!props.width && typeof props.width === 'number' ? props.$scaling.scaleUpTo4K(props.width) : props.width};
  height: ${props => !!props.height && typeof props.width === 'number' ? props.$scaling.scaleUpTo4K(props.height) : props.height};
  max-width: ${props => !!props.maxWidth && typeof props.width === 'number' ? props.$scaling.scaleUpTo4K(props.maxWidth) : props.maxWidth};
  max-height: ${props => !!props.maxHeight && typeof props.width === 'number' ? props.$scaling.scaleUpTo4K(props.maxHeight) : props.maxHeight};
  margin: ${props => props.margin};
  margin-top: ${props => props.marginTop};
  margin-right: ${props => props.marginRight};
  margin-bottom: ${props => props.marginBottom};
  margin-left: ${props => props.marginLeft};
  padding: ${props => props.padding};
  padding-top: ${props => props.paddingTop};
  padding-right: ${props => props.paddingRight};
  padding-bottom: ${props => props.paddingBottom};
  padding-left: ${props => props.paddingLeft};
`;

export default ContainerSP;
