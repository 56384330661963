import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  HTTP_ERROR_ENCOUNTERED,
  HTTP_ERROR_CLEARED
} from '@domain/action-types';

const useDispatchError = () => {
  const dispatch = useDispatch();

  const dispatchNewError = useCallback(
    error => dispatch({ type: HTTP_ERROR_ENCOUNTERED, error }),
    [dispatch]
  );

  const dispatchErrorCleared = () =>
    dispatch({
      type: HTTP_ERROR_CLEARED
    });

  return { dispatchErrorCleared, dispatchNewError };
};

export default useDispatchError;
