import moment from 'moment'

function generateEventForAddToCalendar(incident, finalBodyshop, bodyshopName) {
  let checkinEvent = []
  let checkoutEvent = []
  if (incident && incident.intakeDate) {
    const checkinDate = moment.utc(incident.intakeDate).locale('nl')
    const now = moment().format("YYYY-MM-DDTHH:mm:ss.SSSSZ")
    const formattednow = moment().utc(now).locale('nl')

    if (checkinDate.diff(formattednow, 'minutes') > 0) {
      if (incident.carIntakeMethod === "Bodyshop_pickup") {
        const calendarEvent = {
          startDatetime: incident.intakeDate,
          endDatetime: incident.intakeDate_EndWindow,
          address: incident.checkInAddress,
          title: ` Afspraak: ${bodyshopName} komt de auto halen voor reparatie`,
          description: `${bodyshopName} komt uw auto ophalen voor reparatie.\\nZorg dat u uw rijbewijs, kentekenbewijs en groene kaart bij de hand heeft.\\n\\nVoor vragen kunt u contact opnemen met ${bodyshopName}.\\n\\nContactgegevens ${bodyshopName}:\\n\\n${finalBodyshop.street} ${finalBodyshop.houseNumber}\\n${finalBodyshop.city} ${finalBodyshop.zipcode || finalBodyshop.zipCode}\\n\\nTelefoonnummer\\n${finalBodyshop.phoneNumber}`,
          sequence: incident.checkInSequence || 0,
          stamp: incident.checkInScheduledDate,
          uid: incident.checkInUUID,
          organizer: bodyshopName,
          email: finalBodyshop.emailAddress
        }
        checkinEvent.push(calendarEvent)
      } else {
        const calendarEvent = {
          startDatetime: incident.intakeDate,
          endDatetime: incident.intakeDate_EndWindow,
          address: finalBodyshop,
          title: `Afspraak: Auto naar ${bodyshopName} brengen voor reparatie`,
          description: `Uw auto staat ingepland voor herstel bij ${bodyshopName}.\\nZorg dat u uw rijbewijs, kentekenbewijs en groene kaart bij de hand heeft.\\n\\nVoor vragen kunt u contact opnemen met ${bodyshopName}.\\n\\nContactgegevens ${bodyshopName}:\\n\\n${finalBodyshop.street} ${finalBodyshop.houseNumber}\\n${finalBodyshop.city} ${finalBodyshop.zipcode || finalBodyshop.zipCode}\\n\\nTelefoonnummer\\n${finalBodyshop.phoneNumber}`,
          sequence: incident.checkInSequence || 0,
          stamp: incident.checkInScheduledDate,
          uid: incident.checkInUUID,
          organizer: bodyshopName,
          email: finalBodyshop.emailAddress
        }
        checkinEvent.push(calendarEvent)
      }
    }
  }
  if (incident && incident.outtakeDate) {
    const checkoutDate = moment.utc(incident.outtakeDate).locale('nl')
    const now = moment().format("YYYY-MM-DDTHH:mm:ss.SSSSZ")
    const formattednow = moment().utc(now).locale('nl')

    if (checkoutDate.diff(formattednow, 'minutes') > 0) {
      if (incident.carOuttakeMethod === "bodyshop_dropoff") {
        const calendarEvent = {
          startDatetime: incident.outtakeDate,
          endDatetime: incident.outtakeDate_EndWindow,
          address: incident.checkOutAddress,
          title: `Afspraak: Uw gerepareerde auto wordt gebracht door ${bodyshopName}`,
          description: `Uw auto is hersteld door ${bodyshopName}.\\n\\nVoor vragen kunt u contact opnemen met ${bodyshopName}.\\n\\nContactgegevens ${bodyshopName}:\\n\\n${finalBodyshop.street} ${finalBodyshop.houseNumber}\\n${finalBodyshop.city} ${finalBodyshop.zipcode || finalBodyshop.zipCode}\\n\\nTelefoonnummer\\n${finalBodyshop.phoneNumber}`,
          sequence: incident.checkOutSequence || 0,
          stamp: incident.checkOutScheduledDate,
          uid: incident.checkOutUUID,
          organizer: bodyshopName,
          email: finalBodyshop.emailAddress
        }
        checkoutEvent.push(calendarEvent)
      } else {
        const calendarEvent = {
          startDatetime: incident.outtakeDate,
          endDatetime: incident.outtakeDate_EndWindow,
          address: finalBodyshop,
          title: `Afspraak: Gerepareerde auto ophalen bij ${bodyshopName}`,
          description: `Uw auto is hersteld door ${bodyshopName}.\\n\\nVoor vragen kunt u contact opnemen met ${bodyshopName}\\n\\nContactgegevens ${bodyshopName}:\\n\\n${finalBodyshop.street} ${finalBodyshop.houseNumber}\\n${finalBodyshop.city} ${finalBodyshop.zipcode || finalBodyshop.zipCode}\\n\\nTelefoonnummer\\n${finalBodyshop.phoneNumber}`,
          sequence: incident.checkOutSequence || 0,
          stamp: incident.checkOutScheduledDate,
          uid: incident.checkOutUUID,
          organizer: bodyshopName,
          email: finalBodyshop.emailAddress
        }
        checkoutEvent.push(calendarEvent)
      }
    }
  }
  const finalCheckinEvent = checkinEvent.length > 0 ? checkinEvent : null
  const finalCheckoutEvent = checkoutEvent.length > 0 ? checkoutEvent : null
  return { checkinEvent: finalCheckinEvent, checkoutEvent: finalCheckoutEvent }
}

export default generateEventForAddToCalendar
