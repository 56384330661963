import { useState, useRef, useEffect } from 'react';

function useDragAndDrop(handleDroppedFiles) {
  const [isDragging, setIsDragging] = useState(false);
  const cachedTarget = useRef(null);
  const isFileFromOS = useRef(true);
  const hasDraggedFileFromBrowserOutsideOfWindow = useRef(false);

  useEffect(() => {
    function handleDragEnter(event) {
      cachedTarget.current = event.target;
    }

    function handleDragStart() {
      isFileFromOS.current = false;
      hasDraggedFileFromBrowserOutsideOfWindow.current = false;
    }

    function handleDragOver(event) {
      event.preventDefault();

      if (
        !(
          hasDraggedFileFromBrowserOutsideOfWindow.current &&
          document.hasFocus()
        ) &&
        isFileFromOS.current
      ) {
        hasDraggedFileFromBrowserOutsideOfWindow.current = false;
      }

      if (!isDragging) setIsDragging(true);
    }

    function handleDragLeave(event) {
      if (event.target === cachedTarget.current) {
        if (
          isFileFromOS.current &&
          !hasDraggedFileFromBrowserOutsideOfWindow.current
        ) {
          if (isDragging) setIsDragging(false);
        } else {
          hasDraggedFileFromBrowserOutsideOfWindow.current = true;
          isFileFromOS.current = true;
        }
      }
    }

    function handleDrop(event) {
      event.preventDefault();
      isFileFromOS.current = true;
      hasDraggedFileFromBrowserOutsideOfWindow.current = false;
      handleDroppedFiles(event);

      if (isDragging) setIsDragging(false);
    }

    window.addEventListener('dragenter', handleDragEnter);
    window.addEventListener('dragstart', handleDragStart);
    window.addEventListener('dragover', handleDragOver);
    window.addEventListener('dragleave', handleDragLeave);
    window.addEventListener('drop', handleDrop);

    return () => {
      window.removeEventListener('dragenter', handleDragEnter);
      window.removeEventListener('dragstart', handleDragStart);
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('dragleave', handleDragLeave);
      window.removeEventListener('drop', handleDrop);
    };
  }, [isDragging, handleDroppedFiles]);

  return isDragging;
}

export default useDragAndDrop;
