import { UPDATE_CONSENTCODE } from '@domain/action-types';
import { browserStorage } from '@domain/helpers';

const initialState = browserStorage.getConsentCode() || null;

const consentCode = (state = initialState, { type, consentCode }) => {
  switch (type) {
    case UPDATE_CONSENTCODE:
      return consentCode;
    default:
      return state;
  }
};

export default consentCode;
