const checkQuestionnaire = (progress, questionnaire, incident, files, isFeedback = false) => {

  const loopLength = Math.min(progress, questionnaire.length);
  for (let i = 0; i < loopLength; i++) {
    const page = questionnaire[i];
    const { check, path, feedbackCheck } = page;
    const pageFilled = (progress > 100
      ? true :
      isFeedback
        ? feedbackCheck(incident, files)
        : check(incident, files));
    if (!pageFilled) {
      return path;
    }
  }

  if (progress > questionnaire.length - 1) {
    const lastPage = questionnaire[questionnaire.length - 1];
    const { path } = lastPage;
    return path;
  }
  const nextPage = questionnaire[progress];
  const { path } = nextPage;
  return path;
};

const checkProgress = (progress, questionnaire, incident, images) => {
  const loopLength = Math.min(progress, questionnaire.length);

  for (let i = 0; i < loopLength; i++) {
    const page = questionnaire[i];
    const { check } = page;
    const pageFilled = check(incident, images);
    if (!pageFilled) {
      return i;
    }
    else {
      return 0
    }
  }
}

export { checkProgress, checkQuestionnaire };
