const sanitisePhoneNumber = phoneNumber => {
  const nonNumericOrPlus = /[^0-9+]+/gi;

  // need a function to do reverse lookup as it isn't
  // compatible with IE
  // const plusNotAtStart = /(?<!^)\++/gi;
  
  const newValue = phoneNumber
    .replace('(0)', '')
    .replace(nonNumericOrPlus, '');
    // .replace(plusNotAtStart, '');

  return newValue;
};

export default sanitisePhoneNumber;
