import styled from 'styled-components';
import { Colors, Shadows } from '@domain/theming';
import Color from 'color';

const ButtonSP = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  max-width: 380px;
  padding: 12px 24px;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  background: ${props => props.variant === 'outline' ? 'transparent' : props.isThemed ? props.theme.colors.primary : Colors.BLACK};
  color: ${props => props.variant === 'outline' ? !!props.isThemed ? props.theme.colors.primary : Colors.BLACK : props.isThemed ? props.theme.colors.contrastText : Colors.WHITE};
  border: 1px solid ${props => !!props.isThemed ? props.theme.colors.primary : Colors.BLACK};
  border-radius: 16px;
  cursor: pointer;
  transition: 160ms cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: ${props => props.variant !== 'outline' && Shadows.SHADOW6};
  @media (hover: hover) {
    &:hover {
      transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
      box-shadow: ${props => props.variant !== 'outline' && Shadows.SHADOW3};
      background: ${props => props.variant !== 'outline' ? props.isThemed ? props.theme.colors.primary : Color(Colors.BLACK).rgb().alpha(0.7).string() : Color(Colors.BLACK).rgb().alpha(0.08).string()}
    }
  }
  &:focus {
    transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: ${props => props.variant !== 'outline' && Shadows.SHADOW3};
  }
  &:active {
    transform: scale(0.98);
    transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: ${props => props.variant !== 'outline' && Shadows.SHADOW1};
  }
  &[disabled] {
    box-shadow: none;
  }
  &.white {
    box-shadow: none;
  }
  .card & {
    margin: auto auto 0;
  }
`;

export default ButtonSP;
