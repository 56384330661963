import {
  FILES_FETCHED,
  GREENCARD_DELETED,
  GREENCARD_UPLOADED,
  IMAGES_UPLOADED,
  IMAGE_DELETED,
  OFFERS_UPLOADED,
  OFFER_DELETED,
  OTHER_FILES_UPLOADED,
  OTHER_FILE_DELETED,
  REGISTRATION_CERTIFICATE_DELETED,
  REGISTRATION_CERTIFICATE_UPLOADED,
  SAF_DOCUMENTS_UPLOADED,
  SAF_DOCUMENT_DELETED,
  OFFICIAL_REPORT_DELETED,
  OFFICIAL_REPORT_UPLOADED,

} from '@domain/action-types';

import { addMetaToImage } from '@domain/helpers';

const initialState = { damageImages: [], safDocuments: [], otherFiles: [], offers: [], greenCard: [], registrationCertificate: [], officialReports: [] }
const files = (state = initialState, { type, files, cpID }) => {
  switch (type) {
    case FILES_FETCHED:
      const safDocuments = files.safDocuments.map(addMetaToImage)
      const otherFiles = files.otherFiles.map(addMetaToImage)
      const damageImages = files.damageImages.map(addMetaToImage)
      const offers = files.offers.map(addMetaToImage)
      const greenCard = files.greenCard.map(addMetaToImage)
      const registrationCertificate = files.registrationCertificate.map(addMetaToImage)
      const officialReports = files.officialReports.map(addMetaToImage)
      return { ...state, safDocuments, otherFiles, damageImages, offers, greenCard, registrationCertificate, officialReports };
    case SAF_DOCUMENTS_UPLOADED:
      return { ...state, safDocuments: [...state.safDocuments, ...files.map(addMetaToImage)] };
    case SAF_DOCUMENT_DELETED:
      return { ...state, safDocuments: state.safDocuments.filter(file => file.cpID !== cpID) };
    case OTHER_FILES_UPLOADED:
      return { ...state, otherFiles: [...state.otherFiles, ...files.map(addMetaToImage)] };
    case OTHER_FILE_DELETED:
      return { ...state, otherFiles: state.otherFiles.filter(file => file.cpID !== cpID) };
    case OFFERS_UPLOADED:
      return { ...state, offers: [...state.offers, ...files.map(addMetaToImage)] };
    case OFFER_DELETED:
      return { ...state, offers: state.offers.filter(file => file.cpID !== cpID) };
    case IMAGES_UPLOADED:
      return { ...state, damageImages: [...state.damageImages, ...files.map(addMetaToImage)] };
    case IMAGE_DELETED:
      return { ...state, damageImages: state.damageImages.filter(file => file.cpID !== cpID) };
    case GREENCARD_UPLOADED:
      return { ...state, greenCard: [...files.map(addMetaToImage)] };
    case GREENCARD_DELETED:
      return { ...state, greenCard: state.greenCard.filter(file => file.cpID !== cpID) };
    case REGISTRATION_CERTIFICATE_UPLOADED:
      return { ...state, registrationCertificate: [...files.map(addMetaToImage)] };
    case REGISTRATION_CERTIFICATE_DELETED:
      return { ...state, registrationCertificate: state.registrationCertificate.filter(file => file.cpID !== cpID) };
    case OFFICIAL_REPORT_UPLOADED:
      return { ...state, officialReports: [...state.officialReports, ...files.map(addMetaToImage)] };
    case OFFICIAL_REPORT_DELETED:
      return { ...state, officialReports: state.officialReports.filter(file => file.cpID !== cpID) };
    default:
      return state;
  }
};

export default files;
