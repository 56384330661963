// If you have not done so already, create a .env file in the root folder with the url you want to use.
// content should look like REACT_APP_ENDPOINTS_URL=https://www.example.com/api
// ensure your url does not end with a /

/* 
  If the variable starts with REACT_APP_ then React will detect and use it
*/

const baseURL = !!process.env.REACT_APP_ENDPOINTS_URL_V2 ? process.env.REACT_APP_ENDPOINTS_URL_V2 : process.env.REACT_APP_ENDPOINTS_URL || '';

const endpoints = {
  baseURL,
  bodyshop: baseURL + '/bodyshop',
  bodyshopIncident: baseURL + '/incidents/bsinew',
  bodyshopListsEM: baseURL + '/incidents/bodyshoplist',
  bodyshopListsSG: baseURL + '/incidents/bedrijven',
  bodyshopListsIntakePlus: baseURL + '/incidents/intakeplus/bodyshoplist',
  callback: baseURL + '/incidents/callback',
  files: baseURL + '/incidents/files',
  images: baseURL + '/incidents/files',
  incidents: baseURL + '/incidents',
  insurer: baseURL + '/insurer',
  insurerV2: baseURL + '/branding',
  licensePlateCheck: baseURL + '/incidents/licenseplate',
  login: baseURL + '/incidents/login',
  newIncident: baseURL + '/incidents/new',
  pdfDossier: baseURL + '/incidents/dossier',
};

export default endpoints;
