import { object, string } from 'yup';

export default object({
  customerFirstName: string()
    .required()
    .label('Voornaam'),
  customerLastName: string()
    .required()
    .label('Achternaam'),
});
