import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';
import classNames from 'classnames'
import { Colors } from '@domain/theming';
import { useScaling } from '@domain/hooks';

const StyledGrid = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  &.grid-container__flex-column {
    flex-direction: column;
  }
  &.grid-container__main {
    margin: 50px 30px 30px;
    flex: 1 0 auto;
    width: auto;
    transition: border-radius 0.3s ease-in-out;
    .theme-dark & {
      background-color: ${Colors.BLACK};
    }
    .video & {
      background-color: transparent;
    }
  }

  .screen-desktop & {
    &.grid-container__main {
      flex-direction: row;
      height: auto;
      display: flex;
      align-items: center;
      padding: 0 ${props => props.$scaling.scaleUpTo4K(120)}px ${props => props.$scaling.scaleUpTo4K(82)}px;
      margin: 0;
    } & {
       &.grid-container__flex-column {
        flex-direction: column;
       }
    }
  }

  @media screen and (max-width: 1263px) {
    .screen-desktop & {
      &.grid-container__main {
        padding: 0 60px 82px;
      }
    }
  }

  @media screen and (max-width: 1028px) {
    .screen-desktop & {
      &.grid-container__main {
        flex-direction: column;
        align-items: stretch;
        padding: 30px 40px 40px;
      }
    }
  }
`;

function GridContainer({ children, direction, main, className }) {
  const classes = classNames(
    className,
    'grid-container',
    {
      'grid-container__flex-column': direction === 'column',
      'grid-container__main': main,
    },
  )
  const scaling = useScaling();

  return <StyledGrid className={classes} $scaling={scaling}>{React.Children.toArray(children)}</StyledGrid>
}

GridContainer.defaultProps = {
  direction: 'row',
}

GridContainer.propTypes = {
  // We want to limit it to an array of GridColumn type. We will need flow for this.
  children: PropTypes.any.isRequired,
  direction: PropTypes.oneOf([
    'row',
    'column',
  ]),
}

export default GridContainer
