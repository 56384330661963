import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  Heading,
  UseIcon,
  Button,
  Main,
  HeaderGroup,
  CompanyLogo,
  Paragraph,
  NavBar,
} from '@domain/components';
import { useThemes, useScaling } from '@domain/hooks';
import { isMobileOnly } from 'react-device-detect';
import { companyNames, errorConstants, proccessType } from '@domain/constants';
import { chooseLogoToBeDisplayed, browserStorage } from '@domain/helpers';
import { Colors } from '@domain/theming';

const StyledMain = styled(Main)`
  .theme-custom & {
    .error-page-icon {
      background: ${props => props.theme.colors.primary};
      color: ${props => Colors.WHITE};
      border-radius: 12px;
    }
  }
  .theme-dark & {
    .error-page-icon {
      background: ${Colors.WHITE};
      color: ${Colors.BLACK};
      border-radius: 12px;
    }
  }
  .error__page__h1 {
    margin: 0 0 40px 0;
  }
  .error__page__ul {
    margin: 70px 0 0 0;
  }
  .error__page__li {
    height: 32px;
    padding-bottom: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .error__page__content {
    flex: 0 0 0;
    padding: 78px 32px;
  }
  .error__page__button-container {
    align-self: stretch;
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    justify-content: flex-end;
    align-items: stretch;
    padding: 32px;
  }
  .screen-desktop & {
    .error__page__content {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      margin: auto 195px 0px 120px;
      flex: 0 0 0;
    }
    .error__page__button-container {
      margin: auto 195px 40px 120px;
      flex: 0 0 0;
    }
    .error__page__headers {
      width: 480px;
    }
    .error__page__logo__container {
      margin: 40px 0 0 40px;
    }
  }
`;

const StyledLink = styled.a`
  font-size: ${props => !isMobileOnly ? props.$scaling.textSize(14) : props.$scaling.textSizeMobile(14, props.vwWdith)}px;
  line-height: ${props => !isMobileOnly ? props.$scaling.textSize(18) : props.$scaling.textSizeMobile(18, props.vwWdith)}px;
  text-decoration: none;
  display: inline-block;
  padding-left: 20px;
  .theme-custom & {
    color: ${Colors.BLACK}; 
  }
  .theme-dark & {
    color: ${Colors.WHITE}; 
  }
`;


function ErrorPage({
  clearError = () => { },
  error = {},
  history = {},
  logOut = () => { },
}) {
  const insurer = useSelector(state => state.insurer);
  const incident = useSelector(state => state.incident);
  const uuid = useSelector(state => state.uuid);
  const pid = useSelector(state => state.pid)
  const fid = useSelector(state => state.fid)

  const isThemed = !!insurer && insurer.brand !== companyNames.VIP && insurer.objectType !== proccessType.DI;
  const themes = useMemo(() => isThemed ? ['theme-custom'] : ['theme-dark'], [isThemed]);

  const [loggedError, setLoggedError] = useState(false)
  useThemes(themes);
  // code is standard HTTP error code (e.g. 401 etc)
  const { code, errorCode } = error;
  const { httpErrorMessages, httpErrorButtonLabel } = errorConstants;
  const scaling = useScaling();

  function onButtonClick() {
    if (code === 401) {
      browserStorage.removeAuthenticationToken()
      logOut();
    }
    if (clearError) {
      clearError();
    }
  }

  const errorLogMessage = `error encoutered for case with id: ${!!uuid ? uuid : 'empty'}, lp: ${!!incident && !!incident.licensePlate ? incident.licensePlate : 'unknown'}. PID: ${pid ? pid : 'no pid'} FID: ${fid ? fid : 'no fid'} HTTP status code: ${code ? code : 'no status code'} Custom error code: ${errorCode ? errorCode : 'no custom error code'}`

  useEffect(() => {
    if (loggedError === false) {
      console.error(errorLogMessage)
      setLoggedError(true)
    }
  }, [errorLogMessage, loggedError, setLoggedError])


  const isVIP = !!insurer && insurer.objectType === proccessType.Casco && (insurer.brand === companyNames.VIP || !insurer.brand);
  const isDI = !!insurer && insurer.objectType === proccessType.DI

  const companyLogo = {
    ...chooseLogoToBeDisplayed(!!insurer ? insurer : null),
    className: isVIP ? 'vip-logo' : '',
    height: '60',
    // width: 'auto',
  }
  const errorMessage = (code === 404 && !errorCode) ? httpErrorMessages[code] :
    httpErrorMessages[errorCode] || httpErrorMessages.default;
  const buttonLabel = httpErrorButtonLabel[code] || 'Probeer het nogmaals';
  const title = code ? `HTTP error: ${code}` : '';

  return (
    <React.Fragment>
      <StyledMain>
        {!isMobileOnly && (
          <div>
            <NavBar isVIP={isVIP}>
              <div></div>
              <CompanyLogo {...companyLogo} />
              <div></div>
            </NavBar>
          </div>
        )}
        <div className="error__page__content">
          <HeaderGroup className="error__page__headers">
            <Heading level="1" className="error__page__h1">
              {title}
            </Heading>
            <Paragraph>{errorMessage}</Paragraph>
            {(!isVIP && !isDI && errorMessage === httpErrorMessages.default) && <Paragraph>Neem contact op met uw verzekeraar.</Paragraph>}
          </HeaderGroup>
          {(isVIP || isDI) && <ul className="error__page__ul">
            <li className="error__page__li">
              <UseIcon name="phone" className="error-page-icon" />
              <StyledLink
                className="error-page-anchor"
                href="tel:+31202613719"
                $scaling={scaling}
              >
                +31 (0)20 261 3719
              </StyledLink>
            </li>
            <li className="error__page__li">
              <UseIcon name="mail" className="error-page-icon" />
              <StyledLink
                className="error-page-anchor"
                href="mailto:service@mijnschade.com"
                $scaling={scaling}
              >
                service@mijnschade.com
              </StyledLink>
            </li>
          </ul>}
        </div>

        <div className="error__page__button-container">
          {buttonLabel && (
            <Button
              theme="secondary"
              type="button"
              justify="center"
              onClick={onButtonClick}
            >
              {buttonLabel}
            </Button>
          )}
        </div>
      </StyledMain>
    </React.Fragment>
  );
}

export default ErrorPage;
