import React, { useContext, useEffect, useRef, } from 'react';
import { useSelector } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';

import {
  CompanyLogo,
  Container,
  Heading,
  Loader,
  Main,
  NavBar,
  Paragraph,
  Section,
} from '@domain/components';
import { useSaveIncidentFactory, useScaling } from '@domain/hooks';
import { isMobileOnly } from 'react-device-detect';
import { browserStorage, chooseLogoSize, chooseLogoToBeDisplayed, generatePageContent } from '@domain/helpers';
import { detectContentType } from '@domain/content';
import { useQuestionnaire } from '@domain/providers';

const StyledMain = styled(Main)`
  .section {
    display: flex;
    flex-direction: column;
    padding: 32px 32px 0;
  }

  .screen-desktop & {
    .section {
      flex-direction: row;
      padding: 0 ${props => props.$scaling.scaleUpTo4K(60)}px 0 ${props => props.$scaling.scaleUpTo4K(60)}px;
      align-items: center;
      justify-content: center;
      & .container {
      max-width: 60%;
      }
    }
  }

  @media screen and (max-width: 1028px) {
    .screen-desktop & {
      .section {
        flex-direction: column;
         padding: 32px 32px;
         justify-content: flex-start;
        & .container {
        max-width: 100%;
      }
      }
    }
  }


`;

function EndOfFlow({ match }) {

  const scaling = useScaling();
  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer)
  const hasUpdated = useRef(false);
  const { questionnaire } = useQuestionnaire();

  const themeContext = useContext(ThemeContext);
  const currentPath = match.path;
  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined
  const saveIncident = useSaveIncidentFactory(incident, saveBehavior, 'end_of_flow');


  useEffect(() => {
    if (!hasUpdated.current) {
      const storageList = Object.keys(localStorage);
      browserStorage.clearLocalStorage([...storageList], ['consentCode', 'bannerOpen', 'uuid']);
      hasUpdated.current = true;
      const progressStr = 'end_of_flow'
      saveIncident({ ...incident, portalStatus: 'Done', progressStr });
    }


  }, [incident, saveIncident]);

  if (!questionnaire || !questionnaire.length || !insurer || !incident) {
    return <Loader />;
  }

  const contentType = detectContentType(insurer)
  const pageName = questionnaire.find(page => page.path === currentPath).name
  const content = generatePageContent(contentType, insurer, incident, false, isMobileOnly, pageName, null)

  const title = content["title"]
  const showLeftLogo = content["show-left-logo"]
  const companyMiddleLogo = content["company-middle-logo"]
  const text = content["text"]
  const useCustomLogo = content["use-custom-logo"]
  const secondText = content["second-text"]
  const thirdText = content["third-text"]
  const openingText = content["opening-text"]
  const closingText = content["closing-text"]
  const signature = content["signature"]

  const customLogo = {
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'jouw verzekeraar',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }
  const companyLogo = {
    align: companyMiddleLogo ? 'center' : 'left',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'jouw verzekeraar',
  };

  const middleLogo = {
    ...chooseLogoToBeDisplayed(insurer),
    className: '',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  };

  const chooseLogo = useCustomLogo
    ? <CompanyLogo {...customLogo} />
    : companyMiddleLogo
      ? <CompanyLogo {...companyLogo} />
      : <CompanyLogo {...middleLogo} />

  const main = {
    className: 'main__end',
    $scaling: scaling,
  };

  return (
    <React.Fragment>
      <StyledMain {...main}>
        <NavBar className="navbar-end">
          {showLeftLogo ? <Container className="left-side-logo"><CompanyLogo {...companyLogo} /></Container> : <Container className="empty-div" />}
          {chooseLogo}
          <Container className="empty-div" />
        </NavBar>
        <Section className="section">
          <Container className="container">
            {title && <Heading level="1" >{title}</Heading>}
            {openingText && <Paragraph>{openingText}</Paragraph>}
            {text && <Paragraph>{text}</Paragraph>}
            {secondText && <Paragraph >{secondText}</Paragraph>}
            {thirdText && <Paragraph >{thirdText}</Paragraph>}
            {closingText && <Paragraph>{closingText}</Paragraph>}
            {signature && <Paragraph >{signature}</Paragraph>}
          </Container>
        </Section>
      </StyledMain>
    </React.Fragment>
  );
}

export default EndOfFlow;
