const routes = {
  instructions: '/instructies',
  start: '/welkom',
  chauffeur: '/chauffeur', //if no chauffeur, jump over (maybe invalidaton check in questionnaire)
  overview: '/overzicht',
  uploads: '/fotos-uploaden',
  // damage: '/extra-schades', //cannot be made by chauffeur
  // closeUpUploads: '/extra-fotos-uploaden',
  // signingDigital: '/digitaal-ondertekenen',
  signing: '/ondertekenen',
  // takeIn: '/inneming',
  questions: '/overige-vragen',
  end: '/afgerond'
};

/*
const routesNotOnLocation = {
  instructions: '/instructies',
  start: '/welkom',
  overview: '/overzicht', // w/ corresponding content
  uploads: '/fotos-uploaden',
  signing: '/ondertekenen',
  end: '/afgerond'
};
*/

export default routes;
