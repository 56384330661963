import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Colors } from '@domain/theming';
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { isMobileOnly } from 'react-device-detect';
import { useScaling } from '@domain/hooks';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    fontSize: props => props.dynamicFontSize ? (isMobileOnly
      ? props.$scaling.textSizeMobile(14)
      : props.$scaling.textSize(14)) + 'px'
      : '14'
  }
}))

const StyledToggleButtonGroup = withStyles(() => ({
  root: {
    width: '100%',
    background: Colors.WHITE,
    minHeight: '45%',
    marginTop: '15px',
  },
  grouped: {
    color: Colors.GREY_DARK
  },
}))(ToggleButtonGroup);

const VerticalToggleButtons = ({ value, handleChange, dynamicFontSize, arrayOfButtons }) => {

  const scaling = useScaling();
  const classes = useStyles({ scaling: scaling, dynamicFontSize: dynamicFontSize })

  const buttons = arrayOfButtons.map((button => {
    return (
      <ToggleButton value={button.value} aria-label={button.ariaLabel} className={classes.root} key={button.value}>
        {button.label}
      </ToggleButton>
    )
  }))

  return (
    < StyledToggleButtonGroup value={value} orientation='vertical' aria-label="select-damagetype" exclusive={true} onChange={handleChange}>
      {buttons}
    </ StyledToggleButtonGroup>
  );
}

export default VerticalToggleButtons
