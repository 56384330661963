import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useSaveIncidentFactory } from '@domain/hooks';
import { TextInputsPage } from '@domain/pages';

import { chooseLogoSize, generatePageContent, getPrevNextPagePath } from '@domain/helpers';
import { validationSchema, validationSchemaNotRequired } from './validation-schema';
import { Loader } from '@domain/components';
import { detectContentType } from '@domain/content';
import { useQuestionnaire } from '@domain/providers';
import { ThemeContext } from 'styled-components';


function WitnessName({ history, match }) {

  const { questionnaire } = useQuestionnaire();;
  const inCreation = useSelector(state => state.inCreation);
  const insurer = useSelector(state => state.insurer)
  const incident = useSelector((state) => state.incident);
  const files = useSelector(state => state.files)

  const themeContext = useContext(ThemeContext);
  const currentPath = match.path;
  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined

  const saveIncident = useSaveIncidentFactory(incident, saveBehavior);

  const faqs = useMemo(
    () => [
      {
        question: 'Waarom moet ik de gegevens van de getuige opgeven?',
        answer: 'Deze informatie helpt ons met het eventuele verhalen van uw schade. Indien nodig nemen wij ook contact op met de tegenpartij voor extra informatie.'
      }
    ], []);

  if (!insurer || !questionnaire) {
    return <Loader />;
  }

  const pagePath = getPrevNextPagePath(
    history,
    questionnaire,
    incident,
    currentPath,
    files
  );
  const nextPage = pagePath.nextPage !== currentPath
    ? pagePath.nextPage
    : undefined;

  const contentType = detectContentType(insurer)
  const pageName = page.name
  const content = generatePageContent(contentType, insurer, incident, inCreation, false, pageName)

  const customHelpLink = content["custom-help-link"]
  const helpLinkText = content["help-link-text"]
  const useCustomLogo = content["use-custom-logo"]
  const legend = content["over-title-text"]
  const heading = content["title"]

  const middleLogo = {
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }

  const validationSchemaToBeUsed = () => {
    if (page.required === false) {
      return validationSchemaNotRequired
    }
    return validationSchema
  }

  const fields = [
    {
      name: 'witnessFirstName',
      label: 'Voornaam',
      displayEndAdornment: page.required !== false
    },
    {
      name: 'witnessLastName',
      label: 'Achternaam',
      displayEndAdornment: page.required !== false
    },
  ];

  return (
    <TextInputsPage
      buttonText="Volgende"
      currentPath={currentPath}
      faqs={faqs}
      fields={fields}
      files={files}
      formID="name"
      legend={legend}
      heading={heading}
      history={history}
      nextPage={nextPage}
      prevPage={pagePath.prevPage}
      progressIndicatorCurrent={1}
      progressIndicatorSteps={3}
      questionnaire={questionnaire}
      saveIncident={saveIncident}
      validationSchema={validationSchemaToBeUsed()}
      customHelpLink={customHelpLink}
      helpLinkText={helpLinkText}
      customMiddleLogo={useCustomLogo}
      middleLogo={middleLogo}
    />
  );
}

export default WitnessName;
