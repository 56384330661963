import {
  CHECKIN_FLOW_DETECTED,
  CHECKOUT_FLOW_DETECTED,
  FLOW_DETECTED,
  NEW_FLOW_DETECTED,
  ONBOARDING_FLOW_DETECTED,
} from '@domain/action-types';
import { flows } from '@domain/constants';
const { CHECKIN, CHECKOUT, ONBOARDING, NEW } = flows;

const initialFlow = '';

const flow = (state = initialFlow, { type, flow }) => {
  switch (type) {
    case NEW_FLOW_DETECTED:
      return NEW;
    case ONBOARDING_FLOW_DETECTED:
      return ONBOARDING;
    case CHECKIN_FLOW_DETECTED:
      return CHECKIN;
    case CHECKOUT_FLOW_DETECTED:
      return CHECKOUT
    case FLOW_DETECTED:
      return flow;
    default:
      return state;
  }
};

export default flow;
