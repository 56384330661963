import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FilesService } from '@domain/services';
import { INTAKE_IMAGES_FETCHED, FILES_FETCHED } from '@domain/action-types';
import { useDispatchError } from '..';

const useFilesLoader = () => {
  const incident = useSelector(state => state.incident);
  const files = useSelector(state => state.files)
  const images = files.damageImages
  const imagesFetched = !!images.length;
  const authenticated = useSelector(state => state.authenticated);
  const uuid = useSelector(state => state.uuid);
  const dispatch = useDispatch();
  const { dispatchNewError } = useDispatchError();
  const filesFetching = useRef(false);
  const token = useSelector(state => state.token);

  const incidentFetched = incident && incident.status
  useEffect(() => {
    const fetchFiles = async () => {
      filesFetching.current = true;
      try {
        const res = await FilesService.getFiles(uuid, token);
        const intakeImages = res.intakeImages || [];
        dispatch({ type: INTAKE_IMAGES_FETCHED, intakeImages })
        const damageImages = res.damageImages || [];
        const otherFiles = res.otherFiles || [];
        const safDocuments = res.safDocuments || [];
        const offers = res.offers || []
        const greenCard = res.greenCard || []
        const registrationCertificate = res.registrationCertificate || []
        const officialReports = res.officialReports || []
        // const pdfCaseReport = res.pdfCaseReport || []
        const allFiles = { damageImages, otherFiles, safDocuments, offers, greenCard, registrationCertificate, officialReports }
        dispatch({ type: FILES_FETCHED, files: allFiles })
      } catch ({ error }) {
        console.error(`Error occurred inside useFilesLoader during get of files: (FilesService.getFiles)`, error);
        dispatchNewError(error);
      }

      filesFetching.current = false;
    };

    if (
      !imagesFetched &&
      !filesFetching.current &&
      incidentFetched &&
      authenticated &&
      token &&
      uuid
    ) {
      fetchFiles();
    }
  }, [
    authenticated,
    dispatch,
    dispatchNewError,
    imagesFetched,
    incidentFetched,
    token,
    uuid,
  ]);
};

export default useFilesLoader;
