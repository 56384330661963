const routes = {
  welcome: '/welkom',
  personal: '/persoonlijke-gegevens',
  damage: '/schade-beschrijven',
  uploads: '/uploaden',
  ownbodyshop: '/uw-hersteller',
  recovery: '/herstel',
  end: '/afgerond',
  help: '/steun',
  serviceAdvisor: '/serviceadviseur',
  landing: '/start',
  noConfig: '/fout'
};

export default routes;
