import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components'

import { isIE } from 'react-device-detect';
import { Colors } from '@domain/theming';
import { useScaling } from '@domain/hooks';

const StyledMainDiv = styled.div`
  flex: 1 10 0;
  display: flex;
  flex-direction: column;
  transition: border-radius 0.3s ease-in-out, transform 0.3s ease-in-out;
  background-color: ${Colors.GREY_LIGHTER};
  z-index: 3;
  min-height: 100%;
  .theme-dark & {
    background-color: ${Colors.BLACK};
  }
  .video & {
    background: none;
  }
  &.faq-open {
    transform: translateX(calc(-100% + 92px));
    border-radius: 12px;
    .navbar {
      border-top-right-radius: 12px;
      .screen-mobile & {
        position: sticky;
        top: 0;
      }
    }
    .grid-container__main {
      border-bottom-right-radius: 12px;
    }
    &.main__recovery {
      .screen-mobile & {
        .navbar {
          + div {
            margin-top: -69px; // = height of navbar
            border-top-right-radius: 12px;
          }
        }
      }
    }
  }
  .theme-custom & {
    background-color: ${Colors.GREY_LIGHTER};
    .authentication-heading {
      color: ${props => props.theme.colors.primary};
    }
  }

  .screen-desktop:not(.screen-ie) &,
  .screen-mobile &,
  .screen-tablet & {
    &:after {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0);
      transition: background .3s, z-index 0s .3s;
      z-index: -1;
    }
    &.main--dimmed:after {
      z-index: 1;
      background: rgba(0, 0, 0, 0.3);
      transition: background .3s;
      cursor: pointer;
    }
    .video & {
      &:after {
        z-index: -1;
      }
    }
  }

  .screen-desktop & {
    &.faq-open {
      border-radius: ${props => props.$scaling.scaleUpTo4K(12)}px;
      transform: translateX(-${props => props.$scaling.scaleUpTo4K(480)}px);
      .navbar {
        border-top-right-radius: ${props => props.$scaling.scaleUpTo4K(12)}px;
      }
      .grid-container__main {
        border-bottom-right-radius: ${props => props.$scaling.scaleUpTo4K(12)}px;
      }
    }
  }

  .video & {
    background: transparent;
  }

  @media screen and (max-width: 580px) {
    .screen-desktop & {
      &.faq-open {
        transform: translateX(-74%);
      }
    }
  }
`;

function Main({ children, faqVisible, className, dimmed, callBack }) {
  const scaling = useScaling();
  const classes = classNames(
    'main',
    className,
    { 'faq-open': faqVisible },
    { 'main--dimmed': dimmed }
  );

  return (
    <>
      {isIE ? (
        <StyledMainDiv className={classes} role="main" onClick={callBack} $scaling={scaling} >
          {React.Children.toArray(children)}
        </StyledMainDiv>
      ) : (
        <StyledMainDiv as="main" className={classes} role="main" onClick={callBack} $scaling={scaling} >
          {React.Children.toArray(children)}
        </StyledMainDiv>
      )}
    </>
  );
}
Main.defaultProps = {
  faqVisible: false
};

Main.propTypes = {
  children: PropTypes.any.isRequired,
  faqVisible: PropTypes.any
};

export default Main;
