import imageCompression from 'browser-image-compression'
import { isIE } from 'react-device-detect';
import { splitStringAtFirstComma, encodeFileInBase64 } from '@domain/helpers';

async function optimizeImages(uploads, insurer) {
  const options = {
    maxSizeMB: 1,
    useWebWorker: true,
    initialQuality: 0,
    maxIteration: 20
  }

  if (isIE) {
    return uploads
  }

  const optimizedUploads = Object.entries(uploads).reduce(async (acc, [key, file]) => {
    const files = await acc

    if (file.type.startsWith('image')) {
      try {
        var blob = await imageCompression(file, options)
        files[key] = blob
        return files
      }
      catch (error) {
        console.error("error compressing image", error)
      }
    }
    files[key] = file
    return files

  }, Promise.resolve({}))

  return optimizedUploads
}

async function prepareFileForUploading(file) {
  const encodedFile = await encodeFileInBase64(file);
  if (!encodedFile) {
    return null;
  }
  const [metadata, content] = splitStringAtFirstComma(encodedFile);
  const { name } = file;
  return {
    fileName: name,
    content,
    cpID: 'CP' + Math.random().toString(), // can we guarantee uniqueness?
    fileAction: 'create',
    metadata
  };
}

async function prepareFilesForUploading(files, insurer) {

  const optimizedUploads = await optimizeImages(files, insurer)

  const arrayOfFiles = await Promise.all(Object.values(optimizedUploads).map(prepareFileForUploading));

  return arrayOfFiles;
}

export default prepareFilesForUploading;
