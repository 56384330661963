import { useCallback, useEffect } from 'react';

function useEnterKey(callback) {
  const handleKeyPress = useCallback(
    event => {
      const key = event.which || event.keyCode;
      const enterKey = 13;
      if (key === enterKey) {
        callback(event);
      }
    },
    [callback]
  );

  useEffect(() => {
    document.addEventListener('keypress', handleKeyPress);
    return () => document.removeEventListener('keypress', handleKeyPress);
  }, [callback, handleKeyPress]);
}

export default useEnterKey;
