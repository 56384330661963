// NPM imports.
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isMobileOnly } from 'react-device-detect';
import { useScaling } from '@domain/hooks';

const StyledP = styled.p`
font-size: ${props => !isMobileOnly ? props.$scaling.textSize(14) : props.$scaling.textSizeMobile(14, props.vwWdith)}px;
line-height: ${props => !isMobileOnly ? props.$scaling.textSize(18) : props.$scaling.textSizeMobile(18, props.vwWdith)}px;
`;


// Keep paragraph's simple. Style the <p> element globally on the <p> element according to the brand style guide.
// use <em> and <bold> tag's if you want to mark certain words bold, <em> giving higher semantics then <bold>.
// Remember that paragraphs are used for 'paragraphs' of text in a story. Something in the lines of
// 'Lorem ipsum dolor, sit amet, dolor ipsum' could actually be a subheading: <h2>, <h3> etc.
function Paragraph({ children, ...props }) {
  const scaling = useScaling();
  return <StyledP {...props} $scaling={scaling}>{React.Children.toArray(children)}</StyledP>;
}

Paragraph.propTypes = {
  children: PropTypes.any.isRequired
};

export default Paragraph;
