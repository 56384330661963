// NPM imports.
import React, { useMemo } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

// Bounded context imports.
import routes from './routes'
import Instructions from './Instructions'
import Start from './Start'
import Chauffeur from './Chauffeur'
import Overview from './Overview'
import Uploads from './Uploads'
//import Damage from './Damage'
//import CloseUpUploads from './CloseUpUploads'
//import SigningDigital from './SigningDigital'
import Signing from './Signing'
//import TakeIn from './TakeIn'
import Questions from './Questions'
import End from './End'
import { useSelector } from 'react-redux'
import { useThemes } from '@domain/hooks'
import { companyNames, proccessType } from '@domain/constants'

function CheckIn({ history, location, match }) {
  const insurer = useSelector(state => state.insurer);

  const currentPath = match.path;
  const isThemed = !!insurer && insurer.brand !== companyNames.VIP && insurer.objectType !== proccessType.DI;
  const themes = useMemo(() => [isThemed ? 'theme-custom' : 'theme-def'], [isThemed]);
  useThemes(themes);

  return (
    <React.Fragment>
      <Switch>
        <Route exact path={currentPath + routes.instructions} component={Instructions} />
        <Route exact path={currentPath + routes.start} component={Start} />
        <Route exact path={currentPath + routes.chauffeur} component={Chauffeur} />
        <Route exact path={currentPath + routes.overview} component={Overview} />
        <Route path={currentPath + routes.uploads} component={Uploads} />
        {/* <Route exact path={currentPath + routes.damage} component={Damage} /> */}
        {/* <Route exact path={currentPath + routes.closeUpUploads} component={CloseUpUploads} /> */}
        <Route exact path={currentPath + routes.signing} component={Signing} />
        {/* <Route exact path={currentPath + routes.signingDigital} component={SigningDigital} /> */}
        {/* <Route exact path={currentPath + routes.takeIn} component={TakeIn} /> */}
        <Route exact path={currentPath + routes.questions} component={Questions} />
        <Route exact path={currentPath + routes.end} component={End} />
        <Redirect to={currentPath + routes.instructions} />
      </Switch>
    </React.Fragment>
  );
}

export default CheckIn
