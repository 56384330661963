const object = 'object';

const camelCaseStringToPascalCaseString = str => {
  if (str === 'cpID') {
    return 'CP_ID';
  }
  if (str === 'uuid') {
    return 'UUID';
  }
  if (str === 'url') {
    return 'URL';
  }
  if (str === 'safDocuments') {
    return 'SAFDocuments'
  }
  const firstChar = str.charAt(0);
  return firstChar.toUpperCase() + str.slice(1);
};

const camelCaseToPascalCase = input => {
  if (typeof input === 'string') {
    return camelCaseStringToPascalCaseString(input);
  }
  if (Array.isArray(input)) {
    return input.map(camelCaseToPascalCase);
  }

  const result = {};
  for (let key in input) {
    let newKey = camelCaseStringToPascalCaseString(key);
    const value = input[key];
    if (typeof value !== object) {
      result[newKey] = value;
    } else {
      result[newKey] = camelCaseToPascalCase(value);
    }
  }
  return result;
};

export default camelCaseToPascalCase;
