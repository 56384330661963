import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import { UseIcon } from '..';
import { Colors } from '@domain/theming';

const StyledRatingDiv = styled.div`
  .half-star {
    display: inline-block;
    position: relative;
    width: 13px;
  }
  .left-half-star,
  .right-half-star {
    display: inline-block;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  .left-half-star {
    width: 6.5px;
    height: 13px;
  }
  .right-half-star {
    position: absolute;
    left: 0;
    // color: ${Colors.GREY_LIGHT};
    z-index: -1;
  }

  .theme-custom & {
    color: ${props => props.theme.colors.primary};
  }
`;

function Rating({ rating, color = '#000' }) {
  const ratingNumerical = rating ? Number(rating.split('')[1]) : 10;

  function renderRating() {
    const card = [];

    const numberOfFullStars = Math.floor(ratingNumerical / 2);
    const halfStar = 2 * (ratingNumerical / 2 - numberOfFullStars);

    _.times(numberOfFullStars, index => {
      card.push(
        <UseIcon name="icon-star" key={index} width="13" height="13" />
      );
    });
    if (halfStar) {
      card.push(
        <React.Fragment key={4}>
          <div className="half-star">
            <div className="left-half-star">
              <UseIcon name="icon-star" key="half" width="13" height="13" />
            </div>
            <div className="right-half-star">
              <UseIcon name="icon-star" key="half" width="13" height="13" />
            </div>
          </div>
        </React.Fragment>
      );
    }
    return card;
  }

  return (
    <React.Fragment>
      <StyledRatingDiv className="rating">{renderRating()}</StyledRatingDiv>
    </React.Fragment>
  );
}

export default Rating;
