import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Upload from './Upload';
import ImageDelete from './ImageDelete';
import routes from './routes'

const { upload, inspect } = routes;

function Uploads({ match }) {
  const currentPath = match.path;
  return (
    <Switch>
      <Route exact path={currentPath + upload} component={Upload} />
      <Route exact path={currentPath + inspect} component={ImageDelete} />
      <Redirect to={currentPath + upload} />
    </Switch>
  );
}

export default Uploads;
