import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import { Colors } from '@domain/theming';
import { useScaling } from '@domain/hooks';

const StyledItem = styled.div`
  width: ${props => props.$scaling.scaleUpTo4K(4)}px;
  height: ${props => props.$scaling.scaleUpTo4K(4)}px;
  margin-right: ${props => props.$scaling.scaleUpTo4K(8)}px;
  background-color: ${Colors.GREY_LIGHT};
  border-radius: 50px;
  &:last-child {
    margin-right: 0;
  }
  &.progression-indicator-item--active {
    width: ${props => props.$scaling.scaleUpTo4K(16)}px;
    background-color: ${Colors.BLACK};
  }
  .navbar--overlap & {
    &.progression-indicator-item--active {
      background: ${Colors.WHITE};
  
      &--black {
        background-color: ${Colors.BLACK};
        background: ${Colors.BLACK};
      }
    }
  }
`;

function ProgressionIndicatorItem({ active, color }) {
  const scaling = useScaling();
  const classes = classNames(
    'progression-indicator-item',
    {
      'progression-indicator-item--active': active
    },
    {
      'progression-indicator-item--active--black': active && color === 'black'
    }
  );
  return <StyledItem className={classes} $scaling={scaling} />;
}

ProgressionIndicatorItem.propTypes = {
  active: PropTypes.bool
};

export default ProgressionIndicatorItem;
