// NPM imports.
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// Domain imports.
import {
  BackgroundVideo,
  Button,
  CompanyLogo,
  Container,
  GridContainer,
  GridColumn,
  Heading,
  Loader,
  Main,
  NavBar,
} from '@domain/components';
import { goToNextPage, browserStorage } from '@domain/helpers';
import { useThemes } from '@domain/hooks';

import questionnaire from '../questionnaire';
import { companyNames, proccessType } from '@domain/constants';


const StyledMain = styled(Main)`
  padding: 32px;
  .checkinout__start--content {
    flex: 1 0 auto;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    padding-top: 40px;
    h3 {
      margin-bottom: 10px;
    }
    h2 {
      margin-top: 20px;
    }
  }
  .checkinout__start--info {
    .checkinout__start--license {
      justify-content: center;
    }
  }
  .checkinout__start--footer {
    flex: 0 0 auto;
    margin-top: auto;
    padding-top: 60px;
  }
  .screen-desktop & {
    button {
      margin-left: auto;
      @media (max-width: 580px) {
        width: 100%;
      }
    }
  }
`;

const StyledNavBar = styled(NavBar)`
  .main__checkinout--start & {
    border: none;
  }
  img {
    flex: 1 0 auto;
  }
`;


function Start({ history, match }) {

  const themes = useMemo(() => ['home-layout'], []);
  useThemes(themes);

  const incident = useSelector(state => state.incident);
  const bodyshop = useSelector(state => state.bodyshop);
  const insurer = useSelector(state => state.insurer);

  if (!incident || !bodyshop || !insurer) {
    return <Loader />;
  }

  const { customerFirstName, customerLastName, licensePlate } = incident;

  const insurerName = insurer.brandName || 'uw verzekeraar';
  const insurerLogo = insurer.logoURL || '';

  const currentPath = match.path;

  const progress = questionnaire.find(page => page.path === currentPath).name
  browserStorage.setProgress(progress);

  const onClickButton = () => {
    goToNextPage(history, questionnaire, incident, currentPath);
  };

  const isVIP = insurer.objectType === proccessType.Casco && (insurer.brand === companyNames.VIP || !insurer.brand)
  const isThemed = !!insurer && insurer.brand !== companyNames.VIP && insurer.objectType !== proccessType.DI;

  const companyLogo = {
    align: 'center',
    width: '120',
    height: '64',
    src: insurerLogo,
    alt: insurerName,
  };


  const header = isVIP
    ? <Heading className="h1__tablet" level="1">VIP Schadeservice</Heading>
    : <Heading className="h1__tablet" level="1">{insurer.brandName} <br></br> Schadeservice</Heading>

  return (
    <React.Fragment>
      <StyledMain className="main__checkinout--start">
        <StyledNavBar>
          <CompanyLogo {...companyLogo} />
        </StyledNavBar>
        <GridContainer>
          <GridColumn className="checkinout__start--content">
            <Container className="checkinout__start--heading">
              <Heading level="3">Check-in</Heading>
              {header}
            </Container>
            <Container className="checkinout__start--info">
              <Heading level="2">{customerFirstName + ' ' + customerLastName}</Heading>
              <Container flex={true} className="checkinout__start--license">
                <Heading level="2">{licensePlate}</Heading>
              </Container>
            </Container>
          </GridColumn>
        </GridContainer>
        <GridContainer className="checkinout__start--footer">
          <GridColumn>
            <Button onClick={onClickButton}>Start</Button>
          </GridColumn>
        </GridContainer>
      </StyledMain>
      <BackgroundVideo
        autoPlay={true}
        img={!isVIP || isThemed}
        imgFileName="BSSP03.jpg"
        loop={true}
        type="video/mp4"
        videoFileName="DEF1.mp4"
      />
    </React.Fragment>
  )
}

export default Start;
