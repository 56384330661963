import { combineReducers } from 'redux';

import authenticated from './authenticated';
import bodyshop from './bodyshop';
import bodyshopLists from './bodyshopLists';
import fid from './fid';
import files from './files'
import pid from './pid';
import companyToken from './companyToken';
import consentCode from './consentCode';
import faqSeen from './faqSeen';
import flow from './flow';
import httpError from './httpError';
import inCreation from './inCreation';
import intakeImages from './intakeImages';
import incident from './incident';
import insurer from './insurer';
import pagesExcluded from './pagesExcluded';
import rid from './rid'
import selectedImage from './selectedImage';
import spCase from './spCase';
import token from './token';
import uuid from './uuid';

// When you write a new reducer, import it in this file and include it in the object given to combineReducers
// Try and make sure that the name is descriptive, and keep the list in alphabetical order
const reducer = combineReducers({
  authenticated,
  bodyshop,
  bodyshopLists,
  fid,
  files,
  pid,
  companyToken,
  consentCode,
  faqSeen,
  flow,
  httpError,
  inCreation,
  intakeImages,
  incident,
  insurer,
  pagesExcluded,
  rid,
  selectedImage,
  spCase,
  token,
  uuid,
});

export default reducer;
