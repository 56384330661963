import { CASE_UUID_FOUND, NEW_UUID_FOUND } from '@domain/action-types';

const initialCaseUUID = '';

const uuid = (state = initialCaseUUID, { type, uuid }) => {
  switch (type) {
    case CASE_UUID_FOUND:
      return uuid;
    case NEW_UUID_FOUND:
      return uuid;
    default:
      return state;
  }
};

export default uuid;
