// NPM imports.
import { useState, useEffect } from 'react';

// Domain wide imports.
import { mapYupValidationSchemaErrors } from '@domain/helpers';

function useFormHandle(
  validationSchema,
  initialValues,
  callback,
  convertServerValuesToPortalValues = x => x,
  convertPortalValuesToServerValues = x => x,
  controlValue = (name, value) => value,
) {
  if (!validationSchema) {
    throw new Error('Please provide a valid Yup schema.');
  }

  const [values, setValues] = useState(
    convertServerValuesToPortalValues(initialValues),
  );

  const [errors, setErrors] = useState({});

  const [buttonClicked, setButtonClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    async function testInputs() {
      try {
        await validationSchema.validate(values, { abortEarly: false });
        setErrors({});
      } catch (errorResult) {
        setErrors(mapYupValidationSchemaErrors(errorResult.inner));
      }
    }
    testInputs();
  }, [values, validationSchema]);

  async function handleSubmit(event) {
    event.preventDefault();
    setButtonClicked(true);
    setIsLoading(true);
    try {
      await validationSchema.validate(values, { abortEarly: false });
      await callback(convertPortalValuesToServerValues(values));
      setIsLoading(false);
    } catch (errorResult) {
      setIsLoading(false);
      setErrors(mapYupValidationSchemaErrors(errorResult.inner));
    }
  }

  async function handleChange(event) {
    const { name, value } = event.target;
    const valueToSet = controlValue(name, value);
    setValues(vals => ({ ...vals, [name]: valueToSet }));
  }

  return {
    values,
    errors,
    handleSubmit,
    handleChange,
    buttonClicked,
    setValues,
    isLoading,
  };
}

export default useFormHandle;
