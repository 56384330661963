const routes = {
  start: '/overzicht',
  offer: '/offerte',
  bodyshopSelectEM: '/contactgegevens',
  bodyshopSelect: '/keuze-hersteller',
  bodyshopConfirm: '/bevestig-hersteller',
  bodyshopSelectIntakePlus: '/keuze-uw-hersteller'
};

export default routes;
