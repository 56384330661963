import React from 'react';
import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Input,
  Container,
  Form,
  Heading,
  ValidationFeedback
} from '@domain/components';


function InputDialog({
  open,
  onYesClick = () => { },
  onNoClick = () => { },
  title,
  content,
  yesLabel = "Ja",
  noLabel = "Nee",
  showYesButton = true,
  showNoButton = true,
  handleChange,
  value,
  form,
  formInput,
  errors,
  buttonClicked,
  confirmationMessage,
  onCloseClick,
}) {

  const StyledHeading = styled(Heading)`
  .theme-custom & {
    color: ${props => props.theme.colors.primary};
  }
  `

  const contentToShow = () => {
    if (confirmationMessage) {
      return (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
          <DialogActions>
            {showNoButton && (
              <Button onClick={onCloseClick} color="primary" autoFocus>
                <span>doorgaan</span>
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      )
    }
    return (
      <>
        <Form {...form} onSubmit={onYesClick} >
          <Container key={formInput.name} className="form-input">
            <StyledHeading level={'4'}>Vertel ons hoe we u kunnen helpen</StyledHeading>
            <Input {...formInput} />
            <Container className="validation__container">
              {buttonClicked && errors[formInput.name] && (
                <ValidationFeedback type="error">{errors[formInput.name]}</ValidationFeedback>
              )}
            </Container>
          </Container>
          <DialogActions>
            {showYesButton && (
              <Button type="submit" color="primary">
                <span>{yesLabel}</span>
              </Button>
            )}
            {showNoButton && (
              <Button onClick={onNoClick} color="primary" autoFocus>
                <span>{noLabel}</span>
              </Button>
            )}
          </DialogActions>
        </Form>
      </>
    )
  }



  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {contentToShow()}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default InputDialog;
