import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { alpha } from '@material-ui/core/styles';
import { isMobileOnly } from 'react-device-detect';
import { useScaling } from '@domain/hooks';

function StyledDropdown({
  label,
  onChange,
  value,
  valueList = {},
  className,
  customBaseSize
}) {
  const scaling = useScaling()
  const useStyles = makeStyles((theme) => ({
    formControl: {
      '& .MuiInputUnderline': {
        '&:after': {
          borderColor: theme.colors.primary + ' !important',
        },
        '&:before': {
          borderColor: theme.colors.primary + ' !important',
        }
      },

    },
    select: {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(1),
      height:
        (!isMobileOnly
          ? scaling.textSize(!!customBaseSize ? (customBaseSize * 1.5) : 67)
          : scaling.textSizeMobile(!!customBaseSize ? customBaseSize * 1.5 : 67)) + 'px'
    },
    dropdown: {
      textAlign: 'left',
      alignItems: 'center',
      fontSize:
        (isMobileOnly
          ? scaling.dropdownSizeMobile(customBaseSize || 32, null)
          : scaling.dropdownSize(customBaseSize || 32, null)) + 'px',
      fontWeight: 'bold',
      lineHeight:
        (!isMobileOnly
          ? scaling.textSize(!!customBaseSize ? customBaseSize * 1.125 : 36)
          : scaling.textSizeMobile(!!customBaseSize ? customBaseSize * 1.125 : 36)) + 'px',
      marginBottom: '0',
      marginTop: 'auto',
    },
    inputInput: {
      textAlign: 'left',
      marginTop: 'auto',
      paddingTop:
        ((!isMobileOnly ?
          scaling.textSize(6) :
          scaling.textSizeMobile(6)) + 'px'),
      paddingBottom:
        ((!isMobileOnly ?
          scaling.textSize(7) :
          scaling.textSizeMobile(7)) + 'px'),
      height:
        (!isMobileOnly
          ? scaling.textSize(!!customBaseSize ? customBaseSize * 1.75 : 56)
          : scaling.textSizeMobile(!!customBaseSize ? customBaseSize * 1.75 : 56)) + 'px'
    },
    inputUnderline: {
      borderColor: theme.colors.primary,
    },
    dropdownIcon: {
      color: theme.colors.primary,
    },
    dropdownUnlabelled: {
    },
    dropdownLabel: {
      transform: 'translate(0, 12px) scale(1)',
      fontSize: '24px',
      fontWeight: 'bold'
    },
    dropdownLabelShrink: {
      height: '44px',
      color: theme.colors.primary,
      fontSize: ((!isMobileOnly ?
        scaling.textSize(16) :
        scaling.textSizeMobile(16)) + 'px'),
      fontWeight: 'bold',
      transform: `translate(0, -${((!isMobileOnly ?
        scaling.textSize(20) :
        scaling.textSizeMobile(20)))}px)`,
      lineHeight: ((!isMobileOnly ?
        scaling.textSize(14) :
        scaling.textSizeMobile(14)) + 'px'),
      letterSpacing: '0.0625em',
      transformOrigin: 'top left',
      '.theme-custom &': {
        color: theme.colors.primary,

      }
    },
    dropdownLabelFocused: {
      color: theme.colors.primary,
    },
    menuItem: {
      '&:hover': {
        backgroundColor: alpha(theme.colors.primary, 0.18)
      },
    },
    menuItemSelected: {
      '&&': {
        backgroundColor: alpha(theme.colors.primary, 0.28),
        '&:hover': {
          backgroundColor: alpha(theme.colors.primary, 0.28)
        }
      }
    }
  }));

  const classes = useStyles();

  if (Array.isArray(valueList)) {

    return (
      <FormControl classes={{ root: classes.formControl }}>
        {!!label && <InputLabel classes={{ root: classes.dropdownLabel, shrink: classes.dropdownLabelShrink, focused: classes.dropdownLabelFocused }}>{label}</InputLabel>}
        <Select
          onChange={onChange}
          value={value}
          classes={{ select: classes.select, root: classes.dropdown, nativeInput: classes.inputInput }}
        >
          {valueList.map((item, i) => {

            return (
              <MenuItem classes={{ root: classes.menuItem, selected: classes.menuItemSelected }} key={label + item + i} value={item}>{item}</MenuItem>
            );
          })}
        </Select>
      </FormControl >
    )

  } else {
    // the list is an object
    // where the key is the value
    // and the value attached to the key is the display name
    const valueListKeys = Object.keys(valueList);

    return (
      <FormControl classes={{ root: classes.formControl }}>
        {!!label && <InputLabel classes={{ root: classes.dropdownLabel, shrink: classes.dropdownLabelShrink, focused: classes.dropdownLabelFocused }}>{label}</InputLabel>}
        <Select
          classes={{ select: classes.select, root: classes.dropdown, nativeInput: classes.inputInput }}
          onChange={onChange}
          value={value}
        >
          {valueListKeys.map((item, i) => {

            return (
              <MenuItem classes={{ root: classes.menuItem, selected: classes.menuItemSelected }} key={label + item + i} value={item}>{valueList[item]}</MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }
}

export default StyledDropdown;
