import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../Button';
import Container from '../Container';
import Input from '../Input';
import ListSelection from '../ListSelection';
import { Colors, Shadows } from '@domain/theming';
import Heading from '../Heading';
import { useScaling } from '@domain/hooks';
import ValidationFeedback from '../ValidationFeedback';

const StyledContainer = styled(Container)`
  width: 100%;
  max-width: ${props => props.$scaling.scaleUpTo4K(640)}px;
  flex: 0 1 auto;
  background: ${Colors.WHITE};
  transition: all .5s ease-in-out;
  &.create-mode {
    flex-grow: 1;
    min-height: 0;
  }
  .MuiList-root {
    .MuiListItem-root {
      padding: ${props => props.$scaling.scaleUpTo4K(8)}px ${props => props.$scaling.scaleUpTo4K(30)}px;
    }
  }
  .bodyshop-widget__form {
    padding: ${props => props.$scaling.scaleUpTo4K(30)}px;
    padding-top: ${props => props.$scaling.scaleUpTo4K(45)}px;
    h4 {
      margin-bottom: ${props => props.$scaling.scaleUpTo4K(38)}px;
    }
    .MuiTextField-root {
      margin-bottom: ${props => props.$scaling.scaleUpTo4K(30)}px;
    }
    button {
      width: 100%;
    }
  }
  .MuiTextField-root {
    margin: 0;
    flex: 1 1 auto;
    color: ${Colors.GREY_DARK}
    .MuiInputAdornment-root {
      background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 36%);
    }
  }

`;

const StyledValidationFeedback = styled(ValidationFeedback)`
    max-width: ${props => props.$scaling.scaleUpTo4K(338)}px;
    margin: 0;
    margin-top: -25px;
`;

const ListContainer = styled(Container)`
  box-shadow: ${Shadows.SHADOW1};
  border-radius: 16px;
  padding: 20px 20px 10px;
  margin-bottom: 30px;
`;

const initialNewBodyshop = { companyName: '', zipCode: '', houseNumber: '', houseNumberAddition: '', phoneNumber: '' };

function BodyshopWidget({
  callback,
  list = [],
  showSelected,
  selectedUUID,
  buttonClicked,
  errors
}) {
  const scaling = useScaling();
  const [formattedList, setFormattedList] = useState();
  const [filteredList, setFilteredList] = useState();
  const [newBodyshop, setNewBodyshop] = useState(initialNewBodyshop);
  const [selectedBodyshop, setSelectedBodyshop] = useState();
  const [shouldShowSelected, setShouldShowSelected] = useState(showSelected)
  const [filterValue, setFilterValue] = useState(newBodyshop.companyName || '');
  const [createMode, setCreateMode] = useState(true);
  const preselected = useRef(false);
  const rejected = useRef(false);


  useEffect(() => {
    setShouldShowSelected(showSelected)
  }, [showSelected])

  useEffect(() => {
    if (!!formattedList || !!filteredList) {
      return;
    }
    const newList = list.map((item) => {
      return ({
        title: item.companyName,
        subtitle: item.city || '',
        uuid: item.uuid
      })
    });
    setFormattedList(newList);
    setFilteredList(newList);
  }, [filteredList, formattedList, list]);

  const generateFilteredList = useCallback(
    (valueToFilter, attrToFilter) => {
      const filteredList = formattedList.filter(item => {
        return !!item && !!item[attrToFilter] && item[attrToFilter].toLowerCase().includes(valueToFilter.toLowerCase())
      }).filter((filteredItem, index, self) =>
        index === self.findIndex((t) => (
          t.uuid === filteredItem.uuid
        ))
      )
      return filteredList;
    }, [formattedList])

  useEffect(() => {

    if (shouldShowSelected && formattedList) {
      const newFilteredList = generateFilteredList(selectedUUID, 'uuid');
      setFilteredList(newFilteredList);
      return
    }
    if (!filterValue) {
      // if the user deletes the filterValue (name) the list dissapears
      setCreateMode(true);
      rejected.current = false
      return;
    }

    // we don't want to look for a match before a certain
    // number of characters were inputed
    if (createMode && filterValue.length <= 5) {
      rejected.current = false
      return;
    }
    // we don't want the list to update when they delete characters
    // and there is just a few left (the first letter will always have
    // a few unwanted matches...)
    if (!createMode && filterValue.length <= 3) {
      rejected.current = false
      return;
    }


    const newFilteredList = generateFilteredList(filterValue, 'title');
    setFilteredList(newFilteredList);

  }, [createMode, filterValue, formattedList, selectedUUID, shouldShowSelected, generateFilteredList]);

  useEffect(() => {
    if (!filteredList) {
      return
    }
    if (
      filteredList.length >= 1 &&
      filteredList.length <= 3 && !rejected.current// set to one to only show it if there is only one match
    ) {
      setCreateMode(false);
    } else {
      setCreateMode(true);
    }
  }, [filteredList, selectedBodyshop])


  useEffect(() => {
    if (preselected.current) {
      callback(createMode, selectedBodyshop, newBodyshop);
      return;
    }
    const preselectedBodyshop = callback(createMode, selectedBodyshop, newBodyshop);
    if (preselectedBodyshop && filteredList) {
      setSelectedBodyshop(list.find(item => item.uuid === preselectedBodyshop));
      preselected.current = true;
      setCreateMode(false)
    }
  }, [callback, createMode, filteredList, list, selectedBodyshop, newBodyshop]);

  useEffect(() => {
    if (preselected.current && selectedBodyshop) {
      setFilterValue(selectedBodyshop.companyName);
    }
  }, [selectedBodyshop])

  const onFilterInputChange = (event) => {
    if (shouldShowSelected && selectedUUID) {
      generateFilteredList(filterValue, 'title')
      setShouldShowSelected(false)
    }
    const { value } = event.target;
    const newValue = value;
    if (newValue.length < 1) {
      setSelectedBodyshop(null)
    }
    setFilterValue(newValue);
    setNewBodyshop(oldState => ({
      ...oldState,
      companyName: newValue,
    }));
  }

  const onRejectBodyshop = () => {
    setCreateMode(true);
    setSelectedBodyshop(null);
    setNewBodyshop(oldState => ({
      ...oldState,
      companyName: filterValue,
    }))
    rejected.current = true;
  }

  const onListSelectionChange = ({ uuid }) => {
    const alreadySelected = !!selectedBodyshop && uuid === selectedBodyshop.uuid;
    if (alreadySelected) {
      return;
    }
    const newSelectedBodyshop = list.find(item => item.uuid === uuid);
    if (newSelectedBodyshop) {
      setSelectedBodyshop(newSelectedBodyshop);
      rejected.current = false;
    }
  }

  const onFormInputChange = (event) => {
    const newValue = event.target.value;
    const name = event.target.name;
    if (name === 'houseNumber') {
      setNewBodyshop(oldState => ({
        ...oldState,
        [name]: parseFloat(newValue),
      }));
    } else {
      setNewBodyshop(oldState => ({
        ...oldState,
        [name]: newValue,
      }));
    }
  };


  return (
    <StyledContainer className={'bodyshop-widget' + (createMode ? ' create-mode' : '')} $scaling={scaling}>
      <Container className="bodyshop-widget__form">
        <Input
          customBaseSize={24}
          id="companyName"
          label="Naam"
          name="companyName"
          onChange={onFilterInputChange}
          valid={!errors['companyName'] || !buttonClicked}
          value={filterValue}
          type='text'
          transparentAdornment={true}
          required
        />
        {/* <Input
          customBaseSize={16}
          id="filterInput"
          placeholder="Filter list..."
          name="filterInput"
          onChange={onFilterInputChange}
          valid={true}
          value={filterValue}
        /> */}
        {!createMode &&
          <>
            <ListContainer>
              <Heading level="4">{preselected.current ? 'Dit is de hersteller die u heeft gekozen:' : 'Klik u hersteller hieronder aan. Staat deze er niet tussen klik dan op “mijn hersteller staat hier niet tussen”.'}</Heading>
              <ListSelection
                className="bodyshop-widget__list"
                listItems={filteredList}
                onClickItem={onListSelectionChange}
                preselectedItemUUID={!!selectedBodyshop ? selectedBodyshop.uuid : null}
              />
            </ListContainer>
            <Button shadow={false} onClick={onRejectBodyshop}>
              Mijn hersteller staat er niet tussen
            </Button>
          </>
        }
        {createMode &&

          <>
            {rejected.current && <Heading level="4">We hebbe deze hersteller niet in onze database staan. Voeg a.u.b. de volgende gegevens zelf in.</Heading>}
            <Container className="form-input">
              <Input
                customBaseSize={24}
                id="postcode"
                label="Postcode"
                name="zipCode"
                onChange={onFormInputChange}
                valid={!errors['zipCode'] || !buttonClicked}
                value={newBodyshop.zipCode}
                transparentAdornment={true}
                type='text'
                required
              />
              {buttonClicked && errors['zipCode'] && (<Container className="validation__container">
                <StyledValidationFeedback type="error" $scaling={scaling}>{errors['zipCode']}</StyledValidationFeedback>
              </Container>)}
            </Container>
            <Container className="form-input">
              <Input
                customBaseSize={24}
                id="houseNumber"
                label="Huisnummer"
                name="houseNumber"
                onChange={onFormInputChange}
                valid={!errors['houseNumber'] || !buttonClicked}
                value={newBodyshop.houseNumber}
                type='text'
                transparentAdornment={true}
                required={true}
              />
              {buttonClicked && errors['zipCode'] && (<Container className="validation__container">
                <StyledValidationFeedback type="error" $scaling={scaling}>{errors['houseNumber']}</StyledValidationFeedback>

              </Container>)}
            </Container>
            <Container className="form-input">
              <Input
                customBaseSize={24}
                id="houseNumberAddition"
                label="Toevoeging"
                name="houseNumberAddition"
                onChange={onFormInputChange}
                valid={!errors['houseNumberAddition'] || !buttonClicked}
                value={newBodyshop.houseNumberAddition}
                type='text'
                transparentAdornment={true}
                required={true}
              />
              {buttonClicked && errors['houseNumberAddition'] && (<Container className="validation__container">
                <StyledValidationFeedback type="error" $scaling={scaling}>{errors['houseNumberAddition']}</StyledValidationFeedback>
              </Container>)}
            </Container>
            <Container className="form-input">
              <Input
                customBaseSize={24}
                id="phoneNumber"
                label="Telefoon (optioneel)"
                name="phoneNumber"
                onChange={onFormInputChange}
                valid={!errors['phoneNumber'] || !buttonClicked}
                value={newBodyshop.phoneNumber}
                transparentAdornment={true}
                type='text'
              />
              {buttonClicked && errors['phoneNumber'] && (<Container className="validation__container">
                <StyledValidationFeedback type="error" $scaling={scaling}>{errors['phoneNumber']}</StyledValidationFeedback>
              </Container>)}
            </Container>
          </>}
      </Container>
    </StyledContainer >
  );
}

BodyshopWidget.propTypes = {
  list: PropTypes.array.isRequired,
}

export default BodyshopWidget;
