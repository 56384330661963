import React, { Children, memo } from 'react';
import styled from 'styled-components';
import { Colors } from '@domain/theming';
import './heading.scss';
import { motion } from 'framer-motion';

const H1 = styled(motion.h1).attrs(props => ({
  as: props.as
}))`
  color: ${props => (props.color === 'white' ? Colors.WHITE : Colors.GREY_DARK)};
  text-align: ${props => props.textAlign};
  margin: ${props => props.margin};
  margin-top: ${props => props.marginTop};
  margin-right: ${props => props.marginRight};
  margin-bottom: ${props => props.marginBottom};
  margin-left: ${props => props.marginLeft};
`;

const HeadingSP = ({
  animate,
  className,
  children,
  color,
  initial,
  level,
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  textAlign = 'center',
  variants,
}) => {
  const ActualHeading =
    level === 1
      ? <H1 as={motion.h1} />
      : level === 2
        ? <H1 as={motion.h2} />
        : level === 3
          ? <H1 as={motion.h3} />
          : level === 4
            ? <H1 as={motion.h4} />
            : level === 5
              ? <H1 as={motion.h5} />
              : <H1 as={motion.h6} />

  return (
    <ActualHeading
      className={className}
      color={color}
      margin={margin}
      marginTop={marginTop}
      marginRight={marginRight}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      textAlign={textAlign}

      initial={initial}
      animate={animate}
      variants={variants}
    >
      {Children.toArray(children)}
    </ActualHeading>
  );
};

export default memo(HeadingSP);
