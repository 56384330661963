import useWindowSize from "../useWindowSize";

const useScaling = () => {
  const { vWidth } = useWindowSize();

  const textSize = (baseSize) => {
    const min_vw = 640;
    const max_vw = 1080;
    const xl_vw = 1920;
    const xxl_vw = 3840;
    const min_line_height = baseSize / 1.3;
    const mid_line_height = baseSize;
    const max_line_height = baseSize * 2.2;
    const fluid_range_sm =
      min_line_height +
      (mid_line_height - min_line_height) *
      ((vWidth - min_vw) / (max_vw - min_vw));
    const fluid_range_xl =
      mid_line_height +
      (max_line_height - mid_line_height) *
      ((vWidth - xl_vw) / (xxl_vw - xl_vw));

    if (vWidth < min_vw) {
      return min_line_height.toFixed(2);
    } else if (vWidth >= min_vw && vWidth < max_vw) {
      return fluid_range_sm.toFixed(2);
    } else if (vWidth >= max_vw && vWidth < xl_vw) {
      return mid_line_height.toFixed(2);
    } else if (vWidth >= xl_vw && vWidth < xxl_vw) {
      return fluid_range_xl.toFixed(2);
    } else if (vWidth >= xxl_vw) {
      return max_line_height.toFixed(2);
    }
  }

  const textSizeMobile = (baseSize) => {
    const min_vw = 320;
    const max_vw = 400;
    const min_line_height = baseSize / 1.3;
    const mid_line_height = baseSize;
    const fluid_range_sm =
      min_line_height +
      (mid_line_height - min_line_height) *
      ((vWidth - min_vw) / (max_vw - min_vw));

    if (vWidth < min_vw) {
      return min_line_height.toFixed(2);
    } else if (vWidth >= min_vw && vWidth < max_vw) {
      return fluid_range_sm.toFixed(2);
    } else if (vWidth >= max_vw) {
      return mid_line_height.toFixed(2);
    }
  }

  const inputSize = (val, baseSize, inputRefCurrent) => {
    if (!val) return baseSize;
    const min_vw = 640;
    const max_vw = 1080;
    const xl_vw = 1920;
    const xxl_vw = 3840;
    const min_font_size = baseSize / 1.5;
    const mid_font_size = baseSize;
    const max_font_size = baseSize * 2.2;
    const fluid_range_sm =
      min_font_size +
      (mid_font_size - min_font_size) *
      ((vWidth - min_vw) / (max_vw - min_vw));
    const fluid_range_xl =
      mid_font_size +
      (max_font_size - mid_font_size) * ((vWidth - xl_vw) / (xxl_vw - xl_vw));

    const len = val.toString().length; // Housenumbers are stored as actual numbers serverside
    const width = inputRefCurrent ? inputRefCurrent.offsetWidth : 0;
    const ratio = 1.5; // Experiment with ratio. Bigger value = bigger text
    const fontSize = (width * ratio) / len;
    if (vWidth < min_vw) {
      return Math.max(
        min_font_size / 2,
        Math.min(min_font_size, fontSize)
      ).toFixed(2);
    } else if (vWidth >= min_vw && vWidth < max_vw) {
      return Math.max(
        fluid_range_sm / 2,
        Math.min(fluid_range_sm, fontSize)
      ).toFixed(2);
    } else if (vWidth >= max_vw && vWidth < xl_vw) {
      return Math.max(
        mid_font_size / 2,
        Math.min(mid_font_size, fontSize)
      ).toFixed(2);
    } else if (vWidth >= xl_vw && vWidth < xxl_vw) {
      return Math.max(
        fluid_range_xl / 2,
        Math.min(fluid_range_xl, fontSize)
      ).toFixed(2);
    } else if (vWidth >= xxl_vw) {
      return Math.max(
        max_font_size / 2,
        Math.min(max_font_size, fontSize)
      ).toFixed(2);
    }
  }

  const inputSizeMobile = (val, baseSize, inputRefCurrent) => {
    if (!val) return baseSize;

    const min_vw = 320;
    const max_vw = 400;
    const min_font_size = baseSize / 1.5;
    const mid_font_size = baseSize;
    const fluid_range_sm =
      min_font_size +
      (mid_font_size - min_font_size) *
      ((vWidth - min_vw) / (max_vw - min_vw));

    const len = val.toString().length === 0 ? 1 : val.toString().length; // Housenumbers are stored as actual numbers serverside
    const width = inputRefCurrent ? inputRefCurrent.offsetWidth : 0;
    const ratio = 1.5; // Experiment with ratio. Bigger value = bigger text
    const fontSize = (width * ratio) / len;
    // return Math.max(16, Math.min(32, fontSize)); // Value cannot exceed 32 or be lower than 16

    if (vWidth < min_vw) {
      return Math.max(
        mid_font_size / 2,
        Math.min(min_font_size, fontSize)
      ).toFixed(2);
    } else if (vWidth >= min_vw && vWidth < max_vw) {
      return Math.max(
        fluid_range_sm / 2,
        Math.min(fluid_range_sm, fontSize)
      ).toFixed(2);
    } else if (vWidth >= max_vw) {
      return Math.max(
        mid_font_size / 2,
        Math.min(mid_font_size, fontSize)
      ).toFixed(2);
    }
  }


  const scaleUpTo4K = (baseSize) => {
    const xl_vw = 1920;
    const xxl_vw = 3840;

    // what percentage of the viewport width is the baseSize of the element
    const percentage = (100 / xl_vw) * baseSize;
    if (isNaN(percentage)) {
      return undefined;
    }
    if (baseSize <= 1) {
      if (vWidth >= (xxl_vw / 4) * 3) {
        return baseSize * 2;
      } else {
        return baseSize;
      }
    }
    // scale up to desired size, with breakpoints
    if (vWidth <= xl_vw) {
      return baseSize;
    } else if (vWidth >= xxl_vw) {
      return (xxl_vw / 100) * percentage.toFixed(2);
    } else {
      return (((vWidth) / 100) * percentage).toFixed(2);
    }
  }

  const dropdownSize = (baseSize, inputRefCurrent) => {
    const min_vw = 640;
    const max_vw = 1080;
    const xl_vw = 1920;
    const xxl_vw = 3840;
    const min_font_size = baseSize / 1.5;
    const mid_font_size = baseSize;
    const max_font_size = baseSize * 2.2;
    const fluid_range_sm =
      min_font_size +
      (mid_font_size - min_font_size) *
      ((vWidth - min_vw) / (max_vw - min_vw));
    const fluid_range_xl =
      mid_font_size +
      (max_font_size - mid_font_size) * ((vWidth - xl_vw) / (xxl_vw - xl_vw));

    const ratio = 1.5; // Experiment with ratio. Bigger value = bigger text
    const fontSize = (20 * ratio)
    if (vWidth < min_vw) {
      return Math.max(
        min_font_size / 2,
        Math.min(min_font_size, fontSize)
      ).toFixed(2);
    } else if (vWidth >= min_vw && vWidth < max_vw) {
      return Math.max(
        fluid_range_sm / 2,
        Math.min(fluid_range_sm, fontSize)
      ).toFixed(2);
    } else if (vWidth >= max_vw && vWidth < xl_vw) {
      return Math.max(
        mid_font_size / 2,
        Math.min(mid_font_size, fontSize)
      ).toFixed(2);
    } else if (vWidth >= xl_vw && vWidth < xxl_vw) {
      return Math.max(
        fluid_range_xl / 2,
        Math.min(fluid_range_xl, fontSize)
      ).toFixed(2);
    } else if (vWidth >= xxl_vw) {
      return Math.max(
        max_font_size / 2,
        Math.min(max_font_size, fontSize)
      ).toFixed(2);
    }
  }

  const dropdownSizeMobile = (baseSize, inputRefCurrent) => {
    const min_vw = 320;
    const max_vw = 400;
    const min_font_size = baseSize / 1.5;
    const mid_font_size = baseSize;
    const fluid_range_sm =
      min_font_size +
      (mid_font_size - min_font_size) *
      ((vWidth - min_vw) / (max_vw - min_vw));

    // const len = val.toString().length === 0 ? 1 : val.toString().length; // Housenumbers are stored as actual numbers serverside
    // const width = inputRefCurrent ? inputRefCurrent.offsetWidth : 0;
    const ratio = 1.5; // Experiment with ratio. Bigger value = bigger text
    const fontSize = (20 * ratio)
    // return Math.max(16, Math.min(32, fontSize)); // Value cannot exceed 32 or be lower than 16

    if (vWidth < min_vw) {
      return Math.max(
        mid_font_size / 2,
        Math.min(min_font_size, fontSize)
      ).toFixed(2);
    } else if (vWidth >= min_vw && vWidth < max_vw) {
      return Math.max(
        fluid_range_sm / 2,
        Math.min(fluid_range_sm, fontSize)
      ).toFixed(2);
    } else if (vWidth >= max_vw) {
      return Math.max(
        mid_font_size / 2,
        Math.min(mid_font_size, fontSize)
      ).toFixed(2);
    }
  }
  return { inputSize, inputSizeMobile, scaleUpTo4K, textSize, textSizeMobile, dropdownSize, dropdownSizeMobile };
}
export default useScaling;
