import React from 'react';
import styled from 'styled-components';
import { Colors } from '@domain/theming';
import { isMobileOnly } from 'react-device-detect';
import { useScaling } from '@domain/hooks';

const StyledErrorMessage = styled.span`
  color: ${Colors.RED};
  font-size: ${props => props.dynamicFontSize ? (isMobileOnly
    ? props.$scaling.textSizeMobile(12)
    : props.$scaling.textSize(12)) + 'px' : '12px'}
  line-height: ${props => props.dynamicFontSize ? (isMobileOnly
    ? props.$scaling.textSizeMobile(14)
    : props.$scaling.textSize(14)) + 'px' : '14px'}
`;

const ErrorMessage = ({
  dynamicFontSize,
  children
}) => {

  const scaling = useScaling();
  return (
    <StyledErrorMessage
      dynamicFontSize={dynamicFontSize}
      $scaling={scaling}
    >
      {React.Children.toArray(children)}
    </StyledErrorMessage>
  );
};
export default ErrorMessage;
