import { useCallback, useEffect, useState, useRef } from 'react';
import { isMobile } from 'react-device-detect';

function useRadio(
  currentValue,
  incidentField,
  goToNextPage,
  saveValue,
  checkIfValueIsChosen = (x, y, _id) => x === y,
) {
  const [selectedValue, setSelectedValue] = useState(currentValue);
  const [hasClicked, setHasClicked] = useState(false);
  const requestMade = useRef(false);
  const saveIncident = useCallback(
    async (value) => {
      if (value && value !== currentValue && !requestMade.current) {
        requestMade.current = true;
        await saveValue(value);
      }
    },
    [currentValue, saveValue],
  );

  useEffect(() => {
    if (hasClicked) {
      saveIncident(selectedValue);
    }
  }, [hasClicked, saveIncident, selectedValue]);

  useEffect(() => {
    if (hasClicked && selectedValue === currentValue) {
      goToNextPage(selectedValue);
    }
  }, [currentValue, goToNextPage, hasClicked, selectedValue]);

  const isAutoFocus = useCallback(
    // TODO: does this work ?
    (value, id) => {
      if (isMobile) {
        return false;
      }

      if (checkIfValueIsChosen(currentValue, value, id)) {
        return true;
      }

      return id === 1;
    },
    [checkIfValueIsChosen, currentValue],
  );

  useEffect(() => {
    function handleEnterPress(event) {
      const { code } = event;
      const enter = 'Enter';
      if (code === enter) {
        saveIncident(selectedValue);
      }
    }

    document.addEventListener('keydown', handleEnterPress);

    return () => {
      document.removeEventListener('keydown', handleEnterPress);
    };
  }, [saveIncident, selectedValue]);

  async function handleChange(event, value) {
    event.persist();
    const { target, type } = event;
    value = value || target.value;

    if (type === 'click') {
      // used to check if(type === 'click' && clientX !== 0 && clientY !== 0) for unknown reasons.
      // Most likely for arrow keys navigation. The check was removed so keyboard shortcuts ( like M and V would work)
      setSelectedValue(value);
      setHasClicked(true);
    } else {
      setSelectedValue(value);
    }
  }

  return [handleChange, isAutoFocus, selectedValue];
}

export default useRadio;
