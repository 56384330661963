import React from 'react';

import { Colors, Shadows } from '@domain/theming';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { useScaling } from '@domain/hooks';

const HeaderContainer = styled(motion.div).attrs(props => ({
  className: 'header ' + props.className,
  ...props.animation,
}))`
  width: 100%;
  height: ${props => props.$scaling.scaleUpTo4K(props.height)}px;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 8px 0px;
  position: ${props => props.fixed ? 'absolute' : 'relative'};
  top: 0;
  background: ${props => props.background};
  border-bottom: ${props => !!props.border ? props.$scaling.scaleUpTo4K(props.border) : '0px'} solid ${props => !!props.border ? props.borderColor : Colors.GREY_PRIMARY};
  box-shadow: ${props => props.shadow ? Shadows.SHADOW3 : 'none'};
  padding: ${props => props.$scaling.scaleUpTo4K(10)}px;
  z-index: 1;
  + main {
    padding-top: ${props => props.fixed ? props.$scaling.scaleUpTo4K(83) + 'px' : '0px'}
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: flex-end;
  width:100%;
  height:  ${props => props.$scaling.scaleUpTo4K(props.height)}px;
  padding: ${props => props.$scaling.scaleUpTo4K(4)}px 0;
`;

const LogoImg = styled.img`
  width: auto;
  margin: auto;
  height: 80%;
`;

const variants = (height, scaling) => {
  return {
    hidden: {
      y: -(scaling.scaleUpTo4K(height + 6)),
      transition: {

      }
    },
    visible: {
      y: 0,
      transition: {
        ease: 'easeIn',
        duration: 0.3,
      }
    },
  }
}

const HeaderSP = ({
  animated = false,
  background,
  border,
  borderColor,
  className,
  fixed = true,
  height = 83,
  imgSrc = '/img/vindmijnhersteller.png',
  logoAlign = 'center',
  shadow = false
}) => {

  const scaling = useScaling();

  const headerAnimation = {
    initial: "hidden",
    animate: "visible",
    variants: animated && variants(height, scaling),
  }

  return (

    <HeaderContainer
      animation={headerAnimation}
      background={background}
      border={border}
      borderColor={borderColor}
      className={className}
      fixed={fixed}
      height={height}
      logoAlign={logoAlign}
      shadow={shadow}
      $scaling={scaling}
    >
      <LogoContainer $scaling={scaling} height={height}>
        <LogoImg
          src={imgSrc}
        />
      </LogoContainer>
    </HeaderContainer>
  );
};


export default HeaderSP;
