// NPM Imports.
import React from 'react';
import classnames from 'classnames';

function HeaderGroup({ children, className }) {
  const classes = classnames('header-group', className);

  return <div className={classes}>{React.Children.toArray(children)}</div>;
}

export default HeaderGroup;
