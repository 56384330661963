// NPM imports.
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { createGlobalStyle } from 'styled-components';

// Bounded context imports.
import Start from './Start';
import DamageImages from './DamageImages';
import OtherFilesUpload from './OtherFilesUpload';
import PoliceReport from './PoliceReport';
import GreenCard from './GreenCard';
import LicensePlateRegister from './LicensePlateRegister';
import Saf from './Saf';

// Routes for damage flow.
import routes from './routes';
import { useScaling } from '@domain/hooks';

import { useQuestionnaire } from '@domain/providers';
import { checkforValidPath } from '@domain/helpers';
import { useSelector } from 'react-redux';
const UploadsStyle = createGlobalStyle`
  .main__damage {
    .h1 {
      margin-bottom: 42px;
    }
  }
  .screen-desktop & {
    .main__damage {
      .grid__column--main {
        padding-right: 0;
      }
      .legend {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(24)}px;
      }
      h1 {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(50)}px;
        // max-width: 500px;
      }
      h1 + .button {
        margin-top: ${props => props.$scaling.scaleUpTo4K(70)}px;
      }
    }
  }
  @media screen and (max-width: 1028px) {
    .screen-desktop & {
      .main__damage {
        .grid__column--main {
          padding-right: 0;
        }
        .damage-region {
          margin: auto;
        }
        h1 {
          margin: 0 auto 50px;
        }
      }
    }
    .screen-ie & {
      .main__damage {
        .grid-container__main {
          // display:block;
          legend {
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
  @media screen and (max-width: 580px) {
    .screen-desktop & {
      .main__damage {
        h1 {
          margin-left: 0;
        }
      }
    }
    .screen-ie & {
      .main__damage {
        .grid-container__main {
          legend {
            text-align: left;
          }
        }
      }
    }
  }
`;

function Uploads({ history, match, location }) {
  const scaling = useScaling();
  const currentPath = match.path;

  const { questionnaire } = useQuestionnaire();

  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);
  const files = useSelector(state => state.files)

  const validPath = (!!questionnaire && !!questionnaire.find(page => page.path === location.pathname)) || (location.pathname.includes('/bekijken'))
  const newValidPath = checkforValidPath(incident, questionnaire, files, insurer).path

  return (
    <>
      <Switch>
        {(!!questionnaire && questionnaire.length && !validPath) && <Redirect to={newValidPath} />}
        <Route exact path={currentPath + routes.start} component={Start} />
        <Route exact path={currentPath + routes.otherFiles} component={OtherFilesUpload} />
        <Route exact path={currentPath + routes.saf} component={Saf} />
        <Route exact path={currentPath + routes.safRequired} component={Saf} />
        <Route exact path={currentPath + routes.policeReport} component={PoliceReport} />
        <Route path={currentPath + routes.damageImages} component={DamageImages} />
        <Route path={currentPath + routes.greenCard} component={GreenCard} />
        <Route path={currentPath + routes.licensePlateRegister} component={LicensePlateRegister} />
        <Redirect to={currentPath + routes.start} />
      </Switch>
      <UploadsStyle $scaling={scaling} />
    </>
  );
}

export default Uploads;
