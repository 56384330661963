import {
  HTTP_ERROR_ENCOUNTERED,
  HTTP_ERROR_CLEARED
} from '@domain/action-types';

const httpError = (state = null, { type, error }) => {
  switch (type) {
    case HTTP_ERROR_CLEARED:
      return null;
    case HTTP_ERROR_ENCOUNTERED:
      return { ...error };
    default:
      return state;
  }
};

export default httpError;
