import React, { Children, useEffect, useState } from 'react';

import { motion } from 'framer-motion';
import styled from 'styled-components';
import ContainerSP from '../ContainerSP';
import classNames from 'classnames';
import { isMobileOnly } from 'react-device-detect';
import UseIcon from '../UseIcon';

const StyledContainer = styled(motion(ContainerSP))`
  overflow: hidden;
  width: 100%;
  -webkit-mask-image: linear-gradient(to bottom, white 100%, transparent 100%);
  mask-image: linear-gradient(to bottom, white 100%, transparent 100%);
  &.closed {
    ${props => props.minLine > 0 && `-webkit-mask-image: linear-gradient(to bottom, white 100%, transparent 100%);
    mask-image: linear-gradient(to bottom, white 100%, transparent 100%);`}
  }

`;

const StyledDiv = styled.div`
margin: 0 auto;
`
const StyledUseIcon = styled(UseIcon)`
  transition: .25s ease-out;
  transform: rotate(90deg);
  .container & {
    align-self: center;
  }
  
  .open & {
    transform: rotate(-90deg) translateY(3px);
  }
  .screen-ie & {
    position: absolute;
    top: 0px;
    right: 24px;
    height: 100%;
  }

  color:
`;
const ExpandableTextBox = ({
  children,
  className,
  minLine = 5,
  max = 'auto',
  startOpen = true,
}) => {
  const [isOpen, setIsOpen] = useState(startOpen);


  const variants = {
    open: {
      height: max,
    },
    closed: {
      height: `${minLine <= 0 ? 'auto' : minLine * 17.5 + 'px'}`,
    }
  }
  const classNameList = classNames(className, { 'closed': !isOpen, 'maxed': minLine <= 0 })
  useEffect(() => {
    if (minLine <= 0) {
    }
  }, [minLine]);

  const handleOnClick = () => {
    if (minLine > 0) {
      setIsOpen(oldState => !oldState);
    }
  }

  return (
    <>
      <StyledContainer
        animate={minLine <= 0 ? 'open' : isOpen ? 'open' : 'closed'}
        className={classNameList}
        flexDirection="column"
        onClick={handleOnClick}
        transition={{ duration: 0.33 }}
        variants={variants}
        minLine={minLine}
      >
        {Children.toArray(children)}
      </StyledContainer>
      {isMobileOnly && <StyledDiv className={isOpen ? "container open" : "container"}><StyledUseIcon name="arrow-right" className={"button__icon"} width={46} height={46} onClick={handleOnClick} /></StyledDiv>}
    </>
  );
}

export default ExpandableTextBox;
