function base64PdfToBlob(base64String) {

  var binary = atob(base64String.replace(/\s/g, '')); // decode base64 string, remove space for IE compatibility
  var len = binary.length;
  var buffer = new ArrayBuffer(len);
  var view = new Uint8Array(buffer);
  for (var i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }            
  var blob = new Blob([view], { type: "application/pdf" });
  return blob;
}

export default base64PdfToBlob;
