import { object, array, string } from 'yup';

const fileSchema = object({
  cpID: string().required(),
  content: string().required(),
});

const validationSchema = validationText => array()
  .of(fileSchema)
  .min(1, validationText)

const validationSchemaNotRequired = array()
  .of(fileSchema)

export { validationSchema, validationSchemaNotRequired }