import moment from 'moment'
import { isIE } from 'react-device-detect'

function generateCalendarLink(events) {

  const icsEvents = events.map(event => {
    const { startDatetime, endDatetime, address, description, title, sequence, stamp, uid, organizer, email } = event

    const dtstamp = moment.utc(stamp).locale('nl').format('YYYYMMDDTHHmmss')
    const initialDatetime = moment.utc(startDatetime).locale('nl').format('YYYYMMDDTHHmmss')
    const finishDatetime = moment.utc(endDatetime).locale('nl').format('YYYYMMDDTHHmmss')
    const zipcode = address.zipcode || address.zipCode
    const location = `${address.street} ${address.houseNumber}, ${zipcode} ${address.city}`

    const icsEvent =
      `BEGIN:VEVENT
UID:${uid}
SUMMARY:${title}
DTSTAMP:${dtstamp}
DTSTART:${initialDatetime}
DTEND:${finishDatetime}
SEQUENCE:${sequence}
DESCRIPTION:${description}
URL:${document.URL}
ORGANIZER;CN=${organizer}:mailto:${email}
LOCATION:${location}
END:VEVENT`
    return icsEvent
  })

  const icsCalendar =
    `BEGIN:VCALENDAR
VERSION:2.0
METHOD:REQUEST
${icsEvents.join("\n")}
END:VCALENDAR`

  const blob = new Blob([icsCalendar], { type: 'text/calendar;charset=utf-8' });
  if (isIE) {
    window.navigator.msSaveOrOpenBlob(blob, `afspraak.ics`)
  } else {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', `afspraak.ics`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export default generateCalendarLink