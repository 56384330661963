import {
  LOGGED_IN,
  LOGGED_OUT,
  TOKEN_FOUND,
} from '@domain/action-types';
import { browserStorage } from '@domain/helpers';

const initialToken = '';

const token = (state = initialToken, { type, incident, token }) => {
  const storedToken = browserStorage.getAuthenticationToken() || '';
  const incidentToken = (!!incident && !!incident.token) ? incident.token : '';
  const newToken =
    !!incidentToken && incidentToken !== '' ?
      incidentToken :
      !!storedToken && storedToken !== '' && storedToken !== 'undefined' ?
        storedToken
        : '';
  switch (type) {
    case LOGGED_IN:
      return !!incident ? newToken : state;
    case LOGGED_OUT:
      return '';
    case TOKEN_FOUND:
      return token;
    default:
      return state;
  }
};

export default token;
