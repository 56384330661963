// NPM imports.
import React, { useMemo } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// Bounded context imports.
import Welcome from './Welcome';
import Landing from './Landing';
import Personal from './Personal';
import Damage from './Damage';
import Recovery from './Recovery';
import Uploads from './Uploads'
import OwnBodyshop from './OwnBodyshop'
import Support from './Support';
import ServiceAdvisor from './ServiceAdvisor'
import NoConfigPage from './NoConfigPage'
// Routes for top level onboarding flow.
import routes from './routes';
import { useSelector } from 'react-redux';
import { useThemes } from '@domain/hooks';
import { Loader } from '@domain/components';
import { browserStorage, checkforValidPath, checkInitialProgress, checkIsFeedback, checkIsNewStatusCase } from '@domain/helpers';
import { companyNames, proccessType } from '@domain/constants';
import { useQuestionnaire } from '@domain/providers';

function Onboarding({ history, location, match }) {
  const currentPath = match.path;
  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);
  const files = useSelector(state => state.files)

  const { questionnaire } = useQuestionnaire();
  const isThemed = !!insurer && insurer.brand !== companyNames.VIP && insurer.objectType !== proccessType.DI;

  const themes = useMemo(() => [isThemed ? 'theme-custom' : 'theme-def'], [isThemed]);
  useThemes(themes);

  if (!incident || !insurer || !questionnaire) {
    return <Loader />;
  }

  const currentProgress = checkInitialProgress(incident, questionnaire)
  const { damage, personal, recovery, uploads, landing, welcome, ownbodyshop, help, serviceAdvisor, noConfig } = routes;

  const isFeedback = checkIsFeedback(incident)
  const isNewStatusCase = checkIsNewStatusCase(incident)
  const inBodyshopChange = browserStorage.getInBodyshopChange()
  const newValidPath = checkforValidPath(incident, questionnaire, files, insurer).path

  const validPath = questionnaire && questionnaire.find(page => page.path === location.pathname)
  const redirect = !isNewStatusCase && (!isFeedback || (isFeedback && incident.portalStatus === 'Done')) && location.pathname !== newValidPath
  const lostInfo = validPath && isNewStatusCase && questionnaire.findIndex(page => page.path === location.pathname) > questionnaire.findIndex(page => page.path === newValidPath)

  const createdByExchange = incident && incident.createdBy === 'exchange'
  const schadegarantCase = insurer && insurer.objectType === proccessType.Schadegarant

  return (
    <Switch>
      {(redirect || lostInfo) && !schadegarantCase && !createdByExchange && !inBodyshopChange && <Redirect to={newValidPath} />}
      <Route path={currentPath + noConfig} component={NoConfigPage} />
      <Route path={currentPath + welcome} component={Welcome} />
      <Route path={currentPath + landing} component={Landing} />
      <Route path={currentPath + personal} component={Personal} />
      <Route path={currentPath + damage} component={Damage} />
      <Route path={currentPath + uploads} component={Uploads} />
      <Route path={currentPath + ownbodyshop} component={OwnBodyshop} />
      <Route path={currentPath + serviceAdvisor} component={ServiceAdvisor} />
      <Route path={currentPath + recovery} component={Recovery} />
      <Route path={currentPath + help} component={Support} />
      {!!questionnaire && questionnaire.length && questionnaire[currentProgress] && <Redirect to={questionnaire[currentProgress].path} />}
    </Switch>
  );
}

export default Onboarding;
