import { object, string } from 'yup';
import { regEx } from '@domain/constants';

const { emailRegEx, phoneRegEx } = regEx;

export default object({
  customerEmail: string()
    .matches(emailRegEx, 'Vul alstublieft een geldig e-mailadres in')
    .required()
    .label('E-mailadres'),
  customerMobileNumber: string()
    .matches(
      phoneRegEx,
      'Vul alstublieft een geldig telefoonnummer in (zonder spaties)',
    )
    .required()
    .label('Telefoonnummer'),
});
