import { companyNames, proccessType } from "@domain/constants"

const detectContentType = (insurer) => {

  if (insurer.objectType === proccessType.DI) {
    return 'DI'
  }
  if (insurer.objectType === proccessType.Schadegarant) {
    return 'schadegarant'
  }
  if (insurer.objectType === proccessType.Casco && (insurer.brand === companyNames.VIP || !insurer.brand)) {
    return 'VIP'
  }
  if (insurer.objectType === proccessType.EM) {
    return 'EM'
  }
  if (insurer.objectType === proccessType.DealerSass) {
    return 'dealerSaas'
  }
  if (insurer.objectType === proccessType.Casco && insurer.brand === companyNames.Rhion) {
    return 'rhionCasco'
  }
  if (insurer.objectType === proccessType.Intake) {
    return 'intake'
  }
  if (insurer.objectType === proccessType.IntakeSpecial) {
    return 'intakeSpecial'
  }
  if (insurer.objectType === proccessType.IntakePlus) {
    return 'intakePlus'
  }
  if (insurer.objectType === proccessType.DealerDistribution) {
    return "dealerDistribution"
  }
  return "notCustom"
}

export default detectContentType