import React, {
  Children,
  createContext,
  useEffect,
  useState,
} from 'react';

import { useProvider } from '@domain/hooks';
import { useSelector } from 'react-redux';
import questionnaire from '../../../Onboarding/questionnaire';

const QuestionnaireContext = createContext(null);

const QuestionnaireProvider = ({ children }) => {

  const displayName = 'Questionnaire'

  const insurer = useSelector((state) => state.insurer)
  const incident = useSelector((state) => state.incident)
  const pagesExcluded = useSelector(state => state.pagesExcluded);

  const [configuration, setConfiguration] = useState(null);
  const [usedPagesExcluded, setUsedPagesExcluded] = useState(pagesExcluded)

  function generateQuestionnaire(arrayToMap, pageToExcluded) {
    const jumpOverString = pageToExcluded || ""
    const configNames = arrayToMap.map(page => page.name.replace(/-/g, "_"))
    const finalConfig = configNames.filter(page => !jumpOverString.includes(page))
    const finalQuestionnaire = finalConfig.map(page => {
      const configPage = arrayToMap.find(pg => pg.name.replace(/-/g, "_") === page)
      const pages = questionnaire.find(pg => pg.name.replace(/-/g, "_") === page)
      const currentPage = configPage.required === false
        ? { ...configPage, ...pages, check: () => true }
        : { ...configPage, ...pages }
      return currentPage
    })
    return finalQuestionnaire
  }

  useEffect(() => {
    if (!insurer) {
      return
    }
    if ((!configuration || !configuration.length) && insurer.configuration) {
      setUsedPagesExcluded(pagesExcluded)
      const finalQuestionnaire = generateQuestionnaire(insurer.configuration, pagesExcluded)
      setConfiguration(finalQuestionnaire)
      return
    }
    if (incident && incident.status && incident.configuration && configuration && usedPagesExcluded !== pagesExcluded) {
      setUsedPagesExcluded(pagesExcluded)
      const finalQuestionnaire = generateQuestionnaire(incident.configuration, pagesExcluded)
      setConfiguration(finalQuestionnaire)
      return
    }
    if (!incident && insurer && insurer.configuration && configuration && usedPagesExcluded !== pagesExcluded) {
      setUsedPagesExcluded(pagesExcluded)
      const finalQuestionnaire = generateQuestionnaire(insurer.configuration, pagesExcluded)
      setConfiguration(finalQuestionnaire)
      return
    }
    if (!configuration && insurer && !insurer.configuration) {
      setUsedPagesExcluded(pagesExcluded)
      setConfiguration([{ "index": 1, "name": "no_configuration", check: () => true, path: "/onboarding/fout", required: true }])
      return
    }

  }, [insurer, incident, configuration, pagesExcluded, usedPagesExcluded])

  function updateQuestionnaire(incident) {
    const finalQuestionnaire = generateQuestionnaire(incident.configuration, pagesExcluded)
    setConfiguration(finalQuestionnaire)
    return
  }

  const values = {
    displayName,
    questionnaire: configuration,
    updateQuestionnaire,
  };

  return (
    <QuestionnaireContext.Provider value={values}>
      {Children.toArray(children)}
    </QuestionnaireContext.Provider>
  );
};

const useQuestionnaire = () => useProvider(QuestionnaireContext);

export { QuestionnaireProvider, useQuestionnaire };
