// NPM imports.
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

// Domain imports.
import { useSaveIncidentFactory, } from '@domain/hooks';
import { chooseLogoSize, generatePageContent, getPrevNextPagePath } from '@domain/helpers';
import { TimelinePage } from '@domain/pages';
import { companyNames, proccessType } from '@domain/constants';
import { Heading, Loader, Text } from '@domain/components';
import { detectContentType } from '@domain/content';
import { useQuestionnaire } from '@domain/providers';
import { ThemeContext } from 'styled-components';
function Start({ history, match }) {


  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer)
  const files = useSelector(state => state.files)
  const themeContext = useContext(ThemeContext);

  const { questionnaire } = useQuestionnaire();

  const currentPath = match.path;
  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined

  const saveIncident = useSaveIncidentFactory(incident, saveBehavior);

  if (!questionnaire.length || !incident || !insurer) {
    return <Loader />;
  }

  const isVIP = insurer.objectType === proccessType.Casco && (insurer.brand === companyNames.VIP || !insurer.brand)


  const pagePath = getPrevNextPagePath(
    history,
    questionnaire,
    incident,
    currentPath,
    files
  );
  const nextPage = pagePath.nextPage !== currentPath
    ? pagePath.nextPage
    : undefined;

  const contentType = detectContentType(insurer)
  const pageName = questionnaire.find(page => page.path === currentPath).name
  const content = generatePageContent(contentType, insurer, incident, false, false, pageName)

  const title = content["title"]
  const text = content["text"]
  const showTimeline = content["show-timeline"]
  const timelineTitle = content["timeline-title"]
  const timeline = content["timeline"]
  const useCustomLogo = content["use-custom-logo"]

  const middleLogo = {
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }

  const headerGroupContent = (
    <>
      {title && <Heading level="1" className="page-wizard__h1 h1__mobile">{title}</Heading>}
      {text && <Text>{text}</Text>}
    </>
  );

  return (
    <TimelinePage
      headerGroupContent={headerGroupContent}
      currentPath={currentPath}
      history={history}
      nextPage={nextPage}
      isVIP={isVIP}
      prevPage={pagePath.prevPage}
      questionnaire={questionnaire}
      saveIncident={saveIncident}
      showTimeline={showTimeline}
      timeline={timeline}
      unmuted={2}
      timelineHeader={timelineTitle}
      customMiddleLogo={useCustomLogo}
      middleLogo={middleLogo}
      files={files}
    />
  );
}

export default Start;
