// NPM imports.
import React from 'react';
import { useSelector } from 'react-redux';

// Domain imports.
import {
  Heading,
  Loader,
} from '@domain/components';
import { getPrevNextPagePath } from '@domain/helpers';
import { useSaveIncident, useCheckoutQuestionnaire } from '@domain/hooks';
import { TimelinePage } from '@domain/pages';
import { companyNames, proccessType } from '@domain/constants';

function Overview({ history, match }) {


  const currentPath = match.path;
  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer)

  const questionnaire = useCheckoutQuestionnaire()

  const saveIncident = useSaveIncident();

  const headerGroupContent = (
    <>
      <Heading level={'1'} className="checkinout__timeline--title">Check-out overzicht</Heading>
    </>
  );

  if (!incident || !insurer) {
    return <Loader />;
  }

  const pagePath = getPrevNextPagePath(history, questionnaire, incident, currentPath);
  const isVIP = insurer.objectType === proccessType.Casco && (insurer.brand === companyNames.VIP || !insurer.brand);
  const isNotObjectTypeDefined = !insurer.objectType

  const timeline = [
    {
      text: 'Controle vervangend vervoer',
      icon: 'car-out',
    },
    {
      text: 'Eigen risico afrekenen',
      icon: 'invoice',
    },
    {
      text: 'Vragen',
      icon: 'questionmark',
    },
  ];

  const insurerName = insurer.brandName || 'uw verzekeraar';
  const insurerLogo = insurer.logoURL || '';

  const middleLogo = {
    src: insurerLogo,
    alt: insurerName,
    className: isVIP ? 'vip-logo' : '',
    height: '60',
    // width: 'auto',
  }

  return (
    <TimelinePage
      buttonText="Volgende"
      currentPath={currentPath}
      headerGroupContent={headerGroupContent}
      history={history}
      nextPage={pagePath.nextPage}
      prevPage={pagePath.prevPage}
      questionnaire={questionnaire}
      saveIncident={saveIncident}
      timeline={timeline}
      unmuted={0}
      customMiddleLogo={isNotObjectTypeDefined}
      middleLogo={middleLogo}
    />
  )
}

export default Overview;
