import { date, object, string } from 'yup';

const licenseRegex = /^[0-9a-zA-Z]{6}$/;

const validationSchema = object({
  damageDate: date().required().label('Schadedatum'),
  licensePlate: string()
    .required()
    .length(6)
    .matches(licenseRegex, 'Vul alstublieft een geldig kenteken in')
    .trim()
    .uppercase()
    .label('Kenteken'),
});

export { validationSchema };
