import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';
import { Colors, Shadows } from '@domain/theming';
import { useScaling } from '@domain/hooks';

const StyledLabel = styled.label`
  align-items: center;
  background-color: transparent;
  border: solid 1px;
  border-radius: ${props => props.$scaling.scaleUpTo4K(16)}px;
  box-sizing: border-box;
  display: flex;
  flex: 0 0 50%;
  justify-content: center;
  font-size: ${props => props.$scaling.textSizeMobile(12)}px;
  line-height: ${props => props.$scaling.textSizeMobile(18)}px;
  font-weight: 700;
  cursor: pointer;
  min-height: ${props => props.$scaling.scaleUpTo4K(64)}px;
  padding: 0 ${props => props.$scaling.scaleUpTo4K(24)}px;
  text-transform: uppercase;
  text-align: center;
  transition: 160ms cubic-bezier(0.4, 0, 0.2, 1);
  @media (hover: hover) {
    :hover {
      transform: scale(0.99);
      transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
  &:focus {
    transform: scale(0.99);
    transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  &:active {
    transform: scale(0.98);
    transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  &.radio__input--checked {
    background: ${Colors.BLACK};
    color: ${Colors.WHITE};
    border-color: ${Colors.BLACK};
    box-shadow: ${Shadows.SHADOW6};
    @media (hover: hover) {
      &:hover {
        box-shadow: ${Shadows.SHADOW3};
      }
    }
    &:focus {
      box-shadow: ${Shadows.SHADOW3};
    }
    &:active {
      box-shadow: ${Shadows.SHADOW1};
    }
  }
  &.radio__label--unchecked {
    background: ${Colors.WHITE};
    color: ${Colors.GREY_DARK};
    border-color: ${Colors.GREY_DARK};
  }

  .screen-desktop & {
    font-size: ${props => props.$scaling.textSize(12)}px;
    line-height: ${props => props.$scaling.textSize(18)}px;
  }

  .screen-ie & {
    height: 0;
    padding-top: auto;
  }

  .theme-custom & {
    &.radio__label--checked {
      background: ${props => props.theme.colors.primary};
      border-color: ${props => props.theme.colors.primary};
    }
    &.radio__label--unchecked {
      border-color: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.primary};
      &:hover {
        border-color: ${props => props.theme.colors.dark};
        color: ${props => props.theme.colors.dark};
        background: ${props => props.theme.colors.light};
      }
    }
  }
`;

// eslint-disable-next-line react/display-name
const RadioLabel = React.forwardRef(({ defaultChecked, children, className, ...props }, ref) => {
  const scaling = useScaling();
  const classes = classNames(
    'radio__label',
    {
      'radio__label--checked': defaultChecked,
      'radio__label--unchecked': !defaultChecked
    },
    className
  );

  return (
    <StyledLabel className={classes} {...props} ref={ref} $scaling={scaling}>
      {React.Children.toArray(children)}
    </StyledLabel>
  );
})

RadioLabel.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  htmlFor: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default RadioLabel;
