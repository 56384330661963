import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';

import Start from './Start';
import CauseDamageCategory from './CauseDamage';
import CauseDamageAanrijding from './CauseDamage/aanrijding';
import CauseDamageAnders from './CauseDamage/anders';
import AnimalOwnerKnown from './AnimalOwnerKnown';
import CounterPartyKnown from './CounterPartyKnown';
import WitnessKnown from './WitnessKnown';
import WitnessAddress from './WitnessAddress';
import WitnessContact from './WitnessContact';
import WitnessName from './WitnessName';
import WitnessPostal from './WitnessPostal';
import Speed from './Speed';
import Interior from './Interior';
import Exterior from './Exterior';
import CauseDamageDropdpwn from './CauseDamageDropdown'
import GlassDamageSort from './GlassDamageSort'
import CauseDamageDropdownAanrijding from './CauseDamageDropdown/aanrijding';
import CauseDamageDropdownAnders from './CauseDamageDropdown/anders';
import CauseDamageGlassOverig from './CauseDamageGlass/overig'
import routes from './routes';
import { useScaling } from '@domain/hooks';
import DamageType from './DamageType';
import PolicyNumber from './PolicyNumber';
import DriveableDamage from './DriveableDamage';
import Mileage from './VehicleMileage';
import DamageNumber from './DamageNumber';
import CarOwner from './CarOwner';
import LeasingCompany from './LeasingCompany';
import PayingDamage from './PayingDamage';
import RecoverableDamage from './RecoverableDamage';
import PossibleWBF from './PossibleWBF';
import InsuranceType from './InsuranceType';
import ExtraDamage from './ExtraDamage';
import CarInfo from './CarInfo';
import CauseDamageCombinedDropdown from './CauseDamageCombinedDropdown'
import CreatedByDealer from './CreatedByDealer';
import { useQuestionnaire } from '@domain/providers';
import { checkforValidPath } from '@domain/helpers';

const DamageStyle = createGlobalStyle`
  .main__damage {
    .h1 {
      margin-bottom: 42px;
    }
  }
  .screen-desktop & {
    .main__damage {
      .grid__column--main {
        padding-right: 0;
      }
      .legend {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(24)}px;
      }
      h1 {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(50)}px;
        &.dropdown {
          margin-bottom: ${props => props.$scaling.scaleUpTo4K(70)}px
        }
      }
      h1 + .button {
        margin-top: ${props => props.$scaling.scaleUpTo4K(70)}px;
      }
    }
  }
  @media screen and (max-width: 1028px) {
    .screen-desktop & {
      .main__damage {
        .grid__column--main {
          padding-right: 0;
        }
        .damage-region {
          margin: auto;
        }
        h1 {
          margin: 0 auto 50px;
        }
      }
    }
    .screen-ie & {
      .main__damage {
        .grid-container__main {
          // display:block;
          legend {
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
  @media screen and (max-width: 580px) {
    .screen-desktop & {
      .main__damage {
        h1 {
          margin-left: 0;
        }
      }
    }
    .screen-ie & {
      .main__damage {
        .grid-container__main {
          legend {
            text-align: left;
          }
        }
      }
    }
  }
`;

const PersonalStyle = createGlobalStyle`
  .main__personal {
    .h1 {
      margin-bottom: 42px;
    }
  }

  .screen-desktop & {
    .main__personal {
      .legend {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(24)}px;
      }
      .h1 {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(70)}px;
        // max-width: ${props => props.$scaling.scaleUpTo4K(448)}px;
      }
    }
    .main__personal--gender {
      .h1 {
        // so that the heading is at the same vertical position then on the textInputPage before
        // margin-bottom: ${props => props.$scaling.scaleUpTo4K(93)}px;
      }
    }
  }

  @media screen and (max-width: 1028px)  {
    .screen-desktop & {
      .main__personal {
        .h1 {
          margin-bottom: 50px;
          // max-width: none;
        }
      }
    }
    .screen-ie & {
      .main__personal {
        .legend {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
  
  @media screen and (max-width: 580px) {
    .screen-desktop & {
      .form {
        flex-wrap: wrap;
        .form-input {
          flex: 1 1 100%;
          padding: 0 !important;
        }
      }
    }
    .screen-ie & {
      .main__personal {
        .legend {
          text-align: left;
        }
      }
    }
  }
`;

function Damage({ history, match, location }) {
  const scaling = useScaling();
  const bsLists = useSelector(state => state.bodyshopLists);

  useEffect(() => {
    if (!bsLists) {
      return;
    }
  }, [bsLists]);

  const { questionnaire } = useQuestionnaire();

  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);
  const files = useSelector(state => state.files)

  const currentPath = match.path;

  const validPath = (!!questionnaire && !!questionnaire.find(page => page.path === location.pathname))
    || (location.pathname === (currentPath + routes.causeDamageDropDown + routes.causeDamageAanrijding))
    || (location.pathname === (currentPath + routes.causeDamageDropDown + routes.causeDamageAnders))
    || (location.pathname === (currentPath + routes.causeDamageDropDown + routes.causeDamageOverig))
    || (location.pathname === (currentPath + routes.causeDamageAanrijding))
    || (location.pathname === (currentPath + routes.causeDamageAnders))
  const newValidPath = checkforValidPath(incident, questionnaire, files, insurer).path

  return (
    <>
      <Switch>
        {(!!questionnaire && questionnaire.length && !validPath) && <Redirect to={newValidPath} />}

        <Route exact path={currentPath + routes.start} component={Start} />
        <Route
          exact
          path={currentPath + routes.causeDamageCategory}
          component={CauseDamageCategory}
        />
        <Route
          exact
          path={currentPath + routes.causeDamageAanrijding}
          component={CauseDamageAanrijding}
        />
        <Route
          exact
          path={currentPath + routes.causeDamageAnders}
          component={CauseDamageAnders}
        />
        <Route exact path={currentPath + routes.animalOwnerKnown} component={AnimalOwnerKnown} />
        <Route exact path={currentPath + routes.carInfo} component={CarInfo} />
        <Route exact path={currentPath + routes.createdByDealer} component={CreatedByDealer} />
        <Route exact path={currentPath + routes.counterPartyKnown} component={CounterPartyKnown} />
        <Route exact path={currentPath + routes.witnessKnown} component={WitnessKnown} />
        <Route exact path={currentPath + routes.witnessAddress} component={WitnessAddress} />
        <Route exact path={currentPath + routes.witnessContact} component={WitnessContact} />
        <Route exact path={currentPath + routes.witnessName} component={WitnessName} />
        <Route exact path={currentPath + routes.witnessPostal} component={WitnessPostal} />
        <Route exact path={currentPath + routes.speed} component={Speed} />
        <Route exact path={currentPath + routes.interior} component={Interior} />
        <Route exact path={currentPath + routes.exterior} component={Exterior} />
        <Route exact path={currentPath + routes.glassDamageSort} component={GlassDamageSort} />
        <Route exact path={currentPath + routes.causeDamageDropDown} component={CauseDamageDropdpwn} />
        <Route exact path={currentPath + routes.causeDamageCombinedDropDown} component={CauseDamageCombinedDropdown} />
        <Route exact path={currentPath + routes.carOwner} component={CarOwner} />
        <Route exact path={currentPath + routes.leasingCompany} component={LeasingCompany} />
        <Route exact path={currentPath + routes.payingDamage} component={PayingDamage} />
        <Route exact path={currentPath + routes.recoverableDamage} component={RecoverableDamage} />
        <Route exact path={currentPath + routes.possibleWBF} component={PossibleWBF} />
        <Route exact path={currentPath + routes.insuranceType} component={InsuranceType} />
        <Route exact path={currentPath + routes.extraDamage} component={ExtraDamage} />
        <Route exact path={currentPath + routes.policyNumber} component={PolicyNumber} />
        <Route exact path={currentPath + routes.driveabledamage} component={DriveableDamage} />
        <Route exact path={currentPath + routes.vehicleMileage} component={Mileage} />
        <Route exact path={currentPath + routes.damageNumber} component={DamageNumber} />
        <Route
          exact
          path={currentPath + routes.causeDamageDropDown + routes.causeDamageAanrijding}
          component={CauseDamageDropdownAanrijding}
        />
        <Route
          exact
          path={currentPath + routes.causeDamageDropDown + routes.causeDamageAnders}
          component={CauseDamageDropdownAnders}
        />
        <Route
          exact
          path={currentPath + routes.causeDamageDropDown + routes.causeDamageOverig}
          component={CauseDamageGlassOverig}
        />
        <Route exact path={currentPath + routes.damageType} component={DamageType} />
        <Redirect to={currentPath + routes.start} />
      </Switch>
      <DamageStyle $scaling={scaling} />
      <PersonalStyle $scaling={scaling} />
    </>
  );
}

export default Damage;
