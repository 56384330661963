import React, { useCallback, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  RadioGroup,
  Radio,
  NavBar,
  IconLink,
  ProgressionIndicator,
  IconButton,
  FAQ,
  GridColumn,
  GridContainer,
  Legend,
  Heading,
  Main,
  Container,
  Loader,
  CompanyLogo
} from '@domain/components';
import { useFaq, useRadio, useSaveIncidentFactory, } from '@domain/hooks';
import { goToNextPage, getPrevNextPagePath, browserStorage, generatePageContent, chooseLogoSize } from '@domain/helpers';
import { detectContentType } from '@domain/content';
import { useQuestionnaire } from '@domain/providers';
import { ThemeContext } from 'styled-components';

const customerGender = 'customerGender';

function GenderCompleteOptions({ history, match }) {

  const { questionnaire } = useQuestionnaire();
  const insurer = useSelector((state) => state.insurer);
  const incident = useSelector((state) => state.incident);
  const files = useSelector(state => state.files)

  const themeContext = useContext(ThemeContext);

  const currentPath = match.path;
  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined

  const saveIncident = useSaveIncidentFactory(incident, saveBehavior);

  const currentValue = incident[customerGender];

  useEffect(() => {
    if (!!questionnaire && questionnaire.length) {
      const progress = questionnaire.find(page => page.path === currentPath).name
      browserStorage.setProgress(progress);
    }
  }, [currentPath, questionnaire])

  const [
    faqVisible,
    handleOnHelpClick,
    clickOutSideFaq,
    faqListProps,
    faqSeen,
  ] = useFaq();

  const isPageBeforeSuccess = questionnaire[questionnaire.length - 2].path === currentPath

  const saveValue = useCallback(
    async (value) => {
      await saveIncident({ ...incident, [customerGender]: value }, isPageBeforeSuccess);
    },
    [incident, saveIncident, isPageBeforeSuccess],
  );

  const [handleChange, isAutoFocus, selectedValue] = useRadio(
    currentValue,
    customerGender,
    () => goToNextPage(history, questionnaire, incident, currentPath, files),
    saveValue);

  if (!insurer || !questionnaire) {
    return <Loader />
  }

  const pagePath = getPrevNextPagePath(
    history,
    questionnaire,
    incident,
    currentPath,
    files
  );
  const nextPage = pagePath.nextPage !== currentPath
    ? pagePath.nextPage
    : undefined;

  const main = {
    faqVisible: faqVisible,
    dimmed: faqVisible,
    className: 'main__personal main__personal--gender',
    callBack: clickOutSideFaq,
  };

  const iconLink = (triggerKey) => ({
    type: 'arrow-left',
    theme: 'secondary',
    to: pagePath.prevPage,
    replace: true,
    triggerKey
  });

  const iconLinkForward = (triggerKey) => ({
    type: 'arrow-right',
    theme: 'secondary',
    to: nextPage && nextPage,
    replace: true,
    triggerKey
  });

  const iconButton = {
    type: faqVisible ? 'arrow-right' : 'questionmark',
    className: faqSeen ? '' : 'unclicked-faq-button',
    theme: 'secondary',
    onClick: handleOnHelpClick,
    faq: true,
  };

  const radio = (id, value, triggerKeys) => ({
    name: 'gender',
    id: `gender-option-${id}`,
    defaultChecked: selectedValue === value,
    value,
    onClick: handleChange,
    radioClassName: 'radio--gender',
    autoFocus: isAutoFocus(value, id),
    triggerKeys
  });

  const contentType = detectContentType(insurer)
  const pageName = questionnaire.find(page => page.path === currentPath).name
  const content = generatePageContent(contentType, insurer, incident, false, false, pageName)

  const legend = content["over-title-text"]
  const heading = content["title"]
  const customHelpLink = content["custom-help-link"]
  const helpLinkText = content["help-link-text"]
  const useCustomLogo = content["use-custom-logo"]
  const faqs = content["faqs"]

  const middleLogo = {
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }

  const chooseMiddleComponent = useCustomLogo
    ? <CompanyLogo {...middleLogo} />
    : <ProgressionIndicator steps={3} current={1} />
  return (
    <React.Fragment>
      <FAQ
        faqListProps={faqListProps}
        faqs={faqs}
        faqLink={insurer.helpcenterLink}
        customHelpLink={customHelpLink}
        helpLinkText={helpLinkText}
        faqVisible={faqVisible}
      />
      <Main {...main}>
        <NavBar>
          <Container className="icon-link-container">
            <IconLink {...iconLink([37])} />
            {nextPage && <IconLink {...iconLinkForward([39])} />}
          </Container>
          {chooseMiddleComponent}
          <Container className="faq-button-container">
            <IconButton {...iconButton} />
          </Container>
        </NavBar>
        <GridContainer main={true}>
          <GridColumn className="grid__column--main">
            <Legend>{legend}</Legend>
            <Heading level="1">{heading}</Heading>
          </GridColumn>
          <GridColumn
            justify="flex-end"
            className="grid__column--footer grid__column--radio-group"
          >
            <RadioGroup className='gender--more-opts'>
              <Radio {...radio(1, 'male', [77, 109, 49])}>Man</Radio>
              <Radio {...radio(2, 'female', [86, 118, 50])}>Vrouw</Radio>
              <Radio {...radio(3, 'other', [])}>Geen voorkeur</Radio>
            </RadioGroup>
          </GridColumn>
        </GridContainer>
      </Main>
    </React.Fragment>
  );
}

export default GenderCompleteOptions;
