import { object, string } from 'yup';

const postcodeRegEx = /^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i;

const validationSchema = object({
  zipCode: string()
    .matches(postcodeRegEx, 'Controleer alstublieft postcode.')
    .required()
    .label('Postcode of plaatsnaam')
});

export { validationSchema };
