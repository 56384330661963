// expects to receive the branding (the way it is today insurer). 
//when updated it is going to contain the enum key(string with the company name) and the brandLogoURL if there is any

function chooseLogoToBeDisplayed(insurer, isCustomLogoBG) {
  if (insurer === null) {
    return { src: "/img/transparent.png", alt: '' }
  }
  const { brand, logoURL, brandLogoURL, brandName } = insurer
  const customLogo = logoURL || brandLogoURL

  if (brand === 'Akzo') {
    return { src: "/img/akzo-logo.png", alt: 'Akzo Nobel logo' }
  }
  if (brand === 'Schadegarant') {
    return { src: "/img/schadegarant-logo.png", alt: 'Schadegarant logo' }
  }
  if (brand === 'VIP') {
    const customLogoBackground = isCustomLogoBG === 'black'
      ? { src: "/img/vip-logo-black.png", alt: 'VIP Schadeservice logo' }
      : { src: "/img/vip-logo-white.png", alt: 'VIP Schadeservice logo' }
    return customLogoBackground
  }
  const customLogoToDisplay = customLogo
    ? { src: customLogo, alt: `${brandName} logo` }
    : { src: "/img/transparent.png", alt: '' }
  return customLogoToDisplay
}

export default chooseLogoToBeDisplayed
