import { INSURER_FETCHED } from '@domain/action-types';

const insurer = (state = null, { type, insurer }) => {
  switch (type) {
    case INSURER_FETCHED:
      return { ...insurer };
    default:
      return state;
  }
};

export default insurer;
