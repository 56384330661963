import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { TOKEN_FOUND } from '@domain/action-types';
import { browserStorage } from '@domain/helpers'

const useTokenChecker = () => {
  const dispatch = useDispatch();
  const token = useSelector(state => state.token);
  const uuid = useSelector(state => state.uuid);
  const incident = useSelector(state => state.incident);

  useEffect(() => {
    //
    if (!uuid) {
      return;
    }
    const localToken = browserStorage.getAuthenticationToken()
    if (localToken && token !== localToken && localToken !== '') {
      dispatch({
        type: TOKEN_FOUND,
        token: localToken,
      });
    }

  }, [dispatch, incident, token, uuid]);
};

export default useTokenChecker;
