import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

const StyledListItem = styled.li`
  margin-right: 6px;
  height: 80px;
  width: 80px;
  border-radius: 8px;
  overflow: hidden;
  flex-shrink: 0;
  &.image-wheel-li--selected {
    border: 1px solid #707070;
  }
  .image-wheel-img {
    height: 80px;
    width: 80px;
    object-fit: cover;
    cursor: zoom-in;
  }
`;

function ImageWheelImage({ src, onClick, selected = false }) {
  const classes = classNames('image-wheel-li', {
    'image-wheel-li--selected': selected
  });

  return (
    <StyledListItem className={classes} onClick={onClick}>
      <img src={src} alt="uploaded" className="image-wheel-img" />
    </StyledListItem>
  );
}

ImageWheelImage.propTypes = {
  src: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool
};

export default ImageWheelImage;
