import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom"
import { IncidentService } from '@domain/services';
import { INCIDENT_UPDATED, IN_CREATION_STOP, TEMP_INCIDENT_CLEANED } from '@domain/action-types';
import useDispatchError from '../useDispatchError';
import { browserStorage, checkIsFeedback } from '@domain/helpers';
import { useQuestionnaire } from '@domain/providers';

const useSaveIncident = () => {
  const incidentSaving = useRef(false);
  const dispatch = useDispatch();
  const { dispatchNewError } = useDispatchError();
  const inCreation = useSelector(state => state.inCreation)
  const pagesExcluded = useSelector((state) => state.pagesExcluded);
  const flow = useSelector((state) => state.flow)
  const { updateQuestionnaire } = useQuestionnaire()

  const incident = useSelector((state) => state.incident);

  const location = useLocation()
  // Memoised because this function will sometimes be used in effects

  const saveIncident = useCallback(
    async (newDetails, isPageBeforeSuccess = false) => {
      if (incidentSaving.current) {
        return;
      }
      incidentSaving.current = true;
      const progressStr = browserStorage.getProgress() ? { progressStr: browserStorage.getProgress() } : {}
      newDetails = { ...newDetails, ...progressStr }
      const newAttibutes = Object.keys(newDetails)
      if (newAttibutes.every(name => newDetails[name] === incident[name])) {
        if (!isPageBeforeSuccess || incident.portalStatus === 'Done') {
          incidentSaving.current = false;
          return incident;
        }
      }

      const isFillingFeedback = checkIsFeedback(incident) && (location.pathname.includes('onboarding'))
      const FeedbackProcess = isFillingFeedback ? { FeedbackProcess: true } : {}
      const excludedPages = pagesExcluded ? { pagesExcluded } : {}
      const portalStatus = isPageBeforeSuccess ? { portalStatus: 'Done' } : {}
      const completeDetails = { ...incident, ...newDetails, ...excludedPages, ...portalStatus, ...FeedbackProcess }
      try {
        const incident = await IncidentService.save({ ...completeDetails });
        if (incident) {
          if (incident.errorCode === 24) {
            dispatchNewError(incident.errorCode);
          }
          if (flow === 'ONBOARDING' && incident.configuration) {
            updateQuestionnaire(incident)
          }
          dispatch({
            type: INCIDENT_UPDATED,
            incident,
          });

          if (inCreation) {
            dispatch({
              type: IN_CREATION_STOP,
            })
            dispatch({
              type: TEMP_INCIDENT_CLEANED
            });
            browserStorage.setInCreation(false)
          }
        }

        incidentSaving.current = false;

        return incident;
      } catch ({ error }) {
        console.error(`Error occurred inside useSaveIncident trying to post incident (IncidentService.save):`, error);
        dispatchNewError(error);
        incidentSaving.current = false;
        return {};
      }
    },
    [dispatch, incident, pagesExcluded, dispatchNewError, flow, inCreation, updateQuestionnaire, location.pathname],
  );

  return saveIncident;
};

export default useSaveIncident;
