import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';

// Domain imports.
import { Heading, ImageDialog, Loader } from '@domain/components';
import { useSaveIncidentFactory, } from '@domain/hooks';
import { getPrevNextPagePath, browserStorage, generatePageContent, chooseLogoSize, checkIsFeedback } from '@domain/helpers';
import { FilesUploadPage } from '@domain/pages';

import { validationSchema, validationSchemaNotRequired } from './validation-schema';
import { detectContentType } from '@domain/content';
import { useQuestionnaire } from '@domain/providers';

const imageDetails = {
  imagePath: `/img/groeneKaart.png`,
  imageAlt: 'groene Kaart',
  className: 'greencard--image',
}

const instructions = [
  {
    title: 'Klik hier om een voorbeeld te zien van de groene kaart',
    imagePath: `/img/groeneKaart.png`,
    imageAlt: 'groene Kaart',
    className: 'greencard--image',
  }
];

const StyledHeading = styled(Heading)`
  padding-top: 20px;
`
function GreenCard({ history, match }) {


  const { questionnaire } = useQuestionnaire();

  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);
  const files = useSelector(state => state.files)

  const themeContext = useContext(ThemeContext);

  const greenCardImages = files.greenCard
  const currentPath = match.path;
  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined

  const saveIncident = useSaveIncidentFactory(incident, saveBehavior);
  //Handle image modal

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!!questionnaire && questionnaire.length) {
      const progress = questionnaire.find(page => page.path === currentPath).name
      browserStorage.setProgress(progress);
    }
  }, [currentPath, questionnaire])

  if (!greenCardImages || !insurer || !incident || !questionnaire) {
    return <Loader />;
  }

  const isFeedback = checkIsFeedback(incident)

  const pagePath = getPrevNextPagePath(
    history,
    questionnaire,
    incident,
    currentPath,
    files,
    isFeedback,
  );
  const nextPage = pagePath.nextPage !== currentPath
    ? pagePath.nextPage
    : undefined;


  const isPageBeforeSuccess = questionnaire[questionnaire.length - 2].path === currentPath

  function feedbackCause() {
    const page = questionnaire.find(page => page.name === 'green_card')
    const feedbacktext = page.feedbacktext ? decodeURIComponent(page.feedbacktext) : null
    return feedbacktext
  }

  const contentType = detectContentType(insurer)
  const pageName = questionnaire.find(page => page.path === currentPath).name
  const content = generatePageContent(contentType, insurer, incident, false, false, pageName)

  const legend = content["over-title-text"]
  const heading = content["title"]
  const firstParagraph = content["intro-text"]
  const customHelpLink = content["custom-help-link"]
  const helpLinkText = content["help-link-text"]
  const useCustomLogo = content["use-custom-logo"]
  const mobileParagraph = content["mobile-text"]
  const secondParagraph = content["second-text"]
  const extraBlockText = content["extra-block-text"]
  const faqs = content["faqs"]
  const validationText = content["validation-text"]

  const validationSchemaToBeUsed = () => {
    if (page.required === false) {
      return validationSchemaNotRequired
    }
    return validationSchema(validationText)
  }

  const faqsMobile = faqs.concat({
    question: "Heeft u een voorbeeld van een groene kaart?",
    answer: (
      <dl>
        {instructions.map(({ title, explanation }, i) => (
          <React.Fragment key={title}>
            <div className="small__image"><img className={imageDetails.className} src={imageDetails.imagePath} alt={imageDetails.imageAlt} onClick={handleOpen} /></div>
          </React.Fragment>
        ))}
      </dl>
    ),
  })

  const middleLogo = {
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }

  const extraLeftBlock = (<React.Fragment>
    <StyledHeading level="4">
      <b>{extraBlockText}</b>
    </StyledHeading >
    <div className="small__image"><img className={imageDetails.className} src={imageDetails.imagePath} alt={imageDetails.imageAlt} onClick={handleOpen} /></div>
    <ImageDialog handleClose={handleClose} open={open} src={imageDetails.imagePath} alt={imageDetails.imageAlt} />
  </React.Fragment>
  )
  const dialogMobile = (<ImageDialog handleClose={handleClose} open={open} src={imageDetails.imagePath} alt={imageDetails.imageAlt} />)

  return (
    <FilesUploadPage
      history={history}
      nextPage={nextPage}
      prevPage={pagePath.prevPage}
      questionnaire={questionnaire}
      saveIncident={saveIncident}
      buttonText="Volgende"
      currentPath={currentPath}
      filesSetName="greenCard"
      filesSet={greenCardImages}
      feedbackCause={feedbackCause()}
      validationSchema={validationSchemaToBeUsed()}
      faqs={faqs}
      faqsMobile={faqsMobile}
      legend={legend}
      multiple={false}
      heading={heading}
      mobileParagraph={mobileParagraph}
      firstParagraph={firstParagraph}
      secondParagraph={secondParagraph}
      files={files}
      incident={incident}
      insurer={insurer}
      isPageBeforeSuccess={isPageBeforeSuccess}
      showDesktopParagraphOnMobile={true}
      extraLeftBlock={extraLeftBlock}
      dialogMobile={dialogMobile}
      isFeedback={isFeedback}
      customHelpLink={customHelpLink}
      helpLinkText={helpLinkText}
      customMiddleLogo={useCustomLogo}
      middleLogo={middleLogo}
    />
  )
}

export default GreenCard
