import checkInRoutes from './routes';
import routes from '../routes';

const { checkin } = routes;
const {
  chauffeur,
  end,
  instructions,
  overview,
  questions,
  signing,
  start,
  uploads,
} = checkInRoutes;

const questionnaire = [
  {
    name: 'instructions',
    path: checkin + instructions,
    check: () => true,
  },
  {
    name: 'start',
    path: checkin + start,
    check: () => true,
  },
  {
    name: 'chauffeur',
    path: checkin + chauffeur,
    check: () => true,
  },
  {
    name: 'timeline',
    path: checkin + overview,
    check: () => true,
  },
  {
    name: 'uploads',
    path: checkin + uploads,
    check: () => true,
  },
  // {
  //   name: 'damage',
  //   path: checkin + damage,
  //   check: () => true,
  // },
  // {
  //   name: 'closeUpUploads',
  //   path: checkin + closeUpUploads,
  //   check: () => true,
  // },
  // {
  //   name: 'signingDigital',
  //   path: checkin + signingDigital,
  //   check: () => true,
  // },
  {
    name: 'signing',
    path: checkin + signing,
    check: () => true,
  },
  // {
  //   name: 'takeIn',
  //   path: checkin + takeIn,
  //   check: () => true,
  // },
  {
    name: 'questions',
    path: checkin + questions,
    check: () => true,
  },
  {
    name: 'end',
    path: checkin + end,
    check: () => true,
  },
];

export default questionnaire;
