import { object, boolean } from 'yup';

function checkForTruthyValue(obj) {
  return Object.values(obj).some(value => value);
}

const shape = {
  BackRight: boolean(),
  BackLeft: boolean(),
  BackCentre: boolean(),
  CentreLeft: boolean(),
  CentreRight: boolean(),
  Centre: boolean(),
  FrontLeft: boolean(),
  FrontRight: boolean(),
  FrontCentre: boolean(),
};

export default object()
  .shape(shape)
  .test(
    'User must click area',
    'Selecteer tenminste één gebied',
    checkForTruthyValue,
  );
