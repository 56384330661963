import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import { generateColumnClasses } from '@domain/helpers';
import { useScaling } from '@domain/hooks';

const StyledColumn = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  &.grid__column--main {
    flex: 1 1 auto;
  }
  &.grid__column--footer {
    flex: 0 0 auto;
  }

  .screen-desktop & {
    flex: 1 1 auto;
    .two-third & {
      flex: 2 1 auto;
    }
    &.grid__column--footer {
      align-items: flex-end;
      padding-left: ${props => props.$scaling.scaleUpTo4K(60)}px;
      .validation-feedback {
        width: ${props => props.$scaling.scaleUpTo4K(240)}px;
        margin: 0;
        padding: ${props => props.$scaling.scaleUpTo4K(5)}px ${props => props.$scaling.scaleUpTo4K(20)}px; // to align with below button
      }
    }
  }

  //following should be deleted at some point
  &.grid__column--align__auto {
    align-items: normal;
  }
  &.grid__column--align__flex-start {
    align-items: flex-start;
  }
  &.grid__column--align__flex-end {
    align-items: flex-end;
  }
  &.grid__column--align__center {
    align-items: center;
  }
  &.grid__column--align__baseline {
    align-items: baseline;
  }
  &.grid__column--align__stretch {
    align-items: stretch;
  }
  &.grid__column--justify__flex-start {
    justify-content: flex-start;
  }
  &.grid__column--justify__flex-end {
    justify-content: flex-end;
  }
  &.grid__column--justify__center {
    justify-content: center;
  }
  &.grid__column--justify__space-between {
    justify-content: space-between;
  }
  &.grid__column--justify__space-around {
    justify-content: space-around;
  }
  &.grid__column--justify__space-evenly {
    justify-content: space-evenly;
  }

  @media screen and (max-width: 1028px) {
    .screen-desktop & {
      &.grid__column--main {
        width: 100%;
        max-width: 500px;
        text-align: center;
        margin: auto;
        height: auto;
        .validation-feedback {
          text-align: left;
        }
      }
      &.grid__column--footer {
        width: 100%;
        flex: 0 0 auto;
        // max-width: 500px;
        margin: auto;
        flex-direction: row;
        justify-content: flex-end;
        padding-left: 0;
      }
      &.grid__column--radio-group {
        max-width: 500px;
      }
    }
  }

  @media screen and (max-width: 580px) {
    .screen-desktop & {
      &.grid__column--main {
        text-align: left;
      }
      &.grid__column--footer {
        button {
          flex: 1;
        }
      }
    }
  }

`;

const maxNrOfGridColumns = 12;

function GridColumn({
  align = 'auto',
  children,
  className,
  grow,
  justify,
  order = 0,
  span = 12,
}) {
  // Memoize expensive operations that should only be called once.
  const memoizedColumnClasses = useMemo(
    () => generateColumnClasses(span, maxNrOfGridColumns),
    [span]
  );
  const classes = classNames(
    className,
    'grid__column',
    memoizedColumnClasses,
    `grid__column--align__${align}`,
    `grid__column--justify__${justify}`
  );
  const scaling = useScaling();

  return <StyledColumn className={classes} $scaling={scaling}>{React.Children.toArray(children)}</StyledColumn>;
}

GridColumn.propTypes = {
  align: PropTypes.oneOf([
    'auto',
    'flex-start',
    'flex-end',
    'center',
    'baseline',
    'stretch'
  ]),
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  grow: PropTypes.bool,
  justify: PropTypes.oneOf([
    'flex-start',
    'flex-end',
    'center',
    'space-between',
    'space-around',
    'space-evenly'
  ]),
  order: PropTypes.number,
  span: PropTypes.number,
};

export default GridColumn;
