import { object, string } from 'yup';
import { regEx } from '@domain/constants';

const { emailRegEx, phoneRegEx } = regEx;

const validationSchema = object({
  witnessEmail: string()
    .matches(emailRegEx, 'Vul alstublieft een geldig e-mailadres in')
    .label('E-mailadres')
    .required(),
  witnessMobileNumber: string()
    .matches(
      phoneRegEx,
      'Vul alstublieft een geldig telefoonnummer in (zonder spaties)',
    )
    .label('Telefoonnummer')
    .required(),
});

const validationSchemaNotRequired = object({
  witnessEmail: string()
    .matches(emailRegEx, { message: 'Vul alstublieft een geldig e-mailadres in', excludeEmptyString: true })
    .label('E-mailadres'),
  witnessMobileNumber: string()
    .matches(
      phoneRegEx, { message: 'Vul alstublieft een geldig telefoonnummer in (zonder spaties)', excludeEmptyString: true },
    )
    .label('Telefoonnummer'),
});

export { validationSchema, validationSchemaNotRequired };