const routes = {
  start: '/overzicht',
  license: '/kenteken',
  name: '/naam',
  postal: '/postcode-gegevens',
  address: '/adres-gegevens',
  contact: '/contact-gegevens',
  gender: '/geslacht-gegevens',
  genderCompleteOptions: '/geslacht'
};

export default routes;
