import { useEffect } from 'react';


const useInputBlur = (el, onBlur) => {
  useEffect(() => {
    if (el === null) {
      return;
    }

    el.addEventListener('blur', onBlur);

    return () => el.removeEventListener('blur', onBlur);
  }, [el, onBlur]);
};

export default useInputBlur;
