import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useSaveIncidentFactory, } from '@domain/hooks';
import { sanitisePhoneNumber, getPrevNextPagePath, generatePageContent, chooseLogoSize } from '@domain/helpers';
import { TextInputsPage } from '@domain/pages';
import { validationSchema, validationSchemaNotRequired } from './validation-schema';
import { Loader } from '@domain/components';
import { isMobileOnly } from 'react-device-detect';
import { detectContentType } from '@domain/content';
import { useQuestionnaire } from '@domain/providers';
import { ThemeContext } from 'styled-components';

function WitnessContact({ history, match }) {

  const { questionnaire } = useQuestionnaire();;
  const inCreation = useSelector(state => state.inCreation);
  const insurer = useSelector(state => state.insurer);
  const files = useSelector(state => state.files)
  const incident = useSelector((state) => state.incident);

  const currentPath = match.path;
  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined

  const saveIncident = useSaveIncidentFactory(incident, saveBehavior);
  const themeContext = useContext(ThemeContext);

  const controlValue = (name, value: string) => {
    if (name !== 'witnessMobileNumber') {
      return value;
    }
    const newValue = sanitisePhoneNumber(value);
    return newValue;
  };

  const faqs = useMemo(
    () => [
      {
        question: 'Waarom moet ik de gegevens van de getuige opgeven?',
        answer: 'Deze informatie helpt ons met het eventuele verhalen van uw schade. Indien nodig nemen wij ook contact op met de tegenpartij voor extra informatie.'
      }
    ], []);

  if (!insurer || !questionnaire || !incident) {
    return <Loader />;
  }

  const pagePath = getPrevNextPagePath(
    history,
    questionnaire,
    incident,
    currentPath,
    files
  );
  const nextPage = pagePath.nextPage !== currentPath
    ? pagePath.nextPage
    : undefined;

  const contentType = detectContentType(insurer)
  const pageName = page.name
  const content = generatePageContent(contentType, insurer, incident, inCreation, isMobileOnly, pageName)

  const validationSchemaToBeUsed = () => {
    if (page.required === false) {
      return validationSchemaNotRequired
    }
    return validationSchema
  }

  const customHelpLink = content["custom-help-link"]
  const helpLinkText = content["help-link-text"]
  const useCustomLogo = content["use-custom-logo"]
  const legend = content["over-title-text"]
  const heading = content["title"]

  const middleLogo = {
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }

  const fields = [
    {
      name: 'witnessEmail',
      label: 'E-mailadres',
      displayEndAdornment: page.required !== false
    },
    {
      name: 'witnessMobileNumber',
      label: 'Telefoonnummer',
      displayEndAdornment: page.required !== false
    },
  ];

  return (
    <TextInputsPage
      buttonText="Volgende"
      controlValue={controlValue}
      faqs={faqs}
      fields={fields}
      files={files}
      formID="contact"
      legend={legend}
      heading={heading}
      history={history}
      nextPage={nextPage}
      prevPage={pagePath.prevPage}
      currentPath={currentPath}
      progressIndicatorCurrent={1}
      progressIndicatorSteps={3}
      questionnaire={questionnaire}
      saveIncident={saveIncident}
      validationSchema={validationSchemaToBeUsed()}
      customHelpLink={customHelpLink}
      helpLinkText={helpLinkText}
      customMiddleLogo={useCustomLogo}
      middleLogo={middleLogo}
    />
  );
}

export default WitnessContact;
