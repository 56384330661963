import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import { useScaling } from '@domain/hooks';

const Styledheader = styled.header`
  display: flex;
  flex-direction: column;
  margin: 32px;

  .screen-desktop & {
    margin: ${props => props.$scaling.scaleUpTo4K(40)}px;
    margin-bottom: 0;
  }
`;


function Header({ children, className }) {
  const classes = classNames('header', className);
  const scaling = useScaling();

  return (
    <Styledheader className={classes} role="banner" $scaling={scaling}>
      {React.Children.toArray(children)}
    </Styledheader>
  );
}

Header.propTypes = {
  children: PropTypes.any
};

export default Header;
