import { object, string } from 'yup';

const postcodeRegEx = /^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i;
const houseNumberRegEx = /(^[0-9]+)([\s_-]*)([a-zA-Z0-9\s_-]*)/i;

const alreadyRejected = (badZipCodes: string[]) => (zipCode: string) =>
  !badZipCodes.includes(zipCode.toUpperCase().replace(/ /g, ''));

const validationSchema = badZipCodes => object({
  witnessZipCode: string()
    .matches(postcodeRegEx, 'Controleer alstublieft de postcode.')
    .test(
      'This postcode has already been rejected',
      'De postcode bestaat niet',
      alreadyRejected(badZipCodes)
    )
    .label('Postcode')
    .required(),
  witnessHouseNumber: string()
    .matches(houseNumberRegEx, 'Controleer alstublieft de huisnummer.')
    .label('Huisnummer')
    .required(),
  witnessHouseNumberAddition: string()
    .label('Toevoeging')
    .max(5)
})

const validationSchemaNotRequired = badZipCodes => object({
  witnessZipCode: string()
    .matches(postcodeRegEx, { message: 'Controleer alstublieft de postcode.', excludeEmptyString: true })
    .test(
      'This postcode has already been rejected',
      'De postcode bestaat niet',
      alreadyRejected(badZipCodes)
    )
    .label('Postcode'),
  witnessHouseNumber: string()
    .matches(houseNumberRegEx, { message: 'Controleer alstublieft de huisnummer.', excludeEmptyString: true })
    .label('Huisnummer'),
  witnessHouseNumberAddition: string()
    .label('Toevoeging')
    .max(5)
})

export { validationSchema, validationSchemaNotRequired };