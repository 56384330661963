// NPM imports.
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

// Domain imports.
import {
  Heading, Loader,
} from '@domain/components';
import { browserStorage, getPrevNextPagePath } from '@domain/helpers';
import { useSaveIncident } from '@domain/hooks';
import { TimelinePage } from '@domain/pages';

import questionnaire from '../questionnaire';
import { companyNames, proccessType } from '@domain/constants';

function End({ history, match }) {


  const currentPath = match.path;
  const incident = useSelector(state => state.incident);
  const bodyshop = useSelector(state => state.bodyshop);
  const insurer = useSelector(state => state.insurer)

  const saveIncident = useSaveIncident();

  const portalStatus = 'portalStatus'
  // To tell the core platform the check-out was completed
  // No dependencyArray as second argument to have run only once on rendering
  useEffect(() => {
    if (incident) {
      saveIncident({
        ...incident,
        [portalStatus]: 'Done',
      });
      const storageList = Object.keys(localStorage);
      browserStorage.clearLocalStorage([...storageList], ['consentCode', 'bannerOpen', 'uuid']);
    }
  }, [incident]);

  const pagePath = getPrevNextPagePath(history, questionnaire, incident, currentPath);

  const headerGroupContent = (
    <>
      <Heading level={'1'} className="checkinout__timeline--title">De check-in is compleet!</Heading>
    </>
  );

  if (!incident || !insurer || !bodyshop) {
    return <Loader />;
  }
  const bodyshopName = bodyshop.companyName;
  const isVIP = insurer.objectType === proccessType.Casco && (insurer.brand === companyNames.VIP || !insurer.brand)

  const timeline = [
    {
      text: 'Schade melden en dossier opstellen',
      icon: 'mail',
    },
    {
      text: `Hersteller ${bodyshopName} neemt binnen één werkdag contact met u op`,
      icon: 'user',
    },
    {
      text: 'Uw auto wordt opgehaald',
      icon: 'towtruck',
    },
    {
      text: 'Uw auto wordt hersteld',
      icon: 'wrench',
    },
    {
      text: 'Uw auto wordt ook gratis weer bij u terug gebracht',
      icon: 'car-out',
    },
  ];

  const insurerName = insurer.brandName || 'uw verzekeraar';
  const insurerLogo = insurer.logoURL || '';

  const middleLogo = {
    src: insurerLogo,
    alt: insurerName,
    className: isVIP ? 'vip-logo' : '',
    height: '60',
    // width: 'auto',
  }
  return (
    <TimelinePage
      backgroundVideo={true}
      buttonDisplayed={false}
      buttonText="Volgende"
      currentPath={currentPath}
      headerGroupContent={headerGroupContent}
      history={history}
      prevPage={pagePath.prevPage}
      questionnaire={questionnaire}
      saveIncident={saveIncident}
      timeline={timeline}
      unmuted={2}
      isVIP={isVIP}
      customMiddleLogo={!isVIP}
      middleLogo={middleLogo}
    />
  )
}

export default End;
