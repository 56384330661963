import React, { useMemo, useContext } from 'react';

import { chooseLogoSize, chooseLogoToBeDisplayed } from '@domain/helpers';
import { useThemes, useScaling } from '@domain/hooks';
import styled, { ThemeContext } from 'styled-components';

import {
  Button,
  CompanyLogo,
  Container,
  HeaderGroup,
  IconLink,
  Main,
  NavBar,
  Section,
  Text,
  Timeline,
  TimelineIcon,
  TimelineItem,
  UseIcon,
  Heading
} from '@domain/components';
import { Colors } from '@domain/theming';
import { companyNames } from '@domain/constants';

import routes from '../routes';
import baseRoutes from '../../routes';



const StyledMain = styled(Main)`
  .page-wizard__content {
    display: flex;
    flex-direction: column;
    padding: 32px 32px 0;
  }
  .page-wizard__heading {
    flex: 1 1 auto;
    .h1 {
      margin: 30px 0 12px;
      word-break: break-word;
    }
  }
  .page-wizard__info {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    .screen-desktop & {
      flex: 1 1 auto;
      width: 50%;
      height: 50%;
      justify-content: flex-start;
    }
    .button {
      margin-top: 15px;
      width: 100%;
      &__contact {
        height: auto;
        text-align: left;
        padding: 24px;
        .header-group {
          margin-right: auto;
        }
        span {
          text-transform: initial;
          font-weight: normal;
        }
        .h5 {
          margin-bottom: 15px;
        }
      }
    }
  }
  .page-wizard__timeline {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 50px 0;
    .page-wizard__timeline--heading {
      margin-bottom: 10px;
    }
  }
  .page-wizard__button {
    padding: 0 32px 32px;
    justify-content: stretch;
    button {
    }
  }

  .screen-desktop & {
    .page-wizard__content {
      flex-direction: row;
      padding: 22px 40px 0;
      align-items: center;
    }
    .page-wizard__heading {
      padding-left: ${props => props.$scaling.scaleUpTo4K(80)}px;
      .h1 {
        margin-bottom: 0;
        // max-width: ${props => props.$scaling.scaleUpTo4K(500)}px;
      }
    }
    .page-wizard__text {
      display: block;
      // max-width: ${props => props.$scaling.scaleUpTo4K(380)}px;
      margin-top: ${props => props.$scaling.scaleUpTo4K(20)}px;
      + .page-wizard__text {
        margin-top: 0;
      }
    }
    .page-wizard__info {
      flex: 1 1 auto;
      width: 50%;
      height: 50%;
      justify-content: flex-start;
    }
    .page-wizard__timeline {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      padding: 0;
      padding-right: ${props => props.$scaling.scaleUpTo4K(120)}px;
      padding-left: ${props => props.$scaling.scaleUpTo4K(40)}px;
      .page-wizard__timeline--heading {
        width: 100%;
        max-width: ${props => props.$scaling.scaleUpTo4K(350)}px;
        display: block;
      }
      .timeline {
      }
    }
    .page-wizard__button {
      padding: 0 ${props => props.$scaling.scaleUpTo4K(40)}px ${props => props.$scaling.scaleUpTo4K(40)}px;
      button {
        margin-left: auto;
      }
    }
  }

  .theme-custom & {
    .page-wizard__timeline--heading {
      color: ${props => props.theme.colors.primary};
    }
    .timeline__item {
      color: ${props => props.theme.colors.primary};
      .timeline__icon {
        color: ${props => Colors.WHITE};
        background: ${props => props.theme.colors.primary};
      }
    }
  }
  .theme-dark & {
    .timeline__item {

      color: ${Colors.WHITE};
      .timeline__icon {
        color: ${Colors.GREY_DARKER};
        background: ${Colors.WHITE};
      }
    }
  }

  @media screen and (max-width: 1263px) {
    .screen-desktop & {
      .page-wizard__heading {
        padding-left: 20px;
      }
      .page-wizard__timeline {
        padding-right: 40px;
      }
    }
  }

  @media screen and (max-width: 1028px)  {
    .screen-desktop & {
      .page-wizard__content {
        flex-direction: column;
        justify-content: flex-start;
      }
      .page-wizard__heading {
        text-align: center;
        padding: 0;
      }
      .page-wizard__info {
        width: 100%;
        .button {
          max-width: 580px;
        }
      }
      .page-wizard__button {
        padding-top: 50px;
      }
      .page-wizard__text {
        width: auto;
        margin: auto;
      }
      .page-wizard__timeline {
        padding: 50px 0;
        .timeline {
        }
      }
    }
    .screen-tablet & {
      .page-wizard__content {
        flex-direction: row;
      }
      .page-wizard__heading {
        flex: 1 1 50%;
        margin-bottom:20%;
      }
      .page-wizard__timeline {
        flex: 1 1 50%;
        padding-right:40px;
      }
    }

  @media screen and (max-width: 580px)  {
    .screen-desktop & {
      .page-wizard__button {
        button {
          width: 100%;
        }
      }
    }
  }

  .screen-ie & {
    .page-wizard__content {
      flex: 1 0 auto;
      height: auto;
      min-height: 454px;
    }
    .page-wizard__button {
      flex: 0 0 auto;
    }
  }
`;

function TimelinePage({ location, history, match, branding, search }) {

  const scaling = useScaling();

  const isSchadegarant = branding.brand === companyNames.Schadegarant || false;
  const isRhion = branding.brand === companyNames.Rhion || false;
  const isVIP = branding.brand === companyNames.VIP || !branding.brand;
  const isDI = false; // until it's used on for DI bodyshops
  const isThemed = branding.brand !== companyNames.VIP && !isDI;
  const isSaas = (!isDI && !isVIP && !isSchadegarant) || false;
  const isNN = isSaas || isSchadegarant;

  const themes = useMemo(() => isThemed ? ['theme-custom'] : ['theme-dark'], [isThemed]);
  useThemes(themes);

  const themeContext = useContext(ThemeContext);
  async function handleOnClick() {
    history.push(baseRoutes.preview + routes.question + search);
  }


  const timelineHeader = isSaas ? 'Wat moet u doen' : null;

  const personalTimeline = [
    {
      text: 'Vul uw persoonlijke gegevens in zodat we u kunnen bereiken.',
      icon: 'user'
    },
    {
      text:
        'Beantwoord vier vragen over uw schade zodat we deze goed in kaart kunnen brengen.',
      icon: 'questionmark'
    },
    {
      text:
        `Door fotos en aanvullende documenten te leveren kunnen wij veel sneller aan de slag met uw schade.`,
      icon: 'file'
    },
    {
      text: isVIP ? !isNN ? 'U wordt direct gekoppeld aan uw VIP Schadeservice herstelbedrijf.' :
        'U wordt direct gekoppeld aan een schadeherstelbedrijf'
        : 'Wij bellen u voor het maken van een herstelafspraak.',
      icon: 'star'
    }
  ];

  const saasTimeline = [
    {
      text: 'Klik op “Volgende” om uw persoonlijke gegevens in te vullen.',
      icon: 'user'
    },
    {
      text: 'Voeg een beschrijving van de schade toe.',
      icon: 'questionmark'
    },
    {
      text:
        `Upload vijf foto’s van de schade.`,
      icon: 'file'
    },
    {
      text: 'U wordt direct gekoppeld aan een herstelbedrijf.',
      icon: 'star'
    }
  ];

  const timeline = isSaas ? saasTimeline : personalTimeline;
  const unmuted = 0;

  const iconLink = {
    type: 'arrow-left',
    theme: isThemed ? 'secondary' : 'primary',
    to: baseRoutes.preview + routes.welcome + search,
  };

  const iconLinkForward = {
    type: 'arrow-right',
    theme: isThemed ? 'secondary' : 'primary',
    to: baseRoutes.preview + routes.question + search,
  };

  const fakeInsurer = {
    brand: branding.brand,
    brandLogoURL: branding.logoURL,
    brandName: branding.brandName,
  }
  const companyLogo = {
    ...chooseLogoToBeDisplayed(fakeInsurer),
    className: isVIP ? 'vip-logo' : '',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  };

  const timelineIcon = (item, i) => ({
    name: item.icon,
    className: 'timeline__item__icon',
    timeline: true,
    muted: i !== unmuted,
  });

  const button = {
    onClick: handleOnClick,
    className: 'button--icon button--next__page',
    theme: isThemed ? 'primary' : 'secondary',
    justify: 'space-between'
  };

  const text = () => {
    if (isVIP) {
      return 'In vier stappen het beste herstelbedrijf voor uw schade.'
    }
    if (isSaas) {
      return 'Hiernaast ziet u de stappen om uw schade volledig te melden.'
    } else {
      return 'In 4 stappen uw schade volledig online beschreven'
    }
  }

  const headerGroupContent = (
    <>
      {' '}
      <Heading level="1" className="page-wizard__h1 h1__mobile">
        Beste klant,
      </Heading>
      <Text className="page-wizard__text">
        {text()}
      </Text>
      {isSaas && <Text className="page-wizard__text">
        {isRhion ? <>
          Heeft u alle informatie toegevoegd? Dan stellen wij het meest geschikte herstelbedrijf aan u voor. <br /> Het herstelbedrijf neemt binnen één werkdag contact met u op om een afspraak in te plannen en komt naar u toe om uw auto op te halen.
        </> : 'Heeft u alle informatie toegevoegd? Dan stellen wij het meest geschikte herstelbedrijf aan u voor.'}
      </Text>}
    </>
  );


  return (
    <div className="screen-desktop">
      <StyledMain className="main__damage--start" $scaling={scaling}>
        <NavBar blackPage={!isThemed} isVIP={isVIP}>
          <Container className="icon-link-container">
            <IconLink {...iconLink} />
            <IconLink {...iconLinkForward} />
          </Container>
          <CompanyLogo {...companyLogo} />
          <div className="empty-div" />
        </NavBar>
        <Section className="page-wizard__content">
          <Container className="page-wizard__heading">
            <HeaderGroup className={"header-group-timeline"}>{headerGroupContent}</HeaderGroup>
          </Container>

          <Container className="page-wizard__timeline">
            {timelineHeader && <Heading level={'4'} className="page-wizard__timeline--heading">{timelineHeader}</Heading>}
            <Timeline>
              {timeline.map((item, i) => (
                <TimelineItem key={i} muted={i !== unmuted} active={i === unmuted}>
                  <TimelineIcon {...timelineIcon(item, i)} />
                  <Text className="timeline__text">{item.text}</Text>
                </TimelineItem>
              ))}
            </Timeline>
          </Container>
        </Section>
        <Container className="page-wizard__button">
          <Button {...button}>
            Volgende
            <UseIcon name="arrow-right" className="button__icon" />
          </Button>
        </Container>
      </StyledMain>
    </div>
  );
}

export default TimelinePage;
