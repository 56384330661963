/* eslint-disable */
import { setLocale } from 'yup'

// see https://github.com/jquense/yup/blob/master/src/locale.js for reference
// ${path} will be the name of the input or the label supplied in the yup schema
setLocale({
  mixed: {
      default: '${path} is niet correct',
      required: '${path} is een verplicht veld',
      oneOf: '${path} moet een van de volgende waardes zijn: ${values}',
      notOneOf: '${path} mag niet een van de volgende waardes zijn: ${values}',
      // implementation of notType is over simplified: please see https://github.com/jquense/yup/blob/master/src/locale.js for reference
      notType: '${path} is niet correct ingevuld',
  },
  string: {
      length: '${path} moet exact ${length} karakters zijn',
      min: '${path} moet minimaal ${min} karakters zijn',
      max: '${path} mag maximaal ${max} karakters zijn',
      matches: '${path} must match the following: "${regex}"',
      email: '${path} moet een geldig e-mailadres zijn',
      url: '${path} moet een geldige URL zijn',
      trim: '${path} mag geen spaties bevatten',
      lowercase: '${path} mag geen hoofdletters bevatten',
      uppercase: '${path} mag geen kleine letters bevatten',
  },
  number: {
      min: '${path} moet groter of gelijk zijn aan ${min}',
      max: '${path} moet minder of gelijk zijn aan ${max}',
      less: '${path} moet minder zijn dan ${less}',
      more: '${path} moet groter zijn dan ${more}',
      positive: '${path} moet een positief nummer zijn',
      negative: '${path} moet een negatief nummer zijn',
      integer: '${path} moet een getal zijn',
  },

  date: {
      min: '${path} moet later zijn dan ${min}',
      max: '${path} moet eerder zijn dan ${max}',
  },
  boolean: {},
  object: {
      noUnknown: '${path} mag geen ongeldige waarden bevatten.',
  },
  array: {
      min: '${path} moet minimaal ${min} items bevatten',
      max: '${path} mag maximaal ${max} items bevatten',
  },
})
