import checkIsFeedback from "../checkIsFeedback";
import checkIsNewStatusCase from "../checkIsNewStatusCase";

const checkforValidPath = (incident, questionnaire, files = [], insurer) => {

  const isFeedback = checkIsFeedback(incident)
  const isNewStatusCase = checkIsNewStatusCase(incident)

  if (!isNewStatusCase && !isFeedback) {
    return questionnaire[questionnaire.length - 1]
  }

  const nextValidPage = questionnaire.find((page, i) => {
    const { check, feedbackCheck } = page;
    const pageFilled =
      isFeedback
        ? feedbackCheck(incident, files)
        : check(incident, files);
    return !pageFilled
  })

  if (nextValidPage) {
    return nextValidPage
  }

  return questionnaire[questionnaire.length - 1]

}

export default checkforValidPath