import { object, string } from 'yup';

const mileageRegEx = /^[0-9]+$/i;


export const validationSchema = object({
  vehicleMileage: string()
    .matches(mileageRegEx, 'Controleer alstublieft uw mileage.')
    .required()
    .label('kilometerstand'),
});



