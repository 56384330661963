import React from 'react';
import styled from 'styled-components';

const LogoContainer = styled.div`
  width: ${props => props.width || '250px'};
  height: ${props => props.height || 'auto'};
  max-width: ${props => props.maxWidth};
  max-height: ${props => props.maxHeight};
  margin: auto;
`;

const LogoImg = styled.img`
  width: 100%;
  height: auto;
`;

const Logo = ({
  width,
  height,
  maxWidth,
  maxHeight,
  imgURL
}) => {
  return (
    <LogoContainer width={width} height={height} maxWidth={maxWidth} maxHeight={maxHeight} className="logo">
      <LogoImg src={imgURL || '/img/vip-logo-white.png'} />
    </LogoContainer>
  );
}

export default Logo;
