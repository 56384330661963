function pick(object, keys) {
  return keys.reduce((obj, key) => {
    if (object) {
      obj[key] = object[key] || '';
    }
    return obj;
  }, {});
}

export default pick;
