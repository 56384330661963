import React, { useEffect, useState } from 'react';

import {
  Main,
  Legend,
  Heading,
  RadioGroup,
  Radio,
  GridContainer,
  GridColumn,
  NavBar,
  IconLink,
  ProgressionIndicator,
  IconButton,
  FAQ,
  Container,
  Loader,
  CompanyLogo
} from '@domain/components';
import { causeDamageCategories, companyNames } from '@domain/constants';
import {
  getPrevNextPagePath,
  goToNextPage,
  stripLastPathSegment,
  browserStorage,
  generatePageContent,
  chooseLogoSize
} from '@domain/helpers';
import { useFaq, useRadio, useSaveIncidentFactory } from '@domain/hooks';

import routes from '../routes';
import { useSelector } from 'react-redux';
import { detectContentType } from '@domain/content';
import { useQuestionnaire } from '@domain/providers';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';


const causeDamage = 'causeDamage';

function CauseDamageAanrijding({ history, match }) {

  const { questionnaire } = useQuestionnaire();

  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);
  const files = useSelector(state => state.files)
  const [
    faqVisible,
    handleOnHelpClick,
    clickOutSideFaq,
    faqListProps,
    faqSeen,
  ] = useFaq();

  const themeContext = useContext(ThemeContext)

  const currentValue = incident.causeDamage;

  // not true. But this page doesn't exist on the questionnaire, so I am using the previous one as a reference to generate the next page
  const currentPath = '/onboarding/schade-beschrijven/oorzaak'

  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined

  const saveIncident = useSaveIncidentFactory(incident, saveBehavior);

  useEffect(() => {
    if (!!questionnaire && questionnaire.length) {
      const progress = questionnaire.find(page => page.path === currentPath).name
      browserStorage.setProgress(progress);
    }
  }, [currentPath, questionnaire])

  const { causeDamageCategory } = routes;

  const [causeDamageSelected, setCauseDamageSelected] = useState(null);

  useEffect(() => {
    if (!!insurer && insurer.objectType === companyNames.Schadegarant && causeDamageSelected && incident.causeDamage) {
      goToNextPage(history, questionnaire, incident, currentPath, files)
    }
    if (causeDamageSelected && incident.causeDamage) {
      goToNextPage(history, questionnaire, incident, currentPath, files)
    }
  }, [questionnaire, causeDamageSelected, history, incident, insurer, files])

  const isPageBeforeSuccess = questionnaire[questionnaire.length - 2].path === currentPath

  const [handleChange, isAutoFocus, selectedValue] = useRadio(
    currentValue,
    causeDamage,
    (value) => { /* don't do anything, wait for the incident to be update in the redux store */setCauseDamageSelected(value) },
    value => saveIncident({ [causeDamage]: value }, isPageBeforeSuccess),
  );

  if (!insurer || !questionnaire) {
    return <Loader />;
  }

  const pagePath = getPrevNextPagePath(
    history,
    questionnaire,
    incident,
    currentPath,
    files
  );
  const nextPage = pagePath.nextPage !== currentPath
    ? pagePath.nextPage
    : undefined;


  const prevPage = stripLastPathSegment(match.path) + causeDamageCategory;

  const main = {
    faqVisible: faqVisible,
    dimmed: faqVisible,
    className: 'main__damage',
    callBack: clickOutSideFaq,
  };

  const iconLink = (triggerKey) => ({
    type: 'arrow-left',
    theme: 'secondary',
    to: prevPage,
    replace: true,
    triggerKey
  });

  const iconLinkForward = (triggerKey) => ({
    type: 'arrow-right',
    theme: 'secondary',
    to: nextPage,
    replace: true,
    triggerKey
  });

  const iconButton = {
    type: faqVisible ? 'arrow-right' : 'questionmark',
    className: faqSeen ? '' : 'unclicked-faq-button',
    theme: 'secondary',
    onClick: handleOnHelpClick,
    faq: true,
  };

  const radio = (id, value, triggerKeys) => ({
    name: 'speed',
    id: `speed-option-${id}`,
    defaultChecked: selectedValue === value,
    value,
    onClick: handleChange,
    radioClassName: 'radio--speed',
    autoFocus: isAutoFocus(value, id),
    triggerKeys
  });

  const contentType = detectContentType(insurer)
  const pageName = questionnaire.find(page => page.path === currentPath).name
  const content = generatePageContent(contentType, insurer, incident, false, false, pageName)


  const customHelpLink = content["custom-help-link"]
  const helpLinkText = content["help-link-text"]
  const useCustomLogo = content["use-custom-logo"]
  const legend = content["over-title-text"]
  const heading = content["title"]
  const faqs = content["faqs"]

  const middleLogo = {
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }

  const chooseMiddleComponent = useCustomLogo
    ? <CompanyLogo {...middleLogo} />
    : <ProgressionIndicator steps={3} current={2} />

  return (
    <React.Fragment>
      <FAQ
        faqListProps={faqListProps}
        faqs={faqs}
        faqLink={insurer.helpcenterLink}
        customHelpLink={customHelpLink}
        helpLinkText={helpLinkText}
        faqVisible={faqVisible}
      />
      <Main {...main}>
        <NavBar>
          <Container className="icon-link-container">
            <IconLink {...iconLink([37])} />
            {nextPage && <IconLink {...iconLinkForward([39])} />}
          </Container>
          {chooseMiddleComponent}
          <Container className="faq-button-container">
            <IconButton {...iconButton} />
          </Container>
        </NavBar>
        <GridContainer main={true}>
          <GridColumn className="grid__column--main">
            <Legend>{legend}</Legend>
            <Heading level="1">{heading}</Heading>
          </GridColumn>

          <GridColumn
            justify="flex-end"
            className="grid__column--footer grid__column--radio-group"
          >
            <RadioGroup>
              <Radio {...radio(1, causeDamageCategories.Aanrijding[0], [49])}>
                {causeDamageCategories.Aanrijding[0]}
              </Radio>
              <Radio {...radio(2, causeDamageCategories.Aanrijding[1], [50])}>
                {causeDamageCategories.Aanrijding[1]}
              </Radio>
              <Radio {...radio(3, causeDamageCategories.Aanrijding[2], [51])}>
                {causeDamageCategories.Aanrijding[2]}
              </Radio>
              <Radio {...radio(4, causeDamageCategories.Aanrijding[3], [52])}>
                {causeDamageCategories.Aanrijding[3]}
              </Radio>
            </RadioGroup>
          </GridColumn>
        </GridContainer>
      </Main>
    </React.Fragment>
  );
}

export default CauseDamageAanrijding;
