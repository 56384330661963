import {
  SET_EXCLUDED_PAGES,
  EXCLUDED_PAGES_FOUND_IN_INCIDENT,
  EXCLUDED_PAGES_FOUND_IN_BROWSER_STORAGE,
  UPDATE_EXCLUDED_PAGES,
} from '@domain/action-types';

const pagesExcluded = (state = null, { type, pages }) => {
  switch (type) {
    case SET_EXCLUDED_PAGES:
      return pages;
    case EXCLUDED_PAGES_FOUND_IN_INCIDENT:
      return pages;
    case EXCLUDED_PAGES_FOUND_IN_BROWSER_STORAGE:
      return pages;
    case UPDATE_EXCLUDED_PAGES:
      return pages;
    default:
      return state;
  }
};

export default pagesExcluded;
