import React, { useState } from 'react';

import { Colors, Shadows } from '@domain/theming';
import styled from 'styled-components';

import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { motion } from "framer-motion"
import Button from '../Button';
import DialogButton from '@material-ui/core/Button';
import Container from '../Container';
import Text from '../Text';
import { companyNames, proccessType } from '@domain/constants';
import { useSelector } from 'react-redux';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

const ConsentBanner = styled(motion.div)`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  color: ${Colors.BLACK};
  flex-direction: column;
  > div {
    padding: 30px 40px 50px 40px;
    width: 88%;
    max-width: 1200px;
    margin: auto;
    position: relative;
    top: 30px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background-color: ${Colors.GREY_LIGHT};
    box-shadow: ${Shadows.SHADOW4};
  }
`;

const ContainerStyled = styled(props => <Container {...props} />)`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
`;

const BannerButton = styled(props => <Button {...props} />)`
  width: 120px;
  text-transform: initial;
  flex: 0 1 auto;
  padding: 10px;
  height: auto;
  border-radius: 15px;
  margin-right: 20px;
  display: flex;
`;
const Link = styled.a`
  color: ${Colors.BLACK};
  text-decoration: underline;
  cursor: pointer;
  padding: 10px 0 12px;
`;

const TableStyled = styled(props => <Table {...props} />)`
  margin-bottom: 40px;
`;
const LinkBlock = styled(props => <Link {...props} />)`
  display: block;
`;

const variants = {
  open: {
    opacity: 1,
    transition: {
      duration: .3,
      delay: .3,
    }
  },
  closes: {
    opacity: 0,
    transition: {
      duration: 1,
    }
  }
}


const categories = {
  "Analytics": [{
    category: 'Analytics',
    description: `Google Analytics 4 (GA4) is the next generation of Analytics which collects event-based data from both websites and apps`,
    id: 'ga',
    name: 'Google Analytics 4',
  }],
  "Heatmaps & Recordings": [{
    category: 'Heatmaps & Recordings',
    description: `Hotjar is a heatmap and recording tool for tracking what your users are doing on your website, and where they're doing it`,
    id: 'hotjar',
    name: 'Hotjar',
  }]
}
const ConsentDialogContent = ({
  preferences,
  updatePreferences
}) => (
  <>
    <Container margin="10px 0 20px">
      <Text>
        We gebruiken de data verzameld door de cookies en de JavaScript
        Libraries om uw ervaring met de website te verbeteren, website-verkeer
        te analyzeren, en de algemene prestatie van de website te verhogen.
      </Text>
    </Container>
    <TableStyled size="small">
      <TableHead>
        <TableRow>
          <TableCell>Allow</TableCell>
          <TableCell>Category</TableCell>
          <TableCell>Purpose</TableCell>
          <TableCell>Tool Name</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(categories).map((category) => {
          return (categories[category].map((destination) => (
            <TableRow key={destination.id}>
              <TableCell component="th" scope="row">
                <Checkbox
                  checked={Boolean(preferences[destination.id])}
                  onChange={() => updatePreferences(destination.id)}
                />
              </TableCell>
              <TableCell>{destination.category}</TableCell>
              <TableCell>{destination.description}</TableCell>
              <TableCell>{destination.name}</TableCell>
            </TableRow>
          )))
        })}
      </TableBody>
    </TableStyled>
  </>
);



const Cookie = ({
  preferences,
  updateConsent,
  updatePreferences
}) => {

  const consent = useSelector((state) => state.consentCode)
  const branding = useSelector((state) => state.insurer)

  const [dialogOpen, setDialogOpen] = useState(false);

  const isSG = branding && branding.objectType === companyNames.Schadegarant
  const isDealerDistribution = branding && branding.objectType === proccessType.DealerDistribution
  const isDealerSaas = branding && branding.objectType === proccessType.DealerSass

  const writeKeyHotjar = process.env.REACT_APP_HOTJAR_API_KEY
  const writeKeyGA = process.env.REACT_APP_GA_API_KEY

  if (consent !== null) {
    return <></>
  }
  if (!writeKeyHotjar && !writeKeyGA) {
    return <></>
  }
  return (
    <>
      <ConsentBanner
        animate={'open'}
        variants={variants}
      >
        <Container flexDirection="column">
          <Text>
            We gebruiken cookies (en andere dergelijke technologieën) om
            data te verzamelen zodat wij uw ervaring op onze site kunnen
            verbeteren. Door gebruik te maken van onze website gaat u
            akkoord met het verzamelen van data zoals beschreven in onze{' '}
            <Link
              href="https://www.openclaims.com/wp-content/uploads/2018/05/Privacy-Statement-Openclaims-1.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              {' '}
              Website Data Collection Policy
            </Link>
            .
          </Text>
          {isSG && <Text> Wilt u weten waar Schadegarant uw data voor nodig heeft, klik dan{' '}
            <Link
              href="https://www.schadegarant.nl/privacystatement/"
              rel="noopener noreferrer"
              target="_blank"
            >
              {' '}
              hier
            </Link>.</Text>}
          {isDealerDistribution && <Text> Wilt u weten waar Bovemij uw data voor nodig heeft, klik dan{' '}
            <Link
              href="https://www.bovemij.nl/privacyverklaring"
              rel="noopener noreferrer"
              target="_blank"
            >
              {' '}
              hier
            </Link>.</Text>}
          {isDealerSaas && <Text><br /><b>Wat doen wij met uw persoonsgegevens?</b>
            Als wij een schademelding krijgen leggen wij gegevens vast.
            Wij registreren en verwerken de persoonsgegevens van partijen die betrokken zijn bij de schade. Wij zijn er verantwoordelijk voor dat dat zorgvuldig gebeurt.
            De persoonsgegevens leggen wij ook vast bij de Stichting CIS (Centraal Informatie Systeem).
            Hoe wij omgaan met persoonsgegevens kunt u lezen in onze privacyverklaring op onze{' '}
            <Link
              href="https://www.dealerloketbv.nl"
              rel="noopener noreferrer"
              target="_blank"
            >
              {' '}
              <b>website</b>
            </Link>.</Text>}
          <ContainerStyled>
            <BannerButton type="button" onClick={() => updateConsent({ ga: true, hotjar: true })}>
              Ok
            </BannerButton>
            <LinkBlock onClick={() => setDialogOpen(true)}>
              Click hier om uw voorkeuren te wijzigen
            </LinkBlock>
          </ContainerStyled>
        </Container>
      </ConsentBanner>
      <div>
        <Dialog
          open={dialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Voorkeuren voor dataverzameling van deze site"}</DialogTitle>
          <DialogContent>
            <ConsentDialogContent
              preferences={preferences}
              updateConsent={updateConsent}
              updatePreferences={updatePreferences}
            />
          </DialogContent>
          <DialogActions>
            <DialogButton onClick={() => setDialogOpen(false)} color="primary" autoFocus>
              Annuleren
            </DialogButton>
            <DialogButton onClick={() => {
              updateConsent(preferences)
              // setDialogOpen(false)
            }} color="primary">
              Opslaan
            </DialogButton>

          </DialogActions>
        </Dialog>
      </div>

    </>
  );
};

export default Cookie;
