import { useLayoutEffect } from 'react';

// Always memoise the themes variable when useThemes is called, otherwise the app will remove and add the classnames on every rerender 😱
// GOOD: const themes = useMemo(() => ['some-theme'],[]); useThemes(themes);
// BAD: useThemes(['some-theme']);
// BAD: const themes = ['some-theme']; useThemes(themes);
// ALTERNATIVE: const themes = ['some-theme']; function MyComponent() {useThemes(themes); return <div/ >;}
function useThemes(themes) {
  useLayoutEffect(() => {
    const bodyElement = document.getElementsByTagName('body')[0];
    for (let i = 0; i < themes.length; i += 1) {
      bodyElement.classList.add(themes[i]);
    }
    return function _clean() {
      for (let i = 0; i < themes.length; i += 1) {
        // if (themes[i] !== 'theme-custom') {
        bodyElement.classList.remove(themes[i]);
        // }
      }
    };
  }, [themes]);
}

export default useThemes;
