
export default function chooseLogoSize(size) {
  switch (size) {
    case 'XS':
      return 75;
    case 'S':
      return 85;
    case 'M':
      return 95;
    case 'L':
      return 105;
    case 'XL':
      return 115;
    default:
      return 85;
  }

}