// NPM imports.
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import { Colors } from '@domain/theming';
import { isMobileOnly } from 'react-device-detect';
import { useScaling } from '@domain/hooks';

const StyledLabel = styled.label`
font-size: ${props => !isMobileOnly ? props.$scaling.textSize(16) : props.$scaling.textSizeMobile(16, props.vwWdith)}px;
line-height: ${props => !isMobileOnly ? props.$scaling.textSize(20) : props.$scaling.textSizeMobile(20, props.vwWdith)}px;
  color: ${Colors.BLACK};
  font-weight: bold;
  &.label--text--valid,
  &.label--text--invalid {
    position: relative;
    width: 100%;
    display: inline-block;
  
    &:after {
      position: absolute;
  
      right: 0;
      top: 60px;
    }
  }
  &.label--text--valid:after {
    content: '✔';
    right: 8px;
  }
  &.label--text--invalid:after {
    content: '!';
    right: 14px;
    color: ${Colors.RED};
  }
`;


function Label({ children, className, htmlFor, valid, inputType }) {
  const scaling = useScaling();
  const classes = classNames('label', className, {
    'label--text--valid': valid && inputType === 'text',
    'label--text--invalid': !valid && inputType === 'text'
  });

  return (
    <StyledLabel htmlFor={htmlFor} className={classes} $scaling={scaling}>
      {React.Children.toArray(children)}
    </StyledLabel>
  );
}

Label.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  htmlFor: PropTypes.string.isRequired
};

export default Label;
