import { useSelector, useDispatch } from 'react-redux';
import { BodyshopListsService } from '@domain/services';
import { BODYSHOP_LISTS_FETCHED } from '@domain/action-types';

function useReloadBodyshopList() {
  const token = useSelector(state => state.token);
  const uuid = useSelector(state => state.uuid);
  const incident = useSelector(state => state.incident)
  const dispatch = useDispatch()

  const reloadBodyshopList = async () => {
    try {
      const newBodyshopListsResponse = await BodyshopListsService.getEM(uuid, token, incident)
      const newBodyshopList = newBodyshopListsResponse.carRefinishers;
      if (newBodyshopList.length > 0) {
        dispatch({
          type: BODYSHOP_LISTS_FETCHED,
          bodyshopLists: { carRefinishers: newBodyshopList },
        });
      }
    } catch ({ error }) {
      console.error(`Error occurred inside useReloadBodyshopList trying to retrieve bodyshopList for expertise manager (BodyshopListsService.getEM):`, error);
    }
  };

  return reloadBodyshopList
}

export default useReloadBodyshopList