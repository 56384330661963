import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Domain imports.
import {
  RadioGroup,
  Radio,
  NavBar,
  IconLink,
  ProgressionIndicator,
  IconButton,
  FAQ,
  GridColumn,
  GridContainer,
  Legend,
  Heading,
  Main,
  Container,
  Loader,
  CompanyLogo
} from '@domain/components';
import { useFaq, useRadio, useSaveIncidentFactory } from '@domain/hooks';
import { goToNextPage, getPrevNextPagePath, browserStorage, generatePageContent, chooseLogoSize } from '@domain/helpers';
import { detectContentType } from '@domain/content';
import { useQuestionnaire } from '@domain/providers';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const faqs = [
  {
    question: 'Waarom moet ik de gegevens van de eigenaar opgeven?',
    answer: 'Deze informatie helpt ons met het eventuele verhalen van uw schade. Indien nodig nemen wij ook contact op met de tegenpartij voor extra informatie.',
  },
];
const counterpartyKnown = 'counterpartyKnown';
function AnimalOwnerKnown({ history, match }) {

  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);
  const files = useSelector(state => state.files)
  const { questionnaire } = useQuestionnaire();
  // const questionnaire = useQuestionnaire();
  const [
    faqVisible,
    handleOnHelpClick,
    clickOutSideFaq,
    faqListProps,
    faqSeen,
  ] = useFaq();

  const currentValue = incident.counterpartyKnown;

  const currentPath = match.path;
  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined

  const saveIncident = useSaveIncidentFactory(incident, saveBehavior);

  const isPageBeforeSuccess = questionnaire[questionnaire.length - 2].path === currentPath

  const themeContext = useContext(ThemeContext)

  const saveValue = useCallback(
    async (value) => {
      await saveIncident({ ...incident, [counterpartyKnown]: value }, isPageBeforeSuccess);
    },
    [incident, saveIncident, isPageBeforeSuccess],
  );

  useEffect(() => {
    if (!!questionnaire && questionnaire.length > 0) {
      const progress = questionnaire.find(page => page.path === currentPath).name
      browserStorage.setProgress(progress);
    }
  }, [currentPath, questionnaire])

  const [handleChange, isAutoFocus, selectedValue] = useRadio(
    currentValue,
    counterpartyKnown,
    () => goToNextPage(history, questionnaire, incident, currentPath, files),
    saveValue,
  );

  if (!insurer || !questionnaire) {
    return <Loader />;
  }

  const pagePath = getPrevNextPagePath(
    history,
    questionnaire,
    incident,
    currentPath,
    files
  );
  const nextPage = pagePath.nextPage !== currentPath
    ? pagePath.nextPage
    : undefined;

  const main = {
    faqVisible: faqVisible,
    dimmed: faqVisible,
    className: 'main__damage',
    callBack: clickOutSideFaq,
  };

  const iconLink = (triggerKey) => ({
    type: 'arrow-left',
    theme: 'secondary',
    to: pagePath.prevPage,
    replace: true,
    triggerKey
  });

  const iconLinkForward = (triggerKey) => ({
    type: 'arrow-right',
    theme: 'secondary',
    to: nextPage && nextPage,
    replace: true,
    triggerKey
  });

  const iconButton = {
    type: faqVisible ? 'arrow-right' : 'questionmark',
    className: faqSeen ? '' : 'unclicked-faq-button',
    theme: 'secondary',
    onClick: handleOnHelpClick,
    faq: true,
  };

  const radio = (id, value, triggerKeys) => ({
    name: 'animal-owner',
    id: `animal-owner-option-${id}`,
    defaultChecked: selectedValue === value,
    value,
    onClick: handleChange,
    radioClassName: 'radio--animal-owner',
    autoFocus: isAutoFocus(value, id),
    triggerKeys
  });

  const contentType = detectContentType(insurer)
  const pageName = questionnaire.find(page => page.path === currentPath).name
  const content = generatePageContent(contentType, insurer, incident, false, false, pageName)

  const customHelpLink = content["custom-help-link"]
  const helpLinkText = content["help-link-text"]
  const useCustomLogo = content["use-custom-logo"]
  const legend = content["over-title-text"]
  const heading = content["title"]

  const middleLogo = {
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }

  const chooseMiddleComponent = useCustomLogo
    ? <CompanyLogo {...middleLogo} />
    : <ProgressionIndicator steps={3} current={2} />

  return (
    <React.Fragment>
      <FAQ
        faqListProps={faqListProps}
        faqs={faqs}
        faqLink={insurer.helpcenterLink}
        customHelpLink={customHelpLink}
        helpLinkText={helpLinkText}
        faqVisible={faqVisible} />
      <Main {...main}>
        <NavBar>
          <Container className="icon-link-container">
            <IconLink {...iconLink([37])} />
            {nextPage && <IconLink {...iconLinkForward([39])} />}
          </Container>
          {chooseMiddleComponent}
          <Container className="faq-button-container">
            <IconButton {...iconButton} />
          </Container>
        </NavBar>
        <GridContainer main={true}>
          <GridColumn className="grid__column--main">
            <Legend>{legend}</Legend>

            <Heading level="1">{heading}</Heading>
          </GridColumn>
          <GridColumn
            justify="flex-end"
            className="grid__column--footer grid__column--radio-group"
          >
            <RadioGroup>
              <Radio {...radio(1, 'yes', [106, 74, 49])}>Ja</Radio>
              <Radio {...radio(2, 'no', [110, 78, 50])}>Nee</Radio>
            </RadioGroup>
          </GridColumn>
        </GridContainer>
      </Main>
    </React.Fragment>
  );
}

export default AnimalOwnerKnown;
