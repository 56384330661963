import { NEW_FID_FOUND } from '@domain/action-types';

const initialFID = null;

const fid = (state = initialFID, { type, fid }) => {
  switch (type) {
    case NEW_FID_FOUND:
      return fid;
    default:
      return state;
  }
};

export default fid;
