import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import classNames from 'classnames';
import { useScaling } from '@domain/hooks';

const StyledContainer = styled.div`
  .radio-group {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
  }

  .screen-desktop & {
    width: 100%;
    .radio-group {
      padding-top: ${props => props.$scaling.scaleUpTo4K(16)}px;
      flex: 0 1 0;
      justify-content: flex-end;
      width: ${props => props.$scaling.scaleUpTo4K(512)}px;
      margin-left: auto;
      margin-right: 0;
    }
    .gender--more-opts {
      align-items: center;
      flex-direction: column;
      width: 100%;
    }
  }
  
  @media screen and (max-width: 580px) {
    .screen-desktop & {
      .radio-group {
        width: 100%;
      }
    }
    .gender--more-opts {
      align-items: center;
      flex-direction: column;
      width: 100%;
      .radio--gender {
        width:100%
      }
    }
  }
`;

function RadioGroup({ children, className }) {
  const scaling = useScaling();
  const classes = classNames('radio-group ', className);

  return (
    <StyledContainer className="radio-group-container" $scaling={scaling}>
      <div className={classes}>{React.Children.toArray(children)}</div>
    </StyledContainer>
  );
}

RadioGroup.propTypes = {
  children: PropTypes.any.isRequired
};

export default RadioGroup;
