import { object, string } from 'yup';

const postcodeRegEx = /^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i;
//const houseNumberRegEx = /(^[0-9]+)([\s_-]*)([a-zA-Z0-9\s_-]*)/i;

const validationSchema = object({
  zipCodeOrCity: string()
    .matches(postcodeRegEx, 'Controleer alstublieft postcode.')
    .required()
    .label('Postcode of plaatsnaam')
});

const validationSchemaNotRequired = object({
  zipCodeOrCity: string()
    .label('Postcode of plaatsnaam')
});
export { validationSchema, validationSchemaNotRequired };
