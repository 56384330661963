const carBrandsSchadegarant = [
  "Abarth",
  "Aiways",
  "Alfa Romeo",
  "Aston Martin",
  "Audi",
  "Bentley",
  "BMW",
  "Buick",
  "Cadillac",
  "Chevrolet",
  "Chevrolet USA",
  "Chrysler",
  "Citro\u00ebn",
  "Dacia",
  "Daewoo",
  "Daf",
  "Daihatsu",
  "Daimler",
  "Datsun",
  "De Tomaso",
  "Dodge",
  "DS",
  "Ferrari",
  "Fiat",
  "Ford",
  "Ford USA",
  "Galloper",
  "GMC",
  "Honda",
  "Hummer",
  "Hyundai",
  "Infinity",
  "Isuzu",
  "Iveco",
  "Jaguar",
  "Jeep",
  "Kia",
  "Lada",
  "Lancia",
  "Land Rover",
  "LDV",
  "Lexus",
  "Lincoln",
  "Lotus",
  "Maserati",
  "Maxus",
  "Mazda",
  "Mercedes-Be",
  "Mercedes-Benz",
  "Mercury",
  "MG",
  "Mini",
  "Mitsubishi",
  "Morgan",
  "Nissan",
  "Opel",
  "Peugeot",
  "Polestar",
  "Pontiac",
  "Porsche",
  "Renault",
  "Rover",
  "Saab",
  "Seat",
  "Skoda",
  "Smart",
  "SsangYong",
  "Subaru",
  "Suzuki",
  "Talbot",
  "Tesla",
  "Toyota",
  "Triumph",
  "Volkswagen",
  "Volvo",
  "Overig"
]

export default carBrandsSchadegarant;
