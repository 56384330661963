import React from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useScaling } from '@domain/hooks';
import { isMobileOnly } from 'react-device-detect';

function Item({
  onClick,
  selectable = true,
  selected,
  subtitle,
  title,
}) {
  const scaling = useScaling();
  const useStyles = makeStyles(theme => ({
    root: {
      '&:hover': {
        backgroundColor: alpha(theme.colors.primary, 0.18)
      },
    },
    selected: {
      '&&': {
        backgroundColor: alpha(theme.colors.primary, 0.28),
        '&:hover': {
          backgroundColor: alpha(theme.colors.primary, 0.28)
        }
      }
    },
    primary: {
      fontSize: (!isMobileOnly ? scaling.textSize(16) : scaling.textSizeMobile(16)) + 'px',
      lineHeight: (!isMobileOnly ? scaling.textSize(20) : scaling.textSizeMobile(20)) + 'px',
    },
    secondary: {
      fontSize: (!isMobileOnly ? scaling.textSize(16) : scaling.textSizeMobile(14)) + 'px',
      lineHeight: (!isMobileOnly ? scaling.textSize(16) : scaling.textSizeMobile(18)) + 'px',
    }
  }));

  const classes = useStyles();

  return (
    <ListItem button={selectable} classes={{ root: classes.root, selected: classes.selected }} onClick={onClick} selected={selected}>
      <ListItemText classes={{ primary: classes.primary, secondary: classes.secondary }} primary={title} secondary={subtitle} />
    </ListItem>
  );
}

Item.propTypes = {
  onClick: PropTypes.func,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default Item;
