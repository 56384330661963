import { IMAGE_SELECTED } from '@domain/action-types';

const selectedImage = (state = null, { type, index }) => {
  switch (type) {
    case IMAGE_SELECTED:
      return index;
    default:
      return state;
  }
};

export default selectedImage;
