import { object, array, string, } from 'yup';

const fileSchema = object({
  cpID: string().required(),
  content: string().required(),
});

export const validationSchema = array()
  .of(fileSchema)
  .min(1, 'Voeg alstublieft minimaal één bestand’s toe');

// eslint-disable-next-line no-useless-escape
const priceRegex = /^\d+([\,\.]\d{1,2})?$/
export const validationSchemaTextInput = object({
  calculationTotalPriceInc: string()
    .matches(priceRegex, 'Totale reparatiebedrag moet overeenkomen met het volgende formaat: 000.00')
    .required()
    .label('Totale reparatiebedrag'),
});
