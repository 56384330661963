import React, { useEffect, useState } from 'react';

import Heading from '../Heading';

import { Colors, Shadows } from '@domain/theming';
import PropTypes from 'prop-types';
import { motion } from "framer-motion"
import styled from 'styled-components';
import { useScaling } from '@domain/hooks';
import { browserStorage } from '@domain/helpers';

const StyledBanner = styled(motion.div)`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(0,0,0,0.85);
  box-shadow: ${Shadows.SHADOW3};
  color: ${Colors.WHITE};
  z-index: 10;
  &.white {
    background: rgba(255,255,255,0.8);
    color: ${Colors.BLACK};
  }
  &.transparent {
    background: rgba(0,0,0,0);
  }
`;

const StyledContainer = styled.div`
  padding: ${props => props.$scaling.scaleUpTo4K(30)}px 0;
  flex: 1 1 auto;
`;

const BannerBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 0px;
  min-width: ${props => props.$scaling.scaleUpTo4K(120)}px;
  @media screen and (max-width: 1263px) {
    min-width: 60px;
  }
  @media screen and (max-width: 1028px) {
    min-width: 40px;
  }
`;

const StyledButton = styled.button`
  width: ${props => props.$scaling.scaleUpTo4K(40)}px;
  height: ${props => props.$scaling.scaleUpTo4K(40)}px;
  background: transparent;
  border: 0;
  color: inherit;
  flex: 0 0 auto;
  transition: .2s ease-in-out;
  border-radius: ${props => props.$scaling.scaleUpTo4K(16)}px;
  &:hover, &:focus {
    background: rgba(255,255,255,0.2);
  }
  &:active {
    background: rgba(255,255,255,0.25);
    color: rgba(255,255,255,0.75);
  }
  @media screen and (max-width: 1263px) {
    width: 30px;
    height: 30px;
  }
`;

const variants = {
  open: {
    opacity: 1,
    transition: {
      duration: .3,
      delay: .3,
    }
  },
  close: {
    opacity: 0,
    transition: {
      duration: 1,
    }
  }
}

function Banner({
  content,
  theme,
}) {
  const scaling = useScaling();
  const initialBannerOpen = browserStorage.getBannerOpen()
  const [bannerOpen, setBannerOpen] = useState(initialBannerOpen === 'false' ? false : true);
  useEffect(() => {
    browserStorage.setBannerOpen(bannerOpen)
  }, [bannerOpen])

  return (
    <>
      <StyledBanner
        animate={bannerOpen ? 'open' : 'close'} variants={variants}>
        <BannerBlock $scaling={scaling} />
        <StyledContainer $scaling={scaling}>
          <Heading level="4">{content}</Heading>
        </StyledContainer>
        <BannerBlock $scaling={scaling}>
          <StyledButton onClick={() => setBannerOpen(false)} $scaling={scaling}>X</StyledButton>
        </BannerBlock>
      </StyledBanner>
    </>
  )
};

Banner.propTypes = {
  content: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(['white', 'black', 'transparent']),
}

Banner.defaultProps = {
  content: '',
  theme: 'black',
}

export default Banner;
