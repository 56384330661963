import React from 'react';
import { UseIcon } from '../..';
import { motion } from "framer-motion"
import styled from 'styled-components';
import { Heading } from '@domain/components';

import ImageDialog from '../../ImageDialog';
import { isMobileOnly } from 'react-device-detect';
import { useScaling } from '@domain/hooks';

const paragraphVariants = {
  closed: {
    height: 0,
    overflow: 'hidden'
  },
  open: { height: 'auto' }
}

const imageVariants = {
  closed: {
    height: 0,
    overflow: 'hidden'
  },
  open: {
    height: 'auto',
    cursor: 'pointer'
  }
}

const Explanation = styled(motion.p)`
  font-size: ${props => !isMobileOnly ? props.$scaling.textSize(14) : props.$scaling.textSizeMobile(14, props.vwWdith)}px;
  line-height: ${props => !isMobileOnly ? props.$scaling.textSize(18) : props.$scaling.textSizeMobile(18, props.vwWdith)}px;
`;

const StyledItem = styled.li`
  position: relative;
  min-height: 19px;
  margin-bottom: 10px;
  .instruction__title {
    cursor: pointer;
  }
  .small__image {
    cursor: pointer;
    max-width: 250px;
    margin-top: 10px;
  }
  .instruction__icon {
    position: absolute;
    right: 0;
    top: -7px;
  }
  .screen-desktop & {
    min-height: ${props => props.$scaling.scaleUpTo4K(19)}px;
    margin-bottom: ${props => props.$scaling.scaleUpTo4K(10)}px
    .instruction__explanation {
      max-width: ${props => props.$scaling.scaleUpTo4K(320)}px;
    }
  }
`;

const Instruction = ({
  children,
  title,
  explanation,
  imagePath,
  imageAlt,
  imageClassName,
  isOpen,
  toggleIsOpen
}) => {
  const scaling = useScaling();
  const iconName = isOpen ? 'minus' : 'plus';

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <StyledItem className="instruction" $scaling={scaling}>
        <Heading level="4" className="instruction__title" onClick={toggleIsOpen}>
          {title}
          <UseIcon name={iconName} className="instruction__icon" />
        </Heading>
        {imagePath && <div className="small__image"><motion.img animate={isOpen ? 'open' : 'closed'} variants={imageVariants} className={imageClassName} src={imagePath} alt={imageAlt} onClick={handleOpen} /></div>}
        <Explanation
          className="instruction__explanation"
          variants={paragraphVariants}
          animate={isOpen ? 'open' : 'closed'}
          $scaling={scaling}
        >
          {explanation}
        </Explanation>
      </StyledItem>
      {open && <ImageDialog handleClose={handleClose} open={open} src={imagePath} alt={imageAlt} />}
    </React.Fragment >
  );
};

export default Instruction;
