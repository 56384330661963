import React from 'react';

function FAQItem({ children }) {
  return (
    <React.Fragment>
      <li className="faq__item">{React.Children.toArray(children)}</li>
    </React.Fragment>
  );
}

export default FAQItem;
