const routes = {
  // bodyshop: '/herstel-bedrijf',
  start: '/start',
  success: '/gelukt',
  thankYou: '/afgerond',
  end: '/einde',
  endOfFlow: '/afgelopen'
  // noBodyshop: '/geen-hersteller-gevonden',
};

export default routes;
