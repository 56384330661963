import { object, string } from 'yup';

const validationSchema = object({
  witnessFirstName: string()
    .required()
    .label('Voornaam'),
  witnessLastName: string()
    .required()
    .label('Achternaam'),
});

const validationSchemaNotRequired = object({
  witnessFirstName: string()
    .label('Voornaam'),
  witnessLastName: string()
    .label('Achternaam'),
});

export { validationSchema, validationSchemaNotRequired };