import { BODYSHOP_LISTS_FETCHED } from '@domain/action-types';

const bodyshopLists = (state = null, { type, bodyshopLists }) => {
  switch (type) {
    case BODYSHOP_LISTS_FETCHED:
      return bodyshopLists;
    default:
      return state;
  }
};

export default bodyshopLists;
