import React from 'react';

import { render } from '@testing-library/react';
import { createMemoryHistory } from 'history';
import { Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core';
import { ThemeProvider } from 'styled-components';
import { createCustomTheme } from '@domain/helpers';
import { Colors } from '@domain/theming';
import { QuestionnaireProvider } from '@domain/providers';
import { HelmetProvider } from 'react-helmet-async'
const branding = {
  primaryColor: Colors.GREY_DARKER,
  // secondaryColor: '#FFFFFF',
  // fontFamily: 'Gabriel sans',
  // logoURL: '',
  //  faviconURL: 
};

const theme = createCustomTheme(branding);
const muiDefaultTheme = createTheme();
const muiTheme = { ...muiDefaultTheme, ...theme };


const renderInProvider = (
  ui,
  {
    store = {},
  }
) => {
  const Wrapper = ({ children }) => (
    <Provider store={store}>
      <HelmetProvider>
        <MuiThemeProvider theme={muiTheme}>
          <ThemeProvider theme={theme}>
            {children}
          </ThemeProvider>
        </MuiThemeProvider>
      </HelmetProvider>
    </Provider>
  )
  return {
    ...render(ui, { wrapper: Wrapper })
  }
}

const renderInRouter = (
  ui,
  {
    store = {},
    route = '/',
    history = createMemoryHistory({ initialEntries: [route] }),
    // history = createMemoryHistory(route)
  } = {}
) => {
  const Wrapper = ({ children }) => (
    <Provider store={store}>
      <HelmetProvider>
        <QuestionnaireProvider>
          <MuiThemeProvider theme={muiTheme}>
            <ThemeProvider theme={theme}>
              <Router history={history}>
                {children}
              </Router>
            </ThemeProvider>
          </MuiThemeProvider>
        </QuestionnaireProvider>
      </HelmetProvider>
    </Provider>
  )
  return {
    ...render(ui, {
      wrapper: Wrapper
    }),
    history,
  }
}

const renderInRoute = (
  ui,
  {
    path = "/",
    route = "/",
    store = {},
    history = createMemoryHistory({ initialEntries: [route] }),
  } = {}
) => {

  const Wrapper = ({ children }) => (
    <Provider store={store}>
      <HelmetProvider>
        <MuiThemeProvider theme={muiTheme}>
          <ThemeProvider theme={theme}>
            <Router history={history}>
              <Route path={path} component={ui} />
            </Router>
          </ThemeProvider>
        </MuiThemeProvider>
      </HelmetProvider>
    </Provider>
  )
  return {
    ...render(
      ui, {
      wrapper: Wrapper,
    }
    ),
    history,
  };
}

export default {
  renderInProvider,
  renderInRoute,
  renderInRouter,
}
