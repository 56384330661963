import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BodyshopService } from '@domain/services';
import { proccessType } from '@domain/constants'
import { BODYSHOP_FETCHED, HTTP_ERROR_ENCOUNTERED } from '@domain/action-types';

function useBodyshopLoader() {
  const uuid = useSelector(state => state.uuid);
  const dispatch = useDispatch();
  const incident = useSelector(state => state.incident);
  const bodyshopLoading = useRef(false);
  const token = useSelector(state => state.token);
  const insurer = useSelector(state => state.insurer)

  const isDI = !!insurer && insurer.objectType === proccessType.DI

  const outletUUID = incident
    ? isDI
      ? incident.outletUUID || incident.debiteurNummer
      : incident.outletUUID || incident.carRefinisherUUID || incident.debiteurNummer
    : ''
  useEffect(() => {
    if (bodyshopLoading.current || !insurer) {
      return;
    }

    if (!outletUUID) {
      return;
    }

    if (!uuid) {
      return;
    }

    if (!token) {
      return;
    }

    const getBodyshop = async () => {
      bodyshopLoading.current = true;
      try {
        const newBodyshop = await BodyshopService.get(outletUUID, token);
        dispatch({ type: BODYSHOP_FETCHED, bodyshop: newBodyshop });
      } catch ({ error }) {
        console.error(`Error occurred inside useBodyshopLoader trying to retrieve bodyshop (BodyshopService.get):`, error);
        dispatch({ type: HTTP_ERROR_ENCOUNTERED, error });
      }

      bodyshopLoading.current = false;
    };
    getBodyshop();
  }, [outletUUID, uuid, dispatch, token, insurer]);
}

export default useBodyshopLoader;
