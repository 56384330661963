import { object, string } from 'yup';

export const validationSchema = object({
  customerPolisNumber: string()
    .required()
    .label('Polisnummer'),
});

export const validationSchemaNotRequired = object({
  customerPolisNumber: string()
    .label('Polisnummer'),
});
