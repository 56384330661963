import {
  parseFetchResponseToJson,
  camelCaseToPascalCase,
} from '@domain/helpers';
import { browserStorage } from '@domain/helpers';

import { endpoints } from '@domain/constants';
const incidentEndpoint = endpoints.incidents;

function get(uuid, token) {
  const url = incidentEndpoint;
  const config = {
    method: 'GET',
    headers: camelCaseToPascalCase({
      token,
      uuid,
    }),
  };

  return fetch(url, config).then((response) =>
    parseFetchResponseToJson(response, { endpoint: incidentEndpoint }));
}

function save(incident) {
  const body = JSON.stringify(camelCaseToPascalCase(incident))
  const config = {
    method: 'POST',
    headers: camelCaseToPascalCase({
      token: browserStorage.getAuthenticationToken(),
      'Content-Type': 'application/json',
    }),
    body: body,
  };

  return fetch(incidentEndpoint, config).then((response) =>
    parseFetchResponseToJson(response, { endpoint: incidentEndpoint, body }));
}

export default {
  endpoints,
  get,
  save,
};
