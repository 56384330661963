import { object, string } from 'yup';

export default object({
  vehicleManufacturerName: string()
    .required()
    .label('Merk'),
  vehicleModelName: string()
    .required()
    .label('Model'),
});
