import {
  SP_DETAILS_DETECTED,
  SP_SG_DETAILS_DETECTED,
  SP_FOUND_IN_BROWSER_STORAGE,
} from '@domain/action-types';

const initialState = false;

const spCase = (state = initialState, { type, incident }) => {
  switch (type) {
    case SP_DETAILS_DETECTED:
      return true;
    case SP_SG_DETAILS_DETECTED:
      return true;
    case SP_FOUND_IN_BROWSER_STORAGE:
      return true
    default:
      return state;
  }
};

export default spCase;
