import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';

import Start from './Start';
import License from './License';
import Name from './Name';
import Postal from './Postal';
import Address from './Address';
import Contact from './Contact';
import Gender from './Gender'
import GenderCompleteOptions from './GenderCompleteOptions'

import routes from './routes';
import { useScaling } from '@domain/hooks';
import { useQuestionnaire } from '@domain/providers';
import { checkforValidPath } from '@domain/helpers';
import { useSelector } from 'react-redux';

const PersonalStyle = createGlobalStyle`
  .main__personal {
    .h1 {
      margin-bottom: 42px;
    }
  }

  .screen-desktop & {
    .main__personal {
      .legend {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(24)}px;
      }
      .h1 {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(70)}px;
        // max-width: ${props => props.$scaling.scaleUpTo4K(448)}px;
      }
    }
    .main__personal--gender {
      .h1 {
        // so that the heading is at the same vertical position then on the textInputPage before
        // margin-bottom: ${props => props.$scaling.scaleUpTo4K(93)}px;
      }
    }
  }

  @media screen and (max-width: 1028px)  {
    .screen-desktop & {
      .main__personal {
        .h1 {
          margin-bottom: 50px;
          // max-width: none;
        }
      }
    }
    .screen-ie & {
      .main__personal {
        .legend {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
  
  @media screen and (max-width: 580px) {
    .screen-desktop & {
      .form {
        flex-wrap: wrap;
        .form-input {
          flex: 1 1 100%;
          padding: 0 !important;
        }
      }
    }
    .screen-ie & {
      .main__personal {
        .legend {
          text-align: left;
        }
      }
    }
  }
`;

function Personal({ history, match, location }) {
  const scaling = useScaling();
  const currentPath = match.path;

  const { questionnaire } = useQuestionnaire();

  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);
  const files = useSelector(state => state.files)

  const validPath = !!questionnaire && !!questionnaire.find(page => page.path === location.pathname)
  const newValidPath = checkforValidPath(incident, questionnaire, files, insurer).path

  return (
    <>
      <Switch>
        {(!!questionnaire && questionnaire.length && !validPath) && <Redirect to={newValidPath} />}
        <Route exact path={currentPath + routes.start} component={Start} />
        <Route exact path={currentPath + routes.license} component={License} />
        <Route exact path={currentPath + routes.name} component={Name} />
        <Route exact path={currentPath + routes.contact} component={Contact} />
        <Route exact path={currentPath + routes.postal} component={Postal} />
        <Route exact path={currentPath + routes.address} component={Address} />
        <Route exact path={currentPath + routes.gender} component={Gender} />
        <Route exact path={currentPath + routes.genderCompleteOptions} component={GenderCompleteOptions} />
        <Redirect to={currentPath + routes.start} />
      </Switch>
      <PersonalStyle $scaling={scaling} />
    </>
  );
}

export default Personal;
