import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl'

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
  },
  checkedIcon: {
    backgroundColor: theme.colors.primary + ' !important',
  },
}));

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

export default function OptionsRadios({ options, value, handleChange }) {


  const generateRadios = options.map((radio) => {
    return (
      <FormControlLabel key={radio.name} value={radio.name} control={<StyledRadio color={"default"} />} label={radio.label} />
    )

  })

  return (
    <FormControl component="fieldset">
      <RadioGroup value={value} onChange={handleChange}>
        {generateRadios}
      </RadioGroup>
    </FormControl>
  );
}