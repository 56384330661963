const routes = {
  instructions: '/instructies',
  start: '/welkom',
  chauffeur: '/specialist', //if no chauffeur, jump over (maybe invalidaton check in questionnaire)
  overview: '/overzicht',
  // agreementRecovery: '/akkoord-herstel',
  replacementVehicle: '/controle-vervangend-vervoer',
  ownRisk: '/eigen-risico-afrekenen',
  questions: '/overige-vragen',
  // signing: '/ondertekenen'
  end: '/afgerond', //thank you page here please
  success: '/success',
};

/*

const routesNotOnLocation = {
  instructions: '/instructies',
  start: '/welkom',
  specialist: '/specialist',
  overview: '/overzicht', // w/ coreresponding content
  replacementVehicle: '/controle-vervangend-vervoer',
  ownRisk: '/eigen-risico-afrekenen',
  // signing: '/ondertekenen'
};

*/


export default routes;
